import {
  all, put, takeLatest, select,
} from 'redux-saga/effects';
import _ from 'lodash';

import {
  IGetSourceStatistic,
  IGetReservesBySource,
  IGetSourceInfo,
} from '../actions/sourceStatistic/ISourceStatisticActions';
import {
  GET_SOURCE_STATISTIC_REQUEST,
  GET_RESERVES_BY_SOURCE_REQUEST,
  GET_SOURCE_INFO_REQUEST,
} from '../actions/sourceStatistic/SourceStatisticActionsTypes';
import {
  getReservesBySourceSuccess,
  getReservesBySourceError,
  getSourceStatisticSuccess,
  getSourceStatisticsError,
  getSourceInfoSuccess,
} from '../actions/sourceStatistic/SourceStatisticActions';
import { getSourceStatisticReq, getAllBookingBySource } from '../api';
import { IGetReservesData } from '../types/IApiResponse';
import { parseReserveList } from '../helpers/reserveHelpers';
import { TReserve } from '../types/IBookingStore';
import { customErrorShackBar } from '../actions/snackbars/SnackBarsActions';

/** @description Сага для получения статистики выбранного источника */
export function* getSourceStatSaga(action: IGetSourceStatistic) {
  const restaurantId = yield select(state => state.cabinet.currentRestaurant.id);
  const defaultDate = yield select(state => state.cabinet.StatisticDate.value);
  const { source, date } = action.payload;
  const period = (date && date.value) || defaultDate;

  try {
    const stats = yield getSourceStatisticReq(restaurantId, period, source);
    yield put(getSourceStatisticSuccess(stats));
  } catch (e) {
    yield put(getSourceStatisticsError());
    yield put(customErrorShackBar('Не удалось получить статистику'));
  }
}

/** @description Сага для получения резервов выбранного источника */
export function* getReservesBySource(action: IGetReservesBySource) {
  const restaurantId = yield select(state => state.cabinet.currentRestaurant.id);
  const defaultPeriod = yield select(state => state.cabinet.StatisticDate.value);

  const {
    source, size, page, sort, date,
  } = action.payload;

  try {
    const response: IGetReservesData = yield getAllBookingBySource(
      restaurantId, source, (date && date.value) || defaultPeriod, sort, size, page,
    );

    const parseReserves: Array<TReserve> = response.content.map((i: TReserve) => parseReserveList(i));
    const parsePage = {
      current: response.number,
      size: response.size,
      first: response.first,
      last: response.last,
      totalPages: response.totalPages,
      totalElements: response.totalElements,
    };

    // --------Костыль------- //
    const reserve = _.find(parseReserves, (r: TReserve) => r.source.id === action.payload.source);

    if (reserve) {
      yield put(getSourceInfoSuccess({
        lastReserve: '',
        createdDate: '',
        name: reserve.source.name,
        isActive: true,
        description: '—',
      }));
    } else {
      // TODO Костыль для костыля выше
      yield put(getSourceInfoSuccess({
        lastReserve: '',
        createdDate: '',
        name: '—',
        isActive: true,
        description: '—',
      }));
      yield put(customErrorShackBar('Не удалось получить информацию по источнику'));
    }
    // --------Костыль------- //

    yield put(getReservesBySourceSuccess(parseReserves, parsePage));
  } catch (e) {
    yield put(getReservesBySourceError());
    yield put(customErrorShackBar('Не удалось получить список резервов'));
  }
}

// TODO Нужно реализовать получение информации источника с бэка
/** @description Сага для получения инормации выбранного источника */
export function* getSourceInfo(action: IGetSourceInfo) {
  // const reserveList = yield select(state => state.statistic.reserves);
  // const reserve = _.find(reserveList, (r: TReserve) => r.source.key === action.payload.source);
  //
  // if (reserve) {
  //   yield put(getSourceInfoSuccess({
  //     lastReserve: '',
  //     createdDate: '',
  //     name: reserve.source.name,
  //     isActive: true,
  //     description: '',
  //   }));
  // } else {
  //   yield put(customErrorShackBar('Не удалось получить информацию по источнику'));
  // }
}

export default function* saga() {
  yield all([
    takeLatest(GET_SOURCE_STATISTIC_REQUEST, getSourceStatSaga),
    takeLatest(GET_RESERVES_BY_SOURCE_REQUEST, getReservesBySource),
    takeLatest(GET_SOURCE_INFO_REQUEST, getSourceInfo),
  ]);
}
