import 'moment/locale/ru';

import { DELETE_RESTAURANT_REQUEST } from '../actions/restaurant/RestaurantsActionTypes';
import { IHomeStore } from '../types/IHomeStore';
import { IAppActions } from '../types/IAppActions';
import * as HomeActionTypes from '../actions/home/HomeActionTypes';

export const initState: IHomeStore = {
  connectError: null,
  connectStatus: false,
  organizationList: [],
  pinCodeModal: false,
  getStatisticError: false,
  getAttendanceProcess: false,
  getAttendanceError: false,
  currentRestaurantStatistic: undefined,
  currentRestaurantAttendance: undefined,
  pinCode: undefined,
  timeLeft: null,
  connectProcess: false,
};

export default function reducer(state = initState, action: IAppActions): IHomeStore {
  switch (action.type) {
    case HomeActionTypes.CONNECT_RESTAURANT_SUCCESS:
      return {
        ...state,
        connectStatus: true,
        connectProcess: false,
        connectError: null,
      };

    case HomeActionTypes.RESET_CONNECT_STATUS:
      return {
        ...state,
        connectStatus: false,
      };

    case HomeActionTypes.AUTH_FORM_IIKO_SUCCESS:
      return {
        ...state,
        organizationList: action.payload,
      };

    case HomeActionTypes.OPEN_PIN_CODE_MODAL_STATE: {
      return {
        ...state,
        pinCodeModal: true,
      };
    }

    case HomeActionTypes.CLOSE_PIN_CODE_MODAL_STATE: {
      return {
        ...state,
        pinCodeModal: false,
      };
    }

    case DELETE_RESTAURANT_REQUEST: {
      return {
        ...state,
        timeLeft: null,
        pinCode: undefined,
      };
    }

    case HomeActionTypes.GET_RESTAURANT_PIN_CODE_SUCCESS: {
      return {
        ...state,
        pinCode: action.payload.pinCode,
        timeLeft: action.payload.timeLeft,
      };
    }

    case HomeActionTypes.GET_STATISTIC_REQUEST: {
      return {
        ...state,
      };
    }

    case HomeActionTypes.GET_STATISTIC_SUCCESS: {
      return {
        ...state,
        currentRestaurantStatistic: action.payload,
        getStatisticError: true,
      };
    }

    case HomeActionTypes.GET_STATISTIC_ERROR: {
      return {
        ...state,
        getStatisticError: true,
      };
    }

    case HomeActionTypes.GET_ATTENDANCE_REQUEST: {
      return {
        ...state,
        getAttendanceProcess: true,
      };
    }

    case HomeActionTypes.GET_ATTENDANCE_SUCCESS: {
      return {
        ...state,
        currentRestaurantAttendance: action.payload,
        getAttendanceProcess: true,
        getAttendanceError: false,
      };
    }

    case HomeActionTypes.GET_ATTENDANCE_ERROR: {
      return {
        ...state,
        getAttendanceProcess: false,
        getAttendanceError: true,
      };
    }

    default:
      return state;
  }
}
