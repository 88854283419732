import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import EmployeeComponent from '../../../components/Employees/Employee';
import NotFoundComponent from '../../../components/Employees/Employee/NotFound';
import { Employees } from '../../../entities';
import { Routers } from '../../../config/constants';


export default function EmployeeContainer() {
  const { isLoading, data: employees } = Employees.useEmployees();
  const { data: currentEmployee } = Employees.useCurrentEmployee();
  const history = useHistory();
  const params = useParams<{employeeId: string}>();
  const deleteMutation = Employees.useDeleteMutation();
  const resetPasswordMutation = Employees.useResetPasswordMutation();

  useEffect(() => {
    if (deleteMutation.status === 'success') {
      history.push(Routers.employees);
    }
  }, [deleteMutation.status]);

  if (isLoading) {
    return null;
  }

  const id = parseInt(params.employeeId, 10);
  const employee = employees && employees[id];

  if (!employee) {
    return <NotFoundComponent />;
  }

  const canBeDeleted = Employees.employeeCanBeDeleted(id, employees);
  const isCurrentEmployee = (currentEmployee && currentEmployee.id === employee.id) || false;

  const onEdit = () => history.push(`/employees/${id}/edit`);
  const onResetPassword = () => resetPasswordMutation.mutate({ id });
  const onDelete = () => {
    if (canBeDeleted) {
      deleteMutation.mutate({ id });
    }
  };

  return (
    <EmployeeComponent
      employee={employee}
      onEdit={onEdit}
      onResetPassword={isCurrentEmployee || !Employees.isActive(employee) ? undefined : onResetPassword}
      onDelete={isCurrentEmployee ? undefined : onDelete}
      canBeDeleted={canBeDeleted}
    />
  );
}
