import React, { useState } from 'react';
import moment from 'moment';

import {
  Grid,
  Paper,
  Divider,
  Box,
  makeStyles,
  Typography,
  Theme,
} from '@material-ui/core';

import {
  EditOutlined,
  Delete,
  LockOpen,
  AccountCircle,
} from '@material-ui/icons';

import locales from '../../../locales';
import { primaryColor } from '../../../theme';
import Title from '../../Common/Title';
import ContextButtonComponent from '../../Common/Button/Context';
import RoleDescription from './RoleDescription';
import DeleteDialog from '../Dialog/Delete';
import { Employees } from '../../../entities';


const useStyles = makeStyles((theme: Theme) => {
  const spacing1 = theme.spacing(1);
  const spacing2 = theme.spacing(2);
  const spacing3 = theme.spacing(3);

  return {
    user: {
      // padding: `${spacing2}px ${spacing3}px`,
      padding: spacing2,
      marginBottom: theme.spacing(4),
    },

    role: {
      display: 'inline-block',
      backgroundColor: '#e7ebee',
      padding: `3px ${spacing2}px 2px`,
      marginBottom: spacing2,
    },

    // @todo Специальный блок со стилями только из-за того,
    //   что цвет через прозрачность сделан
    roleText: {
      opacity: 0.7,
      fontSize: 16,
      textTransform: 'uppercase',
    },

    avatar: {
      color: 'rgb(192, 192, 192)',
      fontSize: 56,
      marginTop: -5,
      marginRight: spacing3,
    },

    contact: {
      marginBottom: 2,
      color: '#546e7a',
    },

    status_active: {
      fontWeight: 500,
      marginTop: spacing2,
      marginBottom: spacing1,
      textTransform: 'uppercase',
      color: primaryColor,
    },

    status_disabled: {
      fontWeight: 500,
      marginTop: spacing2,
      marginBottom: spacing1,
      textTransform: 'uppercase',
    },

    dates: {
      color: '#aaaaaa',
      fontSize: 12,
    },

    innerSystemTitle: {
      color: '#aaaaaa',
      marginBottom: spacing1,
    },

    innerSystemValue: {
      fontWeight: 500,
      fontSize: 16,
    },
  };
});

const EmployeeComponent: React.FC<{
  employee: GM.Employees.Employee;
  onEdit: () => void;
  onResetPassword?: () => void;
  onDelete?: () => void;
  canBeDeleted: boolean;
}> = ({
  employee,
  onEdit,
  onResetPassword,
  onDelete,
  canBeDeleted,
}) => {
  const classes = useStyles();
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const closeDeleteDialog = () => setDeleteDialogOpen(false);
  const [isUnableToDeleteAdminAlert, setUnableToDeleteAdminAlert] = useState(false);

  return (
    <>
      <Grid item md={6} xs={12}>
        <Paper elevation={0}>
          <Box className={classes.user}>
            <Box className={classes.role}>
              <span className={classes.roleText}>
                {locales.t('employees.label')}
              </span>
            </Box>

            <Box display="flex">
              <Box flexShrink={0}>
                <AccountCircle className={classes.avatar} />
              </Box>

              <Box flexShrink={1}>
                <Typography gutterBottom variant="h6">
                  {Employees.getName(employee)}
                </Typography>

                {employee.email && (
                  <Box className={classes.contact}>
                    {employee.email}
                  </Box>
                )}

                {employee.phone && (
                  <Box className={classes.contact}>
                    {employee.phone}
                  </Box>
                )}

                {(employee.status == 'ACTIVE' && (
                  <Box className={classes.status_active}>
                    {locales.t('employees.status_active')}
                  </Box>
                )) || (
                  <Box className={classes.status_disabled}>
                    {locales.t('employees.status_disabled')}
                  </Box>
                )}

                <Box className={classes.dates}>
                  {employee.createdDate && (
                    <div>
                      {locales.t('employees.registered')}
                      {': '}
                      {moment(employee.createdDate).format('LLL')}
                    </div>
                  )}

                  {employee.lastModifiedDate && (
                    <div>
                      {locales.t('employees.modified')}
                      {': '}
                      {moment(employee.lastModifiedDate).format('LLL')}
                    </div>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>

          <Divider />

          {/*
          <Box p={3}>
            <div className={classes.innerSystemTitle}>
              {locales.t('employees.properties.iikoEmployee')}
              :
            </div>
            <div className={classes.innerSystemValue}>
              Сидорова Валерия
            </div>
          </Box>

          <Divider />
          */}

          <Box p={2}>
            <Box>
              <ContextButtonComponent
                startIcon={<EditOutlined />}
                onClick={onEdit}
                title={locales.t('employees.edit')}
              />
            </Box>

            {onResetPassword && (
              <Box>
                <ContextButtonComponent
                  startIcon={<LockOpen />}
                  onClick={onResetPassword}
                  title={locales.t('employees.reset_password')}
                />
              </Box>
            )}

            {onDelete && (
              <>
                <Box>
                  <ContextButtonComponent
                    startIcon={<Delete />}
                    title={locales.t('employees.delete')}
                    color={canBeDeleted ? 'inherit' : 'secondary'}
                    onClick={() => {
                      if (canBeDeleted) {
                        setDeleteDialogOpen(true);
                      } else {
                        setUnableToDeleteAdminAlert(true);
                      }
                    }}
                  />
                </Box>

                {isDeleteDialogOpen && (
                  <DeleteDialog onClose={closeDeleteDialog} onDelete={onDelete} />
                )}

                {isUnableToDeleteAdminAlert && (
                  <Employees.UnableToDeleteAlert onClose={() => setUnableToDeleteAdminAlert(false)} />
                )}
              </>
            )}
          </Box>
        </Paper>
      </Grid>
      <Grid item md={6} xs={12}>
        <Paper elevation={0}>
          <Title
            text={locales.t('employees.properties.authority')}
            borderBottom
          />

          {employee.authorities && employee.authorities.map(id => (
            <RoleDescription
              key={id}
              title={locales.t(`employees.properties.authorities.${id}.title`)}
              list={locales.t(`employees.properties.authorities.${id}.list`).split('.')}
              isDivider
              isReadonly
            />
          ))}
        </Paper>
      </Grid>
    </>
  );
};

export default EmployeeComponent;
