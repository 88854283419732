import React, { Fragment } from 'react';
import wrapSvgPath from './wrapSvgPath';

const userIconPath = (
  <Fragment>
    <path id="user-filled" fill="#c5c5c5" d="M32 16A16 16 0 1 0 5.193 27.786l-.015.013.519.438c.034.029.07.052.1.08.276.229.561.446.852.656.094.068.189.136.285.2q.466.321.955.61c.071.042.143.083.214.123.357.2.721.394 1.094.57l.083.037a15.856 15.856 0 0 0 3.87 1.225l.107.019c.421.073.847.131 1.278.17l.158.012c.429.035.863.058 1.3.058s.866-.023 1.293-.057l.162-.012q.641-.058 1.267-.167l.109-.02a15.855 15.855 0 0 0 3.815-1.2l.133-.061q.538-.253 1.054-.545.128-.072.255-.147c.313-.184.621-.377.92-.582.108-.073.213-.151.319-.227.255-.184.506-.374.75-.572.054-.044.112-.081.165-.126l.532-.445-.016-.013A15.959 15.959 0 0 0 32 16zM1.164 16a14.836 14.836 0 1 1 24.675 11.091 4.36 4.36 0 0 0-.52-.312l-4.926-2.463a1.29 1.29 0 0 1-.717-1.16v-1.72c.114-.141.234-.3.359-.475a11.856 11.856 0 0 0 1.522-2.981 2.112 2.112 0 0 0 1.213-1.914V14a2.117 2.117 0 0 0-.516-1.378V9.91a4.692 4.692 0 0 0-1.1-3.412C20.087 5.274 18.351 4.655 16 4.655s-4.087.62-5.159 1.843a4.693 4.693 0 0 0-1.1 3.412v2.715A2.12 2.12 0 0 0 9.229 14v2.063a2.116 2.116 0 0 0 .78 1.641 10.932 10.932 0 0 0 1.8 3.717v1.684a1.3 1.3 0 0 1-.676 1.139l-4.6 2.509a4.2 4.2 0 0 0-.438.277A14.8 14.8 0 0 1 1.164 16z" />
  </Fragment>
);

export default wrapSvgPath(userIconPath, '0 0 32 32');
