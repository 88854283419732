import React, { PureComponent, Fragment } from 'react';
import Typography from '@material-ui/core/Typography/Typography';
import _ from 'lodash';

import Grid from '@material-ui/core/Grid/Grid';

import { IProps, IState } from './IStatistics';
import { IDate } from '../../types/IDate';
import locales from '../../locales';
import PageHeaderWrap from '../../components/Common/PageHeaderWrap';
import PageContentWrap from '../../components/Common/PageContentWrap';
import StatisticTable from '../../components/Statistics/StatisticsTable';
import StatisticsDiagram from '../../components/Statistics/StatisticsDiagram';
import StatisticsReserveTable from '../../components/Statistics/StatisticsReserveTable';
import CashBoxWarningCont from '../CashBoxWarning';
import StatisticDateCont from '../StatisticDateCont';
import { NoHostessWarning } from '../../features';

class StatisticsCont extends PureComponent<IProps, IState> {
  componentDidMount(): void {
    const {
      getStatistics, currentRestaurantId,
    } = this.props;
    if (currentRestaurantId) {
      getStatistics({ orderBy: 'date', order: 'desc' }, 0, 10);
    }
  }

  /** @description Запрос списка резервов в случае изменения текущего ресторана */
  componentDidUpdate(prevProps: IProps) {
    const { currentRestaurantId, getStatistics } = this.props;

    if (currentRestaurantId && _.isUndefined(prevProps.currentRestaurantId)) {
      getStatistics({ orderBy: 'date', order: 'desc' }, 0, 10);
    } else if (currentRestaurantId !== prevProps.currentRestaurantId) {
      getStatistics({ orderBy: 'date', order: 'desc' }, 0, 10);
    }
  }

  /** @description Ручка для изменения периода выборки статистики */
  handleGetStatistics = (date: IDate): void => {
    const { getStatistics } = this.props;

    getStatistics(
      { orderBy: 'date', order: 'desc' },
      0,
      10,
      date,
    );
  };

  /** @description Ручка для преключения страниц и редактирования количества строк */
  handleGetReserves = (page: number, size: number, sortType: 'desc' | 'asc') => {
    const { getStatistics } = this.props;

    getStatistics({ orderBy: 'date', order: sortType }, page, size);
  };

  /** @description Открытие детальной инфы резерва */
  handleOpenBookingDetails = (bookingId: number) => {
    const { openBookingDetails } = this.props;

    openBookingDetails(bookingId);
  };

  render() {
    const {
      pie, summary, reserves, pages, bookingsOrders, moneySymbol,
    } = this.props;

    return (
      <Fragment>
        <PageHeaderWrap>
          <Typography variant="h6">
            { locales.t('statistics.title') }
          </Typography>
          <StatisticDateCont handleGetStatistics={this.handleGetStatistics} />
        </PageHeaderWrap>
        <CashBoxWarningCont />
        <NoHostessWarning />

        <PageContentWrap fullWidth>
          <Grid item xs={12} lg={8}>
            <StatisticTable statistic={summary} moneySymbol={moneySymbol} />
          </Grid>
          <Grid item xs={6} lg={4}>
            <StatisticsDiagram pie={pie} />
          </Grid>
          <Grid item xs={12} lg={12}>
            <StatisticsReserveTable
              bookingsOrders={bookingsOrders}
              reserves={reserves}
              pages={pages}
              handleGetReserves={this.handleGetReserves}
              handleOpenBookingDetails={this.handleOpenBookingDetails}
              moneySymbol={moneySymbol}
            />
          </Grid>
        </PageContentWrap>
      </Fragment>
    );
  }
}

export default StatisticsCont;
