import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';

import { IAuthContProps } from './IAuthCont';
import SigInForm from '../../components/Auth/SigInForm';
import UseRules from '../../components/Auth/CommonAuth/UseRules';
import Logo from '../../components/Auth/CommonAuth/Logo';
import AuthWrapComp from '../../components/Auth/AuthWrapComp';
import { Routers } from '../../config/constants';
import locales from '../../locales';

class AuthCont extends Component<IAuthContProps, {}> {
  componentDidMount(): void {
    const { getSession, authorized } = this.props;

    if (!authorized) {
      getSession();
    }
  }

  /** @description Ручка для авторизации */
  handleSignIn = (value: any) => {
    const { email, password, remember } = value;
    const { signIn } = this.props;
    signIn(email, password, remember);
  };

  render() {
    const { error, authorized } = this.props;

    const errorText = error
      && (
        <Typography style={{ margin: '20px 0 20px 0' }} color="error" variant="subtitle1">
          { error }
        </Typography>
      );

    if (authorized) {
      return <Redirect to={Routers.home} />;
    }

    return (
      <AuthWrapComp>
        <Logo text={locales.t('common.loginWelcome')} />
        <SigInForm onSubmit={this.handleSignIn} />
        { errorText }
        <UseRules />
      </AuthWrapComp>
    );
  }
}

export default AuthCont;
