import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import StatisticDateCont from './StatisticDateCont';
import {
  switchCustomDateModal,
  changeCustomDate,
} from '../../actions/cabinet/CabinetActions';
import { IAppStore } from '../../types/IAppStore';
import { IStateProps, IDispatchProps } from './IStatisticDate';
import { IDate } from '../../types/IDate';

function mapStateToProps(state: IAppStore, onwProps: { handleGetStatistics: (date: IDate) => void; }): IStateProps {
  return {
    handleGetStatistics: onwProps.handleGetStatistics,
    customDateModalOpen: state.cabinet.StatisticCustomDateModalOpen,
    date: state.cabinet.StatisticDate,
  };
}

function mapDispatchToProps(dispatch: Dispatch): IDispatchProps {
  return bindActionCreators({
    switchCustomDateModal,
    changeCustomDate,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(StatisticDateCont);
