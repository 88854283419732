import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import ResetPassword from './ResetPassword';
import { StateProps, DispatchProps } from './IResetPassord';
import { restorePassword } from '../../actions/restorePassword/RestorePasswordActions';
import { IAppActions } from '../../types/IAppActions';
import { IAppStore } from '../../types/IAppStore';

function mapStateToProps(state: IAppStore): StateProps {
  return {
    restorePasswordError: state.restorePassword.restorePasswordError,
    userEmail: state.restorePassword.userEmail,
    restorePasswordSuccess: state.restorePassword.restorePasswordSuccess,
  };
}

function mapDispatchToProps(dispatch: Dispatch<IAppActions>): DispatchProps {
  return bindActionCreators({
    restorePassword,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
