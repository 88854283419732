import React from 'react';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import locales from '../../locales';

interface IProps {
  handleOpenConnectModal: () => void;
}

const FirstStepConnectSystemMessage: React.FC<IProps> = ({ handleOpenConnectModal }) => (
  <Paper elevation={3} style={{ padding: 24 }}>
    <div style={{ paddingBottom: 24 }}>
      <Typography
        gutterBottom
        variant="h6"
      >
        { locales.t('home.connectAutomationSystemTitle') }
      </Typography>
      <Typography
        variant="body1"
        color="textSecondary"
        gutterBottom
      >
        { locales.t('home.connectAutomationSystemText') }
      </Typography>
    </div>
    <Button
      type="submit"
      variant="contained"
      color="primary"
      onClick={handleOpenConnectModal}
    >
      { locales.t('home.connectBtn') }
    </Button>
  </Paper>
);

export default FirstStepConnectSystemMessage;
