import { all } from 'redux-saga/effects';

import authSaga from './AuthSagas';
import restorePasswordSaga from './RestorePasswordSagas';
import reservationsPageSagas from './ReservationsPageSagas';
import bookingSaga from './BookingSagas';
import cardSettingsSaga from './RestaurantsTables';
import cabinetSaga from './CabinetSagas';
import homeSaga from './HomeSagas';
import menuSaga from './MenuSagas';
import promotionSaga from './PromoSagas';
import statisticSaga from './StatisticSagas';
import userStatistic from './UserStatisticSagas';
import restaurantSaga from './RestaurantSaga';
import restaurantsInfoSagas from './RestaurantsInfoSagas';
import restaurantsSettingsSaga from './RestaurantsSettingsSaga';
import restaurantsSectionsSaga from './RestaurantsSectionsSaga';
import sourceStatistic from './SourceStatisticSaga';
import snackbarsSaga from './SnackbarsSagas';
import schedulesSaga from './SchedulesSaga';
import photoSaga from './PhotoSagas';
import websocketSaga from './WebSocketSaga';
import notification from './NotificationSaga';
import widgetsSaga from './WidgetsSaga';
import restaurantsCallsSaga from './RestaurantsCallsSagas';
import NotificationsSaga from './NotificationsSaga';
import AppReservesSaga from './AppReservesSaga';

export default function* rootSaga() {
  yield all([
    authSaga(),
    cabinetSaga(),
    reservationsPageSagas(),
    homeSaga(),
    restaurantSaga(),
    restaurantsInfoSagas(),
    restaurantsSettingsSaga(),
    restaurantsSectionsSaga(),
    cardSettingsSaga(),
    bookingSaga(),
    snackbarsSaga(),
    statisticSaga(),
    sourceStatistic(),
    restorePasswordSaga(),
    menuSaga(),
    promotionSaga(),
    userStatistic(),
    schedulesSaga(),
    photoSaga(),
    websocketSaga(),
    notification(),
    widgetsSaga(),
    restaurantsCallsSaga(),
    NotificationsSaga(),
    AppReservesSaga(),
  ]);
}
