import { every } from 'lodash';

import {
  IWidgetAdapt, IWidgetColorName,
  IWidgetDataAdapt,
} from '../../../types/IRestaurantWidgetsStore';
import { fallbackValue } from '../../../helpers/widget/constants';

export function transformData(data?: IWidgetAdapt): IWidgetDataAdapt | null {
  if (!data) return null;
  const {
    id, url, status, refererUrl, createdDate, lastModifiedDate, ...settings
  } = data;
  return { ...settings };
}

export function getDefaultColor(name: IWidgetColorName) {
  return fallbackValue.settings ? fallbackValue.settings.colors[name] : null;
}

export function isValidColor(value?: string): boolean {
  const rule = new RegExp(/^#([a-f0-9]{3}){1,2}\b$/i);
  return rule.test(value || '');
}

export const isValidName = (value?: string | null) => String(value || '').trim().length > 0;

/**
 * @description: Validates name and settings.colors
 */
export function isValid(values: IWidgetDataAdapt): boolean {
  const colorFields: IWidgetColorName[] = [
    'mainColor',
    'formBackground',
    'pageBackground',
    'formHeaderColor',
    'formBaseColor',
    'formFieldColor',
  ];
  if (isValidName(values.name)) {
    return every(colorFields.map(field => (values.settings ? values.settings.colors[field] : null)), isValidColor);
  }
  return false;
}


/**
 * @description: Add HEX character to the hex color
 * e.g.: 'ffffff' -> '#ffffff'
 */
export function parseHashSymbolIfNeeded(value: string): string {
  const parsedValue = String(value).startsWith('#') ? value : `#${value}`;
  return parsedValue.replace(/#+/mg, '#');
}
