import React from 'react';

import { Button } from '@material-ui/core';

import { IButtonProps } from '../IProps';


const SolidButtonComponent: React.FC<IButtonProps> = ({
  onClick,
  startIcon,
  title,
  ...other
}) => (
  <Button
    variant="contained"
    color="primary"
    startIcon={startIcon || null}
    onClick={onClick}
    fullWidth
    {...other}
  >
    {title}
  </Button>
);

export default SolidButtonComponent;
