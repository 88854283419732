import React from 'react';
import {
  withStyles,
  createStyles,
  WithStyles,
  Theme,
} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';

import { ITable } from '../../../../types/IRestaurantTablesStore';
import { parseTableHall } from '../../../../helpers/bookingHelpers';

const styles = (theme: Theme) => createStyles({
  wrap: {
    display: 'flex',
    height: 85,
    flex: 1,
    borderRadius: 10,
    border: '1px solid rgba(0, 0, 0, 0.54)',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    cursor: 'pointer',
    backgroundColor: '#ffffff',
  },
  numberWrap: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  number: {
    margin: theme.spacing(),
  },
  marker: {
    backgroundColor: theme.palette.primary.main,
    width: 25,
    height: 25,
    position: 'absolute',
    borderRadius: 60,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    color: 'white',
    top: -10,
    right: -10,
  },
  active: {
    border: '2px solid rgba(0, 0, 0, 0.87)',
    backgroundColor: '#6ae9c5',
  },
  // disabled: {
  //   backgroundColor: '#707070',
  // },
});

interface IProps extends WithStyles<typeof styles> {
  table: ITable;
  handleSelect: (table: ITable) => void;
  active: boolean;
  // disabled: boolean;
}

function RestaurantIcon(props: IProps) {
  const {
    classes,
    handleSelect,
    active,
    table,
    // disabled,
  } = props;

  // const onClick = () => !disabled && handleSelect(table);
  const onClick = () => handleSelect(table);

  return (
    <div
      onClick={onClick}
      className={classNames(classes.wrap, {
        [classes.active]: active,
        // [classes.disabled]: disabled,
      })}
    >
      <div className={classes.numberWrap}>
        <Typography style={{ fontSize: 12 }}>
          {parseTableHall(table.hall)}
        </Typography>
        <Typography variant="h6">
          {table.number}
        </Typography>
      </div>
    </div>
  );
}

export default withStyles(styles)(RestaurantIcon);
