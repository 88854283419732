import React, { Fragment } from 'react';
import wrapWithPath from './wrapSvgPath';

const bookingPath = (
  <Fragment>
    <path id="reserv" fill="#323232" d="M14 4h14v2H14zm0 14h14v2H14zm-6-8H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2zM2 2v6h6V2zm6 22H2a2 2 0 0 1-2-2v-6a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2zm-6-8v6h6v-6z" />
  </Fragment>
);

export default wrapWithPath(bookingPath, '0 0 28 24');
