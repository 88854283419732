import { INewNotification } from './INotificationActions';
import { NEW_NOTIFICATION } from './NotificationActionsTypes';

export function newNotification(type: 'reserve_confirmation'): INewNotification {
  return {
    type: NEW_NOTIFICATION,
    payload: {
      type,
    },
  };
}
