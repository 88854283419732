import React, { useState, useEffect } from 'react';
import _ from 'lodash';

import {
  IFreeDay,
  IRestaurantWorkTimes,
  IScheduleRow,
  TWeekDay,
} from '../../../types/IScheduleStore';
import { getWorkTimeForAutoBooking, parseTimeToInt } from '../../../helpers/scheduleHelpers';
import ScheduleRow from './ScheduleRow';

interface IProps {
  handelUpdateSchedule: (row: IScheduleRow) => void;
  handleDeleteScheduleRow: (row: IScheduleRow) => void;
  handleUpdateFreeDays: (days: {
    activate: Array<TWeekDay>,
    deactivate: Array<TWeekDay>
  }) => void;
  schedule: IScheduleRow;
  freeDays: Array<IFreeDay>;
  restaurantWorkTimes?: IRestaurantWorkTimes;
}

interface IState {
  days: Array<TWeekDay>;
  start: string;
  end: string;
  freeDays: Array<IFreeDay>;
}

function getFreeDays(freeDays: Array<IFreeDay>, restaurantWorkTimes?: IRestaurantWorkTimes): Array<IFreeDay> {
  return freeDays.map((d) => {
    if (d.free && restaurantWorkTimes) {
      return {
        ...d,
        free: !!restaurantWorkTimes[d.day] && !!restaurantWorkTimes[d.day].start,
      };
    }

    return d;
  });
}

const SchedulePeriodRow: React.FC<IProps> = (props) => {
  const {
    schedule,
    handleUpdateFreeDays,
    handelUpdateSchedule,
    handleDeleteScheduleRow,
    freeDays,
    restaurantWorkTimes,
  } = props;

  const [workTimes, changeWorkTime] = useState<Array<string>>([schedule.start, schedule.end]);
  const [state, changeState] = useState<IState>({
    days: schedule.days,
    start: schedule.start,
    end: schedule.end,
    freeDays: getFreeDays(freeDays, restaurantWorkTimes),
  });

  useEffect(() => {
    if (restaurantWorkTimes && !_.isEmpty(restaurantWorkTimes)) {
      const workTimeList = getWorkTimeForAutoBooking(state.days, restaurantWorkTimes);

      if (workTimeList.length > 0) {
        const currentStart = parseTimeToInt(state.start);
        const currentEnd = parseTimeToInt(state.end);
        const workStart = parseTimeToInt(workTimeList[0]);
        const workEnd = parseTimeToInt(workTimeList[workTimeList.length - 1]);

        changeState({
          ...state,
          freeDays: getFreeDays(freeDays, restaurantWorkTimes),
          start: currentStart > workStart ? state.start : workTimeList[0],
          end: currentEnd < workEnd ? state.end : workTimeList[workTimeList.length - 1],
        });
      } else {
        changeState({
          ...state,
          freeDays: getFreeDays(freeDays, restaurantWorkTimes),
        });
      }

      changeWorkTime(workTimeList);
    }
  }, [state.days, restaurantWorkTimes, freeDays]);

  /** @description Отправка экшена на апдейт */
  const onUpdate = () => {
    const initValue = {
      end: schedule.end, start: schedule.start, days: schedule.days,
    };
    const newValue = {
      end: state.end, start: state.start, days: state.days,
    };
    const fieldEmpty = (state.days.length === 0) || !state.start || !state.end;

    if (!_.isEqual(initValue, newValue) && !fieldEmpty) {
      handelUpdateSchedule({
        start: state.start,
        end: state.end,
        id: schedule.id,
        days: state.days,
      });
    }
  };

  /** @description Редактирование полей время и дни недели */
  const onChange = (value: string | Array<string> | unknown, type: 'start' | 'end' | 'days') => {
    if (type === 'days' && Array.isArray(value)) {
      handleUpdateFreeDays({
        activate: value,
        deactivate: state.days.filter(day => value.indexOf(day) < 0),
      });

      changeState({
        ...state,
        [type]: value,
      });
    } else {
      changeState({
        ...state,
        [type]: value,
      });
    }
  };

  /** @description Отпарвка экшена на удаление строки и всех выбранных дней */
  const onDelete = () => {
    const { days } = state;

    handleUpdateFreeDays({
      activate: [], // В случае удаления периода проставлям выбранным дням что они свободны.
      deactivate: days,
    });
    handleDeleteScheduleRow(schedule);
  };

  return (
    <div style={{ paddingBottom: 12 }}>
      <ScheduleRow
        onChange={onChange}
        onDelete={onDelete}
        onUpdate={onUpdate}
        state={state}
        schedule={schedule}
        freeDays={state.freeDays}
        workTimes={workTimes}
      />
    </div>
  );
};

export default SchedulePeriodRow;
