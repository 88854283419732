import { createMuiTheme } from '@material-ui/core/styles';

export const primaryColor = '#6ae9c5';
export const primarySelectedColor = 'rgb(0, 150, 136, 0.15)';
export const primaryHoverColor = 'rgb(0, 150, 136, 0.1)';
export const primaryPostHoverColor = 'rgb(0, 150, 136, 0.05)';
export const errorColor = '#f45858';
export const errorHoverColor = '#c24949';
export const errorContrastText = '#323232';

export default createMuiTheme({
  typography: {
    h4: {
      fontFamily: ['GolosTextWebMedium', 'Roboto'],
      fontSize: 29,
      lineHeight: '32pt',
    },
    h5: {
      fontFamily: ['GolosTextWebMedium', 'Roboto'],
      fontSize: 24,
      lineHeight: '28pt',
    },
    h6: {
      fontFamily: ['GolosTextWebMedium', 'Roboto'],
      fontSize: 20,
      lineHeight: '24pt',
    },
    subtitle1: {
      fontSize: 16,
      lineHeight: '16pt',
    },
    subtitle2: {
      fontFamily: ['GolosTextWebMedium', 'Roboto'],
      fontSize: 14,
      lineHeight: '14pt',
    },
    body1: {
      fontSize: 14,
      lineHeight: '1.4',
    },
    caption: {
      color: '#AAAAAA',
      fontSize: 12,
      lineHeight: '14pt',
    },
    button: {
      textTransform: 'none',
    },
    useNextVariants: true,
    fontFamily: [
      'GolosTextWebRegular',
      'Roboto',
    ],
  },
  palette: {
    primary: {
      main: primaryColor,
      contrastText: '#323232',
    },
    secondary: {
      main: '#c0c0c0',
      contrastText: '#323232',
    },
    grey: {
      main: '#898989',
    },
    buttonGrey: {
      main: '#c5c5c5',
      hover: '#bbbbbb',
      contrastText: '#323232',
    },
    error: {
      main: errorColor,
      hover: errorHoverColor,
      contrastText: '#323232',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        fontFamily: ['GolosTextWebMedium', 'Roboto'],
      },
    },
    MuiInput: {
      underline: {
        '&:hover:not($disabled):not($focused):not($error):before': { borderBottomColor: primaryColor },
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: 12,
        maxWidth: 360,
        padding: '16px 15px 17px 24px',
      },
    },
    MuiMenuItem: {
      root: {
        '&$selected': {
          backgroundColor: primarySelectedColor,
          fontWeight: 500,
          '&:focus': {
            backgroundColor: primarySelectedColor,
          },
          '&:hover': {
            backgroundColor: primaryHoverColor,
          },
        },
        '&:hover': {
          backgroundColor: primaryHoverColor,
        },
        '&:focus': {
          backgroundColor: primaryPostHoverColor,
        },
      },
    },
    MuiListItem: {
      button: {
        '&:hover': {
          backgroundColor: primaryHoverColor,
        },
        '&:focus': {
          backgroundColor: primaryPostHoverColor,
        },
      },
    },

    MuiTouchRipple: {
      rippleVisible: {
        color: 'rgb(0, 150, 136, 0.25) !important',
      },
    },

    MuiTableCell: {
      root: {
        padding: 12,
      },
      head: {
        color: '#AAAAAA',
        fontSize: 12,
        lineHeight: '14pt',
        backgroundColor: '#f7f8fb',
      },
    },
  },
});
