import * as ActionTypes from '../actions/restaurantsWidgets/RestaurantsWidgetsTypes';
import { IAppActions } from '../types/IAppActions';
import { IRestaurantWidgetsStore } from '../types/IRestaurantWidgetsStore';

export const initState: IRestaurantWidgetsStore = {
  isLoading: false,
  widgets: [],
};

export default function RestaurantWidgetsReducer(state = initState, action: IAppActions): IRestaurantWidgetsStore {
  switch (action.type) {
    case ActionTypes.CREATE_WIDGET_REQUEST:
    case ActionTypes.GET_ALL_WIDGETS_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case ActionTypes.GET_ALL_WIDGETS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        widgets: action.payload.widgets,
      };

    case ActionTypes.CREATE_WIDGET_SUCCESS: {
      const widgets = [...state.widgets, action.payload.newWidget];

      return {
        ...state,
        widgets,
        isLoading: false,
      };
    }

    case ActionTypes.DELETE_WIDGET_SUCCESS: {
      const widgets = state.widgets.filter(w => w.id !== action.payload.widgetId);

      return {
        ...state,
        widgets,
        isLoading: false,
      };
    }

    case ActionTypes.UPDATE_WIDGET_SUCCESS: {
      const widgets = state.widgets.map((w) => {
        if (w.id === action.payload.widget.id) {
          return action.payload.widget;
        }

        return w;
      });

      return {
        ...state,
        widgets,
        isLoading: false,
      };
    }

    case ActionTypes.CREATE_WIDGET_ERROR:
    case ActionTypes.GET_ALL_WIDGETS_ERROR: {
      return {
        ...state,
        isLoading: false,
      };
    }

    default:
      return state;
  }
}
