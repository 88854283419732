import { IRestaurantsSettingsActions } from './IRestaurantsSettingsActions';
import * as ActionTypes from './RestaurantsSettingsActionsTypes';

/** @description Переключение типа графика приёма резервов */
export function switchReserveSchedule(isActiveRestaurantSchedule: boolean): IRestaurantsSettingsActions {
  return {
    type: ActionTypes.SWITCH_RESERVE_SCHEDULE_TYPE_REQUEST,
    payload: {
      isActiveRestaurantSchedule,
    },
  };
}

/** @description Успещное переключение типа графика приёма резервов */
export function switchReserveScheduleSuccess(isActiveRestaurantSchedule: boolean): IRestaurantsSettingsActions {
  return {
    type: ActionTypes.SWITCH_RESERVE_SCHEDULE_TYPE_SUCCESS,
    payload: {
      isActiveRestaurantSchedule,
    },
  };
}

/** @description Обработка ошибки переключения типа графика приёма резервов */
export function switchReserveScheduleError(): IRestaurantsSettingsActions {
  return {
    type: ActionTypes.SWITCH_RESERVE_SCHEDULE_TYPE_ERROR,
    error: 'Не удалось переключить тип приёма резервов',
  };
}


/** @description Переключение зала в меню настроек ресторана */
export function switchSectionId(sectionId: number | undefined): IRestaurantsSettingsActions {
  return {
    type: ActionTypes.SET_SECTION_ID,
    payload: {
      sectionId,
    },
  };
}
