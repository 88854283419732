import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import UserStatisticsCont from './UserStatisticCont';
import { IAppStore } from '../../types/IAppStore';
import { IDispatchProps, IStateProps } from './IUserStatisticCont';
import { IAppActions } from '../../types/IAppActions';
import { getUserStatistic } from '../../actions/userStatistic/UserStatisticActions';
import { openBookingDetails } from '../../actions/statistics/StatisticsActions';

function mapStateToProps(state: IAppStore): IStateProps {
  return {
    currentRestaurantId: state.cabinet.currentRestaurant && state.cabinet.currentRestaurant.id,
    moneySymbol: state.cabinet.moneySymbol,
    statistic: state.userStatistics.statistics,
    reserves: state.userStatistics.reserves,
    userInfo: state.userStatistics.userInfo,
    loading: state.loading.isLoading,
    bookingsOrders: state.statistic.bookingsOrders,
  };
}

function mapDispatchToProps(dispatch: Dispatch<IAppActions>): IDispatchProps {
  return bindActionCreators({
    getUserStatistic,
    openBookingDetails,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(UserStatisticsCont);
