import React from 'react';

import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const styles = createStyles({
  section: {
    display: 'flex',
    flex: 1,
    padding: '12px 24px',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  borderBottom: {
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
  },
});

interface IProps extends WithStyles<typeof styles> {
  children: React.ReactNode;
  borderBottom?: boolean;
}

/**
 * @param classes {Object}
 * @param children {Object} - Дочерний элемент
 * @param borderBottom - Граница с низу
 */
const FieldWrap: React.FC<IProps> = ({ classes, children, borderBottom }) => (
  <div className={classNames(classes.section, { [classes.borderBottom]: borderBottom })}>
    { children }
  </div>
);

export default withStyles(styles)(FieldWrap);
