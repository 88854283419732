import * as ActionTypes from './UserStatisticActionsTypes';
import { IUserStatistic } from './IUserStatisticActions';
import { TReserve } from '../../types/IBookingStore';

/** @description Экшен на получение статистики юзера */
export function getUserStatistic(userPhoneNumber: string, sort: { orderBy: string; order: "desc" | "asc" }): IUserStatistic {
  return {
    type: ActionTypes.GET_USER_STATISTIC_REQUEST,
    payload: {
      userPhoneNumber,
      sort,
    },
  };
}

/** @description Экшен на обработку получения статистики юзера */
export function getUserStatisticSuccess(
  userInfo: any, userStats: any, userReserves: Array<TReserve>,
): IUserStatistic {
  return {
    type: ActionTypes.GET_USER_STATISTIC_SUCCESS,
    payload: {
      userInfo,
      userStats,
      userReserves,
    },
  };
}

/** @description Экшен на обработку ошибки получения статистики юзера */
export function getUserStatisticsError(): IUserStatistic {
  return {
    type: ActionTypes.GET_USER_STATISTIC_ERROR,
    error: 'Не удалось получить информацию о госте',
  };
}
