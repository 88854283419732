import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import { withStyles, createStyles, WithStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import RestaurantIcon from './RestaurantIcons';
import RestaurantSchema from './RestaurantSchema';
import ArrowTop from '../../Common/Icons/ArrowTop';
import ArrowBottom from '../../Common/Icons/ArrowBottom';
import { ITable } from '../../../types/IRestaurantTablesStore';
import { TActiveTables } from '../../../types/IReserveModalStore';
import { TSectionAdaptSchema } from '../../../types/IRestaurantTablesSchemaStore';
import { ViewID } from '../ReservesDataFields';
import locales from '../../../locales';

const styles = createStyles({
  wrap: {
    padding: 20,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    // height: 'calc(100vh - 226px)',
    height: 'calc(100vh - 203px)',
  },
  arrowMargins: {
    marginBottom: 18,
    marginTop: 20,
  },
  iconsWrap: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-start',
    alignContent: 'flex-start',
    flexWrap: 'wrap',
    width: '100%',
    overflowY: 'scroll',
    msOverflowStyle: 'none',
    overflow: '-moz-scrollbars-none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  pointer: {
    cursor: 'pointer',
  },
  msgWrap: {
    height: '85%',
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
});

interface IProps extends WithStyles<typeof styles> {
  tables: Array<ITable> | void;
  handleSelectTable: (table: ITable) => void;
  activeTables: TActiveTables;
  isMergeTables: boolean;
  schema: TSectionAdaptSchema | void;
  viewId: ViewID;
  isSchemasEnabled: boolean;
  persons: number;
  alreadySelectedTables: Array<number>;
}

const RestaurantView: React.FC<IProps> = ({
  classes,
  tables,
  handleSelectTable,
  activeTables,
  isMergeTables,
  schema,
  viewId,
  isSchemasEnabled,
  persons,
  alreadySelectedTables,
}) => {
  const tableCont = React.createRef<any>();
  const [initialized, onInit] = useState(false);

  useEffect(() => {
    const tablesReady = tables && tables.length > 0;
    if (viewId === 'scheme') {
      if (tablesReady && schema && !initialized) {
        onInit(true);
      }
    } else if (tablesReady && !initialized) {
      onInit(true);
    }
  });

  const initMessage = (
    <div className={classes.msgWrap}>
      <Typography>
        Укажите кол-во гостей, дату и время,
      </Typography>
      <Typography>
        чтобы увидеть доступные столики
      </Typography>
    </div>
  );

  const noFreeTables = (
    <div className={classes.msgWrap}>
      {
        !isMergeTables ? (
          <span>
            <Typography>
              Нет доступных столов, Попробуйте
            </Typography>
            <Typography>
              включить опцию "Объединить столы"
            </Typography>
          </span>
        ) : (
          <Typography>
            Нет доступных столов
          </Typography>
        )
      }
    </div>
  );

  if (viewId === 'scheme') {
    const disabledSchema = (
      <div className={classes.msgWrap}>
        <Typography>
          { locales.t('restaurantSchema.note.schemasDisabled') }
        </Typography>
      </div>
    );
    if (!isSchemasEnabled && initialized) {
      return (
        <div className={classes.wrap}>
          { disabledSchema }
        </div>
      );
    }

    const emptySchema = (
      <div className={classes.msgWrap}>
        <Typography>
          { locales.t('restaurantSchema.note.schemaDontExists') }
        </Typography>
      </div>
    );
    const message = schema ? noFreeTables : emptySchema;
    const tablesSchema = schema && tables && tables.length > 0 ? (
      <RestaurantSchema
        activeTables={activeTables}
        data={schema}
        tables={tables || []}
        handleSelect={handleSelectTable}
        persons={persons}
        customWidthOffset
        // @todo Реализовать на схеме
        // alreadySelectedTables={alreadySelectedTables}
      />
    ) : message;

    return (
      <div className={classes.wrap}>
        { tables === undefined ? initMessage : tablesSchema }
      </div>
    );
  }

  const scrollToElement = (type: string): void => {
    if (type === 'top') {
      tableCont.current.scrollTop -= 80;
    } else {
      tableCont.current.scrollTop += 80;
    }
  };

  const tablesGrid = (
    tables && tables.length > 0 ? (
      <Grid container spacing={3} alignItems="stretch">
        {tables.map(
          table => (
            <Grid item xs={6} sm={3} xl={2} key={table.id}>
              <RestaurantIcon
                table={table}
                handleSelect={handleSelectTable}
                active={!!activeTables[table.id]}
                // Локальная блокировка отключена, так как текущая реализация
                //   не покрывает 100% случаев. Не удаляем, так как планируем
                //   вернуться к этому позже
                // disabled={alreadySelectedTables.indexOf(table.id) !== -1}
              />
            </Grid>
          ),
        )}
      </Grid>
    )
      : noFreeTables
  );

  return (
    <div className={classes.wrap}>
      <ArrowTop
        className={classes.pointer}
        onClick={() => scrollToElement('top')}
      />
      <div
        ref={tableCont}
        className={classNames(classes.iconsWrap, classes.arrowMargins)}
      >
        { tables === undefined ? initMessage : tablesGrid }
      </div>
      <ArrowBottom
        className={classes.pointer}
        onClick={() => scrollToElement('down')}
      />
    </div>
  );
};

export default withStyles(styles)(RestaurantView);
