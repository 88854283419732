import React from 'react';
import { WrappedFieldProps } from 'redux-form';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import locales from '../../locales';
import { errorColor } from '../../theme';

interface IProps extends WrappedFieldProps {
  maxCapacity: number;
  disabled: boolean;
}

/** @description Селектор для выбора количества персон */
const ReservePersonSelect: React.FC<IProps> = ({
  input,
  meta: { touched, error },
  maxCapacity,
  disabled,
}) => {
  const isError = touched && error;
  const colorError = isError ? { color: errorColor } : {};
  const capacityList = new Array(maxCapacity)
    .fill('')
    .map((v, i) => i + 1);

  return (
    <FormControl fullWidth error={isError} disabled={disabled}>
      <InputLabel htmlFor="person">{ locales.t('common.reserveModal.persons') }</InputLabel>
      <Select
        {...input}
        style={colorError}
      >
        {
          input.value === 0 && (
            <MenuItem value={0}>
              { locales.t('common.reserveModal.emptyPersons') }
            </MenuItem>
          )
        }
        {
          capacityList.map(person => (
            <MenuItem key={person} value={person}>
              { `${person} чел.` }
            </MenuItem>
          ))
        }
      </Select>
    </FormControl>
  );
};

export default ReservePersonSelect;
