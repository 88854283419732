import React from 'react';
import {
  Popover, withStyles, WithStyles,
} from '@material-ui/core';
import styles from './styles';


interface IProps extends WithStyles<typeof styles> {
  refPicker: any;
  handleClose: () => void,
  open: boolean;
}
const CustomPopover: React.FC<IProps> = ({
  refPicker, handleClose, open, children,
}) => (
  <Popover
    id="color-popover"
    open={open}
    anchorEl={refPicker.current}
    onClose={handleClose}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
  >
    {children}
  </Popover>
);

export default withStyles(styles)(CustomPopover);
