import React from 'react';

import {
  withStyles,
  createStyles,
  WithStyles,
  Theme,
} from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Tooltip from '@material-ui/core/Tooltip';
import FormControl from '@material-ui/core/FormControl';

import locales from '../../../locales';
import {
  IFreeDay,
  IScheduleRow,
  TWeekDay,
} from '../../../types/IScheduleStore';
import { sortWeekdays } from '../../../helpers/scheduleHelpers';
import TrashIcon from '../Icons/TrashIcon';

const styles = (theme: Theme) => createStyles({
  formControl: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  timeFieldsWrap: {
    width: 120,
    marginLeft: 20,
  },
  timeFields: {
    width: 80,
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface IProps extends WithStyles<typeof styles>{
  onChange: (value: string | Array<string> | unknown, type: 'start' | 'end' | 'days') => void;
  onUpdate: () => void;
  onDelete: () => void;
  schedule: IScheduleRow;
  freeDays: Array<IFreeDay>;
  state: {
    days: Array<TWeekDay>;
    start: string;
    end: string;
  };
  workTimes: Array<string>;
}

const ScheduleRow: React.FC<IProps> = (props) => {
  const {
    classes,
    schedule,
    freeDays,
    state,
    onChange,
    onUpdate,
    onDelete,
    workTimes,
  } = props;

  return (
    <div className={classes.formControl}>
      <FormControl error={state.days.length === 0 || schedule.error} fullWidth>
        <Select
          multiple
          value={state.days}
          onBlur={onUpdate}
          onChange={e => onChange(e.target.value, 'days')}
          input={<Input id="select-multiple-chip" />}
          renderValue={selected => (
            <div className={classes.chips}>
              {sortWeekdays((selected as string[])) // сортируем список дней
                .map((day: string, index: number) => (
                  <span key={day+index} style={{ marginRight: 5 }}>
                    {(index !== (selected as string[]).length - 1) // если индекс элемента меньше длины, ставим запятую
                      ? `${locales.t(`common.scheduleDays.${day}`)},` // - здесь ставим запятую после дня
                      : locales.t(`common.scheduleDays.${day}`)}
                  </span>
                ))}
            </div>
          )}
          inputProps={{
            name: 'days',
          }}
          MenuProps={MenuProps}
        >
          {freeDays.map(day => (
            <MenuItem
              disabled={!day.free && (state.days.indexOf(day.day) === -1)}
              key={day.day}
              value={day.day}
            >
              { locales.t(`common.scheduleDays.${day.day}`) }
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl className={classes.timeFieldsWrap} disabled={workTimes.length === 0}>
        <Select
          error={schedule.error}
          className={classes.timeFields}
          value={state.start}
          name="start"
          onBlur={onUpdate}
          onChange={e => onChange(e.target.value, 'start')}
        >
          { workTimes.map(time => (<MenuItem key={time} value={time}>{ time }</MenuItem>)) }
        </Select>
      </FormControl>
      <FormControl className={classes.timeFieldsWrap} disabled={workTimes.length === 0}>
        <Select
          error={schedule.error}
          className={classes.timeFields}
          name="end"
          value={state.end}
          onBlur={onUpdate}
          onChange={e => onChange(e.target.value, 'end')}
        >
          { workTimes.map(time => (
            <MenuItem key={time} value={time === '00:00' ? '23:59' : time}>
              { time === '23:59' ? '00:00' : time }
            </MenuItem>)
          ) }
        </Select>
      </FormControl>
      <Tooltip title="Удалить">
        <div style={{ cursor: 'pointer', marginLeft: 40, display: 'flex' }}>
          <TrashIcon
            onClick={onDelete}
          />
        </div>
      </Tooltip>
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(ScheduleRow);
