import React, {
  useRef, useState, useCallback,
} from 'react';
import {
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  IconButton,
  withStyles, WithStyles,
} from '@material-ui/core';
import { Color, ColorBox } from 'material-ui-color';

import EditIcon from '../../Common/Icons/EditIcon';

import CustomPopover from './CustomPopover';

import { parseHashSymbolIfNeeded } from './utils';

import styles from './styles';

interface IProps extends WithStyles<typeof styles> {
  onChange: (value: string) => void;
  value?: string;
  label: string;
  defaultValue?: string | null;
  error: boolean;
  id: string;
}

const CustomField: React.FC<IProps> = React.memo(({
  id,
  label,
  classes,
  value,
  defaultValue,
  onChange,
  error,
}) => {
  const ref = useRef(null);
  const [colorPickerField, onColorPickerToggle] = useState<boolean>(false);

  const handlePickerOpen = useCallback(() => onColorPickerToggle(true), []);
  const handlePickerHide = useCallback(() => onColorPickerToggle(false), []);
  const onColorPickerChange = useCallback((color: Color) => {
    /**
     * @description when format is unknown hex value may return default values
     * to handle user input whe need to parse raw value (e.g. `#0`, {r,g,b})
     */
    if (color.format === 'unknown' && typeof color.raw === 'string') {
      onChange(parseHashSymbolIfNeeded(color.raw));
    } else {
      onChange(parseHashSymbolIfNeeded(color.hex));
    }
  }, []);

  const onInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.target) {
      const { value } = e.target;
      onChange(parseHashSymbolIfNeeded(value));
    }
  }, []);

  return (
    <FormControl className={classes.colorField} error={error} ref={ref}>
      <CustomPopover
        open={colorPickerField}
        refPicker={ref}
        handleClose={handlePickerHide}
      >
        <ColorBox
          value={value}
          onChange={onColorPickerChange}
          inputFormats={['hex', 'rgb']}
          // @ts-ignore - not specified in component types
          disableAlpha
        />
      </CustomPopover>
      <InputLabel htmlFor={id} shrink error={error}>{label}</InputLabel>
      <Input
        id={id}
        type="text"
        value={value}
        placeholder={defaultValue || ''}
        onChange={onInputChange}
        error={error}
        endAdornment={(
          <InputAdornment position="end">
            <IconButton
              className={classes.colorFieldIcon}
              aria-label="toggle color picker"
              onClick={handlePickerOpen}
              disableFocusRipple
            >
              <EditIcon />
            </IconButton>
          </InputAdornment>
        )}
      />
    </FormControl>
  );
});

CustomField.displayName = 'CustomFieldMemo';

export default withStyles(styles)(CustomField);
