import { createSelector } from 'reselect';
import { selectorTableSections, selectorTableSectionsAvailable } from '../../reducers/selectors';
import {
  TSectionAdaptSchema,
  TRestaurantSectionAdapt,
} from '../../types/IRestaurantTablesSchemaStore';
import { IAppStore } from '../../types/IAppStore';

export const selectorId = (state: IAppStore) => state.reservesSystemSettings.sectionId;

/** @description Текущая схема зала */
export const selectorSectionSchema = createSelector([
  selectorTableSectionsAvailable,
  selectorId,
], (
  sections: Array<TRestaurantSectionAdapt> | void,
  sectionId: number | void,
): TSectionAdaptSchema | void => {
  if (typeof sectionId === 'number' && Number(sectionId) > 0) {
    const section = (sections || []).find(d => d.id === sectionId);
    return section ? section.schema : undefined;
  }
  return undefined;
});

/** @description Схема зала для настроек, где нужно ее показывать вне зависимости от доступности столов */
export const selectorSettingsSectionSchema = createSelector([
  selectorTableSections,
  selectorId,
], (
  sections: Array<TRestaurantSectionAdapt> | void,
  sectionId: number | void,
): TSectionAdaptSchema | undefined => {
  if (sectionId) {
    const section = (sections || []).find(d => d.id === sectionId);
    if (section) {
      return section.schema;
    }
  }

  return undefined;
});
