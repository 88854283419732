import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import locales from '../../../locales';
import { customErrorShackBar, customMessageShackBar } from '../../../actions/snackbars/SnackBarsActions';
import { useRestaurantId } from '../../../selectors/RestaurantSelectors';
import { mapResponseToCashbox } from './selectors';
import { getCashboxStatusRequest, getCashboxDeleteRequest } from '../../../api';
import { getCashBoxConnectStatus, getCashBoxPluginsStatus } from '../../../actions/cabinet/CabinetActions';
import { useHistory } from 'react-router-dom';


export function useCashboxQueryKey() {
  const restaurantId = useRestaurantId();
  return ['cashbox', restaurantId];
}

export function useCashbox() {
  const restaurantId = useRestaurantId();
  const request: GM.Cashbox.Request.Status | undefined = restaurantId ? { restaurantId } : undefined;

  const { data: response, isLoading, isFetching } = useQuery({
    staleTime: 60 * 1000 * 30,
    enabled: !!restaurantId,
    queryKey: useCashboxQueryKey(),
    queryFn: () => (request && getCashboxStatusRequest(request)) || undefined,
  });

  let cashbox: GM.Cashbox.Cashbox | null | undefined = (response && mapResponseToCashbox(response)) || undefined;

  return {
    isLoading: isLoading || isFetching,
    cashbox,
  };
}

export function useDeleteMutation() {
  const queryClient = useQueryClient();
  const queryKey = useCashboxQueryKey();
  const dispatch = useDispatch();
  const restaurantId = useRestaurantId();
  const history = useHistory();

  return useMutation({
    mutationFn: (payload: GM.Cashbox.Request.Delete) => getCashboxDeleteRequest(payload),

    onSuccess: (response: any) => {
      if (response.status == 200) {
        dispatch(customMessageShackBar(locales.t('cashbox.settings.actions.delete.success')));
        queryClient.invalidateQueries({ queryKey });

        if (restaurantId) {
          dispatch(getCashBoxPluginsStatus(restaurantId));
          dispatch(getCashBoxConnectStatus(restaurantId));
        }

        history.push('/');
      } else {
        dispatch(customErrorShackBar(locales.t('cashbox.settings.actions.delete.error')));
      }
    },

    onError: () => {
      dispatch(customErrorShackBar(locales.t('cashbox.settings.actions.delete.error')));
    },
  });
}
