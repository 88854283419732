import { put, all, takeLatest } from 'redux-saga/effects';

import {
  loadProgramsReq,
  getProgramsReq,
} from '../api';
import {
  GET_PROGRAMS_REQUEST,
  LOAD_PROGRAMS_REQUEST,
} from '../actions/promo/PromoActionsTypes';
import {
  getProgramsError,
  getProgramsSuccess,
  loadProgramsFromIikoError,
  loadProgramsFromIikoSuccess,
} from '../actions/promo/PromoActions';
import { IGetPrograms, ILoadProgramsFromIiko } from '../actions/promo/IPromoActions';

/** @description Получение акций с бека */
function* getProgramsSaga(action: IGetPrograms) {
  try {
    const programList = yield getProgramsReq(action.payload.restaurantId);
    yield put(getProgramsSuccess(programList));
  } catch (e) {
    yield put(getProgramsError());
  }
}

/** @description Загрузка акций с сервиса iiko */
function* loadProgramsSaga(action: ILoadProgramsFromIiko) {
  try {
    const programList = yield loadProgramsReq(action.payload.restaurantId);
    yield put(loadProgramsFromIikoSuccess(programList));
  } catch (e) {
    yield put(loadProgramsFromIikoError());
  }
}

export default function* saga() {
  yield all([
    takeLatest(GET_PROGRAMS_REQUEST, getProgramsSaga),
    takeLatest(LOAD_PROGRAMS_REQUEST, loadProgramsSaga),
  ]);
}
