import React, { Fragment, useState } from 'react';

import {
  withStyles, createStyles, WithStyles, Theme,
} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Typography from '@material-ui/core/Typography/Typography';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import Button from '@material-ui/core/Button/Button';

import locales from '../../../locales';

const styles = (theme: Theme) => createStyles({
  linkColor: {
    color: theme.palette.primary.main,
  },
  wrap: {
    maxWidth: 600,
    height: 350,
  },
});

interface IProps extends WithStyles<typeof styles> {
  handleCloseDialog: () => void;
  handleSendAuthReq: (authData: { login: string; password: string; }) => void;
}

const FirstStep: React.FC<IProps> = ({ classes, handleCloseDialog, handleSendAuthReq }) => {
  const [authData, changeCred] = useState({ email: '', password: '' });

  /** @description Редактирование полей email и password */
  const onChange = (event: any) => {
    changeCred({
      ...authData,
      [event.target.type]: event.target.value,
    });
  };

  /** @description Отправка запроса */
  const onSend = () => {
    handleSendAuthReq({
      login: authData.email,
      password: authData.password,
    });
  };

  return (
    <Fragment>
      <div className={classes.wrap}>
        <DialogContent>
          <DialogContentText>
            <span style={{ fontWeight: 'bold' }}>{ locales.t('home.authIiko.firstStep.step') }</span>
            { locales.t('home.authIiko.firstStep.stepDescription1') }
            <a
              href={locales.t('home.authIiko.firstStep.iikoHelpLink')}
              target="noopener"
              style={{ textDecoration: 'underline' }}
            >
              { locales.t('home.authIiko.firstStep.stepDescription2') }
            </a>
            { locales.t('home.authIiko.firstStep.stepDescription3') }
            <a
              href={locales.t('home.authIiko.firstStep.iikoLink')}
              className={classes.linkColor}
              target="noopener"
            >
              { locales.t('home.authIiko.firstStep.iikoLink') }
            </a>
          </DialogContentText>
          <TextField
            onChange={onChange}
            margin="normal"
            label={locales.t('home.authIiko.email')}
            type="email"
            fullWidth
            value={authData.email}
          />
          <TextField
            onChange={onChange}
            margin="normal"
            label={locales.t('home.authIiko.password')}
            type="password"
            fullWidth
            value={authData.password}
          />
          <div style={{ marginTop: 34 }}>
            <Typography variant="caption">
              { locales.t('home.authIiko.description') }
            </Typography>
          </div>
        </DialogContent>
      </div>
      <div>
        <DialogActions>
          <Button
            color="secondary"
            onClick={handleCloseDialog}
          >
            { locales.t('common.reject') }
          </Button>
          <Button
            color="primary"
            onClick={onSend}
          >
            { locales.t('home.connectBtn') }
          </Button>
        </DialogActions>
      </div>
    </Fragment>
  );
};

export default withStyles(styles)(FirstStep);
