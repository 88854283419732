import React, { useState } from 'react';
import { TextField, Typography, Box } from '@material-ui/core';
import { NoAuthPage } from '../../../shared';
import locales from '../../../locales';

interface Props {
  submitPassword: (password: string) => void;
}

export function ActivationPassword(props: Props) {
  const { submitPassword } = props;
  const [password, setPassword] = useState('');

  return (
    <NoAuthPage
      logoSubTitle={locales.t('activation.setPasswordPage.logoSubTitle')}
      note={locales.t('activation.setPasswordPage.description')}
      buttonTitle={locales.t('activation.setPasswordPage.buttonTitle')}
      buttonAction={() => {
        if (!password) {
          return;
        }

        submitPassword(password);
      }}
    >
      <Typography
        variant="body1"
        align="center"
        dangerouslySetInnerHTML={{ __html: locales.t('activation.setPasswordPage.contentBody') }}
        gutterBottom
      />

      <Box style={{ textAlign: 'center' }}>
        <TextField
          variant="outlined"
          margin="dense"
          type="password"
          onChange={e => setPassword(e.target.value)}
          value={password}
          required
          placeholder={locales.t('activation.setPasswordPage.textFieldLabel')}
          autoFocus
        />
      </Box>
    </NoAuthPage>
  );
}
