export const GET_SUMMARY_STATISTICS_REQUEST = 'GET_SUMMARY_STATISTICS_REQUEST';
export const GET_SUMMARY_STATISTICS_SUCCESS = 'GET_SUMMARY_STATISTICS_SUCCESS';
export const GET_SUMMARY_STATISTICS_ERROR = 'GET_SUMMARY_STATISTICS_ERROR';

export const GET_GUEST_STATISTIC_REQUEST = 'GET_GUEST_STATISTIC ';
export const GET_GUEST_STATISTIC_SUCCESS = 'GET_GUEST_STATISTIC_SUCCESS';
export const GET_GUEST_STATISTIC_ERROR = 'GET_GUEST_STATISTIC_ERROR';

export const OPEN_BOOKING_DETAILS = 'OPEN_BOOKING_DETAILS';
export const OPEN_BOOKING_DETAILS_SUCCESS = 'OPEN_BOOKING_DETAILS_SUCCESS';
export const OPEN_BOOKING_DETAILS_ERROR = 'OPEN_BOOKING_DETAILS_ERROR';

export const CLOSE_BOOKING_DETAILS = 'CLOSE_BOOKING_DETAILS';
