import React, { Fragment } from 'react';
import wrapWithPath from './wrapSvgPath';

const arrowRightPath = (
  <Fragment>
    <path fill="#554d56" d="M.3 14.3c-.4.4-.4 1.02 0 1.4.38.4 1 .4 1.4 0l7-7c.4-.4.4-1.02 0-1.4l-7-7C1.3-.1.68-.1.3.3c-.4.38-.4 1 0 1.4L6.6 8 .3 14.3z" />
  </Fragment>
);

export default wrapWithPath(arrowRightPath, '-5 0 17 17');
