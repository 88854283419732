import React from 'react';
import Paper from '@material-ui/core/Paper';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';

import UserStatisticReserveTableHead from './UserStatisticReserveTableHead';
import UserStatisticsReserveTableItem from './UserStatisticsReserveTableItem';
import { TReserve } from '../../../types/IBookingStore';
import { IBookingOrders } from '../../../types/IStatisticStore';
import Title from '../../Common/Title';
import locales from '../../../locales';

type Props = {
  reserves: Array<TReserve>;
  handelChangeSort: (sort: "desc" | "asc") => void;
  bookingsOrders: IBookingOrders;
  handleOpenBookingDetails: (bookingId: number) => void;
  moneySymbol: string;
};

const index: React.FC<Props> = ({
  reserves,
  bookingsOrders,
  handleOpenBookingDetails,
  handelChangeSort,
  moneySymbol,
}) => {
  const [sortType, changeSortType] = React.useState<"desc" | "asc">('desc');

  /** @description Ручка для изменения типа сортировки */
  const handleChangeSortType = React.useCallback(() => {
    if (sortType === 'desc') {
      changeSortType('asc');
      handelChangeSort('asc');
    } else {
      changeSortType('desc');
      handelChangeSort('desc');
    }
  }, [sortType]);

  return (
    <Paper elevation={3}>
      <Title
        padding="8px 12px 8px 24px"
        text={locales.t('statistics.reserves.title')}
        borderBottom
        spaceBetween
      />
      <Table>
        <UserStatisticReserveTableHead
          onRequestSort={handleChangeSortType}
          order={sortType}
          orderBy="date"
        />
        <TableBody>
          {
            reserves.map(reserve => (
              <UserStatisticsReserveTableItem
                key={reserve.id}
                reserveInfo={reserve}
                order={bookingsOrders[reserve.id]}
                handleOpenBookingDetails={handleOpenBookingDetails}
                moneySymbol={moneySymbol}
              />
            ))
          }
        </TableBody>
      </Table>
    </Paper>
  );
};

export default index;
