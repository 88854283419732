import { TOpenReserve, IOpenReservesStore } from '../types/IBookingStore';
import { IAppActions } from '../types/IAppActions';

import * as ActionTypes from '../actions/reservationsPage/ReservationsPageActionsTypes';

export const initState: IOpenReservesStore = {
  items: undefined,
  isLoading: false,
  error: undefined,
};

export default function reducer(state: IOpenReservesStore = initState, action: IAppActions): IOpenReservesStore {
  switch (action.type) {
    case ActionTypes.FETCH_OPEN_RESERVES:
      return {
        ...state,
        isLoading: true,
        error: initState.error,
      };

    case ActionTypes.FETCH_OPEN_RESERVES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        items: action.reserves,
      };

    case ActionTypes.FETCH_OPEN_RESERVES_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case ActionTypes.RESET_OPEN_RESERVES:
      return {
        ...state,
        items: initState.items,
      };

    case ActionTypes.ADD_OPEN_RESERVE:
      if (state.items && state.items.find(reserve => reserve.id === action.reserve.id)) {
        return {
          ...state,
          items: state.items.map(
            (reserve: TOpenReserve) => (reserve.id === action.reserve.id ? action.reserve : reserve),
          ),
        };
      }

      return {
        ...state,
        items: [
          ...(state.items || []),
          action.reserve,
        ],
      };

    case ActionTypes.REMOVE_OPEN_RESERVE:
      return {
        ...state,
        items: (state.items || []).filter(item => item.id !== action.id),
      };

    default:
      return state;
  }
}
