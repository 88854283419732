import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import ErrorOutline from '@material-ui/icons/ErrorOutline';

import DialogTitle from '../../Common/DialogTitle';
import locales from '../../../locales';
import { errorColor } from '../../../theme';

const DialogContent = withStyles(() => ({
  root: {
    display: 'flex',
    padding: 24,
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: 24,
  },
}))(MuiDialogActions);

interface IProps {
  isOpen: boolean;
  handleCloseModal: (props: any) => void;
  handleDeleteRestaurant: (props: any) => void;
}

const DeleteRestaurantModal: React.FC<IProps> = ({
  isOpen,
  handleCloseModal,
  handleDeleteRestaurant,
}) => (
  <Dialog
    open={isOpen}
    fullWidth
    maxWidth="md"
  >
    <DialogTitle onClose={handleCloseModal}>
      { locales.t('settings.deleteRestaurantModal.title') }
    </DialogTitle>
    <DialogContent>
      <div style={{ paddingRight: 24 }}>
        <ErrorOutline color="error" style={{ fontSize: 54 }} />
      </div>
      <div>
        <Typography gutterBottom>
          { locales.t('settings.deleteRestaurantModal.firstText') }
        </Typography>
        <Typography gutterBottom>
          { locales.t('settings.deleteRestaurantModal.secondText') }
        </Typography>
      </div>
    </DialogContent>
    <DialogActions>
      <Button
        variant="contained"
        onClick={handleCloseModal}
        color="secondary"
        style={{ color: 'white', width: 108 }}
      >
        { locales.t('settings.deleteRestaurantModal.rejectBtn') }
      </Button>
      <Button
        variant="contained"
        onClick={handleDeleteRestaurant}
        style={{ color: 'white', backgroundColor: errorColor }}
      >
        { locales.t('settings.deleteRestaurantModal.confirmBtn') }
      </Button>
    </DialogActions>
  </Dialog>
);

export default DeleteRestaurantModal;
