/**
 * @description: Should parse current redux-form Field.value and return
 * appropriate type. Handle two cases:
 *  1. when user enters correct numeric value, return number
 *  2. when user clears input, return empty string
 */
export default function normalizeDurationHours(
  value: number | string,
): string | number {
  return ((value > 0) ? Number(value) : '');
}
