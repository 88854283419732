// import moment from 'moment';

import { TOpenReserve, TReserve } from './IBookingStore';
import { getRandomInt } from '../api/mocks/helpers';
import { ITable } from './IRestaurantTablesStore';
// import { getNextTime } from '../helpers/dateHelpers';


export type TAppReserveType = 'APP' | 'WIDGET';
export type TAppReserveFormType = 'CREATE' | 'CONFIRM' | 'EDIT';

// Изменения кроме отмены решили пока не отрабатывать. Работа с этим будет в отдельной задаче
// export type TAppReserveOuterChangeType = 'CANCELED' | 'CHANGED';
export type TAppReserveOuterChangeType = 'CANCELED';

export type TNewReserveFields = {
  id?: number;
  date?: string;
  time?: string;
  hours?: string;
  minutes?: number;
  persons?: number;
  firstName?: string;
  lastName?: string;
  comment?: string;
  phone?: string;
  isBanquet?: boolean;
  section?: number;

  tables?: Array<ITable>;
  // Для хранения локального по отношению к ЛК промежуточного
  //   статуса объединения столов без сохранения на сервере
  isMergeTables?: boolean;

  duration?: number;
};

export type TAppReserve = {
  type: TAppReserveType;
  payload?: TOpenReserve | TReserve | TNewReserveFields;
  originPayload?: TOpenReserve | TReserve | TNewReserveFields;
  open: boolean;
  key: string;
  changed: boolean;

  // По резерву могут приходить изменения извне. Сейчас достаточно понимать
  //   отменили или изменили резерв, чтобы удалить его или заблокировать для редактирования.
  //   В следующих работах планируется более развернутая реализация
  outerChangeType?: TAppReserveOuterChangeType;
};

export default class Reserve implements TAppReserve {
  key: string;

  open: boolean;

  payload: TOpenReserve | TReserve | TNewReserveFields;

  originPayload: TOpenReserve | TReserve | TNewReserveFields | undefined;

  type: TAppReserveType;

  changed: boolean;

  outerChangeType: TAppReserveOuterChangeType | undefined;

  makeKey = (): string => {
    const prefix = this.getFormType();

    if (this.payload && this.payload.id) {
      return `${prefix}_${this.payload.id}`;
    }

    return `${prefix}_${getRandomInt(1, 1000)}_${getRandomInt(1, 1000)}`;
  };

  getFormType = (): TAppReserveFormType => {
    if (!this.payload || !this.payload.id) {
      return 'CREATE';
    }

    if (this.type === 'WIDGET') {
      return 'CONFIRM';
    }

    return 'EDIT';
  };

  constructor(type: TAppReserveType, payload?: TOpenReserve | TReserve | TNewReserveFields) {
    this.type = type;
    this.open = false;
    this.changed = false;
    this.outerChangeType = undefined;
    this.payload = payload || {};
    this.originPayload = payload || undefined;

    // if (!this.payload.date) {
    //   this.payload.date = moment(getNextTime()).format('YYYY-MM-DDTHH:mm:00');
    // }

    if (!this.payload.persons) {
      this.payload.persons = 2;
    }

    if (!this.payload.duration) {
      this.payload.duration = 120;
    }

    this.key = this.makeKey();
  }
}

export type TAppReserves = Array<Reserve>;

export interface IAppReservesStore {
  items: TAppReserves;
  lastKey?: string;
}
