import React from 'react';

import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table/index';
import TableBody from '@material-ui/core/TableBody/index';
import TableCell from '@material-ui/core/TableCell/index';
import TableRow from '@material-ui/core/TableRow/index';

import StatisticItem from './HomeStatisticItem';
import locales from '../../../locales';
import { TStatistics } from '../../../types/IHomeStore';

const styles = createStyles({
  statistic: {
    display: 'flex',
    justifyContent: 'center',
    minHeight: 140,
  },
  statisticItem: {
    padding: '24px 10px',
  },
  tableHeader: {
    fontWeight: 500,
    padding: 24,
  },
});

interface IProps extends WithStyles<typeof styles> {
  statistic?: TStatistics;
  moneySymbol: string;
}

const HomeStatisticsTable: React.FC<IProps> = ({ classes, statistic, moneySymbol }) => {
  const renderStats = statistic || {
    period: {
      totalSuccessful: 0,
      totalExpectingGuests: 0,
      totalClosed: 0,
      totalCancelledByGuest: 0,
      totalCancelledByRestaurant: 0,
      totalCanceled: 0,
      totalSkipped: 0,
      averageReceiptAmount: 0,
    },
    percent: {
      totalSuccessful: 0,
      totalExpectingGuests: 0,
      totalClosed: 0,
      totalCancelledByGuest: 0,
      totalCancelledByRestaurant: 0,
      totalCanceled: 0,
      totalSkipped: 0,
      averageReceiptAmount: 0,
    },
    previous: {
      totalSuccessful: 0,
      totalExpectingGuests: 0,
      totalClosed: 0,
      totalCancelledByGuest: 0,
      totalCancelledByRestaurant: 0,
      totalCanceled: 0,
      totalSkipped: 0,
      averageReceiptAmount: 0,
    },
  };

  return (
    <div className={classes.statistic}>
      <Table>
        <TableBody>
          <TableRow key="1">
            <TableCell
              className={classes.tableHeader}
              component="th"
              scope="row"
            >
              Успешные
            </TableCell>
            <TableCell className={classes.statisticItem}>
              <StatisticItem
                type={locales.t('home.statistic.allReserve')}
                stat={renderStats.period.totalSuccessful}
                metric={renderStats.percent.totalSuccessful}
              />
            </TableCell>
            <TableCell className={classes.statisticItem}>
              <StatisticItem
                type={locales.t('home.statistic.waitingGuests')}
                stat={renderStats.period.totalExpectingGuests}
              />
            </TableCell>
            <TableCell className={classes.statisticItem}>
              <StatisticItem
                type={locales.t('home.statistic.closedInvoice')}
                stat={renderStats.period.totalClosed}
              />
            </TableCell>
            <TableCell className={classes.statisticItem}>
              <StatisticItem
                type={locales.t('home.statistic.averageCheck', { moneySymbol })}
                stat={renderStats.period.averageReceiptAmount}
                metric={renderStats.percent.averageReceiptAmount}
              />
            </TableCell>
          </TableRow>
          <TableRow key="2">
            <TableCell
              className={classes.tableHeader}
              component="th"
              scope="row"
            >
              Отмена
            </TableCell>
            <TableCell className={classes.statisticItem}>
              <StatisticItem
                type={locales.t('home.statistic.allRejectsReserve')}
                stat={renderStats.period.totalCanceled}
                metric={renderStats.percent.totalCanceled}
              />
            </TableCell>
            <TableCell className={classes.statisticItem}>
              <StatisticItem
                type={locales.t('home.statistic.missed')}
                stat={renderStats.period.totalSkipped}
              />
            </TableCell>
            <TableCell className={classes.statisticItem}>
              <StatisticItem
                type={locales.t('home.statistic.totalCancelledByGuest')}
                stat={renderStats.period.totalCancelledByGuest}
              />
            </TableCell>
            <TableCell className={classes.statisticItem}>
              <StatisticItem
                type={locales.t('home.statistic.totalCancelledByRestaurant')}
                stat={renderStats.period.totalCancelledByRestaurant}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

export default withStyles(styles)(HomeStatisticsTable);
