import moment, { Moment } from 'moment';
import _ from 'lodash';

import { dashBoardDates, emptyString, emptyTime } from '../config/constants';
import { TReserve } from '../types/IBookingStore';
import { IRestaurantWorkTimes, IWorkTime } from '../types/IScheduleStore';
import { parseTimeToInt } from './scheduleHelpers';

/** @description Парсинг даты для запроса статистики */
const parseDateForStatisticReq = (period: any): any => {
  const today = moment().endOf('day');
  let date = null;

  switch (period.type || period) {
    case dashBoardDates.today: {
      date = {
        from: moment()
          .startOf('day'),
        to: today,
      };
      break;
    }
    case dashBoardDates.tomorrow: {
      date = {
        from: moment()
          .subtract(1, 'day')
          .startOf('day'),
        to: moment()
          .subtract(1, 'day')
          .endOf('day'),
      };
      break;
    }
    case dashBoardDates.seven: {
      date = {
        from: moment()
          .subtract(7, 'day')
          .startOf('day'),
        to: today,
      };
      break;
    }
    case dashBoardDates.fourteen: {
      date = {
        from: moment()
          .subtract(14, 'day')
          .startOf('day'),
        to: today,
      };
      break;
    }
    case dashBoardDates.twentyEight: {
      date = {
        from: moment()
          .subtract(28, 'day')
          .startOf('day'),
        to: today,
      };
      break;
    }
    case dashBoardDates.sixty: {
      date = {
        from: moment()
          .subtract(60, 'day')
          .startOf('day'),
        to: today,
      };
      break;
    }
    case dashBoardDates.firstMonth: {
      date = {
        from: moment()
          .subtract(2, 'month')
          .startOf('month'),
        to: moment()
          .subtract(2, 'month')
          .endOf('month'),
      };
      break;
    }
    case dashBoardDates.secondMonth: {
      date = {
        from: moment()
          .subtract(1, 'month')
          .startOf('month'),
        to: moment()
          .subtract(1, 'month')
          .endOf('month'),
      };
      break;
    }
    case dashBoardDates.currentMonth: {
      date = {
        from: moment().startOf('month'),
        to: today,
      };
      break;
    }

    case dashBoardDates.custom: {
      return period.value;
    }

    default:
      date = {
        from: moment().startOf('day'),
        to: today,
      };
  }

  return {
    from: date.from.format('YYYY-MM-DDTHH:mm:00'),
    to: date.to.format('YYYY-MM-DDTHH:mm:00'),
  };
};

/** @description Возвращаем месяц для дейт пикера */
const monthForPeriodPicker = (period: any): any => {
  switch (period) {
    case dashBoardDates.firstMonth: {
      const month = moment().subtract(2, 'month').format('MMMM YYYY');
      return month[0].toUpperCase() + month.slice(1);
    }
    case dashBoardDates.secondMonth: {
      const month = moment().subtract(1, 'month').format('MMMM YYYY');
      return month[0].toUpperCase() + month.slice(1);
    }
    case dashBoardDates.currentMonth: {
      const month = moment().format('MMMM YYYY');
      return month[0].toUpperCase() + month.slice(1);
    }

    default: return '';
  }
};

/**
 * Возвращает дату для таблицы резервов в формате HH:mm
 * @param date
 * @returns {string}
 */
const parseDateFromBookingList = (date: any): any => moment(date).format('HH:mm');

/**
 * Возвращает дату для таблицы резервов в формате "21 апр. 21:30"
 * @param date
 * @returns {string}
 */
const parseCreateDateFromBookingList = (date: any): any => moment(date)
  .locale('ru')
  .format('DD MMM HH:mm');

/**
 * Возвращает дату для пикера в разделе резервы DD:MMMM
 * @param date
 */
// TODO надо продумать обработку кейсов когда прилетает невалидная date
const parseDateFromBookingDatePicker = (date: any): any => {
  if (!_.isUndefined(date)) {
    if (moment(date).format('YYYY:MM:DD') === moment().format('YYYY:MM:DD')) {
      return {
        date: `Сегодня, ${moment(date).format('D MMM')}`,
        day: moment(date).locale('ru').format('dddd'),
      };
    }

    return {
      date: moment(date).format('D MMM'),
      day: moment(date).locale('ru').format('dddd'),
    };
  }
  return {
    date: 'Дата не валидна, обновите страницу.',
  };
};

/** @description Возращает период */
const getPeriod = (date: {
  from: string;
  to: string;
}): string => {
  const from = moment(date.from);
  const to = moment(date.to);

  const diffDays: any = to.diff(from, 'days');

  switch (diffDays) {
    case diffDays < 7 || (diffDays > 7 && diffDays < 31):
      return 'DAY';
    case diffDays > 31:
      return 'MONTH';
    default:
      return 'DAY';
  }
};

/** @description Возвращает формат даты */
const getDateFormat = (date: {
  from: string;
  to: string;
}): string => {
  const from = moment(date.from);
  const to = moment(date.to);

  const diffDays: any = to.diff(from, 'days');

  switch (diffDays) {
    case diffDays < 7:
      return 'ddd';
    case diffDays > 7 && diffDays < 31:
      return 'DD.MM';
    case diffDays > 31:
      return 'MM.YYYY';
    default:
      return 'ddd';
  }
};

/**
 * @description Округление текущей даты
 * @param roundBy - дефолтное значение 15
 * @returns {Object}
 */
const roundCurrentTimeBy = (roundBy: number = 15): Moment => {
  const interval = roundBy * 60 * 1000;
  // @ts-ignore
  return moment(Math.ceil(moment() / interval) * interval);
};

/**
 * @description Ближайшая дата для резерва: следующая 15-минутная отсечка;
 *   если до ближайшей меньше 10 минут, то через одну
 */
const getNextTime = (): Date => {
  const interval = 15;
  const gap = 10;
  const ms = 60 * 1000;
  const intervalMs = interval * ms;

  const now = Date.now();
  let next = Math.ceil(now / intervalMs) * intervalMs;

  if (now + gap * ms > next) {
    next += intervalMs;
  }

  return new Date(next);
};

/**
 * @description Слияние даты и времени для запроса на бронирование
 * @param {date} date
 * @param {string} time
 */
const mergeDateAndTime = (date: any, time: any): any => {
  const parseTime = time.split(':');
  return moment(date).set(({
    hour: parseTime[0], minute: parseTime[1], second: 0, millisecond: 0,
  }));
};

/**
 * @description Ищем новую дату
 * @param oldTimeList
 * @param newTimeList
 * @param time
 */
const getSelectedTimeFromByNewDate = (
  oldTimeList: Array<IWorkTime>, newTimeList: Array<IWorkTime>, time: string,
) => {
  const oldTime = _.find(oldTimeList, t => t.value === time);

  if (oldTime) {
    const time = _.find(newTimeList, { label: oldTime.label, type: oldTime.type });
    return time ? time.value : emptyTime;
  }

  return newTimeList.length > 0 ? newTimeList[0].value : emptyTime;
};

/**
 * @description Парсинг времени для getTimeList
 * @param date
 * @param time - Время в виде строки в формате HH:MM
 */
const parseTimeByString = (date: Moment, time: string): Moment => {
  const parse = time
    .split(':')
    .map(i => parseInt(i));

  return moment(date).hours(parse[0]).minutes(parse[1]);
};

/**
 * @description Генерация времени для getTimeList
 * @param start - Время начала работы ресторана
 * @param end - Время завершения работы ресторана
 * @param type - Тип времени current либо previous
 */
const generateTimeList = (start: Moment, end: Moment, type: string): Array<IWorkTime> => {
  const timeList = [];
  const stop = end > start
    ? end
      .diff(start, 'minutes') / 15
    : end
      .add(1, 'day')
      .diff(start, 'minutes') / 15;

  for (let i = 0; i <= stop; i += 1) {
    start.add(i === 0 ? 0 : 15, 'minutes');
    timeList.push({
      label: start.format('HH:mm'),
      value: start.format('YYYY-MM-DDTHH:mm:00'),
      type,
    });
  }

  return timeList;
};

/** @description Проверяем кейс когда текущее время не попадает в рамки рабочего времени в текущий день */
function isPastTime(workTime: { start: string, end: string }, selectedDate: Moment) {
  const parseStart = parseTimeToInt(workTime.start);
  const parseEnd = parseTimeToInt(workTime.end);
  const isToday = moment(0, 'HH')
    .diff(selectedDate, 'day') === 0;
  const currentTime = roundCurrentTimeBy();
  const notWorkingTime = currentTime.diff(parseTimeByString(moment(), workTime.end)) > 0;

  return parseStart < parseEnd && isToday && notWorkingTime;
}

/**
 * @description Формирование списка для бронирования
 * @param date - Дата дня для которого нужно получить расписание
 * @param scheduleList - Расписание работы ресторана
 * @param isEdit - Флаг для определения для какой формы генерим расписание
 */
const getTimeList = (
  date: Moment | string, scheduleList?: IRestaurantWorkTimes, isEdit?: boolean,
): Array<IWorkTime> => {
  const selectedDate = moment(date);
  const weekDay = moment(date)
    .locale('en')
    .format('dddd')
    .toUpperCase();

  const currentWorkTime = scheduleList ? scheduleList[weekDay] : null;
  let timeLabels;

  if (
    !currentWorkTime
    || !(currentWorkTime.start && currentWorkTime.end)
    || isPastTime(currentWorkTime, selectedDate)
  ) {
    return [];
  }

  const isToday = moment(0, 'HH')
    .diff(date, 'day') === 0;

  if (currentWorkTime.start && currentWorkTime.end) {
    const currentTime = roundCurrentTimeBy();
    const notWorkingTime = parseTimeByString(moment(), currentWorkTime.start).diff(currentTime) > 0;

    const startTime = (!isToday || notWorkingTime) ? parseTimeByString(selectedDate, currentWorkTime.start) : currentTime;
    const endTime = parseTimeByString(selectedDate, currentWorkTime.end);

    timeLabels = generateTimeList(startTime, endTime, 'current');

    // Для кейса когда открываем форму редактирования и время брони уже прошло
    if (isEdit && (
      _.findIndex(timeLabels, { value: selectedDate.format('YYYY-MM-DDTHH:mm:00') }) === -1
    )) {
      timeLabels.unshift({
        value: moment(selectedDate).format('YYYY-MM-DDTHH:mm:00'),
        type: '',
        label: moment(selectedDate).format('HH:mm'),
      });
    }
  }

  if (currentWorkTime.previous && !isToday) {
    const previousDay = moment(selectedDate);
    const previousDayStart = moment(previousDay).hours(0).minutes(0);
    const previousDayEnd = parseTimeByString(previousDay, currentWorkTime.previous);

    const previousDayEndTimeLabels = generateTimeList(previousDayStart, previousDayEnd, 'previous');

    return previousDayEndTimeLabels.concat([{
      label: emptyString, value: emptyString, type: 'previous',
    }], timeLabels || []);
  }

  return timeLabels || [];
};

/** @description Парсинг статуса брони */
const getBookingStatus = (booking: TReserve): string => {
  const currentDate = moment();
  const bookingDate = moment(booking.date);
  const dateDiff = bookingDate.diff(currentDate, 'minutes');

  if (bookingDate < currentDate) {
    return 'GUEST_IS_LATE';
  } if (dateDiff <= 60) {
    return 'NEED_PREPARE_TABLE';
  } return booking.state;
};

/** @description Парсинг даты для отправки на бэк */
const parseDateForReq = (date: string | Moment): string => moment(date).format('YYYY-MM-DDTHH:mm:00');

/** @description Разбираем минуты на часы и минуты */
function getHoursAndMinutes(ms: number): { h: number, m: number } {
  const h = Math.floor(ms / 60);
  const m = ms - (h * 60);

  return { h, m };
}

export {
  parseDateForStatisticReq,
  parseDateFromBookingList,
  parseCreateDateFromBookingList,
  getPeriod,
  getDateFormat,
  getHoursAndMinutes,
  getSelectedTimeFromByNewDate,
  parseDateFromBookingDatePicker,
  getTimeList,
  generateTimeList,
  parseTimeByString,
  roundCurrentTimeBy,
  mergeDateAndTime,
  getBookingStatus,
  parseDateForReq,
  monthForPeriodPicker,
  getNextTime,
};
