import { IAppActions } from '../types/IAppActions';
import { SOCKET_RECONNECT, SET_SOCKET_CONNECT_STATUS } from '../actions/webSocketService/WebSocketActionsTypes';
import { IWebsocketStore } from '../types/IWebsocketStore';

export const initState: IWebsocketStore = {
  socketIsConnect: undefined,
  socketIsReconnect: false,
};

export default function reducer(state: IWebsocketStore = initState, action: IAppActions): IWebsocketStore {
  switch (action.type) {
    case SET_SOCKET_CONNECT_STATUS: {
      return {
        ...state,
        socketIsConnect: action.payload.isConnect,
        socketIsReconnect: false,
      };
    }

    case SOCKET_RECONNECT: {
      return {
        ...state,
        socketIsReconnect: true,
      };
    }

    default:
      return state;
  }
}
