import React from 'react';
import classNames from 'classnames';

import AccountCircle from '@material-ui/icons/AccountCircle';
import Paper from '@material-ui/core/Paper';
import {
  Typography, createStyles, withStyles, WithStyles,
} from '@material-ui/core';
import moment from 'moment';

import locales from '../../../locales';
import { TSourceInfo } from '../../../types/ISourceStatistic';
import UserStatisticTableItem
  from '../../Common/Statistic/StatisticTable/UserStatisticTableItem.tsx';
import StatisticItem from '../../Common/Statistic/StatisticTable/StatisticTableItem';

interface Props {
  sourceInfo: TSourceInfo;
  statistic: {
    total: number;
    totalCancelledByGuest: number;
    totalCancelledByRestaurant: number;
    totalDeclined: number;
    totalClosed: number;
    totalReceiptAmount: number;
    averageReceiptAmount: number;
    commissionAmount: number;
  };
  moneySymbol: string;
}

const styles = () => createStyles({
  wrap: {
    height: '100%',
    minHeight: 302,
  },
  roleWrap: {
    padding: '24px 24px 16px 24px',
    display: 'flex',
  },
  roleTitle: {
    backgroundColor: '#f7f8fb',
    padding: '0px 24px',
    height: 24,
    display: 'flex',
    alignItems: 'center',
  },
  activityDateWrap: {
    paddingTop: 12,
  },
  infoWrap: {
    display: 'flex',
    padding: '0 16px',
  },
  userInfoWrap: {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'column',
  },
  statusWrap: {
    padding: '4px 8px',
    textTransform: 'uppercase',
    backgroundColor: 'rgb(106, 233, 197, 0.3)',
    color: 'black',
  },
  statusWrap__deactivated: {
    backgroundColor: 'rgb(244, 88, 88, 0.3)',
  },
  userIcon: {
    width: 77,
    height: 77,
    marginRight: 14,
  },
  userStatWrap: {
    display: 'flex',
    padding: '50px 24px 50px 24px',
  },
});

interface IProps extends Props, WithStyles<typeof styles> {}

const index: React.FC<IProps> = ({ sourceInfo, classes, statistic, moneySymbol }) => {
  const createdDate = sourceInfo.createdDate ? moment(sourceInfo.createdDate).format('DD MMMM YYYY г.') : '—';
  const lastReserve = sourceInfo.lastReserve ? moment(sourceInfo.lastReserve).format('DD MMMM YYYY г.') : '—';

  const isActive = sourceInfo.isActive ? (
    <Typography className={classes.statusWrap} variant="overline">
      { locales.t('sourceStatistic.sourceCard.status.active') }
    </Typography>
  ) : (
    <Typography className={classNames(classes.statusWrap, classes.statusWrap__deactivated)} variant="overline">
      { locales.t('sourceStatistic.sourceCard.status.notActive') }
    </Typography>
  );

  return (
    <Paper elevation={3} className={classes.wrap}>
      <div className={classes.roleWrap}>
        <div className={classes.roleTitle}>
          <Typography variant="body1">
            { locales.t('sourceStatistic.sourceCard.source') }
          </Typography>
        </div>
      </div>
      <div className={classes.infoWrap}>
        <AccountCircle
          color="secondary"
          className={classes.userIcon}
        />
        <div>
          <Typography variant="h4">
            { sourceInfo ? sourceInfo.name : '—' }
          </Typography>
          <Typography variant="body1">
            { sourceInfo ? sourceInfo.description : '—' }
          </Typography>
          <div className={classes.userInfoWrap}>
            <div>
              { isActive }
            </div>
            <div className={classes.activityDateWrap}>
              <Typography variant="body2">
                { `${locales.t('sourceStatistic.sourceCard.createdDate')}: ${createdDate}` }
              </Typography>
              <Typography variant="body2">
                { `${locales.t('sourceStatistic.sourceCard.lastReserve')}: ${lastReserve}` }
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.userStatWrap}>
        <UserStatisticTableItem
          type={locales.t('sourceStatistic.statisticTable.checkRow.average')}
          stat={`${statistic.averageReceiptAmount.toFixed()} ${moneySymbol}`}
          textAlign="center"
        />
        <UserStatisticTableItem
          type={locales.t('sourceStatistic.statisticTable.reservesRow.success')}
          stat={statistic.totalClosed || 0}
          textAlign="center"
        />
        <UserStatisticTableItem
          type={locales.t('sourceStatistic.statisticTable.checkRow.summary')}
          stat={`${statistic.totalReceiptAmount.toFixed()} ${moneySymbol}`}
          textAlign="center"
        />
      </div>
    </Paper>
  );
};

export default withStyles(styles)(index);
