import React from 'react';
import { WrappedFieldProps } from 'redux-form';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography/Typography';
import { withStyles, WithStyles, createStyles } from '@material-ui/core';

const styles = createStyles({
  formTextInput: {
    // fontSize: '0.8125rem'
  },
  wrap: {
    textAlign: 'right',
  },
  textAreaCont: {
    '&& textarea': {
      overflowX: 'hidden',
    },
  },
});

interface IProps extends WithStyles<typeof styles>, WrappedFieldProps {
  label: string;
  type: string;
  staticValue: string;
  rows: number;
  disabled: boolean;
}

const TextAreaComp: React.FC<IProps> = ({
  input,
  label,
  type,
  staticValue,
  meta: { touched, error },
  rows = 3,
  classes,
  disabled,
}) => (
  <FormControl fullWidth>
    <TextField
      className={classes.textAreaCont}
      {...input}
      multiline
      rows={rows}
      label={(touched && !!error) ? error : label}
      error={touched && !!error}
      type={type}
      inputProps={{ maxLength: 160 }}
      disabled={!!staticValue || disabled}
    />
    <div className={classes.wrap}>
      <Typography variant="caption">
        {input.value ? input.value.length : 0}
        /160
      </Typography>
    </div>
  </FormControl>
);

export default withStyles(styles)(TextAreaComp);
