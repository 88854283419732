import React, { Fragment } from 'react';
import wrapSvgPath from './wrapSvgPath';

const flashInactive = (
  <Fragment>
    <g id="_x30_27---Flash">
      <circle fill="#B3B5B5" cx="256" cy="256" r="256" />
      <path fill="#919191" d="M200.5,78.7L133.4,280c-0.5,1.6-0.3,3.3,0.7,4.6c1,1.3,2.5,2.1,4.2,2.1h88.3
			c1.6,0,3.2,0.8,4.1,2.1s1.2,3,0.8,4.6l-42.2,140.3c-0.7,2.3,0.3,4.8,2.3,6c2.1,1.2,4.7,0.8,6.3-1l179.6-204.8
			c1.3-1.5,1.7-3.6,0.9-5.5s-2.6-3-4.7-3h-81.5c-1.7,0-3.3-0.8-4.2-2.3s-1.1-3.2-0.5-4.8l53-132.5c1.3-3.2,0.9-6.7-1-9.5
			s-5.1-4.5-8.5-4.5H210.2C205.8,71.7,201.9,74.5,200.5,78.7L200.5,78.7z"
      />
    </g>
  </Fragment>
);

export default wrapSvgPath(flashInactive, '0 0 512 512');
