import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { Routers } from '../config/constants';
import PageContentWrap from '../components/Common/PageContentWrap';

import {
  EmployeeListContainer,
  EmployeeContainer,
  EmployeeFormContainer,
} from '../containers/Employees';

const EmployeesPage: React.FC = () => (
  <PageContentWrap fullWidth spacing={0}>
    <Switch>
      <Route exact path={`${Routers.employees}/add`}>
        <EmployeeFormContainer type="add" />
      </Route>
      <Route exact path={`${Routers.employees}/:employeeId`} component={EmployeeContainer} />
      <Route exact path={`${Routers.employees}/:employeeId/edit`}>
        <EmployeeFormContainer type="edit" />
      </Route>
      <Route path={Routers.employees} component={EmployeeListContainer} />
    </Switch>
  </PageContentWrap>
);

export default EmployeesPage;
