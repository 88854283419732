import React from 'react';

import { CallNotification } from '../../../../../types/INotificationsStore';
import BodyVisits from '../BodyVisits';

interface CallBodyProps {
  notification: CallNotification;
}

function CallBody(props: CallBodyProps) {
  const { notification } = props;

  return notification.open ? <BodyVisits notification={notification} /> : null;
}

export default CallBody;
