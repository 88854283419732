import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Layer, Stage } from 'react-konva';
import Konva from 'konva';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Increase from '@material-ui/icons/Add';
import Decrease from '@material-ui/icons/Remove';
import FilterCenterFocusIcon from '@material-ui/icons/FilterCenterFocus';
import { withStyles, WithStyles } from '@material-ui/core/styles';

import TableElement from './TableElement';
import ImageElement from './ImageElement';
import RectElement from './RectElement';
import EllipseElement from './EllipseElement';
import TextElement from './TextElement';

import {
  genAvailableTableMap,
  getScaleToFit,
  handleTouchMove,
  handleZoomChange,
  hasCapacity,
  SCALE_MAX,
  SCALE_MIN,
  scaleDown,
  scaleUp,
} from './utils';

import { TActiveTables } from '../../../../types/IReserveModalStore';
import { ITable } from '../../../../types/IRestaurantTablesStore';
import {
  TSectionAdaptSchema,
  TSectionElementScheme,
  TSectionImageScheme,
  TSectionMarkScheme,
  TSectionTableScheme,
} from '../../../../types/IRestaurantTablesSchemaStore';

import styles from './styles';

// to avoid issue with multi-touch scaling
Konva.hitOnDragEnabled = true;

const StyledButton = withStyles({
  root: {
    minWidth: 38,
    maxWidth: 38,
  },
})(Button);

interface IProps extends WithStyles<typeof styles> {
  handleSelect?: (table: ITable) => void;
  tables: ITable[],
  activeTables: TActiveTables;
  data: TSectionAdaptSchema;
  customWidthOffset?: boolean;
  persons: number;
  readOnly?: boolean;
}

function RestaurantSchema(props: IProps) {
  const {
    classes, data, tables, activeTables, handleSelect, customWidthOffset, persons,
    readOnly,
  } = props;

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [scale, setScale] = useState(0.5);
  const [offsetWidth, setOffsetWidth] = useState(250);

  const availableTables = useMemo(() => genAvailableTableMap(tables), [tables]);

  const divEl = useRef<HTMLDivElement>(null);
  const shapeRef = useRef<Konva.Stage>(null);

  const scaleTo = (scale: number) => {
    setScale(scale);
    if (shapeRef.current) {
      const stage = shapeRef.current;
      // reset position after dragging/scaling
      stage.setPosition({ x: 0, y: 0 });
      stage.batchDraw();
    }
  };

  const scaleToFit = () => {
    scaleTo(getScaleToFit(data, width, height));
  };

  const onTableSelect = (data: ITable) => {
    const table = tables.find(t => t.id === data.id);
    if (table && typeof handleSelect === 'function') handleSelect(table);
  };

  // on mount
  useLayoutEffect(() => {
    if (divEl.current) {
      const { width, height } = divEl.current.getBoundingClientRect();
      scaleTo(getScaleToFit(data, width, height));
    }

    const resize = () => {
      // Determine rectangle on screen
      if (divEl.current) {
        const { width, height } = divEl.current.getBoundingClientRect();
        setHeight(height);
        setWidth(width);
        setOffsetWidth(divEl.current.offsetLeft);
      }
    };

    resize();

    window.addEventListener('resize', resize);
    return () => window.removeEventListener('resize', resize);
  }, []);


  // handle zoom change
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (shapeRef.current) {
      return handleZoomChange(shapeRef.current, setScale);
    }
  }, [shapeRef.current]);
  // handle touch move
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (shapeRef.current) {
      return handleTouchMove(shapeRef.current, setScale);
    }
  }, [shapeRef.current]);

  // handle data change
  useEffect(scaleToFit, [data, width]);


  return (
    <div
      ref={divEl}
      className={classes.wrap}
      style={{
        width: customWidthOffset && offsetWidth ? `calc(100vw - ${offsetWidth}px - 24px)` : '100%',
      }}
    >
      <div className={classes.buttonRight}>
        <StyledButton
          className={classNames(classes.button, classes.buttonShadow)}
          onClick={scaleToFit}
        >
          <FilterCenterFocusIcon />
        </StyledButton>
        <ButtonGroup
          className={classNames(classes.buttonGroup, classes.buttonShadow)}
          orientation="vertical"
          aria-label="vertical button group"
        >
          <StyledButton
            className={classes.button}
            onClick={() => scaleTo(scaleUp(scale))}
            disabled={scale >= SCALE_MAX}
          >
            <Increase />
          </StyledButton>
          <StyledButton
            className={classes.button}
            onClick={() => scaleTo(scaleDown(scale))}
            disabled={scale <= SCALE_MIN}
          >
            <Decrease />
          </StyledButton>
        </ButtonGroup>
      </div>
      <Stage
        ref={shapeRef}
        width={width}
        height={height}
        scaleX={scale}
        scaleY={scale}
        draggable
      >
        <Layer>
          { data.elements.map(({ type, element }: any, i: number) => {
            switch (type) {
              case 'table': {
                const data: TSectionTableScheme = element;
                const { table }: { table: ITable } = element;
                // checking for enough capacity behind a table
                const isCapable = hasCapacity(table, persons);
                const selected = !!activeTables[table.id];
                return (
                  <TableElement
                    key={table.id}
                    data={data}
                    handleSelect={onTableSelect}
                    active={selected}
                    disabled={!availableTables[table.id] || !isCapable || readOnly}
                  />
                );
              }
              case 'rect': {
                const data: TSectionElementScheme = element;
                return (
                  <RectElement
                    key={i.toString()}
                    data={data}
                  />
                );
              }
              case 'ellipse': {
                const data: TSectionElementScheme = element;
                return (
                  <EllipseElement
                    key={i.toString()}
                    data={data}
                  />
                );
              }
              case 'image': {
                const data: TSectionImageScheme = element;
                return (
                  <ImageElement
                    key={data.image ? data.image.id.toString() : i.toString()}
                    data={data}
                  />
                );
              }
              case 'text': {
                const data: TSectionMarkScheme = element;
                return <TextElement key={i.toString()} data={data} />;
              }
              default: {
                return null;
              }
            }
          }) }
        </Layer>
      </Stage>
    </div>
  );
}

export default withStyles(styles)(RestaurantSchema);
