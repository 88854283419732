import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Countdown, { zeroPad } from 'react-countdown-now';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import PinCode from './PinCode';
import locales from '../../../locales';

const styles = {
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: 300,
  },
  btnMargin: {
    marginTop: 20,
  },
};

// TODO Переписать счетчик, убрать react-countdown-now
class ConnectSystemForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCountdown: true,
    };
  }

  /**
   * Решаем проблему с отображением счетчика при повторном запросе пинкода.
   * Если не перерендерить счетчик, то отсчет повторно не запускается.
   */
  handleRerunCountdown = () => {
    const { handleGetPinCode } = this.props;
    this.setState({
      showCountdown: false,
    }, () => {
      handleGetPinCode();
      this.setState({
        showCountdown: true,
      });
    });
  };

  renderCountdown = ({ minutes, seconds, completed }) => {
    const { pinCode } = this.props;

    return (
      <PinCode
        pinCode={pinCode}
        minutes={zeroPad(minutes)}
        seconds={zeroPad(seconds)}
        timeCompleted={completed}
        handleGetPinCode={this.handleRerunCountdown}
      />
    );
  };

  render() {
    const {
      classes,
      pinCode,
      timeLeft,
      connectStatus,
      handleGetPinCode,
      handleCloseConnectModal,
    } = this.props;

    const { showCountdown } = this.state;

    if (connectStatus) {
      return (
        <div className={classes.content}>
          <Typography variant="h6" component="h2" color="primary">
            {locales.t('home.pinCodeSuccess')}
          </Typography>
          <Button
            className={classes.btnMargin}
            variant="contained"
            color="primary"
            onClick={handleCloseConnectModal}
          >
            {locales.t('home.pinCodeSuccessBtn')}
          </Button>
        </div>
      );
    }

    return (
      <div className={classes.content}>
        {!pinCode && (
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={handleGetPinCode}
            >
              {locales.t('home.getPinCodeBtn')}
            </Button>
          </div>
        )}
        {pinCode && timeLeft && showCountdown
        && (
          <Countdown
            date={Date.now() + (timeLeft * 1000)}
            renderer={this.renderCountdown}
            onComplete={() => console.log('timer end')}
          />
        )}
      </div>
    );
  }
}

export default withStyles(styles)(ConnectSystemForm);
