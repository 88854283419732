import {
  all,
  fork,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { getFormValues } from 'redux-form';
import _ from 'lodash';
import * as Sentry from '@sentry/react';

import {
  confirmReserveReq,
  createReserveReq,
  // getUnconfirmedReserves,
  getFreeTablesReq,
  getReserveInfo,
  patchReserveReq,
  sendRejectReserveRequest, getOpenReserves,
} from '../api';
import { fetchSections } from './RestaurantsSectionsSaga';
import * as RejectReserveActionTypes
  from '../actions/reserveReject/RejectReserveActionsTypes';
import * as ReserveModalActionsTypes
  from '../actions/reservationsPage/ReservationsPageActionsTypes';
import * as ReserveModalActions
  from '../actions/reservationsPage/ReservationsPageActions';
import * as ReserveRejectActions
  from '../actions/reserveReject/RejectReserveActions';
import * as IReserveActions
  from '../actions/reservationsPage/IReservationsPageActions';
import { IRejectReserveReq } from '../actions/reserveReject/IRejectReserve';
import { TReserve, TOpenReserves } from '../types/IBookingStore';
import { IAppStore } from '../types/IAppStore';
import { ITable } from '../types/IRestaurantTablesStore';
import { GET_BOOKING_REQUEST } from '../actions/booking/BookingActionsTypes';
import locales from '../locales';
import {
  customErrorShackBar,
  customMessageShackBar,
} from '../actions/snackbars/SnackBarsActions';

import { INIT_END } from '../actions/cabinet/CabinetActionsTypes';
import { getReserveFormName } from '../containers/Reservation/constants';

// import { reservesMockSliced } from '../api/mocks/reserves';
import { TNotification } from '../types/INotificationsStore';
import { removeNotification } from '../actions/notifications/NotificationsActions';
import { changeAppReserve, removeAppReserve } from '../actions/appReserves/AppReservesActions';
import { selectorOpenModal } from '../containers/Reservation/selectors';
import { TModalTypes } from '../pages/ReservationsPage';
import Reserve from '../types/IAppReservesStore';


/** @description Сага для отмена резева */
function* reserveRejectSaga(action: IRejectReserveReq) {
  try {
    const { reserveId } = action.payload;
    const response: any = yield sendRejectReserveRequest(reserveId);
    const reserveInfo: TReserve = response.data;
    yield put(ReserveRejectActions.rejectReserveSuccess(reserveInfo));
  } catch (e) {
    Sentry.captureException(e);
    yield put(ReserveRejectActions.rejectReserveError());
  }
}

/** @description Сага на редактирование резерва */
function* editReserveSaga(action: IReserveActions.IUpdateReserve) {
  const { reserveId, newReserveInfo } = action.payload;

  try {
    const updatedReserve = yield patchReserveReq(reserveId, newReserveInfo);
    yield put(ReserveModalActions.updateReserveSuccess(updatedReserve));
  } catch (e) {
    Sentry.captureException(e);
    yield put(ReserveModalActions.updateReserveError());
  }
}

function* updateReserveSuccessSaga(action: IReserveActions.IUpdateReserveSuccess) {
  const { updatedReserve } = action.payload;

  const reserve = yield select(
    (state: IAppStore) => state.appReserves.items.find((item: Reserve) => item.payload.id === updatedReserve.id),
  );

  if (reserve) {
    yield put(removeAppReserve(reserve.key));
  }
}

function* confirmReserveSuccessSaga(action: IReserveActions.IConfirmReserveSuccess) {
  const { reserveId } = action.payload;

  const reserve = yield select(
    (state: IAppStore) => state.appReserves.items.find((item: Reserve) => item.payload.id === reserveId),
  );

  if (reserve) {
    yield put(removeAppReserve(reserve.key));
  }
}

/** @description Сага на получение свободных столов */
function* fetchTables(reserve: TReserve, restaurantId: number) {
  try {
    const {
      tables, persons, duration, date,
    } = reserve;
    const personsForReq = tables && tables.length > 1 ? 0 : persons;

    const freeTables: Array<ITable> = yield getFreeTablesReq(restaurantId, date, personsForReq, duration)
      .then(r => _.sortBy(r.data, ['hall', i => parseInt(i.number)]));

    const tableList = tables ? tables.concat(freeTables) : freeTables;
    yield put(ReserveModalActions.getFreeTablesSuccess(tableList));
  } catch (e) {
    Sentry.captureException(e);
    yield put(ReserveModalActions.getFreeTablesError());
  }
}

const currentRestaurantSelector = (state: IAppStore) => state.cabinet.currentRestaurant;

// /** @description Сага на получение свободных столов и залов при открытии окна редактирования */
// function* openEditReserveModalSaga(action: IReserveActions.IOpenReserveEditModal) {
//   const restaurant = yield select(currentRestaurantSelector);
//   const restaurantId = restaurant ? restaurant.id : 0;
//
//   console.log('openEditReserveModalSaga', action.payload.reserve, 'EDIT');
//
//   yield fork(fetchTables, action.payload.reserve, restaurantId);
//   yield fork(fetchSections, restaurantId);
// }

// /** @description Сага на получение залов при открытии окна создания */
// function* openCreateReserveModalSaga() {
//   const restaurant = yield select(currentRestaurantSelector);
//   const restaurantId = restaurant ? restaurant.id : 0;
//
//   yield fork(fetchSections, restaurantId);
// }

/** @description Получение и сортировка подходящих столов для резерва. */
function* getFreeTablesSaga(action: IReserveActions.IGetFreeTables) {
  const restaurant = yield select((state: IAppStore) => state.cabinet.currentRestaurant);
  if (!restaurant) {
    yield put(ReserveModalActions.getFreeTablesError());
    return;
  }

  try {
    const {
      date,
      persons,
      duration,
      reserveId,
    } = action.payload;

    const openModal: TModalTypes = yield select(selectorOpenModal);
    const isEdit = openModal === 'EDIT';

    const reserveModal = yield select((state: IAppStore) => state.reserveModal);
    const { isMergeTables, activeTables: activeTablesObj } = reserveModal;

    const freeTables: Array<ITable> = yield getFreeTablesReq(
      restaurant.id,
      date,
      persons,
      duration,
      reserveId,

    ).then(r => _.sortBy(r.data, ['hall', i => parseInt(i.number)]));

    const activeTablesArr = Object.keys(activeTablesObj).map(id => activeTablesObj[id]);

    const freeTablesMap: { [id: number]: ITable } = freeTables
      .reduce((m: { [id: number]: ITable }, t) => ({ ...m, [t.id]: t }), {});

    const { isIncludeReserveTables } = activeTablesArr.reduce((r, t) => {
      // eslint-disable-next-line
      const tableIsFree = freeTablesMap.hasOwnProperty(t.id);

      if (tableIsFree && r.isIncludeReserveTables === true) {
        return r;
      }

      return { isIncludeReserveTables: false };
    }, { isIncludeReserveTables: true });

    if (isMergeTables) {
      if (isIncludeReserveTables) {
        const newFreeTables = freeTables.filter(t => !activeTablesObj[t.id]);
        yield put(ReserveModalActions.getFreeTablesSuccess(activeTablesArr.concat(newFreeTables)));
      } else {
        yield put(ReserveModalActions.resetActiveTable());
        yield put(customMessageShackBar(locales.t('common.reserveModal.resetActiveTables')));
        yield put(ReserveModalActions.getFreeTablesSuccess(freeTables));
      }

      return;
    }

    const activeTable = activeTablesArr[0] || null;
    const findTableBySection = (section: number) => (table: ITable) => (table.section || {}).id === section;

    const reserveFormName = getReserveFormName(openModal);
    let form;

    if (reserveFormName) {
      form = yield select(getFormValues(reserveFormName));
    }

    const { section: currentReserveSection } = form || {};
    // filter all free tables by current selected Section
    const currentFreeTables = currentReserveSection
      ? freeTables.filter(findTableBySection(currentReserveSection))
      : freeTables;

    if (isEdit) {
      /** В случае если это редактирования резерва */
      const rInfo: TReserve = yield select((state: IAppStore) => state.reserveModal.editReserveInfo);
      const { tables } = rInfo;

      if (isIncludeReserveTables && tables && tables.length) {
        /** Если стол резерва доступен в выборке свободных столов,
         * добавляем его в начало списка столов */
        const parseTableList = freeTables.filter(r => r.id !== tables[0].id);
        yield put(ReserveModalActions.getFreeTablesSuccess(tables.concat(parseTableList)));
      } else if (
        tables
        && !isIncludeReserveTables
        && activeTable.id === tables[0].id
      ) {
        /** Если стола резерва нету в списке столов и он ранее был активным,
         * назначаем активным первый стол из списка */
        yield put(ReserveModalActions.getFreeTablesSuccess(freeTables));
        yield put(ReserveModalActions.setActiveTable(currentFreeTables[0]));
        if (currentFreeTables.length) {
          yield put(customMessageShackBar(locales.t('common.reserveModal.wrongTableError')));
        } else {
          yield put(customMessageShackBar(locales.t('common.reserveModal.noActiveTablesInSection')));
        }
      } else {
        /** Для остальных кейсов возвращем список столов */
        if (activeTable && currentFreeTables.length > 0 && !freeTablesMap[activeTable.id]) {
          /** Если активного стола нет в списке новых столов,
           * назначаем активным первый из списка */
          yield put(ReserveModalActions.setActiveTable(currentFreeTables[0]));
          if (currentFreeTables.length) {
            yield put(customMessageShackBar(locales.t('common.reserveModal.setNewTable')));
          } else {
            yield put(customMessageShackBar(locales.t('common.reserveModal.noActiveTablesInSection')));
          }
        }

        yield put(ReserveModalActions.getFreeTablesSuccess(freeTables));
      }
    } else {
      /** Если активного стола нет в списке новых столов, назначаем активным первый из списка */
      if (activeTable && !freeTablesMap[activeTable.id]) {
        yield put(ReserveModalActions.setActiveTable(currentFreeTables[0]));
        if (currentFreeTables.length) {
          yield put(customMessageShackBar(locales.t('common.reserveModal.setNewTable')));
        } else {
          yield put(customMessageShackBar(locales.t('common.reserveModal.noActiveTablesInSection')));
        }
      }
      yield put(ReserveModalActions.getFreeTablesSuccess(freeTables));
    }
  } catch (e) {
    Sentry.captureException(e);
    yield put(ReserveModalActions.getFreeTablesError());
  }
}

/** @description Создание нового резерва для выбранного ресторана */
function* createReserveSaga(action: IReserveActions.ICreateReserve) {
  try {
    const restaurantId = yield select(state => state.cabinet.currentRestaurant.id);
    yield createReserveReq(action.payload, restaurantId);
    yield put(ReserveModalActions.createReserveSuccess());
  } catch (e) {
    Sentry.captureException(e);
    if (!_.isUndefined(e.response.data[0]) && e.response.data[0].code === 'reservation_incorrect_date') {
      yield put(ReserveModalActions.createReserveError('Не верно указано время резерва'));
    } else {
      yield put(ReserveModalActions.createReserveError());
    }
  }
}

// /** @description Получение и передача на форму данных о новой брони. */
function* openNewReserveModalSaga(action: IReserveActions.IOpenReserveConfirmModal) {
  const restaurant = yield select((state: IAppStore) => state.cabinet.currentRestaurant);
  const restaurantId = restaurant ? restaurant.id : 0;

  try {
    const reserveInfo: TReserve = yield getReserveInfo(action.payload.reserveId);
    const tables = reserveInfo.tables || [];

    const persons = tables.length > 1 ? 0 : reserveInfo.persons;
    const activeTables: { [id: number]: ITable } = tables.reduce((aT, t) => {
      if (t) {
        return {
          ...aT,
          [t.id]: t,
        };
      }

      return aT;
    }, {});

    const freeTables: Array<ITable> = yield getFreeTablesReq(
      restaurant.id, reserveInfo.date, persons, reserveInfo.duration, reserveInfo.id,
    )
      .then(r => _.sortBy(r.data, ['hall', i => parseInt(i.number)]));

    const newFreeTables = tables.concat(freeTables.filter(t => !activeTables[t.id]));

    yield put(ReserveModalActions.getReserveInfoSuccess(reserveInfo, activeTables, newFreeTables));
  } catch (e) {
    Sentry.captureException(e);
    yield put(ReserveModalActions.getReserveInfoError());
  }
  yield fork(fetchSections, restaurantId);
}

/** @description Подтверждение нового резерва
 * Для потдтверждения резерва:
 * 1. Выставляем стол для брони - по дефолту резервы требующие подтверждения без столов
 * 2. Отправляем запрос на подтверждение
 */
function* confirmNewReserveSaga(action: IReserveActions.IConfirmReserve) {
  const { reserveId, reserveInfo } = action.payload;

  try {
    yield patchReserveReq(reserveId, reserveInfo);
    yield confirmReserveReq(reserveId);
    yield put(ReserveModalActions.confirmReserveSuccess(reserveId));
  } catch (e) {
    Sentry.captureException(e);
    if (e.response.status === 409) {
      yield put(ReserveModalActions.closeReserveConfirmModal(reserveId));
      yield put(customErrorShackBar('Резерв был отменен гостем'));
    } else {
      yield put(ReserveModalActions.confirmReserveError());
    }
  }
}

/** @description Отмена резерва из формы подтверждение резерва */
function* rejectNewReserveSaga(action: any) {
  const { reserveId } = action.payload;

  try {
    yield sendRejectReserveRequest(reserveId);
    yield put(ReserveModalActions.rejectNewReserveSuccess(reserveId));
    // TODO Пофиксить вызов GET_BOOKING_REQUEST
    yield put({ type: GET_BOOKING_REQUEST });
  } catch (e) {
    Sentry.captureException(e);
    if (e.response.status === 409) {
      yield put(ReserveModalActions.closeReserveConfirmModal(reserveId));
      yield put(customErrorShackBar('Резерв был отменен гостем'));
    } else {
      yield put(ReserveModalActions.rejectNewReserveError());
    }
  }
}

/** @description Обработка очереди резервов требующих подтверждения */
// function* closeReserveModalSaga(action: any) {
//   const bookingQueue = yield select(state => state.reserveModal.bookingQueue);
//   const reserveId = action.payload ? action.payload.reserveId : null;
//
//   if (bookingQueue.length > 0) {
//     if (reserveId) {
//       const newQueue = bookingQueue.filter((i: any) => i !== reserveId);
//       yield put(ReserveModalActions.updateReserveQueue(newQueue));
//
//       if (newQueue.length > 0) {
//         yield put(ReserveModalActions.openReserveConfirmModal(newQueue[0]));
//       }
//     } else {
//       yield put(ReserveModalActions.openReserveConfirmModal(bookingQueue[0]));
//     }
//   }
// }

/** @description Сага для получения максимальной вместимости ресторана */
function* getMaxCapacitySaga() {
  // const { restaurant, tableList } = yield select((state: IAppStore) => ({
  //   restaurant: state.cabinet.currentRestaurant,
  //   tableList: state.tables.tableList,
  // }));
  // let maxCapacity = 0;
  // const maxCapacityCb = (t: ITable) => {
  //   if (maxCapacity < t.capacity) {
  //     maxCapacity = t.capacity;
  //   }
  // };
  //
  // try {
  //   if (tableList.length > 0) {
  //     tableList.forEach(maxCapacityCb);
  //   } else {
  //     const newTableList: Array<ITable> = yield getAllTableForRestaurantReq(restaurant.id)
  //       .then(res => res.data);
  //     newTableList.forEach(maxCapacityCb);
  //   }
  // } catch (e) {
  //   Sentry.captureException(e);
  //   yield put(ReserveModalActions.setMaxCapacity(0));
  // }

  yield put(ReserveModalActions.setMaxCapacity(100));
}

/**
 * @description Сага для получения неподтверждённых резервов
 * @todo Удалить после переделки на фазу 2 (https://guestme.atlassian.net/browse/DEV-340)?
 */
// function* fetchUnconfirmedReserves() {
//   const restaurant = yield select((state: IAppStore) => state.cabinet.currentRestaurant);
//   if (!restaurant || !restaurant.id) {
//     return;
//   }
//
//   try {
//     const reserves: Array<TReserve> = yield getUnconfirmedReserves(restaurant.id);
//
//     for (let i = 0; i < reserves.length; i += 1) {
//       if (i === 0) {
//         yield put(ReserveModalActions.openReserveConfirmModal(reserves[i].id));
//       } else {
//         yield put(ReserveModalActions.setReserveToQueue(reserves[i].id));
//       }
//     }
//   } catch (e) {
//     Sentry.captureException(e);
//     yield put(ReserveModalActions.fetchOpenReservesError(e.message));
//   }
// }

/** @description Сага назначения активного стола на странице создания/редактирования/подтверждения резервов */
function* setActiveTableSaga(action: IReserveActions.ISetActiveTable) {
  const {
    isMergeTables,
    activeTables,
  } = yield select((state: IAppStore) => state.reserveModal);
  const newTable = action.payload.activeTable;
  const activeTablesId = Object.keys(activeTables).map(id => parseInt(id));

  if (newTable) {
    if (isMergeTables) {
      if (activeTables[newTable.id]) {
        const newActiveTables = activeTablesId.reduce((t, id) => {
          if (id !== newTable.id) {
            return {
              ...t,
              [id]: activeTables[id],
            };
          }

          return t;
        }, {});

        yield put(ReserveModalActions.setActiveTableSuccess(newActiveTables));
      } else if (activeTablesId.length > 0 && activeTables[activeTablesId[0]].hall !== newTable.hall) {
        yield put(customErrorShackBar('Столы должны быть в одном зале'));
      } else {
        yield put(ReserveModalActions.setActiveTableSuccess({
          ...activeTables,
          [newTable.id]: newTable,
        }));
      }
    } else {
      yield put(ReserveModalActions.setActiveTableSuccess({
        [newTable.id]: newTable,
      }));
    }
  } else {
    yield put(ReserveModalActions.resetActiveTable());
  }
}

/** @description Сага для переключения функции объеденения столов */
export function* mergeTablesSaga(action: IReserveActions.IMergeTableReq) {
  const { getTableData } = action.payload;
  const restaurant = yield select((store: IAppStore) => store.cabinet.currentRestaurant);

  try {
    const freeTables: Array<ITable> = yield getFreeTablesReq(
      restaurant.id, getTableData.date, getTableData.persons, getTableData.duration, getTableData.reserveId,
    )
      .then(r => _.sortBy(r.data, ['hall', i => parseInt(i.number)]));
    yield put(ReserveModalActions.mergeTablesSuccess(action.payload.mergeTables, freeTables));
  } catch (e) {
    Sentry.captureException(e);
    yield put(ReserveModalActions.mergeTablesError());
  }
}

function* fetchOpenReserves() {
  const restaurant = yield select((state: IAppStore) => state.cabinet.currentRestaurant);
  if (!restaurant || !restaurant.id) {
    return;
  }

  try {
    const reserves: TOpenReserves = yield getOpenReserves(restaurant.id);
    // let reserves: TOpenReserves = yield getOpenReserves(restaurant.id);
    //
    // // @todo Убрать после тестирования
    // if (process.env.NODE_ENV === 'development' && reserves.length === 0) {
    //   reserves = reservesMockSliced;
    // }

    yield put(ReserveModalActions.fetchOpenReservesSuccess(reserves));
  } catch (e) {
    Sentry.captureException(e);
    yield put(ReserveModalActions.fetchOpenReservesError(e.message));
  }
}

function* removeOpenReserveSaga(action: IReserveActions.IRemoveOpenReserve) {
  const notification = yield select(state => state.notifications.items.find(
    (x: TNotification) => x.type === 'RESERVE' && x.payload.id === action.id,
  ));

  if (notification) {
    yield put(removeNotification(notification.getKey(), action.manually));
  }
}

function* confirmOpenReserveSaga(action: IReserveActions.IConfirmOpenReserve) {
  const { reserveId } = action;

  try {
    const responseReserve = yield confirmReserveReq(reserveId);
    yield put(
      ReserveModalActions.confirmOpenReserveSuccess(responseReserve.id),
    );
  } catch (e) {
    Sentry.captureException(e);
    yield put(ReserveModalActions.confirmOpenReserveError(
      e.response.status === 409 ? 'Резерв был отменен гостем' : e.message,
    ));
  }
}

function* openReserveSuccessSaga(
  action: IReserveActions.IConfirmOpenReserveSuccess | IReserveActions.IRejectOpenReserveSuccess,
) {
  yield put(ReserveModalActions.removeOpenReserve(action.reserveId));

  // @todo Как убирать нотификации?
  // yield put(removeNotification());
}

function* rejectOpenReserveSaga(action: IReserveActions.IRejectOpenReserve) {
  const { reserveId } = action;

  try {
    const responseReserve: TReserve = yield sendRejectReserveRequest(reserveId);
    yield put(
      ReserveModalActions.rejectOpenReserveSuccess(responseReserve.id),
    );
  } catch (e) {
    Sentry.captureException(e);
    yield put(ReserveModalActions.rejectOpenReserveError(
      e.response.status === 409 ? 'Резерв был отменен гостем' : e.message,
    ));
  }
}

// @todo Проверить как эти snackbar`ы работают
function* openReserveErrorSaga(
  action: IReserveActions.IConfirmOpenReserveError | IReserveActions.IRejectOpenReserveError,
) {
  yield put(customErrorShackBar(action.error));
}

function* setActiveTableSuccessSaga(
  action: IReserveActions.ISetActiveTableSuccess | IReserveActions.IResetActiveTable,
) {
  if (action.type === ReserveModalActionsTypes.SET_ACTIVE_TABLE_SUCCESS) {
    yield put(changeAppReserve(
      'tables',
      Object.values(action.payload.activeTable),
    ));
  } else {
    yield put(changeAppReserve('tables', undefined));
  }
}

function* openReserveModalSaga() {
  const restaurant = yield select(currentRestaurantSelector);
  yield fork(fetchSections, (restaurant && restaurant.id) || 0);
}

export default function* saga() {
  yield all([
    // takeLatest(INIT_END, fetchUnconfirmedReserves),

    takeLatest(RejectReserveActionTypes.REJECT_RESERVE_REQUEST, reserveRejectSaga),
    takeLatest(ReserveModalActionsTypes.UPDATE_RESERVE_REQUEST, editReserveSaga),
    takeLatest(ReserveModalActionsTypes.GET_FREE_TABLES_REQUEST, getFreeTablesSaga),
    takeLatest(ReserveModalActionsTypes.CREATE_RESERVE_REQUEST, createReserveSaga),
    takeLatest(ReserveModalActionsTypes.CONFIRM_RESERVE_REQUEST, confirmNewReserveSaga),
    takeLatest(ReserveModalActionsTypes.REJECT_NEW_RESERVE_REQUEST, rejectNewReserveSaga),

    // takeLatest(ReserveModalActionsTypes.OPEN_EDIT_RESERVE_MODAL, openEditReserveModalSaga),
    // takeLatest(ReserveModalActionsTypes.OPEN_CREATE_RESERVE_MODAL, openCreateReserveModalSaga),
    takeLatest(ReserveModalActionsTypes.OPEN_CONFIRM_RESERVE_MODAL, openNewReserveModalSaga),

    takeLatest([
      ReserveModalActionsTypes.OPEN_EDIT_RESERVE_MODAL,
      ReserveModalActionsTypes.OPEN_CREATE_RESERVE_MODAL,
      // ReserveModalActionsTypes.OPEN_CONFIRM_RESERVE_MODAL,
    ], openReserveModalSaga),

    // takeEvery([
    //   ReserveModalActionsTypes.CLOSE_CONFIRM_RESERVE_MODAL,
    //   ReserveModalActionsTypes.CLOSE_CREATE_RESERVE_MODAL,
    //   ReserveModalActionsTypes.CREATE_RESERVE_SUCCESS,
    //   ReserveModalActionsTypes.REJECT_NEW_RESERVE_SUCCESS,
    //   ReserveModalActionsTypes.CONFIRM_RESERVE_SUCCESS,
    // ], closeReserveModalSaga),

    takeEvery([
      ReserveModalActionsTypes.OPEN_CREATE_RESERVE_MODAL,
      ReserveModalActionsTypes.OPEN_CONFIRM_RESERVE_MODAL,
      ReserveModalActionsTypes.OPEN_EDIT_RESERVE_MODAL,
    ], getMaxCapacitySaga),
    takeLatest(ReserveModalActionsTypes.SET_ACTIVE_TABLE_REQ, setActiveTableSaga),
    takeLatest(ReserveModalActionsTypes.MERGE_TABLES_REQ, mergeTablesSaga),

    takeLatest(INIT_END, fetchOpenReserves),

    takeEvery(ReserveModalActionsTypes.REMOVE_OPEN_RESERVE, removeOpenReserveSaga),
    takeEvery(ReserveModalActionsTypes.CONFIRM_OPEN_RESERVE, confirmOpenReserveSaga),
    takeEvery(ReserveModalActionsTypes.REJECT_OPEN_RESERVE, rejectOpenReserveSaga),

    takeLatest([
      ReserveModalActionsTypes.CONFIRM_OPEN_RESERVE_ERROR,
      ReserveModalActionsTypes.REJECT_OPEN_RESERVE_ERROR,
    ], openReserveErrorSaga),

    takeLatest([
      ReserveModalActionsTypes.CONFIRM_OPEN_RESERVE_SUCCESS,
      ReserveModalActionsTypes.REJECT_OPEN_RESERVE_SUCCESS,
    ], openReserveSuccessSaga),

    takeEvery([
      ReserveModalActionsTypes.SET_ACTIVE_TABLE_SUCCESS,
      ReserveModalActionsTypes.RESET_ACTIVE_TABLE,
    ], setActiveTableSuccessSaga),

    takeEvery(
      ReserveModalActionsTypes.UPDATE_RESERVE_SUCCESS,
      updateReserveSuccessSaga,
    ),

    takeEvery(
      ReserveModalActionsTypes.CONFIRM_RESERVE_SUCCESS,
      confirmReserveSuccessSaga,
    ),
  ]);
}
