import * as ActionTypes from './ReservationsPageActionsTypes';
import {
  TActiveTables,
  TCreateReserveInfo,
  // TCreateReserveInitialData,
  TUpdateReserveInfo,
} from '../../types/IReserveModalStore';
import { IReservationsPageActions } from './IReservationsPageActions';
import { TOpenReserve, TOpenReserves, TReserve } from '../../types/IBookingStore';
import { ITable } from '../../types/IRestaurantTablesStore';
import { TNewReserveFields } from '../../types/IAppReservesStore';

/** @description Экшен на открытие модального окна для редактирования резерва */
export function openReserveEditModal(reserve: TReserve): IReservationsPageActions {
  return {
    type: ActionTypes.OPEN_EDIT_RESERVE_MODAL,
    payload: {
      reserve,
    },
  };
}

/** @description Экшен для закрытие модального она для редактирования резерва */
export function closeReserveEditModal(): IReservationsPageActions {
  return {
    type: ActionTypes.CLOSE_EDIT_RESERVE_MODAL,
  };
}

/** @description Экшен для отправки запроса на редактирование резерв */
export function updateReserve(reserveId: number, newReserveInfo: TUpdateReserveInfo): IReservationsPageActions {
  return {
    type: ActionTypes.UPDATE_RESERVE_REQUEST,
    payload: {
      reserveId,
      newReserveInfo,
    },
  };
}

/** @description Экшен для обработки успешного редактирования резерва */
export function updateReserveSuccess(updatedReserve: TReserve): IReservationsPageActions {
  return {
    type: ActionTypes.UPDATE_RESERVE_SUCCESS,
    message: 'Резерв обновляется',
    payload: {
      updatedReserve,
    },
  };
}

/** @description Экшен для обработки ошибки при редактирования резерва */
export function updateReserveError(): IReservationsPageActions {
  return {
    type: ActionTypes.UPDATE_RESERVE_ERROR,
    error: 'Не удалось отредактировать резерв',
  };
}

/** @description Экшен для отправки запроса на получение свободных столов */
export function getFreeTables(
  date: string, persons: number, duration: number, reserveId?: number,
): IReservationsPageActions {
  return {
    type: ActionTypes.GET_FREE_TABLES_REQUEST,
    payload: {
      date,
      persons,
      duration,
      reserveId,
    },
  };
}

/** @description Экшен для обработки успешного запроса на получение столов */
export function getFreeTablesSuccess(freeTables: Array<ITable>): IReservationsPageActions {
  return {
    type: ActionTypes.GET_FREE_TABLES_SUCCESS,
    payload: {
      freeTables,
      // @ts-ignore
      sections: [],
    },
  };
}

/** @description Экшен для обработки ошибки запроса на получение столов */
export function getFreeTablesError(): IReservationsPageActions {
  return {
    type: ActionTypes.GET_FREE_TABLES_ERROR,
    error: 'Не удалось получить список доступных столов',
  };
}

/** @description Экшен открыть окно для создания нового резерва */
// export function openReserveCreateModal(initialData?: TCreateReserveInitialData): IReservationsPageActions {
//   return {
//     type: ActionTypes.OPEN_CREATE_RESERVE_MODAL,
//     payload: {
//       initialData,
//     },
//   };
// }

export function openReserveCreateModal(initialData?: TNewReserveFields): IReservationsPageActions {
  return {
    type: ActionTypes.OPEN_CREATE_RESERVE_MODAL,
    payload: {
      initialData,
    },
  };
}

/** @description Экшен закрыть окно для создания нового резерва */
export function closeReserveCreateModal(): IReservationsPageActions {
  return {
    type: ActionTypes.CLOSE_CREATE_RESERVE_MODAL,
  };
}

/** @description Экшен для создания нового резерва */
export function createReserve(reserveInfo: TCreateReserveInfo): IReservationsPageActions {
  return {
    type: ActionTypes.CREATE_RESERVE_REQUEST,
    payload: {
      ...reserveInfo,
    },
  };
}

/** @description Экшен для обработки успешного запроса на создание нового резерва */
export function createReserveSuccess(): IReservationsPageActions {
  return {
    type: ActionTypes.CREATE_RESERVE_SUCCESS,
    message: 'Создан новый резерв',
  };
}

/** @description Экшен для обработки ошибки запроса на создание нового резерва */
export function createReserveError(error?: string): IReservationsPageActions {
  return {
    type: ActionTypes.CREATE_RESERVE_ERROR,
    error: error || 'Не удалось создать резерв',
  };
}

/** @description Экшен на обработку успешного получения инфы резерва */
export function getReserveInfoSuccess(
  reserveInfo: TReserve,
  activeTables: TActiveTables,
  freeTables: Array<ITable>,
): IReservationsPageActions {
  return {
    type: ActionTypes.GET_RESERVE_INFO_SUCCESS,
    payload: {
      reserveInfo,
      activeTables,
      freeTables,
    },
  };
}

/** @description Экшен на обработку ошибки получения инфы резерва */
export function getReserveInfoError(): IReservationsPageActions {
  return {
    type: ActionTypes.GET_RESERVE_INFO_ERROR,
    error: 'Не удалось получить информацию резерва',
  };
}

/** @description Экшен для открытия окна подтверждения резерва */
export function openReserveConfirmModal(reserveId: number): IReservationsPageActions {
  return {
    type: ActionTypes.OPEN_CONFIRM_RESERVE_MODAL,
    payload: {
      reserveId,
    },
  };
}

/** @description Экшен для закрытия окна подтверждения резерва */
export function closeReserveConfirmModal(reserveId: number): IReservationsPageActions {
  return {
    type: ActionTypes.CLOSE_CONFIRM_RESERVE_MODAL,
    payload: {
      reserveId,
    },
  };
}

/** @description Экшен для подтверждения резерва */
export function confirmReserve(reserveId: number, reserveInfo: { tables: Array<number> }): IReservationsPageActions {
  return {
    type: ActionTypes.CONFIRM_RESERVE_REQUEST,
    payload: {
      reserveId,
      reserveInfo,
    },
  };
}

/** @description Экшен для обработки успешного подтверждения резерва */
export function confirmReserveSuccess(reserveId: number): IReservationsPageActions {
  return {
    type: ActionTypes.CONFIRM_RESERVE_SUCCESS,
    message: 'Резерв подтвержден',
    payload: {
      reserveId,
    },
  };
}

/** @description Экшен для обработки ошибки при подтверждение резерва */
export function confirmReserveError(): IReservationsPageActions {
  return {
    type: ActionTypes.CONFIRM_RESERVE_ERROR,
    error: 'Не удалось подтвердить резерв',
  };
}

/** @description Экшен для отмены резерва из окна подтверждения */
export function rejectNewReserve(reserveId: number): IReservationsPageActions {
  return {
    type: ActionTypes.REJECT_NEW_RESERVE_REQUEST,
    payload: {
      reserveId,
    },
  };
}

/** @description Экшен для обработки успешной отмены резерва из окна подтверждения */
export function rejectNewReserveSuccess(reserveId: number): IReservationsPageActions {
  return {
    type: ActionTypes.REJECT_NEW_RESERVE_SUCCESS,
    message: 'Резерв отклонен',
    payload: {
      reserveId,
    },
  };
}

/** @description Экшен для обработки ошибки отмены резерва из окна подтверждения */
export function rejectNewReserveError(): IReservationsPageActions {
  return {
    type: ActionTypes.REJECT_NEW_RESERVE_ERROR,
    error: 'Не удалось отклонить резерв',
  };
}

// /** @description Экшен для добавления резерва в очередь на обработку */
// export function setReserveToQueue(reserveId: number): IReservationsPageActions {
//   return {
//     type: ActionTypes.SET_RESERVE_TO_QUEUE,
//     payload: {
//       reserveId,
//     },
//   };
// }
//
// /** @description Экшен для добавления резерва в очередь на обработку */
// export function updateReserveQueue(newQueue: Array<number>): IReservationsPageActions {
//   return {
//     type: ActionTypes.UPDATE_RESERVE_QUEUE,
//     payload: {
//       newQueue,
//     },
//   };
// }

/** @description Экшен для установки актвиного стола */
export function setActiveTable(activeTable: ITable): IReservationsPageActions {
  return {
    type: ActionTypes.SET_ACTIVE_TABLE_REQ,
    payload: {
      activeTable,
    },
  };
}

/** @description Экшен для сброса актвного стола */
export function resetActiveTable(): IReservationsPageActions {
  return {
    type: ActionTypes.RESET_ACTIVE_TABLE,
  };
}

/** @description Экшен для установки актвиного стола */
export function setActiveTableSuccess(activeTable: TActiveTables): IReservationsPageActions {
  return {
    type: ActionTypes.SET_ACTIVE_TABLE_SUCCESS,
    payload: {
      activeTable,
    },
  };
}

/** @description Экшен для установки максимальной вместимости ресторана */
export function setMaxCapacity(capacity: number): IReservationsPageActions {
  return {
    type: ActionTypes.SET_MAX_CAPACITY,
    payload: {
      capacity,
    },
  };
}

/** @description Экшен для перехода в режим объединения столов */
export function mergeTables(mergeTables: boolean, getTableData: {
  duration: number,
  date: string,
  persons: number,
  reserveId?: number,
}): IReservationsPageActions {
  return {
    type: ActionTypes.MERGE_TABLES_REQ,
    payload: {
      mergeTables,
      getTableData,
    },
  };
}

/** @description Экшен для обработки успешного переключения режима "Объединения столов" */
export function mergeTablesSuccess(mergeTables: boolean, tables: Array<ITable>): IReservationsPageActions {
  return {
    type: ActionTypes.MERGE_TABLES_SUCCESS,
    payload: {
      mergeTables,
      tables,
    },
  };
}

/** @description Экшен для обработки ошибки переключения режима "Объединения столов" */
export function mergeTablesError(): IReservationsPageActions {
  return {
    type: ActionTypes.MERGE_TABLES_ERROR,
  };
}


// Open Reserves

export function fetchOpenReserves(): IReservationsPageActions {
  return {
    type: ActionTypes.FETCH_OPEN_RESERVES,
  };
}

export function fetchOpenReservesSuccess(reserves: TOpenReserves): IReservationsPageActions {
  return {
    type: ActionTypes.FETCH_OPEN_RESERVES_SUCCESS,
    reserves,
  };
}

export function fetchOpenReservesError(error: string): IReservationsPageActions {
  return {
    type: ActionTypes.FETCH_OPEN_RESERVES_ERROR,
    error,
  };
}

export function resetOpenReserves(): IReservationsPageActions {
  return {
    type: ActionTypes.RESET_OPEN_RESERVES,
  };
}

export function addOpenReserve(reserve: TOpenReserve): IReservationsPageActions {
  return {
    type: ActionTypes.ADD_OPEN_RESERVE,
    reserve,
  };
}

export function removeOpenReserve(id: number, manually: boolean = false): IReservationsPageActions {
  return {
    type: ActionTypes.REMOVE_OPEN_RESERVE,
    id,
    manually,
  };
}

export function confirmOpenReserve(reserveId: number): IReservationsPageActions {
  return {
    type: ActionTypes.CONFIRM_OPEN_RESERVE,
    reserveId,
  };
}

export function confirmOpenReserveSuccess(reserveId: number): IReservationsPageActions {
  return {
    type: ActionTypes.CONFIRM_OPEN_RESERVE_SUCCESS,
    reserveId,
  };
}

export function confirmOpenReserveError(error: string): IReservationsPageActions {
  return {
    type: ActionTypes.CONFIRM_OPEN_RESERVE_ERROR,
    error,
  };
}

export function rejectOpenReserve(reserveId: number): IReservationsPageActions {
  return {
    type: ActionTypes.REJECT_OPEN_RESERVE,
    reserveId,
  };
}

export function rejectOpenReserveSuccess(reserveId: number): IReservationsPageActions {
  return {
    type: ActionTypes.REJECT_OPEN_RESERVE_SUCCESS,
    reserveId,
  };
}

export function rejectOpenReserveError(error: string): IReservationsPageActions {
  return {
    type: ActionTypes.REJECT_OPEN_RESERVE_ERROR,
    error,
  };
}
