import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';

import DialogTitle from '../Common/DialogTitle';
import locales from '../../locales';
import { errorColor, errorContrastText, errorHoverColor } from '../../theme';

const DialogContent = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    minWidth: 415,
    minHeight: 160,
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing(),
  },
}))(MuiDialogActions);

const RejectBtn = withStyles(() => ({
  root: {
    color: errorContrastText,
    backgroundColor: errorColor,
    '&:hover': {
      backgroundColor: errorHoverColor,
    },
  },
}))(Button);


interface IProps {
  isOpen: boolean;
  handleCloseModal: () => void;
  handleRejectReserve: () => void;
}

const DeleteReserveModal: React.FC<IProps> = ({
  isOpen,
  handleCloseModal,
  handleRejectReserve,
}) => (
  <Dialog
    aria-labelledby="customized-dialog-title"
    open={isOpen}
  >
    <DialogTitle onClose={handleCloseModal}>
      { locales.t('booking.rejectReserve.title') }
    </DialogTitle>
    <DialogContent>
      <Typography gutterBottom>
        { locales.t('booking.rejectReserve.confirm') }
      </Typography>
    </DialogContent>
    <DialogActions>
      <Button
        variant="contained"
        onClick={handleCloseModal}
        color="secondary"
        // style={{ color: 'white', width: 108 }}
      >
        { locales.t('booking.rejectReserve.no') }
      </Button>
      <RejectBtn
        variant="contained"
        onClick={handleRejectReserve}
      >
        { locales.t('booking.rejectReserve.yes') }
      </RejectBtn>
    </DialogActions>
  </Dialog>
);

export default DeleteReserveModal;
