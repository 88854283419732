import React from 'react';
import { connect } from 'react-redux';

import { IAppStore } from '../types/IAppStore';

import ReserveCreateModalCont from '../containers/ReservationPageCreateCont';
import ReserveConfirmModalCont from '../containers/ReservationPageConfirmCont';
import ReserveEditModalCont from '../containers/ReservationPageEditCont';


export type TModalTypes = 'CONFIRM' | 'EDIT' | 'CREATE' | null;

interface IProps {
  openModal: TModalTypes,
}


const ReservationsPage: React.FC<IProps> = ({ openModal }) => (
  <>
    {openModal === 'CONFIRM' && <ReserveConfirmModalCont />}
    {openModal === 'EDIT' && <ReserveEditModalCont />}
    {openModal === 'CREATE' && <ReserveCreateModalCont />}
  </>
);


function mapStateToProps(state: IAppStore): IProps {
  const {
    createReserveModalIsOpen,
    confirmReserveModalIsOpen,
    editReserveModalIsOpen,
  } = state.reserveModal;

  let openModal: TModalTypes = null;

  if (createReserveModalIsOpen) {
    openModal = 'CREATE';
  } else if (editReserveModalIsOpen) {
    openModal = 'EDIT';
  } else if (confirmReserveModalIsOpen) {
    openModal = 'CONFIRM';
  }

  return { openModal };
}

export default connect(mapStateToProps)(ReservationsPage);
