import React, { PureComponent } from 'react';

import TableList from '../../components/Tables';
import { IProps } from './IResevesSystemTables';
import { ITable } from '../../types/IRestaurantTablesStore';

class SettingsCont extends PureComponent<IProps> {
  componentDidMount() {
    const { currentRestaurant } = this.props;
    if (currentRestaurant) {
      this.handleGetAllTablesList(currentRestaurant.id);
    }
  }

  /** @description Запрос списка столов для в случае изменения выбранного рестоарана */
  componentDidUpdate(prevProps: IProps): void {
    const { currentRestaurant } = this.props;
    if (currentRestaurant && prevProps.currentRestaurant !== currentRestaurant) {
      this.handleGetAllTablesList(currentRestaurant.id);
    }
  }

  /** @description Ручка для экшена для получения всех столов для выбранного ресторана */
  handleGetAllTablesList = (id: number) => {
    const { getTablesForSettings } = this.props;
    getTablesForSettings(id);
  };

  /** @description Ручка для экшена для редактирования количества мест у выбранного стола */
  handleUpdateTable = (table: ITable | Array<ITable>) => {
    const {
      currentRestaurant, updateRestaurantTable,
    } = this.props;

    if (currentRestaurant && table) {
      updateRestaurantTable(table);
    }
  };

  render() {
    const {
      hallList,
      customErrorShackBar,
      isAutoBookingEnabled,
    } = this.props;

    return (
      <TableList
        hallList={hallList}
        handleUpdateTable={this.handleUpdateTable}
        customErrorShackBar={customErrorShackBar}
        isAutoBookingEnabled={isAutoBookingEnabled}
      />
    );
  }
}

export default SettingsCont;
