import { useState, useEffect } from 'react';
import { TNotification } from '../types/INotificationsStore';

const dismissedProp = 'dismissedNotifications';

export function getDismissedIds(): Array<string> {
  const storage = localStorage.getItem(dismissedProp);
  if (storage) {
    const parsed = JSON.parse(storage);

    if (Array.isArray(parsed)) {
      return parsed;
    }
  }

  return [];
}

// export function dismiss(id: string) {
//   const dismissed = getDismissedIds();
//
//   dismissed.push(id);
//
//   localStorage.setItem(dismissedProp, JSON.stringify(dismissed));
// }

export function isDismissed(id: string): boolean {
  return getDismissedIds().indexOf(id) !== -1;
}

interface SetWindowSizeProps {
  width?: number;
  height?: number;
}

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<SetWindowSizeProps>({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};

export const sortNotifications = (a: TNotification, b: TNotification) => {
  const sortOrderA = a.getSortOrder();
  const sortOrderB = b.getSortOrder();

  if (sortOrderA < sortOrderB) {
    return -1;
  }

  if (sortOrderA > sortOrderB) {
    return 1;
  }

  return 0;
};
