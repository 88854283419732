import {
  all, put, takeLatest, select,
} from 'redux-saga/effects';

import * as ActionTypes from '../actions/userStatistic/UserStatisticActionsTypes';
import { getUserStatisticSuccess, getUserStatisticsError } from '../actions/userStatistic/UserStatisticActions';
import { IGetUserStatistic } from '../actions/userStatistic/IUserStatisticActions';
import {
  getAllBookingsByUserPhone, getGuestStatistic, getUserVisitInfo,
} from '../api';
import { TReserve } from '../types/IBookingStore';

/** @description Сага для получения статистики по выбранному юзеру */
export function* getUserStatisticSaga(action: IGetUserStatistic) {
  const { userPhoneNumber } = action.payload;

  try {
    const restaurantId = yield select(state => state.cabinet.currentRestaurant.id);
    const userVisits = yield getUserVisitInfo(userPhoneNumber, restaurantId);

    if (userVisits.name === null || userVisits.phone === null) {
      const allReserves: Array<TReserve> = yield select(state => state.statistic.reserves);
      const userReserves = allReserves.filter(r => r.phone === userPhoneNumber);

      const mockUser = {
        firstVisit: null,
        lastVisit: null,
        isActive: false,
        name: userReserves[0].firstName,
        phone: userPhoneNumber,
      };

      const mockStat = {
        averageReceiptAmount: 0,
        total: 0,
        totalCancelledByGuest: 0,
        totalCancelledByRestaurant: 0,
        totalClosed: 0,
        totalDeclined: 0,
        totalReceiptAmount: 0,
      };

      yield put(getUserStatisticSuccess(mockUser, mockStat, userReserves));
    } else {
      const statistics = yield getGuestStatistic(
        restaurantId,
        parseInt(action.payload.userPhoneNumber),
      );

      const bookingList = yield getAllBookingsByUserPhone(
        restaurantId,
        parseInt(userPhoneNumber),
        action.payload.sort,
      );

      yield put(getUserStatisticSuccess(userVisits, statistics, bookingList));
    }
  } catch (e) {
    yield put(getUserStatisticsError());
  }
}

export default function* saga() {
  yield all([
    takeLatest(ActionTypes.GET_USER_STATISTIC_REQUEST, getUserStatisticSaga),
  ]);
}
