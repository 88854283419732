import React, { ChangeEvent, FC } from 'react';

import {
  makeStyles,
  Theme,
  FormControl,
  TextField,
  MenuItem,
} from '@material-ui/core';

import { TRestaurant } from '../../../types/ICabinetStore';
import locales from '../../../locales';
import { Employees } from '../../../entities';


const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),

    [theme.breakpoints.down(750)]: {
      minWidth: 350,
    },

    '& svg': {
      color: '#aaaaaa',
    },
  },

  label: {
    color: '#aaaaaa',

    [theme.breakpoints.up(750)]: {
      fontSize: 16,
    },
  },

  input: {
    color: 'white',
    fontWeight: 500,

    [theme.breakpoints.up(750)]: {
      fontSize: 16,
    },

    '&:before': {
      borderBottomColor: '#aaaaaa',
    },
  },

  list: {
    '& li': {
      color: '#ffffff',
    },

    '& li:last-child': {
      color: theme.palette.grey[400],
    },

    '& li.Mui-selected': {
      backgroundColor: `${theme.palette.primary.dark}!important`,
      color: theme.palette.primary.contrastText,
    },

    '& li:hover': {
      backgroundColor: `${theme.palette.primary.light}!important`,
      color: theme.palette.primary.contrastText,
    },

    backgroundColor: '#323232',
  },
}));


const RestaurantSelect: FC<{
  setCurrentRestaurant: (id: number) => void;
  currentRestaurant?: TRestaurant;
  restaurantList: Array<TRestaurant>;
  onCreateRestaurant: () => void;
}> = ({
  setCurrentRestaurant,
  restaurantList,
  currentRestaurant,
  onCreateRestaurant,
}) => {
  const { data: currentEmployee } = Employees.useCurrentEmployee();

  const onChange = (e: ChangeEvent<{ name?: string; value: any }>) => {
    if (e.target.value) {
      setCurrentRestaurant(parseInt(e.target.value, 10));
    }
  };

  const classes = useStyles();

  return (
    <FormControl fullWidth classes={{ root: classes.formControl }}>
      <TextField
        value={currentRestaurant && currentRestaurant.id}
        onChange={onChange}
        select
        id="select-restaurant"
        label={locales.t('places.restaurantSelect')}
        InputLabelProps={{ classes: { root: classes.label } }}
        InputProps={{ classes: { root: classes.input } }}
        fullWidth
        SelectProps={{
          MenuProps: {
            classes: {
              list: classes.list,
            },
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
          },
        }}
      >
        {restaurantList.map(item => (
          <MenuItem key={item.id} value={item.id}>
            {`${item.name} — ${item.address.street}, ${item.address.home}`}
          </MenuItem>
        ))}

        {currentEmployee && Employees.hasAccess(currentEmployee, 'restaurants:access') && (
          <MenuItem onClick={onCreateRestaurant}>
            {locales.t('places.restaurantAdd')}
          </MenuItem>
        )}
      </TextField>
    </FormControl>
  );
};

export default RestaurantSelect;
