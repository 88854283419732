import React from 'react';
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';

import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import Typography from '@material-ui/core/Typography/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';

interface IProps {
  input: WrappedFieldInputProps;
  meta: WrappedFieldMetaProps;
  label: string;
}

const CheckBoxComp: React.FC<IProps> = ({
  input, label, meta: { touched, error },
}) => {
  // eslint-disable-next-line
  delete input.value; // грязный хак

  return (
    <FormControlLabel
      control={(
        <Checkbox
          color="primary"
          {...input}
        />
      )}
      label={(
        <Typography variant="body1" color={touched && error ? 'error' : 'initial'}>
          { (touched && error) || label }
        </Typography>
      )}
    />
  );
};

export default CheckBoxComp;
