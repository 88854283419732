import React from 'react';
import { Box } from '@material-ui/core';

import locales from '../../../locales';


const NotFoundComponent: React.FC = () => {
  return (
    <Box p={2}>
      {locales.t('employees.not_found')}
    </Box>
  );
};

export default NotFoundComponent;
