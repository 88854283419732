import React, { useState } from 'react';

import {
  withStyles,
  WithStyles,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import Typography from '@material-ui/core/Typography';

import DialogTitle from '../../Common/DialogTitle';

import WidgetSettings from './WidgetSettings';

import {
  IWidgetDataAdapt,
} from '../../../types/IRestaurantWidgetsStore';

import { fallbackValue } from '../../../helpers/widget/constants';
import { isValid } from './utils';

import locales from '../../../locales';
import styles from './styles';

interface IProps extends WithStyles<typeof styles> {
  isOpen: boolean;
  handleClose: () => void;
  handleCreateWidget: (widgetData: IWidgetDataAdapt) => void;
}

const WidgetCreateModal: React.FC<IProps> = ({
  classes,
  isOpen,
  handleClose,
  handleCreateWidget,
}) => {
  /** @description Стейт полей виджета */
  const [data, changeData] = useState<IWidgetDataAdapt>(fallbackValue);

  /** @description Создание виджета */
  const onClick = () => {
    if (isValid(data)) {
      handleCreateWidget(data);
      handleClose();
    }
  };

  return (
    <Dialog
      classes={{ paper: classes.paperEdit }}
      open={isOpen}
    >
      <DialogTitle onClose={handleClose}>
        { locales.t('settings.widgets.createModal.title') }
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText className={classes.desc}>
          <Typography variant="caption">
            { locales.t('settings.widgets.createModal.desc') }
          </Typography>
        </DialogContentText>
        <WidgetSettings data={data} changeData={changeData} />
      </DialogContent>
      <DialogActions className={classes.dialogActionsWrap}>
        <Button
          className={classes.btnWrap}
          variant="contained"
          color="secondary"
          onClick={handleClose}
        >
          { locales.t('settings.widgets.createModal.declineBtn') }
        </Button>
        <Button
          className={classes.btnWrap}
          variant="contained"
          color="primary"
          onClick={onClick}
        >
          { locales.t('settings.widgets.createModal.saveBtn') }
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(WidgetCreateModal);
