import React, { Fragment } from 'react';
import wrapWithPath from './wrapSvgPath';

const EditIcon = (
  <Fragment>
    <g id="edit-filled" opacity="0.7">
      <path
        style={{ fill: '#323232' }}
        d="M.189 51.933l-.18.755a.359.359 0 0 0-.009.073v.014a.36.36 0 0 0 .024.12c0 .009.007.017.011.026a.361.361 0 0 0 .071.107.364.364 0 0 0 .119.079.359.359 0 0 0 .137.027.364.364 0 0 0 .084-.01l.755-.18z"
        transform="translate(0 -33.134)"
      />
      <path
        style={{ fill: '#323232' }}
        d="M19.727.952a3.263 3.263 0 0 0-4.608 0L2.065 14.006c-.006.006-.01.014-.015.02l-.021.028a.352.352 0 0 0-.045.087c0 .009-.008.018-.01.027v.011l-.9 3.794 1.641 1.641 3.794-.9a.261.261 0 0 0 .028-.011l.036-.014a.357.357 0 0 0 .073-.045c.009-.007.018-.012.026-.02l.009-.007L19.727 5.559a3.261 3.261 0 0 0 0-4.607zM6.891 17.372l.128-1.152 8.1-8.1a.362.362 0 0 0-.512-.512l-8.1 8.1-1.728.192.192-1.728 8.1-8.1a.362.362 0 0 0-.512-.512l-8.1 8.1-1.152.128 10.021-10.02 3.584 3.584zM17.423 6.84L13.84 3.256l.512-.512 3.584 3.584zm1.024-1.024l-3.584-3.584.512-.512 3.584 3.58z"
        transform="translate(-.68)"
      />
    </g>
  </Fragment>
);

export default wrapWithPath(EditIcon, '-2 -2 22 24');
