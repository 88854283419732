import { createStyles, Theme } from '@material-ui/core';
import { errorColor, errorHoverColor, errorContrastText } from '../../theme';

const styles = (theme: Theme) => createStyles({
  actions: {
    display: 'flex',
    height: '84px',
    padding: '16px 23px 16px 24px',
    boxShadow: '0 -3px 6px 0 rgba(0, 0, 0, 0.16)',
    backgroundColor: '#ffffff',
    justifyContent: 'space-between',
    zIndex: 20,
  },
  formWrap: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    height: '100%',
    minHeight: 'calc(100vh - 64px)',
  },
  // header: {
  //   display: 'flex',
  //   alignItems: 'center',
  //   height: 55,
  //   padding: '0px 24px 0 6px',
  //   boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
  //   backgroundColor: '#ffffff',
  //   zIndex: 20,
  //   [theme.breakpoints.down('xs')]: {
  //     alignItems: 'flex-start',
  //     flexDirection: 'column',
  //     height: '100%',
  //     paddingBottom: 24,
  //   },
  // },

  headerTitleWrap: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: 250,
    minWidth: 234,
    width: '100%',
  },
  headerTitle: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  justHeaderTitle: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 12,
    minHeight: 48,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 24,
    },
  },

  content: {
    display: 'flex',
    flex: 1,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  btnStyle: {
    width: '100%',
    height: 52,
  },
  btnEdit: {
    color: errorContrastText,
    backgroundColor: errorColor,
    '&:hover': {
      backgroundColor: errorHoverColor,
    },
  },
  banquetError: {
    width: '100%',
    height: 52,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
  },
  banquetErrorText: {
    maxWidth: 370,
    fontFamily: 'GolosTextWebMedium',
  },
});

export default styles;
