import { createSelector } from 'reselect';
import { some } from 'lodash';
import { IAppStore } from '../../types/IAppStore';
import { TRestaurantPlugin } from '../../types/ICabinetStore';

export const cashBoxPlugins = (state: IAppStore) => state.cabinet.cashBoxPlugins;

type VersionType = "subVersion" | "version";

const isUpdateRequired = (property: VersionType) => (plugin: TRestaurantPlugin | void) => {
  const data = plugin || {
    [property]: {
      actual: true,
    },
  };
  return data[property] ? !(data[property] || {}).actual : false;
};

/**
 * @description Вернёт true если хотябы один плагин не актуален
 * @param property
 */
const isSomePluginUpdateRequired = <R>(property: VersionType) => (plugins: R[] | void) => some(plugins || [], isUpdateRequired(property));

/** @description Отображение панели Warning в зависимости от статуса дочернего плагина */
export const isSubVersionUpdateRequired = createSelector([
  cashBoxPlugins,
], isSomePluginUpdateRequired('subVersion'));

/** @description Отображение панели Warning в зависимости от статуса плагина */
export const isVersionUpdateRequired = createSelector([
  cashBoxPlugins,
], isSomePluginUpdateRequired('version'));

export const isCashBoxUpdateRequired = createSelector([
  isVersionUpdateRequired,
  isSubVersionUpdateRequired,
], (isVersionUpdateRequired,
  isSubVersionUpdateRequired) => isVersionUpdateRequired || isSubVersionUpdateRequired);
