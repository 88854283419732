import React from 'react';

import PageHeaderWarning from '../../components/Common/PageHeaderWarning';
import { ICashBoxWarningProps } from './ICashBoxWarning';
import locales from '../../locales';

const CashBoxWarning: React.FC<ICashBoxWarningProps> = (props) => {
  const {
    isOpen,
    isInit,
    isVersionUpdateRequired,
    hideWarning,
  } = props;

  return isOpen && isInit ? (
    <PageHeaderWarning
      onClose={hideWarning}
      title={isVersionUpdateRequired
        ? locales.t('common.warnings.cashBox.versionOutdated.title')
        : locales.t('common.warnings.cashBox.subVersionOutdated.title')
      }
      message={isVersionUpdateRequired
        ? locales.t('common.warnings.cashBox.versionOutdated.message')
        : locales.t('common.warnings.cashBox.subVersionOutdated.message')
      }
    />
  ) : null;
};

export default CashBoxWarning;
