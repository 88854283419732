import React, { PureComponent } from 'react';

import ReservesSystemAdditionalNotificationsComponent from '../../components/ReservesSystem/ReservesSystemAdditionalNotifications';
import { IProps } from './IReservesSystemAdditionalNotifications';
import { TNewInfo } from '../../types/IRestaurantInfoStore';

class ReservesSystemAdditionalNotificationsContainer extends PureComponent<IProps> {
  onUpdate = (data: TNewInfo) => {
    const { currentRestaurant, updateRestaurantInfo } = this.props;
    if (!currentRestaurant) {
      return;
    }

    updateRestaurantInfo({
      ...data,
      currentRestaurant,
    });
  };

  render() {
    const { currentRestaurant } = this.props;
    if (!currentRestaurant) {
      return null;
    }

    return (
      <ReservesSystemAdditionalNotificationsComponent
        settings={currentRestaurant.settings}
        onUpdate={this.onUpdate}
      />
    );
  }
}

export default ReservesSystemAdditionalNotificationsContainer;
