import React, { Fragment } from 'react';

import Paper from '@material-ui/core/Paper/Paper';
import Grid from '@material-ui/core/Grid/index';

import StatisticsTable from './HomeStatisticTable';
import ReserveStatisticChart from '../../Common/Charts/ReserveStatisticChart';
import locales from '../../../locales';
import Title from '../../Common/Title';
import { TAttendance, TStatistics } from '../../../types/IHomeStore';

interface IProps {
  statistic?: TStatistics;
  attendance?: Array<TAttendance>;
  moneySymbol: string;
}

const HomeStatistics: React.FC<IProps> = ({ statistic, attendance, moneySymbol }) => (
  <Fragment>
    <Grid item xs={12} lg={6}>
      <Paper elevation={3}>
        <Title
          text={locales.t('home.statistic.label')}
          // helps="Здесь должен быть текст подсказки"
          borderBottom
          spaceBetween
        />
        <StatisticsTable statistic={statistic} moneySymbol={moneySymbol} />
      </Paper>
    </Grid>
    <Grid item xs={12} lg={6}>
      <Paper elevation={3} style={{ height: '100%' }}>
        <Title
          text={locales.t('home.attendance.label')}
          // helps="Здесь должен быть текст подсказки"
          borderBottom
          spaceBetween
        />
        <ReserveStatisticChart data={attendance} />
      </Paper>
    </Grid>
  </Fragment>
);

export default HomeStatistics;
