import React, { Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles, createStyles, WithStyles } from '@material-ui/core/styles';

import logo from '../../../images/logo.svg';

const styles = createStyles({
  logo: {
    width: 160,
  },
  logoText: {
    fontSize: 16,
    marginTop: 20,
    marginBottom: 10,
    fontFamily: 'inherit',
  },
});

interface IProps extends WithStyles<typeof styles> {
  text: string;
}

const Logo: React.FC<IProps> = ({ classes, text }) => (
  <Fragment>
    <img className={classes.logo} src={logo} alt="logo" />
    <Typography
      className={classes.logoText}
      variant="caption"
      align="center"
      dangerouslySetInnerHTML={{ __html: text }}
    />
  </Fragment>
);

export default withStyles(styles)(Logo);
