import React, { Component } from 'react';
import { Moment } from 'moment';
import _ from 'lodash';

import Reservations from '../../components/Reservations';
import locales from '../../locales';
import DeleteReserveModal from '../../components/Booking/DeleteReserveModal';
import { IProps, IState } from './IReserveEditPage';
import { TUpdateReserveInfo } from '../../types/IReserveModalStore';
import { ITable } from '../../types/IRestaurantTablesStore';
import { parsePhoneNumber } from '../../helpers/reserveHelpers';
import { validateReserveFields, compareTables } from '../../helpers/bookingHelpers';
import { emptyTime } from '../../config/constants';
import { IAppReserveCloseOptions } from '../../actions/appReserves/IAppReservesActions';

import {
  getSelectedTimeFromByNewDate,
  getTimeList,
  parseDateForReq,
  getHoursAndMinutes,
} from '../../helpers/dateHelpers';
import genDurationFromHours from '../../helpers/genDurationFromHours';

class ReserveEditPage extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      rejectModalIsOpen: false,
      timeList: getTimeList(props.reserveInfo.date, props.workTime, true),
    };
  }

  componentDidUpdate() {
    const { freeTables } = this.props;

    if (!freeTables) {
      window.clearTimeout(this.getTablesTimeout);

      this.getTablesTimeout = window.setTimeout(() => {
        this.handleGetFreeTables();
      }, 200);
    }
  }

  componentWillUnmount() {
    window.clearTimeout(this.getTablesTimeout);
  }

  getTablesTimeout: number | undefined;

  /** @description Закрытие модального окна */
  handleCloseDialog = (options?: IAppReserveCloseOptions): void => {
    const { closeAppReserve, selectedAppReserve } = this.props;

    closeAppReserve(
      selectedAppReserve && selectedAppReserve.key,
      options,
    );
  };

  /** @description Выбор стола для резерва */
  handleSelectTable = (newActiveTable: ITable): void => {
    const { setActiveTable, reserveInfo } = this.props;

    if (!reserveInfo.isBanquet) {
      setActiveTable(newActiveTable);
    }
  };

  /** @description Обновление списка столов */
  handleGetFreeTables = (field?: {value: any, type: string}): void => {
    const {
      formName,
      currentValue,
      getFreeTables,
      workTime,
      reserveInfo, // Единственное отличие от формы создания. Нужен рефакторинг
      isMergeTables,
      changeCurrentTime,
      changeAppReserve,
    } = this.props;

    const { timeList } = this.state;

    if (field && field.type === 'date') {
      const newTimeList = getTimeList(field.value, workTime);

      if (currentValue.time !== emptyTime) {
        const newTime = getSelectedTimeFromByNewDate(timeList, newTimeList, currentValue.time);
        changeAppReserve('time', newTime);
        changeCurrentTime(formName, newTime);
      }

      this.setState({
        timeList: newTimeList,
      });
    }

    if (validateReserveFields({
      ...currentValue,
      ...(field ? { [field.type]: [field.value] } : {}),
    })) {
      const newData = {
        date: parseDateForReq(currentValue.date),
        persons: currentValue.persons,
        duration: genDurationFromHours(currentValue.hours),
      };

      if (field) {
        switch (field.type) {
          case 'date': {
            const newTimeList = getTimeList(field.value, workTime);
            newData.date = getSelectedTimeFromByNewDate(
              timeList, newTimeList, currentValue.time,
            );
            break;
          }
          case 'time': {
            newData.date = field.value;
            break;
          }
          case 'persons': {
            newData.persons = field.value;
            break;
          }
          case 'hours': {
            newData.duration = parseInt(field.value) * 60;
            break;
          }
          default:
            break;
        }
      }

      if (isMergeTables) {
        newData.persons = 0;
      }

      // @todo Быстро-фикс. Будет время, нужно разобраться
      // getFreeTables(newData.date, newData.persons, newData.duration, reserveInfo.id);
      if (newData.duration) {
        getFreeTables(newData.date, newData.persons, newData.duration, reserveInfo.id);
      }
    }
  };

  /** @description Отправка запроса на редактирование брони */
  handleSendReq = (newReserveInfo: {
    date: Moment;
    time: string;
    persons: number;
    firstName: string;
    lastName: string;
    hours: number;
    minutes: number;
    comment: string;
    phone: string;
    isBanquet: boolean;
  }): void => {
    const {
      updateReserve,
      reserveInfo,
      customErrorShackBar,
      activeTables,
      closeAppReserve,
      selectedAppReserve,
    } = this.props;

    const selectedDate = parseDateForReq(newReserveInfo.time);
    const activeTablesIds = Object.keys(activeTables).map(id => parseInt(id));
    const activeTablesByID = activeTablesIds.map(id => activeTables[id]);

    const reserveInfoForUpdate: TUpdateReserveInfo = {};

    if (reserveInfo.date !== selectedDate) reserveInfoForUpdate.date = selectedDate;
    if (reserveInfo.comment !== newReserveInfo.comment) reserveInfoForUpdate.comment = newReserveInfo.comment;
    if (reserveInfo.duration !== ((newReserveInfo.hours * 60) + newReserveInfo.minutes)) reserveInfoForUpdate.duration = newReserveInfo.hours * 60;
    if (reserveInfo.persons !== newReserveInfo.persons) reserveInfoForUpdate.persons = newReserveInfo.persons;

    if (reserveInfo.firstName !== newReserveInfo.firstName) {
      reserveInfoForUpdate.firstName = newReserveInfo.firstName;
    }

    if (reserveInfo.lastName !== newReserveInfo.lastName) {
      reserveInfoForUpdate.lastName = newReserveInfo.lastName;
    }

    if (parsePhoneNumber(reserveInfo.phone) !== newReserveInfo.phone) reserveInfoForUpdate.phone = newReserveInfo.phone;
    if (!compareTables(activeTablesByID, reserveInfo.tables)) reserveInfoForUpdate.tables = activeTablesIds;

    // if (!reserveInfo.isBanquet && newReserveInfo.isBanquet) reserveInfoForUpdate.isBanquet = true;
    reserveInfoForUpdate.isBanquet = newReserveInfo.isBanquet;

    if (!_.isEmpty(reserveInfoForUpdate)) {
      if (reserveInfo.persons !== newReserveInfo.persons && activeTablesByID.length === 0) {
        customErrorShackBar(locales.t('common.editReserveModal.needSelectTable'));
      } else {
        updateReserve(reserveInfo.id, reserveInfoForUpdate);

        closeAppReserve(selectedAppReserve && selectedAppReserve.key, {
          remove: true,
          openNext: true,
          // skipModalClosing: true,
        });
      }
    } else {
      this.handleCloseDialog({
        remove: true,
        openNext: true,
      });
    }
  };

  /** @description Ручка для отмены резерва */
  handleRejectReserve = (): void => {
    const {
      rejectReserve,
      reserveInfo,
      closeAppReserve,
      selectedAppReserve,
    } = this.props;

    rejectReserve(reserveInfo.id);

    closeAppReserve(selectedAppReserve && selectedAppReserve.key, {
      remove: true,
      openNext: true,
      // skipModalClosing: true,
    });
  };

  /** @description Ручка для открытия окна подтверждения отмены резерва */
  handleOpenRejectReserveModal = (): void => {
    this.setState({
      rejectModalIsOpen: true,
    });
  };

  /** @description Ручка для закрытия окна подтверждения отмены резерва */
  handleCloseRejectReserveModal = (): void => {
    this.setState({
      rejectModalIsOpen: false,
    });
  };

  /** @description Переключение в режим объеденения столов */
  handleMergeTables = () => {
    const {
      mergeTables,
      isMergeTables,
      currentValue,
      reserveInfo,
    } = this.props;

    const newTableType = !isMergeTables;

    if (newTableType && validateReserveFields(currentValue)) {
      mergeTables(newTableType, {
        date: currentValue.time,
        duration: genDurationFromHours(currentValue.hours),
        persons: 0,
        reserveId: reserveInfo.id,
      });
    } else if (!newTableType && validateReserveFields(currentValue)) {
      mergeTables(newTableType, {
        date: currentValue.time,
        duration: genDurationFromHours(currentValue.hours),
        persons: currentValue.persons,
        reserveId: reserveInfo.id,
      });
    }
  };

  handleErrorMsg = (msg: string) => {
    const { customErrorShackBar } = this.props;

    customErrorShackBar(msg);
  };

  handleViewChange = (sectionId: number) => {
    const { changeSectionToFirstItem, formName } = this.props;

    changeSectionToFirstItem(formName, sectionId);
  };

  render() {
    const { rejectModalIsOpen, timeList } = this.state;

    const {
      formName,
      disabled,
      outerChangeType,

      modalOpen,
      reserveInfo,
      freeTables,
      activeTables,
      maxCapacity,
      isMergeTables,
      currentValue,
      isSchemasEnabled,
      sections,
      schema,

      selectedAppReserve,
      appReserves,
      addAppReserve,
      removeAppReserve,
      openAppReserve,
      closeAppReserve,
      changeAppReserve,
      appReserveFormValues,
      alreadySelectedTables,
    } = this.props;

    if (!modalOpen) {
      return null;
    }

    const duration = getHoursAndMinutes(reserveInfo.duration);
    const minutes = duration.h !== parseInt(String(currentValue.hours)) ? 0 : duration.m;

    return (
      <>
        <Reservations
          form={formName}
          disabled={disabled}
          outerChangeType={outerChangeType}

          type="edit"
          reserveInfo={reserveInfo}

          minutes={minutes}
          timeList={timeList}
          maxCapacity={maxCapacity}

          title={locales.t(`common.editReserveModal.${reserveInfo.isBanquet ? 'banquet' : 'title'}`)}
          reserveBtnText={locales.t('common.editReserveModal.update')}
          rejectBtnText={locales.t('common.editReserveModal.reject')}
          freeTables={freeTables}
          isSchemasEnabled={isSchemasEnabled}
          sections={sections}
          section={currentValue.section}
          schema={schema}
          persons={reserveInfo.persons}
          // @ts-ignore
          onSubmit={this.handleSendReq}
          handleCloseDialog={this.handleCloseDialog}
          handleReject={this.handleOpenRejectReserveModal}
          handleSelectTable={this.handleSelectTable}
          handleGetFreeTables={this.handleGetFreeTables}
          handleMergeTables={this.handleMergeTables}
          handleErrorMsg={this.handleErrorMsg}
          handleViewChange={this.handleViewChange}
          isMergeTables={isMergeTables}
          activeTables={activeTables}
          initialValues={{
            time: reserveInfo.date,
            date: reserveInfo.date,
            persons: reserveInfo.persons,
            hours: duration.h,
            minutes: duration.m,
            comment: reserveInfo.comment,
            firstName: reserveInfo.firstName,
            lastName: reserveInfo.lastName,
            phone: parsePhoneNumber(reserveInfo.phone),
            isBanquet: reserveInfo.isBanquet,
            section: reserveInfo.section,
          }}
          enableReinitialize

          selectedAppReserve={selectedAppReserve}
          appReserves={appReserves}
          addAppReserve={addAppReserve}
          removeAppReserve={removeAppReserve}
          openAppReserve={openAppReserve}
          closeAppReserve={closeAppReserve}
          changeAppReserve={changeAppReserve}
          appReserveFormValues={appReserveFormValues}
          alreadySelectedTables={alreadySelectedTables}

          isBanquet={!!(selectedAppReserve
            && selectedAppReserve.originPayload
            && selectedAppReserve.originPayload.isBanquet)}
        />

        <DeleteReserveModal
          isOpen={rejectModalIsOpen && modalOpen}
          handleCloseModal={this.handleCloseRejectReserveModal}
          handleRejectReserve={this.handleRejectReserve}
        />
      </>
    );
  }
}

export default ReserveEditPage;
