import React, { Fragment } from 'react';
import wrapSvgPath from './wrapSvgPath';

const settingsIconPath = (
  <Fragment>
    <g id="settings" transform="translate(0 -.9)">
      <path
        id="Path_700"
        d="M25.342 16.161v-1.65l1.958-1.753a2.046 2.046 0 0 0 .412-2.578l-2.473-4.124a2.127 2.127 0 0 0-1.753-1.031 1.308 1.308 0 0 0-.619.1l-2.475.825c-.412-.309-.928-.516-1.34-.825l-.515-2.575A2.008 2.008 0 0 0 16.475.9h-4.847a2.107 2.107 0 0 0-2.062 1.65l-.618 2.681c-.412.309-.928.516-1.34.825l-2.475-.928c-.206 0-.412-.1-.619 0a2.069 2.069 0 0 0-1.856 1.031L.286 10.283A2.046 2.046 0 0 0 .7 12.861l1.959 1.753v1.65L.7 18.017a2 2 0 0 0-.412 2.578l2.475 4.124a2.127 2.127 0 0 0 1.751 1.031 1.308 1.308 0 0 0 .619-.1l2.475-.928c.412.309.928.516 1.34.825l.516 2.578a2.008 2.008 0 0 0 2.062 1.65h4.949a2.107 2.107 0 0 0 2.062-1.65l.516-2.578a5.846 5.846 0 0 0 1.34-.825l2.475.825a1.308 1.308 0 0 0 .619.1 2 2 0 0 0 1.753-1.031l2.372-4.124a2.046 2.046 0 0 0-.412-2.578zm-1.856 7.527L19.98 22.45a9.472 9.472 0 0 1-2.78 1.65l-.825 3.712h-4.85L10.8 24.2a9.473 9.473 0 0 1-2.784-1.65l-3.502 1.138-2.475-4.125 2.784-2.475a8.335 8.335 0 0 1 0-3.2l-2.784-2.573L4.514 7.19 8.02 8.427a9.473 9.473 0 0 1 2.78-1.649l.722-3.712h4.846l.722 3.712a9.472 9.472 0 0 1 2.784 1.65l3.612-1.238 2.475 4.124-2.784 2.475a8.335 8.335 0 0 1 0 3.2l2.784 2.578z"
        className="cls-1"
        data-name="Path 700"
      />
      <path
        id="Path_701"
        d="M13.578 21a6 6 0 1 1 6-6 5.933 5.933 0 0 1-5.9 6zm0-10a3.906 3.906 0 0 0-4 3.8v.2a3.906 3.906 0 0 0 3.8 4h.2a3.906 3.906 0 0 0 4-3.8V15a3.906 3.906 0 0 0-3.8-4z"
        className="cls-1"
        data-name="Path 701"
        transform="translate(.422 .441)"
      />
    </g>
  </Fragment>
);

export default wrapSvgPath(settingsIconPath, '0 0 28 28.871');
