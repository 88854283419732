const REMEMBER_ME_KEY = 'REMEMBER_ME';
const ACCESS_TOKEN_KEY = 'ACCESS_TOKEN_KEY';
const REFRESH_TOKEN_KEY = 'REFRESH_TOKEN_KEY';

/**
 * Получить токен по ключу
 * @param tokenName
 */
function getToken(tokenName: string) {
  const isRememberedMe = localStorage.getItem(REMEMBER_ME_KEY) === 'true';
  return isRememberedMe ? localStorage.getItem(tokenName) : sessionStorage.getItem(tokenName);
}

/**
 * Установить признак "Запомнить меня"
 * @param remember
 */
const setRememberMe = (remember: boolean): void => {
  window.localStorage.setItem(REMEMBER_ME_KEY, remember ? 'true' : 'false');
};

/**
 * Сохранить токены авторизации
 * @param accessToken
 * @param refreshToken
 */
const setTokens = (accessToken: string, refreshToken: string): void => {
  const isRememberedMe = window.localStorage.getItem(REMEMBER_ME_KEY) === 'true';

  if (isRememberedMe) {
    window.localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
    window.localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
  } else {
    window.sessionStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
    window.sessionStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
  }
};

/**
 * Получить access токен авторизации
 */
const getAccessToken = () => getToken(ACCESS_TOKEN_KEY);

/**
 * Получить refresh токен авторизации
 */
const getRefreshToken = () => getToken(REFRESH_TOKEN_KEY);

const deleteTokens = (): void => {
  window.sessionStorage.removeItem(ACCESS_TOKEN_KEY);
  window.sessionStorage.removeItem(REFRESH_TOKEN_KEY);
  window.localStorage.removeItem(ACCESS_TOKEN_KEY);
  window.localStorage.removeItem(REFRESH_TOKEN_KEY);
  window.localStorage.removeItem(REMEMBER_ME_KEY);
};

export {
  setTokens,
  setRememberMe,
  deleteTokens,
  getAccessToken,
  getRefreshToken,
};
