import React, { Fragment, useState } from 'react';

import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip/';
import IconButton from '@material-ui/core/IconButton/';
import FilterListIcon from '@material-ui/icons/FilterList';
import Typography from '@material-ui/core/Typography';

import FilterList from './FilterList';
import locales from '../../../locales';
import { TBookingFilters, TFilterListItem } from '../../../types/IBookingStore';

const rows: Array<{
  id: string;
  sortable?: boolean;
  filters?: boolean;
  label: string;
}> = [
  { id: 'status', filters: true, label: 'СТАТУС' },
  { id: 'date', sortable: true, label: 'ВРЕМЯ' },
  { id: 'guests', label: 'ГОСТЕЙ' },
  { id: 'table', label: 'ЗАЛ, СТОЛ' },
  { id: 'name', label: 'ИМЯ' },
  { id: 'phone', label: 'ТЕЛЕФОН' },
  { id: 'comment', label: 'КОММЕНТАРИЙ' },
  { id: 'createDate', label: 'СОЗДАН' },
  { id: 'source', label: 'ИСТОЧНИК' },
];

interface IProps {
  order: 'asc' | 'desc';
  orderBy: string;
  onRequestSort: any;
  filters: TBookingFilters;
  handleFilterBy: any;
}

interface IFilterState {
  anchorEl?: HTMLElement;
  filterIsOpen: boolean;
}

const EnhancedTableHead: React.FC<IProps> = ({
  order,
  orderBy,
  filters,
  handleFilterBy,
  onRequestSort,
}) => {
  /** @description Выпадающий список фильтра */
  const [filter, changeFilterState] = useState<IFilterState>({
    anchorEl: undefined,
    filterIsOpen: false,
  });

  /** @description Отправка запроса и закрытие списка фильтров */
  const handleChangeFilters = (
    e: React.SyntheticEvent<HTMLElement>,
    newFilters?: TFilterListItem,
    oldFilters?: TFilterListItem,
  ) => {
    const { filterIsOpen } = filter;

    if (filterIsOpen && newFilters && newFilters !== oldFilters) {
      handleFilterBy(newFilters);
    }

    changeFilterState({
      filterIsOpen: !filterIsOpen,
      anchorEl: filterIsOpen ? undefined : e.currentTarget,
    });
  };

  /** @description Отправка запроса и закрытие списка фильтров */
  const handleFilterSwitcher = (event: React.SyntheticEvent<HTMLElement>) => {
    const { filterIsOpen } = filter;

    changeFilterState({
      filterIsOpen: !filterIsOpen,
      anchorEl: filterIsOpen ? undefined : event.currentTarget,
    });
  };

  /** @description Переключение сортировки */
  const handleSort = (property: any) => (event: any) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow style={{ backgroundColor: '#f6f9f9' }}>
        {rows.map(row => (
          <TableCell
            key={row.id}
            align="center"
            padding="none"
            sortDirection={orderBy === row.id && order}
          >
            {row.sortable
              ? (
                <TableSortLabel
                  active={orderBy === row.id}
                  direction={order}
                  onClick={handleSort(row.id)}
                >
                  <Typography variant="overline" style={{ fontSize: 11, color: '#aaaaaa' }}>
                    {row.label}
                  </Typography>
                </TableSortLabel>
              )
              : (
                <p>
                  <Typography variant="overline" style={{ fontSize: 11, color: '#aaaaaa' }}>
                    {row.label}
                  </Typography>
                  { row.filters && (
                    <Fragment>
                      <IconButton style={{ padding: '0 0 0 6px' }} onClick={handleFilterSwitcher} aria-label="filter list">
                        <Tooltip title={locales.t('booking.filters.title')}>
                          <FilterListIcon />
                        </Tooltip>
                      </IconButton>
                      <FilterList
                        // @ts-ignore
                        anchorEl={filter.anchorEl}
                        filterIsOpen={filter.filterIsOpen}
                        handleMenuClose={handleChangeFilters}
                        itemList={filters}
                      />
                    </Fragment>
                  )}
                </p>
              )
            }
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default EnhancedTableHead;
