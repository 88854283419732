import React from 'react';

import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import locales from '../../../locales';
import DialogTitle from '../../Common/DialogTitle';
import ConnectSystemForm from './ConnectSystemForm';
import ConnectInstruction from './ConnectInstruction';

const styles = createStyles({
  dialogWidth: {
    maxWidth: 630,
  },
  content: {
    paddingTop: '20px',
  },
  rulesText: {
    fontSize: 12,
    color: '#cbcbcb',
    paddingBottom: 12,
  },
});

interface IProps extends WithStyles<typeof styles> {
  modalOpen: boolean;
  pinCode?: string;
  timeLeft: number;
  handleGetPinCode: () => void;
  handleCloseConnectModal: () => void;
  connectStatus: boolean;
}

const ConnectSystemModal: React.FC<IProps> = ({
  classes,
  modalOpen,
  pinCode,
  timeLeft,
  handleGetPinCode,
  handleCloseConnectModal,
  connectStatus,
}) => (
  <Dialog
    open={modalOpen}
    maxWidth="sm"
    classes={{ paper: classes.dialogWidth }}
  >
    <DialogTitle onClose={handleCloseConnectModal}>
      { locales.t('home.connectFormTitle') }
    </DialogTitle>

    <DialogContent>
      <ConnectInstruction />
      <ConnectSystemForm
        connectStatus={connectStatus}
        pinCode={pinCode}
        timeLeft={timeLeft}
        handleGetPinCode={handleGetPinCode}
        handleCloseConnectModal={handleCloseConnectModal}
      />
      <DialogContentText className={classes.rulesText}>
        { locales.t('home.connectFormImportantDescription') }
      </DialogContentText>
    </DialogContent>
  </Dialog>
);

export default withStyles(styles)(ConnectSystemModal);
