import React from 'react';

import {
  withStyles, WithStyles, createStyles, Theme,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton/IconButton';

import FirstStep from './FirstStep';
import SecondStep from './SecondStep';
import locales from '../../../locales';
import AuthErrorMessages from './AuthIikoErrorMessages';
import { TIikoAuth } from '../../../types/ICabinetStore';

const styles = (theme: Theme) => createStyles({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(),
    top: theme.spacing(),
    color: theme.palette.grey[500],
  },
  bottomBorder: {
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
  },
});

interface IProps extends WithStyles<typeof styles> {
  modalOpen: boolean;
  iikoAuth: TIikoAuth;
  handleSendAuthReq: (authData: { login: string; password: string }) => void;
  handleSendSelectReq: (organizationId: string) => void;
  switchAuthIikoModal: () => void;
}

const AuthIikoModal: React.FC<IProps> = ({
  modalOpen,
  iikoAuth,
  classes,
  handleSendAuthReq,
  handleSendSelectReq,
  switchAuthIikoModal,
}) => {
  const ErrorMessageComp = !iikoAuth.step
    && iikoAuth.step !== (1 || 2)
    && <AuthErrorMessages handleCloseDialog={switchAuthIikoModal} />;

  return (
    <Dialog
      open={modalOpen}
      aria-labelledby="form-dialog-title"
    >
      <div className={classes.bottomBorder}>
        <DialogTitle id="form-dialog-title">{locales.t('home.authIiko.title')}</DialogTitle>
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={switchAuthIikoModal}
        >
          <CloseIcon />
        </IconButton>
      </div>
      {
        iikoAuth.step === 1 && (
          <FirstStep
            handleCloseDialog={switchAuthIikoModal}
            handleSendAuthReq={handleSendAuthReq}
          />
        )
      }
      {
        iikoAuth.step === 2 && (
          <SecondStep
            handleCloseDialog={switchAuthIikoModal}
            handleSendSelectReq={handleSendSelectReq}
            organizationList={iikoAuth.organizationList}
          />
        )
      }
      { ErrorMessageComp }
    </Dialog>
  );
};

export default withStyles(styles)(AuthIikoModal);
