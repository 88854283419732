import { IRestaurantCall, IRestaurantCallsStore } from '../types/IRestaurantCallsStore';
import { IAppActions } from '../types/IAppActions';

import * as ActionTypes from '../actions/restaurantsCalls/RestaurantsCallsActionsTypes';

export const initState: IRestaurantCallsStore = {
  items: undefined,
  isLoading: false,
  error: undefined,
};

export default function reducer(state: IRestaurantCallsStore = initState, action: IAppActions): IRestaurantCallsStore {
  switch (action.type) {
    case ActionTypes.FETCH_RESTAURANT_CALLS:
      return {
        ...state,
        isLoading: true,
        error: initState.error,
      };

    case ActionTypes.FETCH_RESTAURANT_CALLS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        items: action.calls,
      };

    case ActionTypes.FETCH_RESTAURANT_CALLS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case ActionTypes.RESET_RESTAURANT_CALLS:
      return {
        ...state,
        items: initState.items,
      };

    case ActionTypes.ADD_RESTAURANT_CALL:
      if (state.items && state.items.find(call => call.id === action.call.id)) {
        return {
          ...state,
          items: state.items.map(
            (call: IRestaurantCall) => (call.id === action.call.id ? action.call : call),
          ),
        };
      }

      return {
        ...state,
        items: [
          ...(state.items || []),
          action.call,
        ],
      };

    case ActionTypes.REMOVE_RESTAURANT_CALL:
      return {
        ...state,
        items: (state.items || []).filter(item => item.id !== action.id),
      };

      // case ActionTypes.NOTIFY_RESTAURANT_CALL:
      //   return {
      //     ...state,
      //     notificationSeconds: 0,
      //   };
      //
      // case ActionTypes.INCREASE_NOTIFICATION_INTERVAL_RESTAURANT_CALL:
      //   return {
      //     ...state,
      //     notificationSeconds: state.notificationSeconds + 1,
      //   };

    default:
      return state;
  }
}
