import React from 'react';
import { Rect } from 'react-konva';

import { TSectionElementScheme } from '../../../../types/IRestaurantTablesSchemaStore';
import { getColor } from '../../../../helpers/scheme/schemeHelpers';
import rotateAroundCenter from './rotateHelper';

interface IProps {
  data: TSectionElementScheme;
  handleSelect?: (data: TSectionElementScheme) => void;
}

function RectElement(props: IProps) {
  const { handleSelect, data } = props;
  const {
    x, y, angle, width, height, color,
  } = data;

  const shapeRef = React.useRef(null);
  React.useEffect(() => {
    rotateAroundCenter(shapeRef.current, angle);
  }, [shapeRef, angle]);

  const onClick = () => {
    if (typeof handleSelect === 'function') handleSelect(data);
  };

  return (
    <Rect
      ref={shapeRef}
      x={x}
      y={y}
      width={width}
      height={height}
      fill={getColor(color)}
      onClick={onClick}
    />
  );
}

export default RectElement;
