import React from 'react';
import Paper from '@material-ui/core/Paper/Paper';
import PageContentWrap from '../components/Common/PageContentWrap';

import { EmployeeFormContainer } from '../containers/Employees';
import Title from '../components/Common/Title';
import locales from '../locales';

export default function ProfilePage() {
  return (
    <>
      <Paper elevation={3}>
        <Title
          text={locales.t('common.profile')}
          borderBottom
        />
      </Paper>
      <PageContentWrap fullWidth spacing={0}>
        <EmployeeFormContainer type="profile" />
      </PageContentWrap>
    </>
  );
}
