import React from 'react';
import { Route, Switch } from 'react-router-dom';

import CabinetPage from './CabinetPage';
import { Routers } from '../config/constants';
import ProtectedRoute from '../components/Common/ProtectedRoute';
import NotFoundPage from '../pages/NotFoundPage';
import ConfirmationPage from '../pages/ConfirmationPage';
import Auth from '../containers/Auth';
import Registration from '../containers/Registration';
import ResetPassword from '../containers/ResetPassword';
import ThanksYouPage from '../pages/ThanksYouPage';
import RulesPage from '../pages/RulesPage';
import SendNewPassword from '../containers/SendNewPassword';
import { EmployeeConfirmationPage } from '../pages';

function index() {
  return (
    <Switch>
      <Route path={Routers.login} component={Auth} />
      <Route path={Routers.registration} component={Registration} />
      <Route path={Routers.resPassword} component={ResetPassword} />
      <Route path={`${Routers.newPassword}/:key`} component={SendNewPassword} />
      <Route path={`${Routers.confirmation}/:key`} component={ConfirmationPage} />
      <Route path={Routers.thanks} component={ThanksYouPage} />
      <Route path={Routers.rules} component={RulesPage} />

      <Route path={`${Routers.employeeConfirmation}`} component={EmployeeConfirmationPage} />

      <ProtectedRoute path={Routers.main} component={CabinetPage} />

      {/* @todo С правилом Routers.main выше дальше выполнение не проходит. Код ниже не выполняется никогда? */}
      <Route component={NotFoundPage} />
    </Switch>
  );
}

export default index;



