import React, { MouseEvent, useState, useCallback } from 'react';

import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Button from '@material-ui/core/Button';

import locales from '../../locales';
import { Routers } from '../../config/constants';
import HomeIcon from '../Common/Icons/HomeIcon';
import BookingIcon from '../Common/Icons/BookingIcon';
import CardIcon from '../Common/Icons/SettingsIcon';
import SideBarItem from './SideBarItem';
import StatisticIcon from '../Common/Icons/StatisticIcon';
import { Employees } from '../../entities';

const styles = createStyles({
  menu: {
    padding: '0px 18px',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    height: '100%',
    color: '#323232',
  },
  menuHeader: {
    paddingTop: '22px',
    paddingBottom: '15px',
    marginBottom: '10px',
  },
  menuIcon: {
    fontSize: 32,
    color: '#323232',
  },
  newReserveWrap: {
    marginTop: 24,
    width: '100%',
    height: 52,
  },
});

interface IProps extends WithStyles<typeof styles> {
  pathname: string;
  handleSwitchDrawerClose: () => void;
  isRegistrationCompleted: boolean;
  handleOpenReserveCreateModal?: () => void;
}

interface IState {
  showSubMenu: boolean;
}

/** @description Компонент сайдбара */
const SideBar: React.FC<IProps> = ({
  classes,
  pathname,
  handleSwitchDrawerClose,
  handleOpenReserveCreateModal,
  isRegistrationCompleted,
}) => {
  /** @description Стейт видимости сайдбара */
  const [state, setState] = useState<IState>({ showSubMenu: false });

  /**
   * @description Функция проверки адреса подменю на принадлежность разделу "Ресторан"
   * @param pathname
   * @returns {boolean}
   */
  const checkIsRestaurantItem = useCallback((pathname: string) => {
    const restaurantSubMenuRouters = {
      [Routers.reservesSystem]: 'card',
      [Routers.restaurantScheme]: 'card',
      [Routers.cardMenu]: 'card',
      [Routers.cardAction]: 'card',
      [Routers.cardSettings]: 'card',
      [Routers.employees]: 'card',
    };

    return restaurantSubMenuRouters[pathname] === 'card';
  }, []);

  /**
   * @description Ручка для открытия/закрытия подменю
   */
  const handleSwitchSubMenu = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    const { showSubMenu } = state;

    event.stopPropagation();
    event.preventDefault();
    setState({ showSubMenu: !showSubMenu });
  }, [state]);

  const { data: e } = Employees.useCurrentEmployee();
  const settingItems: Array<{name: string, route: string}> = [];

  if (e && Employees.hasAccess(e, 'restaurantSettings:access')) {
    settingItems.push({
      name: locales.t('reservesSystem.title'),
      route: Routers.reservesSystem,
    });

    settingItems.push({
      name: locales.t('restaurantSchema.title'),
      route: Routers.restaurantScheme,
    });
  }

  if (e && Employees.hasAccess(e, 'employees:access')) {
    settingItems.push({
      name: locales.t('employees.title'),
      route: Routers.employees,
    });
  }

  // settingItems.push({
  //   name: locales.t('common.profile'),
  //   route: Routers.profile,
  // });

  return (
    <List className={classes.menu}>
      {(!isRegistrationCompleted || (e && Employees.hasAccess(e, 'stats:access'))) && (
        <SideBarItem
          route={Routers.home}
          pathname={pathname}
          handleSwitchDrawerClose={handleSwitchDrawerClose}
          text={locales.t('sideBar.home')}
          icon={<HomeIcon className={classes.menuIcon} />}
        />
      )}

      {(!isRegistrationCompleted || (e && Employees.hasAccess(e, 'reserves:access'))) && (
        <SideBarItem
          disabled={!isRegistrationCompleted}
          route={Routers.booking}
          pathname={pathname}
          handleSwitchDrawerClose={handleSwitchDrawerClose}
          text={locales.t('sideBar.booking')}
          icon={<BookingIcon className={classes.menuIcon} />}
        />
      )}

      {(!isRegistrationCompleted || (e && Employees.hasAccess(e, 'stats:access'))) && (
        <SideBarItem
          disabled={!isRegistrationCompleted}
          route={Routers.statistics}
          pathname={pathname}
          handleSwitchDrawerClose={handleSwitchDrawerClose}
          text={locales.t('statistics.title')}
          icon={<StatisticIcon className={classes.menuIcon} />}
        />
      )}

      {(!isRegistrationCompleted || settingItems.length > 0) && (
        <SideBarItem
          // Пусть пункт всегда будет доступен, чтобы можно было удалить ресторан, даже
          //   если подключение еще было не полностью закончено
          // disabled={!isRegistrationCompleted}
          // route={Routers.reservesSystem}
          disabled={!e}
          route={settingItems.length > 0 ? settingItems[0].route : Routers.settings}
          pathname={pathname}
          showSubMenu={!!e && state.showSubMenu}
          handleSwitchSubMenu={handleSwitchSubMenu}
          handleSwitchDrawerClose={handleSwitchDrawerClose}
          checkIsRestaurantItem={checkIsRestaurantItem}
          text={locales.t('sideBar.settings')}
          icon={<CardIcon className={classes.menuIcon} />}
          subMenu={e ? settingItems : undefined}
        />
      )}

      {(!isRegistrationCompleted || (e && Employees.hasAccess(e, 'reserves:access')))
        && handleOpenReserveCreateModal && (
        <Button
          disabled={!isRegistrationCompleted}
          className={classes.newReserveWrap}
          variant="contained"
          color="primary"
          onClick={handleOpenReserveCreateModal}
        >
          { locales.t('sideBar.newReserve') }
        </Button>
      )}
    </List>
  );
};

export default withStyles(styles)(SideBar);
