import React from 'react';
import { Add } from '@material-ui/icons';

import {
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
  createStyles,
  Grid,
  Theme,
  Box,
} from '@material-ui/core';

import locales from '../../../locales';
import Title from '../../Common/Title';
import ItemComponent from './Item';
import ContextButtonComponent from '../../Common/Button/Context';
import { Employees } from '../../../entities';

const useStyles = makeStyles((theme: Theme) => createStyles({
  table: {
    '& thead th': {
      textTransform: 'uppercase',
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
}));

const ListComponent: React.FC<{
  isLoading: boolean;
  employees: GM.Employees.Employees;
  onClick: (id: number) => void;
  onEdit: (id: number) => void;
  onAdd: () => void;
  onDelete: (id: number) => void;
  onInvite: (id: number) => void;
  currentEmployee: GM.Employees.Employee | null;
}> = ({
  isLoading,
  employees,
  onClick,
  onEdit,
  onAdd,
  onDelete,
  onInvite,
  currentEmployee,
}) => {
  const classes = useStyles();
  const values = Object.values(employees);

  return (
    <Grid item xs={12}>
      <Paper elevation={0}>
        <Title text={locales.t('employees.title')} borderBottom />

        {isLoading && <Box p={2}>{locales.t('employees.loading')}</Box>}

        {!isLoading && (
          <>
            {values.length > 0 && (
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>{locales.t('employees.properties.name')}</TableCell>
                    <TableCell>{locales.t('employees.properties.email')}</TableCell>
                    <TableCell>{locales.t('employees.properties.roles')}</TableCell>
                    <TableCell>{locales.t('employees.properties.status')}</TableCell>
                    <TableCell>{locales.t('employees.properties.actions')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {values.map((employee => {
                    const isCurrentEmployee = (currentEmployee && currentEmployee.id === employee.id) || false;
                    const canBeDeleted = Employees.employeeCanBeDeleted(employee.id, employees);

                    return (
                      <ItemComponent
                        key={employee.id}
                        employee={employee}
                        isCurrentEmployee={isCurrentEmployee}
                        onClick={onClick}
                        onEdit={onEdit}
                        onInvite={onInvite}
                        onDelete={canBeDeleted ? onDelete : undefined}
                        canBeDeleted={canBeDeleted}
                      />
                    );
                  }))}
                </TableBody>
              </Table>
            )}

            {values.length === 0 && <Box p={3}>{locales.t('employees.empty')}</Box>}

            <Box p={2}>
              <ContextButtonComponent
                startIcon={<Add />}
                onClick={onAdd}
                title={locales.t('employees.add')}
                isStrong
              />
            </Box>
          </>
        )}
      </Paper>
    </Grid>
  );
};

export default ListComponent;
