import * as ReserveActionsTypes
  from '../actions/reservationsPage/ReservationsPageActionsTypes';
import * as ReserveRejectActions
  from '../actions/reserveReject/RejectReserveActionsTypes';
import * as RestaurantActions from '../actions/restaurant/RestaurantsActionTypes';
import { IAppActions } from '../types/IAppActions';
import { IReserveModalStore, TActiveTables } from '../types/IReserveModalStore';

export const initState: IReserveModalStore = {
  activeTables: {},
  previousActiveTables: {},
  editReserveModalIsOpen: false,
  createReserveModalIsOpen: false,
  createReserveModalInitialData: undefined,
  confirmReserveModalIsOpen: false,
  currentReserve: undefined,
  editReserveInfo: undefined,
  freeTables: undefined,
  sections: undefined,
  bookingQueue: [],
  maxCapacity: 0,
  isMergeTables: false,
};

export default function reducer(state = initState, action: IAppActions): IReserveModalStore {
  switch (action.type) {
    case ReserveActionsTypes.SET_ACTIVE_TABLE_SUCCESS:
      return {
        ...state,
        activeTables: action.payload.activeTable,
      };

    case ReserveActionsTypes.OPEN_EDIT_RESERVE_MODAL: {
      const { reserve } = action.payload;
      const tables = reserve.tables || [];

      return {
        ...state,
        editReserveInfo: reserve,
        editReserveModalIsOpen: true,
        activeTables: tables.reduce((at: TActiveTables, t) => ({ ...at, [t.id]: t }), {}),

        // isMergeTables: tables.length > 1,
        // Если локально (без сохранения на сервере) было изменение поля, то берем его,
        //   иначе вычисляем на основания кол-ва столов в резерве
        isMergeTables: reserve.isMergeTables !== undefined ? reserve.isMergeTables : tables.length > 1,
      };
    }

    case ReserveActionsTypes.CLOSE_EDIT_RESERVE_MODAL:
    case ReserveActionsTypes.UPDATE_RESERVE_SUCCESS:
    case ReserveRejectActions.REJECT_RESERVE_SUCCESS:
      return {
        ...state,
        editReserveInfo: undefined,
        editReserveModalIsOpen: false,
        freeTables: undefined,
        activeTables: {},
        previousActiveTables: {},
        isMergeTables: false,
      };

    case RestaurantActions.GET_SECTION_REQUEST_SUCCESS: return {
      ...state,
      sections: action.payload.sections,
    };

    case ReserveActionsTypes.GET_FREE_TABLES_SUCCESS: return {
      ...state,
      freeTables: action.payload.freeTables,
    };

    case ReserveActionsTypes.OPEN_CREATE_RESERVE_MODAL:
      const { payload } = action;
      const initialData = (payload && payload.initialData) || undefined;
      const tables = (initialData && initialData.tables) || [];

      return {
        ...state,
        createReserveModalIsOpen: true,
        createReserveModalInitialData: initialData,

        activeTables: tables.reduce((at: TActiveTables, t) => ({ ...at, [t.id]: t }), {}),

        // isMergeTables: tables.length > 1,
        // Если локально (без сохранения на сервере) было изменение поля, то берем его,
        //   иначе вычисляем на основания кол-ва столов в резерве
        isMergeTables: initialData && initialData.isMergeTables !== undefined
          ? initialData.isMergeTables
          : tables.length > 1,
      };

    case ReserveActionsTypes.CREATE_RESERVE_SUCCESS:
    case ReserveActionsTypes.CLOSE_CREATE_RESERVE_MODAL:
      return {
        ...state,
        createReserveModalIsOpen: false,
        createReserveModalInitialData: undefined,
        freeTables: undefined,
        activeTables: {},
        previousActiveTables: {},
        isMergeTables: false,
      };

    case ReserveActionsTypes.GET_RESERVE_INFO_SUCCESS:
      return {
        ...state,
        currentReserve: action.payload.reserveInfo,
        activeTables: action.payload.activeTables,
        freeTables: action.payload.freeTables,
        confirmReserveModalIsOpen: true,
      };

    case ReserveActionsTypes.REJECT_NEW_RESERVE_SUCCESS:
    case ReserveActionsTypes.CLOSE_CONFIRM_RESERVE_MODAL:
    case ReserveActionsTypes.CONFIRM_RESERVE_SUCCESS:
      return {
        ...state,
        currentReserve: undefined,
        confirmReserveModalIsOpen: false,
        activeTables: {},
        previousActiveTables: {},
        isMergeTables: false,
      };

      // case ReserveActionsTypes.SET_RESERVE_TO_QUEUE:
      //   return {
      //     ...state,
      //     bookingQueue: state.bookingQueue.concat([action.payload.reserveId]),
      //   };
      //
      // case ReserveActionsTypes.UPDATE_RESERVE_QUEUE:
      //   return {
      //     ...state,
      //     bookingQueue: action.payload.newQueue,
      //   };

    case ReserveActionsTypes.SET_MAX_CAPACITY: {
      return {
        ...state,
        maxCapacity: action.payload.capacity,
      };
    }

    case ReserveActionsTypes.MERGE_TABLES_SUCCESS: {
      const { mergeTables, tables } = action.payload;

      return {
        ...state,
        isMergeTables: mergeTables,
        freeTables: tables,
        activeTables: state.previousActiveTables,
        previousActiveTables: state.activeTables,
      };
    }

    case ReserveActionsTypes.RESET_ACTIVE_TABLE:
      return {
        ...state,
        activeTables: {},
      };

    default:
      return state;
  }
}
