import { all, takeEvery, select } from 'redux-saga/effects';

import { IAppStore } from '../types/IAppStore';
import { audioNotification } from '../helpers/notificationHelpers';

import logo from '../images/logo.svg';
import { NEW_NOTIFICATION } from '../actions/notification/NotificationActionsTypes';
import { INewNotification } from '../actions/notification/INotificationActions';

function* reservesNotificationsSaga(action: INewNotification) {
  const isActive = yield select((state: IAppStore) => state.cabinet.audioNotification);

  switch (action.payload.type) {
    case 'reserve_confirmation': {
      if (isActive) {
        audioNotification('new_reserve');
      }

      try {
        Notification.requestPermission((result:any) => {
          if (result === 'granted') {
            new Notification('Новый запрос на резерв.', {
              icon : logo,
            });
          }
        });
      } catch (e) {
        console.error('notification error');
      }

      break;
    }

    default: break;
  }
}

export default function* saga() {
  yield all([
    takeEvery([NEW_NOTIFICATION], reservesNotificationsSaga),
  ]);
}
