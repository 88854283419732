import React from 'react';
import { useHistory } from 'react-router-dom';
import ListComponent from '../../../components/Employees/List';
import { Employees } from '../../../entities';

export default function EmployeeListContainer() {
  const deleteMutation = Employees.useDeleteMutation();
  const inviteMutation = Employees.useInviteEmployeeMutation();
  const employees = Employees.useEmployees();
  const { data: currentEmployee } = Employees.useCurrentEmployee();
  const history = useHistory();

  const onClick = (id: number) => history.push(`/employees/${id}`);
  const onAdd = () => history.push('/employees/add');
  const onDelete = (id: number) => deleteMutation.mutate({ id });
  const onInvite = (id: number) => inviteMutation.mutate({ id });
  const onEdit = (id: number) => {
    if (currentEmployee && currentEmployee.id === id) {
      history.push('/profile');
    } else {
      history.push(`/employees/${id}/edit`);
    }
  };

  return (
    <ListComponent
      isLoading={employees.isLoading}
      employees={employees.data || {}}
      onClick={onClick}
      onEdit={onEdit}
      onAdd={onAdd}
      onDelete={onDelete}
      onInvite={onInvite}
      currentEmployee={currentEmployee}
    />
  );
}
