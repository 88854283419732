import React, { Fragment } from 'react';

import {
  withStyles, WithStyles, createStyles, Theme,
} from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = (theme: Theme) => createStyles({
  wrap: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    flex: 1,
    zIndex: 99999,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  progress: {
    margin: theme.spacing(2),
  },
});

interface IProps extends WithStyles<typeof styles>{
  loading: boolean;
}

const Preloader: React.FC<IProps> = ({ classes, loading }) => {
  return (
    <Fragment>
      {
        loading && (
        <div className={classes.wrap}>
          <CircularProgress
            className={classes.progress}
            size={50}
          />
        </div>
        )
      }
    </Fragment>
  );
};

export default withStyles(styles)(Preloader);
