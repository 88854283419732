import { IAppActions } from '../../types/IAppActions';
import * as ActionTypes from './AppReservesActionsTypes';
import Reserve, { TAppReserveOuterChangeType, TAppReserves } from '../../types/IAppReservesStore';
import { IAppReserveCloseOptions } from './IAppReservesActions';

export function setAppReserves(reserves: TAppReserves): IAppActions {
  return {
    type: ActionTypes.SET_APP_RESERVES,
    reserves,
  };
}

export function addAppReserve(reserve: Reserve): IAppActions {
  return {
    type: ActionTypes.ADD_APP_RESERVE,
    reserve,
  };
}

export function removeAppReserve(key: string): IAppActions {
  return {
    type: ActionTypes.REMOVE_APP_RESERVE,
    key,
  };
}

export function openAppReserve(key?: string): IAppActions {
  return {
    type: ActionTypes.OPEN_APP_RESERVE,
    key,
  };
}

export function closeAppReserve(key?: string, options?: IAppReserveCloseOptions): IAppActions {
  return {
    type: ActionTypes.CLOSE_APP_RESERVE,
    key,
    options,
  };
}

export function toggleAppReserves(): IAppActions {
  return {
    type: ActionTypes.TOGGLE_APP_RESERVES,
  };
}

export function changeAppReserve(name: string, value?: any): IAppActions {
  return {
    type: ActionTypes.CHANGE_APP_RESERVE,
    name,
    value,
  };
}

export function outerChangeTypeAppReserve(
  outerChangeType: TAppReserveOuterChangeType,
  reserveId: number,
): IAppActions {
  return {
    type: ActionTypes.OUTER_CHANGE_APP_RESERVE,
    outerChangeType,
    reserveId,
  };
}
