import * as ActionTypes from './SnackBarsActionsTypes';
import { ISnackBarsActions } from './ISnackBarsActions';

/**
 * @description Экшен для создания нотификации
 * @param notification
 * @returns {{type: string, notification: Object}}}
 */
export function enqueueSnackbar(notification: any): ISnackBarsActions {
  return {
    type: ActionTypes.ENQUEUE_SNACKBAR,
    notification: {
      key: new Date().getTime() + Math.random(),
      ...notification,
    },
  };
}

/**
 * @description Экшен для удаления нотификации
 * @param key
 * @returns {{type: string, key: *}}
 */
export function removeSnackbar(key: string): ISnackBarsActions {
  return {
    type: ActionTypes.REMOVE_SNACKBAR,
    key,
  };
}

/**
 * @description Экшен для создания кастомного уведомления
 * @param error
 */
export function customErrorShackBar(error: string): ISnackBarsActions {
  return {
    type: ActionTypes.CUSTOM_ERROR,
    error,
  };
}

/**
 * @description Экшен для создания кастомного уведомления
 * @param message
 */
export function customMessageShackBar(message: string): ISnackBarsActions {
  return {
    type: ActionTypes.CUSTOM_MESSAGE,
    message,
  };
}

/**
 * @description Экшен для создания кастоного уведомления
 * @param params
 */
export function customSnackBart(params: {
  message: string;
  variant?: string;
  vertical?: string;
  horizontal?: string;
}): ISnackBarsActions {
  return {
    type: ActionTypes.CUSTOM_SNACKBAR,
    payload: {
      params,
    },
  };
}
