import * as ActionTypes from './MenuActionsTypes';
import { IMenuActions } from './IMenuActions';
import { TMenuCategory } from '../../types/IMenuStore';

/** @description Экшен на загрузку меню ресторана с iiko */
export function getRestaurantMenuFromIiko(restaurantId: number, organizationId: string): IMenuActions {
  return {
    type: ActionTypes.GET_MENU_FROM_IIKO_REQUEST,
    payload: { restaurantId, organizationId },
  };
}

/** @description Экшен для обработки успешной загрузки меню ресторана с iiko */
export function getRestaurantMenuFromIikoSuccess(menu: {
  id: number,
  description: string,
  name: string,
  menuList: Array<TMenuCategory>,
}): IMenuActions {
  return {
    type: ActionTypes.GET_MENU_FROM_IIKO_SUCCESS,
    message: 'Загрузка меню произведена успешно',
    payload: menu,
  };
}

/** @description Экшен для обработки ошибки загрузки меню ресторана с iiko */
export function getRestaurantMenuFromIikoError(): IMenuActions {
  return {
    type: ActionTypes.GET_MENU_FROM_IIKO_ERROR,
    error: 'Не удалось загрузить меню из iiko',
  };
}

/** @description Экшен для раскрытия выбранного списка меню */
export function changeVisibleMenuListItem(id: number): IMenuActions {
  return {
    type: ActionTypes.CHANGE_VISIBLE_MENU_LIST_ITEM,
    payload: id,
  };
}

/** @description Экшен для раскрытия всех списков меню */
export function openAllMenu(): IMenuActions {
  return {
    type: ActionTypes.OPEN_ALL_MENU,
  };
}

/** @description Экшен для закрытия всех списков меню */
export function closeAllMenu(): IMenuActions {
  return {
    type: ActionTypes.CLOSE_ALL_MENU,
  };
}

/** @description Экшен на получение меню с бека */
export function getRestaurantMenu(restaurantId: number): IMenuActions {
  return {
    type: ActionTypes.GET_MENU_REQUEST,
    payload: restaurantId,
  };
}

/** @description Экшен на обработку успешного запроса меню с бека */
export function getRestaurantMenuSuccess(menu: any): IMenuActions {
  return {
    type: ActionTypes.GET_MENU_SUCCESS,
    payload: menu,
  };
}

/** @description Экшен на обработку ошибки при запросе меню с бека */
export function getRestaurantMenuError(): IMenuActions {
  return {
    type: ActionTypes.GET_MENU_ERROR,
    error: 'Не удалось загрузить меню с сервера',
  };
}
