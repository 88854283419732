import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';

import { withStyles, createStyles, WithStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

import HelpIcon from './Icons/HelpIcon';

const styles = createStyles({
  tooltip: {
    paddingBottom: 10,
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  icon: {
    cursor: 'help',
    fontSize: 16,
  },
  marginLeft: {
    marginLeft: 10,
  },
});

interface IProps extends WithStyles<typeof styles> {
  content: string | Array<string>;
  marginLeft: boolean;
}

/**
 * @param classes {Object}
 * @param content {string || Array<String> } - Текст тултипа
 * @param marginLeft {boolean} - Маржин с лева 10px
 */
const CustomTooltip: React.FC<IProps> = ({
  content, classes, marginLeft,
}) => {
  if (typeof content === 'string' || _.isEmpty(content)) {
    return (
      <Tooltip
        title={content || ''}
      >
        <div>
          <HelpIcon className={classNames(classes.icon, { [classes.marginLeft]: marginLeft })} />
        </div>
      </Tooltip>
    );
  }

  return (
    <Tooltip
      title={
        content.map((value, index) => (
          <div
            key={index.toString()}
            className={classes.tooltip}
            dangerouslySetInnerHTML={{ __html: value }}
          />
        ))
      }
    >
      <div>
        <HelpIcon className={classNames(classes.icon, { [classes.marginLeft]: marginLeft })} />
      </div>
    </Tooltip>
  );
};

export default withStyles(styles)(CustomTooltip);
