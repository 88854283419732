import { createSelector } from 'reselect';
import { IAppStore } from '../types/IAppStore';
import { TRestaurantSectionAdapt } from '../types/IRestaurantTablesSchemaStore';

/** @description Список залов */
export const selectorTableSections = (state: IAppStore) => state.reserveModal.sections;
/** @description Список залов в которых есть столы для бронирования */
export const selectorTableSectionsAvailable = createSelector(
  [selectorTableSections],
  (sections?: TRestaurantSectionAdapt[]): TRestaurantSectionAdapt[] => (sections || [])
    .filter(section => (section.tables || [])
      .find(table => table.isBookingAllowed)),
);

export type IPropsSelectOption = { value: number, label: string };

const normalizeOption = ({
  id,
  name,
}: TRestaurantSectionAdapt): IPropsSelectOption => ({
  value: id,
  label: name,
});

function section2option(sections?: TRestaurantSectionAdapt[]): Array<IPropsSelectOption> {
  return (sections || []).map(normalizeOption);
}

/** @description Список залов в формате {value,label} для компонента Select */
export const selectorSectionOptions = createSelector([
  selectorTableSections,
], section2option);

/** @description Список доступных залов в формате {value,label} для компонента Select */
export const selectorSectionAvailableOptions = createSelector([
  selectorTableSectionsAvailable,
], section2option);
