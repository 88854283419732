import {
  put, takeLatest, all, select
} from 'redux-saga/effects';

import * as ReservesSystemActionsTypes from '../actions/restaurantsInfo/RestaurantsInfoActionsTypes';
import { cardFieldType } from '../config/constants';
import { updateRestaurantReq } from '../api';
import {
  updateRestaurantInfoSuccess, updateRestaurantInfoError,
} from '../actions/restaurantsInfo/RestaurantsInfoActions';
import { IUpdateRestaurantInfo } from '../actions/restaurantsInfo/IRestaurantsInfoActions';
import { getTablesForSettings } from '../actions/restaurantsTables/RestaurantsTables';
import { IAppStore } from '../types/IAppStore';

/** @description Редактирование полей у выбранного ресторана */
// eslint-disable-next-line
function* updateRestaurantInfoSaga(action: IUpdateRestaurantInfo) {
  try {
    const { value, type, currentRestaurant } = action.payload;
    let newData = null;
    let priceLvlValue = null;

    switch (type) {
      case cardFieldType.phone: {
        newData = {
          ...currentRestaurant,
          info: {
            ...currentRestaurant.info,
            phone: value,
          },
        };
        break;
      }
      case cardFieldType.priceLevel: {
        const moneySymbol = select((state: IAppStore) => state.cabinet.moneySymbol);

        switch (value) {
          case `${moneySymbol}`: {
            priceLvlValue = 'CHEAP';
            break;
          }
          case `${moneySymbol}${moneySymbol}`: {
            priceLvlValue = 'AVERAGE';
            break;
          }
          case `${moneySymbol}${moneySymbol}${moneySymbol}`: {
            priceLvlValue = 'EXPENSIVE';
            break;
          }
          case `${moneySymbol}${moneySymbol}${moneySymbol}${moneySymbol}`: {
            priceLvlValue = 'VERY_EXPENSIVE';
            break;
          }
          default: {
            return 'UNKNOW';
          }
        }

        newData = {
          ...currentRestaurant,
          info: {
            ...currentRestaurant.info,
            priceLevel: priceLvlValue,
          },
        };
        break;
      }
      case cardFieldType.site: {
        newData = {
          ...currentRestaurant,
          info: {
            ...currentRestaurant.info,
            site: value,
          },
        };
        break;
      }
      case cardFieldType.extras: {
        newData = {
          ...currentRestaurant,
          extras: value,
        };
        break;
      }
      case cardFieldType.description: {
        newData = {
          ...currentRestaurant,
          description: value,
        };
        break;
      }
      case cardFieldType.kitchen: {
        newData = {
          ...currentRestaurant,
          kitchens: value,
        };
        break;
      }
      case cardFieldType.name: {
        newData = {
          ...currentRestaurant,
          name: value,
        };
        break;
      }
      case cardFieldType.bookingDuration: {
        newData = {
          ...currentRestaurant,
          settings: {
            ...currentRestaurant.settings,
            // @ts-ignore
            bookingDuration: parseInt(value) * 60,
          },
        };
        break;
      }
      case cardFieldType.isAutoBookingEnabled: {
        newData = {
          ...currentRestaurant,
          settings: {
            ...currentRestaurant.settings,
            isAutoBookingEnabled: value,
          },
        };
        break;
      }
      case cardFieldType.autoBookingMinTimeElapsed: {
        newData = {
          ...currentRestaurant,
          settings: {
            ...currentRestaurant.settings,
            autoBookingMinTimeElapsed: value,
          },
        };
        break;
      }
      case cardFieldType.autoBookingMinFreeTables: {
        newData = {
          ...currentRestaurant,
          settings: {
            ...currentRestaurant.settings,
            autoBookingMinFreeTables: value,
          },
        };
        break;
      }
      case cardFieldType.autoBookingMaxNumberEmptySeats: {
        newData = {
          ...currentRestaurant,
          settings: {
            ...currentRestaurant.settings,
            autoBookingMaxNumberEmptySeats: value,
          },
        };
        break;
      }
      case cardFieldType.isSchemasEnabled: {
        newData = {
          ...currentRestaurant,
          settings: {
            ...currentRestaurant.settings,
            isSchemasEnabled: value,
          },
        };
        break;
      }

      case cardFieldType.isTelegramNotificationEnabled: {
        newData = {
          ...currentRestaurant,
          settings: {
            ...currentRestaurant.settings,
            telegramNotificationSettings: {
              ...currentRestaurant.settings.telegramNotificationSettings,
              isEnabled: value,
            },
          },
        };

        break;
      }

      case cardFieldType.telegramNotificationUsernames: {
        newData = {
          ...currentRestaurant,
          settings: {
            ...currentRestaurant.settings,
            telegramNotificationSettings: {
              ...currentRestaurant.settings.telegramNotificationSettings,
              usernames: value,
            },
          },
        };

        break;
      }

      default: {
        throw Error('empty');
      }
    }

    yield updateRestaurantReq(newData);
    yield put(updateRestaurantInfoSuccess());

    // В случае если был выключен/включен автоприём броней то обновляем информацию по списку столов.
    if (type === cardFieldType.isAutoBookingEnabled) {
      yield put(getTablesForSettings(currentRestaurant.id));
    }
  } catch (e) {
    yield put(updateRestaurantInfoError());
  }
}

export default function* saga() {
  yield all([
    takeLatest(ReservesSystemActionsTypes.UPDATE_RESTAURANT_INFO_REQUEST, updateRestaurantInfoSaga),
  ]);
}
