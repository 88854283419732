import moment from 'moment';

import * as BookingActions from '../actions/booking/BookingActionsTypes';
import { IAppActions } from '../types/IAppActions';
import { IBookingStore } from '../types/IBookingStore';
import { UPDATE_RESERVE_SUCCESS } from '../actions/reservationsPage/ReservationsPageActionsTypes';
import { localStorageItems } from '../config/constants';

export const initState: IBookingStore = {
  isInit: false,
  bookingList: [],
  getBookingInfoProcess: false,
  getBookingInfoError: null,
  currentStatisticDate: moment().format(),
  onlyActive: true,
  sort: {
    orderBy: 'date',
    order: 'asc',
  },
  filters: [
    {
      value: 'accepted',
      active: true,
    },
    {
      value: 'declined',
      active: false,
    },
    {
      value: 'rejected',
      active: false,
    },
    {
      value: 'service',
      active: false,
    },
    {
      value: 'open',
      active: false,
    },
    {
      value: 'closed',
      active: false,
    },
  ],
};

export default function reducer(state = initState, action: IAppActions): IBookingStore {
  switch (action.type) {
    case BookingActions.INIT_BOOKING_PAGE_SUCCESS:
      return {
        ...state,
        isInit: true,
        bookingList: action.payload.reserves,
        filters: action.payload.filters,
        onlyActive: action.payload.onlyActive,
      };

    case BookingActions.INIT_BOOKING_PAGE_ERROR:
      return {
        ...state,
        isInit: true,
        filters: action.payload.filters,
        onlyActive: action.payload.onlyActive,
      };

    case BookingActions.GET_BOOKING_REQUEST:
      return {
        ...state,
        getBookingInfoProcess: true,
      };

    case BookingActions.GET_BOOKING_SUCCESS: {
      const {
        bookingList, date, active, sort,
      } = action.payload;

      return {
        ...state,
        bookingList,
        getBookingInfoProcess: false,
        getBookingInfoError: null,
        currentStatisticDate: date,
        onlyActive: active,
        sort,
      };
    }

    case BookingActions.GET_BOOKING_ERROR:
      return {
        ...state,
        getBookingInfoProcess: false,
        getBookingInfoError: action.error,
        currentStatisticDate: action.payload.date,
      };

    case UPDATE_RESERVE_SUCCESS: {
      return {
        ...state,
        bookingList: state.bookingList.map((b) => {
          if (b.id === action.payload.updatedReserve.id) {
            return action.payload.updatedReserve;
          }

          return b;
        }),
      };
    }

    case BookingActions.SET_FILTERS: {
      localStorage.setItem(localStorageItems.filters, JSON.stringify(action.payload.filters));

      return {
        ...state,
        filters: action.payload.filters,
      };
    }

    case BookingActions.SWITCH_BOOKING_ACTIVE: {
      const { active, resetFilters } = action.payload;

      const updateFilters = active
        ? state.filters.map(f => (f.value === 'accepted' ? { ...f, active } : { ...f, active: false }))
        : state.filters.map(f => (resetFilters ? { ...f, active: false } : f));

      localStorage.setItem(localStorageItems.filters, JSON.stringify(updateFilters));

      return {
        ...state,
        filters: updateFilters,
      };
    }

    default:
      return state;
  }
}
