import React from 'react';
import { NavLink } from 'react-router-dom';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import * as EmailValidator from 'email-validator';

import locales from '../../locales';
import TextField from '../Common/TextFieldComp';
import { Routers } from '../../config/constants';
import CheckBoxComp from '../Common/CheckBoxComp';

// import systems from '../../config/systems';
// import { GoogleLogin } from 'react-google-login';

// TODO Обернуть checkbox remember в Field от redux-form
const SigInForm: React.FC<InjectedFormProps<{}, {}>> = ({ handleSubmit }) => (
  <form onSubmit={handleSubmit} style={{ marginTop: '30px' }}>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Field
          name="email"
          component={TextField}
          label={locales.t('common.email')}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          name="password"
          component={TextField}
          type="password"
          label={locales.t('common.password')}
        />
      </Grid>
      <Grid item xs={12} container direction="row" alignItems="center" justify="space-between">
        <Field
          name="remember"
          type="checkbox"
          label={locales.t('common.rememberMe')}
          component={CheckBoxComp}
        />
        <Typography variant="body1">
          <NavLink to={Routers.resPassword}>{ locales.t('common.restorePasswordLink') }</NavLink>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
        >
          { locales.t('common.login') }
        </Button>
      </Grid>
      <Grid item xs={12} sm={6}>
        <NavLink to={Routers.registration}>
          <Button
            fullWidth
            variant="outlined"
          >
            { locales.t('common.registrationLink') }
          </Button>
        </NavLink>
      </Grid>
      {/* <Grid item xs={ 12 } container justify={'center'}> */}
      {/* <GoogleLogin */}
      {/* clientId={ systems.googleId } */}
      {/* buttonText="Log in with Google" */}
      {/* onSuccess={ () => console.log('google Success') } */}
      {/* onFailure={ () => console.log('google Failure') } */}
      {/* /> */}
      {/* </Grid> */}
    </Grid>
  </form>
);

interface IErrors {
  email?: string;
  password?: string;
}

function validate({ email, password }: IErrors): IErrors {
  const errors: IErrors = {};

  if (!email) errors.email = 'Заполните поле email';
  else if (!EmailValidator.validate(email)) errors.email = 'Введите корректный email';

  if (!password) errors.password = 'Заполните поле пароль';

  return errors;
}

export default reduxForm<{}, {}>({
  form: 'auth',
  validate,
})(SigInForm);
