// @todo Убрать слово Restaurants, пусть будет ощутимо короче и проще просто call(s) везде?

import * as ActionTypes from './RestaurantsCallsActionsTypes';
import { IRestaurantCall, IRestaurantRequestCalls } from '../../types/IRestaurantCallsStore';
import { IAppActions } from '../../types/IAppActions';

export function fetchRestaurantCalls(payload: IRestaurantRequestCalls): IAppActions {
  return {
    type: ActionTypes.FETCH_RESTAURANT_CALLS,
    payload,
  };
}

export function fetchRestaurantCallsSuccess(calls: Array<IRestaurantCall>): IAppActions {
  return {
    type: ActionTypes.FETCH_RESTAURANT_CALLS_SUCCESS,
    calls,
  };
}

export function fetchRestaurantCallsError(error: string): IAppActions {
  return {
    type: ActionTypes.FETCH_RESTAURANT_CALLS_ERROR,
    error,
  };
}

export function resetRestaurantCalls(): IAppActions {
  return {
    type: ActionTypes.RESET_RESTAURANT_CALLS,
  };
}

export function addRestaurantCall(call: IRestaurantCall): IAppActions {
  return {
    type: ActionTypes.ADD_RESTAURANT_CALL,
    call,
  };
}

export function removeRestaurantCall(id: number, manually: boolean = false): IAppActions {
  return {
    type: ActionTypes.REMOVE_RESTAURANT_CALL,
    id,
    manually,
  };
}
