import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import UserStatisticsCont from './SourceStatisticCont';
import { IAppStore } from '../../types/IAppStore';
import { IDispatchProps, IStateProps } from './ISourceStatistic';
import { IAppActions } from '../../types/IAppActions';
import {
  getSourceStatistic, getReservesBySource, getSourceInfo,
} from '../../actions/sourceStatistic/SourceStatisticActions';
import { openBookingDetails } from '../../actions/statistics/StatisticsActions';

function mapStateToProps(state: IAppStore): IStateProps {
  return {
    currentRestaurantId: state.cabinet.currentRestaurant && state.cabinet.currentRestaurant.id,
    moneySymbol: state.cabinet.moneySymbol,
    statistic: state.sourceStatistic.statistic,
    isInit: state.sourceStatistic.isInit,
    reserves: state.sourceStatistic.reserves,
    sourceInfo: state.sourceStatistic.sourceInfo,
    pages: state.sourceStatistic.pages,
    loading: state.loading.isLoading,
    bookingOrders: state.statistic.bookingsOrders,
  };
}

function mapDispatchToProps(dispatch: Dispatch<IAppActions>): IDispatchProps {
  return bindActionCreators({
    getSourceStatistic,
    getReservesBySource,
    getSourceInfo,
    openBookingDetails,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(UserStatisticsCont);
