import { IRestorePasswordActions } from './IRestorePasswordActions';
import * as ActionTypes from './RestorePasswordActionsTypes';

/** @description Экшен для сброса пароля */
export function restorePassword(email: string): IRestorePasswordActions {
  return {
    type: ActionTypes.RESTORE_PASSWORD_REQUEST,
    payload: {
      email,
    },
  };
}

/** @description Экшен для обработки успешного сброса пароля */
export function restorePasswordSuccess(): IRestorePasswordActions {
  return {
    type: ActionTypes.RESTORE_PASSWORD_SUCCESS,
  };
}

/** @description Экшен для обработки ошибки сброса пароля */
export function restorePasswordError(error: string): IRestorePasswordActions {
  return {
    type: ActionTypes.RESTORE_PASSWORD_ERROR,
    error,
  };
}

/** @description Экшен для отправки нового пароля */
export function sendNewPassword(key: string, newPassword: string): IRestorePasswordActions {
  return {
    type: ActionTypes.SEND_NEW_PASSWORD_REQUEST,
    payload: {
      key,
      newPassword,
    },
  };
}

/** @description Экшен для обработки успешной смены пароля */
export function sendNewPasswordSuccess(): IRestorePasswordActions {
  return {
    type: ActionTypes.SEND_NEW_PASSWORD_SUCCESS,
  };
}

/** @description Экшен для обработки успешной смены пароля */
export function sendNewPasswordError(error: string): IRestorePasswordActions {
  return {
    type: ActionTypes.SEND_NEW_PASSWORD_ERROR,
    error,
  };
}
