import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { AxiosError } from 'axios';

import locales from '../../../locales';
import { customErrorShackBar, customMessageShackBar } from '../../../actions/snackbars/SnackBarsActions';
import { useAuthUserId } from '../../../selectors/AuthSelectors';
import { useRestaurantId } from '../../../selectors/RestaurantSelectors';
import { mapResponseToEmployee } from './selectors';

import {
  getActivateEmployeeRequest,
  getCreateEmployeeRequest,
  getCurrentEmployeeRequest,
  getDeleteEmployeeRequest,
  getEmployeeByActivationKeyRequest,
  getEmployeesRequest,
  getResetEmployeePasswordRequest,
  getSendActivationEmailEmployeeRequest,
  getUpdateEmployeeRequest,
} from '../../../api';

export function useEmployeesQueryKey() {
  return [useAuthUserId(), 'employees', useRestaurantId()];
}

export function useEmployees() {
  const restaurantId = useRestaurantId();

  const { data: response, isLoading, isFetching } = useQuery({
    // staleTime: Infinity,
    staleTime: 60 * 1000 * 10,
    enabled: !!restaurantId,
    queryKey: useEmployeesQueryKey(),
    queryFn: () => (restaurantId && getEmployeesRequest(restaurantId)) || undefined,
  });

  let employees: GM.Employees.Employees | null = null;
  if (response && response.data) {
    employees = response.data.reduce((employees, employee) => ({
      ...employees,
      [employee.id]: mapResponseToEmployee(employee),
    }), {});
  }

  return {
    isLoading: isLoading || isFetching,
    data: employees,
  };
}

export function useCurrentEmployeeQueryKey() {
  return [useAuthUserId(), 'employees', 'current', useRestaurantId()];
}

export function useCurrentEmployee() {
  const restaurantId = useRestaurantId();

  const { data: response, isLoading, isFetching } = useQuery({
    staleTime: 60 * 1000 * 5,
    enabled: !!restaurantId,
    queryKey: useCurrentEmployeeQueryKey(),
    queryFn: () => (restaurantId && getCurrentEmployeeRequest(restaurantId)) || undefined,
  });

  let employee = null;
  if (response && response.data) {
    employee = mapResponseToEmployee(response.data);
  }

  return {
    isLoading: isLoading || isFetching,
    data: employee,
  };
}

export function useCreateMutation() {
  const queryClient = useQueryClient();
  const employeesQueryKey = useEmployeesQueryKey();
  const dispatch = useDispatch();

  return useMutation<GM.Employees.Response.Create.Success, AxiosError, any>({
    mutationFn: (payload: { requestData: GM.Employees.Form; restaurantId: number }) => {
      const { requestData, restaurantId } = payload;
      return getCreateEmployeeRequest(restaurantId, requestData);
    },

    onSuccess: (response: any) => {
      if (response.status === 200) {
        dispatch(customMessageShackBar(locales.t('employees.actions.create.success')));
        queryClient.invalidateQueries({ queryKey: employeesQueryKey });
      } else {
        dispatch(customErrorShackBar(locales.t('employees.actions.create.error')));
      }
    },

    onError: (error) => {
      if (error && error.response) {
        const { response } = error;

        if (response.data) {
          const { data } = response;

          if (Array.isArray(data)) {
            const errorMessages: Array<string> = [];

            data.forEach((error: { code: string }) => {
              if (error.code === 'employee_already_exists') {
                errorMessages.push('Пользователь с таким адресом уже существует');
              }
            });

            if (errorMessages.length > 0) {
              dispatch(customErrorShackBar(errorMessages.join('. ')));
              return;
            }
          }

          if (data.errors && Array.isArray(data.errors)) {
            const fields = data.errors.map((error: { field: string }) => {
              let name = locales.t(`employees.properties.${error.field}`);
              if (!name) {
                name = error.field;
              }

              return name;
            });

            if (fields.length > 0) {
              const template = locales.t('employees.errors.placeholder');
              const errorMessage = template.replace('{fields}', fields.join(', ').toLowerCase());

              dispatch(customErrorShackBar(errorMessage));
              return;
            }
          }
        }
      }

      dispatch(customErrorShackBar(locales.t('employees.actions.create.error')));
    },
  });
}

export function useUpdateMutation() {
  const queryClient = useQueryClient();
  const employeesQueryKey = useEmployeesQueryKey();
  const currentEmployeeQueryKey = useCurrentEmployeeQueryKey();
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: (payload: {
      requestData: GM.Employees.Form;
      id: number;
      isProfile?: boolean;
    }) => {
      const { requestData, id } = payload;

      const employeeDataToUpdate: GM.Employees.Request.Update = {
        ...requestData,
        id,
      };

      // Изменять email нельзя по дизайну
      delete employeeDataToUpdate.email;

      return getUpdateEmployeeRequest(employeeDataToUpdate);
    },

    onSuccess: (response: any, payload) => {
      if (response.status === 200) {
        dispatch(customMessageShackBar(locales.t('employees.actions.update.success')));
        queryClient.invalidateQueries({ queryKey: employeesQueryKey });

        if (payload.isProfile) {
          queryClient.invalidateQueries({ queryKey: currentEmployeeQueryKey });
        }
      } else {
        dispatch(customErrorShackBar(locales.t('employees.actions.update.error')));
      }
    },

    onError: () => {
      dispatch(customErrorShackBar(locales.t('employees.actions.update.error')));
    },
  });
}

export function useDeleteMutation() {
  const queryClient = useQueryClient();
  const employeesQueryKey = useEmployeesQueryKey();
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: (payload: GM.Employees.Request.Delete) => getDeleteEmployeeRequest(payload),

    onSuccess: (response: any) => {
      if (response.status === 200) {
        dispatch(customMessageShackBar(locales.t('employees.actions.delete.success')));
        queryClient.invalidateQueries({ queryKey: employeesQueryKey });
      } else {
        dispatch(customErrorShackBar(locales.t('employees.actions.delete.error')));
      }
    },

    onError: () => {
      dispatch(customErrorShackBar(locales.t('employees.actions.delete.error')));
    },
  });
}

export function useInviteEmployeeMutation() {
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: (payload: GM.Employees.Request.Invite) => getSendActivationEmailEmployeeRequest(payload),

    onSuccess: (response: any) => {
      if (response.status === 200) {
        dispatch(customMessageShackBar(locales.t('employees.actions.invite.success')));
      } else {
        dispatch(customErrorShackBar(locales.t('employees.actions.invite.error')));
      }
    },

    onError: () => {
      dispatch(customErrorShackBar(locales.t('employees.actions.invite.error')));
    },
  });
}

export function useGetEmployeeByActivationKeyMutation() {
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: (payload: GM.Employees.Request.GetByActivationKey) => getEmployeeByActivationKeyRequest(payload),

    onSuccess: (response: any) => {
      if (response.status !== 200) {
        dispatch(customErrorShackBar(locales.t('employees.actions.getByActivationKey.error')));
      }
    },

    // onError: (response) => {
    onError: () => {
      dispatch(customErrorShackBar(locales.t('employees.actions.getByActivationKey.error')));
    },
  });
}

export function useActivateEmployeeMutation() {
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: (payload: GM.Employees.Request.Activate) => getActivateEmployeeRequest(payload),

    onSuccess: (response: any) => {
      if (response.status !== 200) {
        dispatch(customErrorShackBar(locales.t('employees.actions.activate.error')));
      }
    },

    onError: () => {
      dispatch(customErrorShackBar(locales.t('employees.actions.activate.error')));
    },
  });
}

export function useResetPasswordMutation() {
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: (payload: GM.Employees.Request.ResetPassword) => getResetEmployeePasswordRequest(payload),

    onSuccess: (response: any) => {
      if (response.status === 200) {
        dispatch(customMessageShackBar(locales.t('employees.actions.resetPasswordInit.success')));
      } else {
        dispatch(customErrorShackBar(locales.t('employees.actions.resetPasswordInit.error')));
      }
    },

    onError: () => {
      dispatch(customErrorShackBar(locales.t('employees.actions.resetPasswordInit.error')));
    },
  });
}
