import { TReserve } from '../types/IBookingStore';
import { phoneCode, reserveState } from '../config/constants';
import { getBookingStatus } from './dateHelpers';

function onlyNumbers(value: string) {
  // console.log(value, value.replace(/[^\d]/g, ''));
  return value.replace(/[^\d]/g, '');
}

/** @description Парсинг статуса резерва */
function parseReserveList(reserve: TReserve) {
  if (reserve.state === reserveState.CANCELING || reserve.state === reserveState.CANCELED) {
    const canceledBy = reserve.canceledBy || reserveState.UNKNOWN;
    return {
      ...reserve,
      state: `${canceledBy}_DECLINE`,
    };
  } if (reserve.state === reserveState.ACCEPTED) {
    return {
      ...reserve,
      state: getBookingStatus(reserve),
    };
  }

  return reserve;
}

/** @description Парсинг номера телефона в формат +7 999 999 99 99 */
function parsePhoneNumber(phone: string) {
  if (phone[1] === '7') {
    return phone.replace(/(\d{1})(\d{3})(\d{7})/,
      '$1 $2 $3');
  }

  if (phone[1] === '3') {
    return phone.replace(/(\d{3})(\d{2})(\d{6})/,
      '$1 $2 $3');
  }

  return phone;
}

/** @description Нормализация номера телефона в формат +7 999 999 99 99 для полей ввода */
const normalizePhoneOld = (value: string): string => {
  if (!value || value === '+7' || value === '7') {
    return '+7 ';
  }

  const onlyNums = value.replace(/[^\d]/g, '');

  if (onlyNums) {
    if (onlyNums.length === 1) {
      return '+7 ';
    }

    if (onlyNums.length > 1 && onlyNums.length <= 4) {
      return `+7 ${onlyNums.slice(1)}`;
    }

    if (onlyNums.length > 4 && onlyNums.length <= 7) {
      return `+7 ${onlyNums.slice(1, 4)} ${onlyNums.slice(4)}`;
    }

    if (onlyNums.length > 7 && onlyNums.length <= 9) {
      return `+7 ${onlyNums.slice(1, 4)} ${onlyNums.slice(4, 7)} ${onlyNums.slice(7, 10)}`;
    }

    if (onlyNums.length > 9) {
      return `+7 ${onlyNums.slice(1, 4)} ${onlyNums.slice(4, 7)} ${onlyNums.slice(7, 9)} ${onlyNums.slice(9, 11)}`;
    }

    return value;
  }

  return '';
};

/** @description Нормализация номера телефона в формат +7 999 9999999 или +380 99 999999 для полей ввода */
const normalizePhone = (value: string, code: string): string => {
  const onlyNums = onlyNumbers(value);
  if (onlyNums) {
    if (
        code === phoneCode.ru.code
        || code === phoneCode.us.code
    ) {
      if (onlyNums.length <= 3) {
        return onlyNums;
      }

      return `${onlyNums.slice(0, 3)} ${onlyNums.slice(3, 10)}`;

    } else if (code === phoneCode.az.code) {
      // Если добавляют 0 перед кодом, например +994 055 225 2206
      const azPos = onlyNums[0] === '0' ? 3 : 2;
      const azLength = onlyNums[0] === '0' ? 10 : 9;

      if (onlyNums.length <= azPos) {
        return onlyNums;
      }

      return `${onlyNums.slice(0, azPos)} ${onlyNums.slice(azPos, azLength)}`;

    } else if (
        code === phoneCode.ukr.code
        || code === phoneCode.uz.code
        || code === phoneCode.am.code
        || code === phoneCode.ge.code
        || code === phoneCode.kz.code
        || code === phoneCode.ae.code
        || code === phoneCode.by.code
        || code === phoneCode.kg.code
        || code === phoneCode.tm.code
    ) {
      if (onlyNums.length <= 2) {
        return onlyNums;
      }

      return `${onlyNums.slice(0, 2)} ${onlyNums.slice(2, 9)}`;

    } else if (code === phoneCode.est.code) {
      if (onlyNums.length <= 2) {
        return onlyNums;
      }

      return `${onlyNums.slice(0, 2)} ${onlyNums.slice(2, 8)}`;
    }

    return value;
  }

  return '';
};

/** @description Разбор номера на код и номер */
function getCodePhoneNumber(number: string): {
  code: string,
  number: string,
} {
  const onlyNums = onlyNumbers(number);
  const first = onlyNums.length >= 1 && onlyNums.substr(0, 1);
  const firstTwo = onlyNums.length >= 2 && onlyNums.substr(0, 2);
  const firstThree = onlyNums.length >= 3 && onlyNums.substr(0, 3);

  if (first) {
    if (firstThree) {
      if (`+${firstThree}` === phoneCode.uz.code) {
        return {
          code: phoneCode.uz.code,
          number: normalizePhone(onlyNums.slice(3), phoneCode.uz.code),
        };
      }

      if (`+${firstThree}` === phoneCode.am.code) {
        return {
          code: phoneCode.am.code,
          number: normalizePhone(onlyNums.slice(3), phoneCode.am.code),
        };
      }

      if (`+${firstThree}` === phoneCode.ge.code) {
        return {
          code: phoneCode.ge.code,
          number: normalizePhone(onlyNums.slice(3), phoneCode.ge.code),
        };
      }

      if (`+${firstThree}` === phoneCode.az.code) {
        return {
          code: phoneCode.az.code,
          number: normalizePhone(onlyNums.slice(3), phoneCode.az.code),
        };
      }

      if (`+${firstThree}` === phoneCode.ae.code) {
        return {
          code: phoneCode.ae.code,
          number: normalizePhone(onlyNums.slice(3), phoneCode.ae.code),
        };
      }

      if (`+${firstThree}` === phoneCode.by.code) {
        return {
          code: phoneCode.by.code,
          number: normalizePhone(onlyNums.slice(3), phoneCode.by.code),
        };
      }

      if (`+${firstThree}` === phoneCode.kg.code) {
        return {
          code: phoneCode.kg.code,
          number: normalizePhone(onlyNums.slice(3), phoneCode.kg.code),
        };
      }

      if (`+${firstThree}` === phoneCode.tm.code) {
        return {
          code: phoneCode.tm.code,
          number: normalizePhone(onlyNums.slice(3), phoneCode.tm.code),
        };
      }
    }

    if (firstTwo) {
      if (`+${firstTwo}` === phoneCode.ukr.code) {
        return {
          code: phoneCode.ukr.code,
          number: normalizePhone(onlyNums.slice(2), phoneCode.ukr.code),
        };
      }

      if (`+${firstTwo}` === phoneCode.est.code) {
        return {
          code: phoneCode.est.code,
          number: normalizePhone(onlyNums.slice(2), phoneCode.est.code),
        };
      }

      if (`+${firstTwo}` === phoneCode.kz.code) {
        return {
          code: phoneCode.kz.code,
          number: normalizePhone(onlyNums.slice(2), phoneCode.kz.code),
        };
      }
    }

    if (`+${first}` === phoneCode.ru.code) {
      return {
        code: phoneCode.ru.code,
        number: normalizePhone(onlyNums.slice(1), phoneCode.ru.code),
      };
    }

    if (`+${first}` === phoneCode.us.code) {
      return {
        code: phoneCode.us.code,
        number: normalizePhone(onlyNums.slice(1), phoneCode.us.code),
      };
    }
  }

  return {
    code: phoneCode.ru.code,
    number: '',
  };
}

const getLocale = (locale?: string) => {
  if (!locale) {
    return phoneCode.ru.code;
  }

  switch (locale) {
    case 'RU':
      return phoneCode.ru.code;
    case 'UA':
      return phoneCode.ukr.code;
    case 'EE':
      return phoneCode.est.code;
    case 'UZ':
      return phoneCode.uz.code;
    case 'AM':
      return phoneCode.am.code;
    case 'GE':
      return phoneCode.ge.code;
    case 'KZ':
      return phoneCode.kz.code;
    case 'AE':
      return phoneCode.ae.code;
    case 'US':
      return phoneCode.us.code;
    case 'AZ':
      return phoneCode.az.code;
    case 'BY':
      return phoneCode.by.code;
    case 'KG':
      return phoneCode.kg.code;
    case 'TM':
      return phoneCode.tm.code;
    default:
      return phoneCode.ru.code;
  }
};

export {
  parseReserveList,
  parsePhoneNumber,
  normalizePhone,
  onlyNumbers,
  getCodePhoneNumber,
  getLocale,
};
