export const Routers = {
  main: '/',
  auth: '/auth',
  login: '/auth',
  resPassword: '/reset-password',
  registration: '/registration',
  cabinet: '/cabinet',
  home: '/home',
  booking: '/booking',
  statistics: '/statistics',
  settings: '/settings',
  servicesInfo: '/services-info',
  reservesSystem: '/reserves-system',
  restaurantScheme: '/restaurant-scheme',
  cardMenu: '/settings/menu',
  cardAction: '/settings/action',
  cardSettings: '/settings/tables-and-reserves',
  userStatistics: '/user-statistics',
  sourceStatistics: '/source-statistics',
  confirmation: '/confirmation',
  thanks: '/thanks',
  rules: 'https://guestme.ru/privacy_policy/',
  newPassword: '/new-password',
  reserve: '/reserve',
  employees: '/employees',
  employeeConfirmation: '/employee/new/confirmation/:key',
  profile: '/profile',
};

/**
 * Виды кухни
 * @type {string[]}
 */
export const KitchenType = [
  'AUSTRALIAN',
  'AUSTRIAN',
  'AZERBAIJAN',
  'ASIAN',
  'AMERICAN',
  'ARABIC',
  'ARGENTINEAN',
  'ARMENIAN',
  'AFGANISTAN',
  'AFRICAN',
  'BANGLADESH',
  'BAR',
  'BBQ',
  'BELGIAN',
  'MIDDLE_EAST',
  'BRAZILIAN',
  'BRITISH',
  'WINE_BAR',
  'EASTERN_EUROPEAN',
  'VIETNAMESE',
  'HAWAIIAN',
  'GASTRO_PUB',
  'GREEK',
  'GRILL',
  'GEORGIAN',
  'DANISH',
  'DELICATESSES',
  'EUROPEAN',
  'EGYPTIAN',
  'SNACK_BAR',
  'HEALTHY',
  'ISRAEL',
  'INDIAN',
  'INDONESIAN',
  'IRISH',
  'SPANISH',
  'ITALIAN',
  'CAUCASIAN',
  'CARIBBEAN',
  'CAFE',
  'CHINESE',
  'KOREAN',
  'CUBAN',
  'CUISINE_OF_SMALL_PEOPLES_OF_CHINA',
  'LATIN_AMERICAN',
  'LATVIAN',
  'LEBANESE',
  'MOROCCAN',
  'INTERNATIONAL',
  'MEXICAN',
  'MONGOLIAN',
  'SEAFOOD',
  'GERMAN',
  'NEPALESE',
  'NORWEGIAN',
  'PUB',
  'BREWERY_PUB',
  'PAKISTANI',
  'PERSIAN',
  'PERUVIAN',
  'PIZZA',
  'PORTUGUESE',
  'ROMANIAN',
  'RUSSIAN',
  'SINGAPORE',
  'SCANDINAVIAN',
  'MODERN',
  'MEDITERRANEAN',
  'STEAKHOUSE',
  'SOUPS',
  'SUSHI',
  'TAIWANESE',
  'THAI',
  'TIBETAN',
  'TUNISIAN',
  'TURKISH',
  'UZBEK',
  'UKRAINIAN',
  'STREET',
  'FAST_FOOD',
  'FRENCH',
  'FUJIAN',
  'FUSION',
  'CROATIAN',
  'CENTRAL_ASIAN',
  'CENTRAL_AMERICAN',
  'CENTRAL_EUROPEAN',
  'CZECH',
  'SWEDISH',
  'SWISS',
  'SCOTTISH',
  'ETHIOPIAN',
  'SOUTHERNEAST',
  'SOUTH_AMERICAN',
  'JAPANESE',
];

/**
 * Дополнительные услуги
 * @type {string[]}
 */
export const extras = [
  'BANQUET',
  'WIFI',
  'RESERVATION',
  'DELIVERY',
  'WHEELCHAIR_ACCESS',
  'TAKEAWAY_FOOD',
  'BUFFET_BREAKFAST',
  'PARKING',
  'ALCOHOL',
  'OUTDOOR_TABLES',
  'TV',
  'HOOKAH',
  'VEGETARIAN',
  'CHILDREN_MENU',
  'BUSINESS_LUNCH',
  'CARD_PAY',
  'NO_LIVE_MUSIC',
  'LIVE_MUSIC',
];

export const cardFieldType = {
  phone: 'phone',
  site: 'site',
  extras: 'extras',
  kitchen: 'kitchen',
  schedule: 'schedule',
  priceLevel: 'priceLevel',
  name: 'name',
  about: 'about',
  description: 'description',
  bookingProcessor: 'bookingProcessor',
  bookingDuration: 'bookingDuration',
  isAutoBookingEnabled: 'isAutoBookingEnabled',
  autoBookingMinTimeElapsed: 'autoBookingMinTimeElapsed',
  autoBookingMinFreeTables: 'autoBookingMinFreeTables',
  autoBookingMaxNumberEmptySeats: 'autoBookingMaxNumberEmptySeats',
  isSchemasEnabled: 'isSchemasEnabled',
  isTelegramNotificationEnabled: 'isTelegramNotificationEnabled',
  telegramNotificationUsernames: 'telegramNotificationUsernames',
};

export const autoBooking = {
  api: 'API',
  cashBox: 'CASHBOX',
};

export const dashBoardDates = {
  today: 'today',
  tomorrow: 'tomorrow',
  seven: 'seven',
  fourteen: 'fourteen',
  twentyEight: 'twentyEight',
  sixty: 'sixty',
  firstMonth: 'firstMonth',
  secondMonth: 'secondMonth',
  currentMonth: 'currentMonth',
  custom: 'custom',
};

export const priceLvl = [
  'CHEAP',
  'AVERAGE',
  'EXPENSIVE',
  'VERY_EXPENSIVE',
];

/**
 * Контрольная точка для планшета в пикселях
 * @type {number}
 */
export const tabletWidth = 1025;

export const workTimes = [
  '00:00',
  '00:15',
  '00:30',
  '00:45',
  '01:00',
  '01:15',
  '01:30',
  '01:45',
  '02:00',
  '02:15',
  '02:30',
  '02:45',
  '03:00',
  '03:15',
  '03:30',
  '03:45',
  '04:00',
  '04:15',
  '04:30',
  '04:45',
  '05:00',
  '05:15',
  '05:30',
  '05:45',
  '06:00',
  '06:15',
  '06:30',
  '06:45',
  '07:00',
  '07:15',
  '07:30',
  '07:45',
  '08:00',
  '08:15',
  '08:30',
  '08:45',
  '09:00',
  '09:15',
  '09:30',
  '09:45',
  '10:00',
  '10:15',
  '10:30',
  '10:45',
  '11:00',
  '11:15',
  '11:30',
  '11:45',
  '12:00',
  '12:15',
  '12:30',
  '12:45',
  '13:00',
  '13:15',
  '13:30',
  '13:45',
  '14:00',
  '14:15',
  '14:30',
  '14:45',
  '15:00',
  '15:15',
  '15:30',
  '15:45',
  '16:00',
  '16:15',
  '16:30',
  '16:45',
  '17:00',
  '17:15',
  '17:30',
  '17:45',
  '18:00',
  '18:15',
  '18:30',
  '18:45',
  '19:00',
  '19:15',
  '19:30',
  '19:45',
  '20:00',
  '20:15',
  '20:30',
  '20:45',
  '21:00',
  '21:15',
  '21:30',
  '21:45',
  '22:00',
  '22:15',
  '22:30',
  '22:45',
  '23:00',
  '23:15',
  '23:30',
  '23:45',
];

export const reserveState = {
  ACCEPTED: 'ACCEPTED', // Подтвержден
  DECLINED: 'DECLINED', // Отклонен рестораном
  GUEST_DECLINE: 'GUEST_DECLINE', // Отменен посетителем
  RESTAURATEUR_DECLINE: 'RESTAURATEUR_DECLINE', // Отменен рестораном
  UNKNOWN_DECLINE: 'UNKNOWN_DECLINE', // Отменен
  CREATED: 'CREATED', // Создан
  SENT: 'SENT', // Ожидаем ответ ресторана",
  CLOSED: 'CLOSED', // Закрыт
  IN_PROGRESS: 'IN_PROGRESS', // Открыт
  PATCHING: 'PATCHING', // Обновление
  ERROR: 'ERROR', // Ошибка
  NEED_PREPARE_TABLE: 'NEED_PREPARE_TABLE', // Пора готовить стол
  GUEST_IS_LATE: 'GUEST_IS_LATE', // Гость паздывает
  UNKNOWN: 'UNKNOWN', // На случай если canceledBy null
  CANCELING: 'CANCELING', // Отменяется
  CANCELED: 'CANCELED', // Отменен
};

// @todo Уточнить статусы
export const editableReserveStates = [
  reserveState.ACCEPTED,
  reserveState.CREATED,
  reserveState.SENT,
  reserveState.IN_PROGRESS,
  reserveState.NEED_PREPARE_TABLE,
  reserveState.GUEST_IS_LATE,
];

export const localStorageItems = {
  autoBookingSchedule: 'autoBookingSchedule',
  filters: 'filters',
};

export const reserveSource = {
  RESTAURATEUR: 'RESTAURATEUR_API',
};

export const reserveModal = {
  EDIT: 'edit',
  CONFIRM: 'confirm',
  NEW: 'new',
};

export const emptyTime = '-';

export const emptyString = '-';

export const weekDays = {
  MONDAY: 'MONDAY',
  TUESDAY: 'TUESDAY',
  WEDNESDAY: 'WEDNESDAY',
  THURSDAY: 'THURSDAY',
  FRIDAY: 'FRIDAY',
  SATURDAY: 'SATURDAY',
  SUNDAY: 'SUNDAY',
};

interface IPhoneCode {
  key: string;
  code: string;
  firstLabel: string;
  secondLabel: string;
  title: string;
}

interface IPhoneCodes {
  [key: string]: IPhoneCode;
}

export const phoneCode: IPhoneCodes = {
  ru: {
    key: 'ru',
    code: '+7',
    firstLabel: '🇷🇺 (+7)',
    secondLabel: '🇷🇺 Россия (+7)',
    title: 'Россия',
  },
  ukr: {
    key: 'ukr',
    code: '+380',
    firstLabel: '🇺🇦 (+380)',
    secondLabel: '🇺🇦 Украина (+380)',
    title: 'Украина',
  },
  est: {
    key: 'est',
    code: '+372',
    firstLabel: '🇪🇪 (+372)',
    secondLabel: '🇪🇪 Эстония (+372)',
    title: 'Эстония',
  },
  uz: {
    key: 'uz',
    code: '+998',
    firstLabel: '🇺🇿 (+998)',
    secondLabel: '🇺🇿 Узбекистан (+998)',
    title: 'Узбекистан',
  },
  am: {
    key: 'am',
    code: '+374',
    firstLabel: '🇦🇲 (+374)',
    secondLabel: '🇦🇲 Армения (+374)',
    title: 'Армения',
  },
  ge: {
    key: 'ge',
    code: '+995',
    firstLabel: '🇬🇪 (+995)',
    secondLabel: '🇬🇪 Грузия (+995)',
    title: 'Грузия',
  },
  kz: {
    key: 'kz',
    code: '+77',
    firstLabel: '🇰🇿 (+77)',
    secondLabel: '🇰🇿 Казахстан (+77)',
    title: 'Казахстан',
  },
  ae: {
    key: 'ae',
    code: '+971',
    firstLabel: '🇦🇪 (+971)',
    secondLabel: '🇦🇪 ОАЭ (+971)',
    title: 'ОАЭ',
  },
  us: {
    key: 'us',
    code: '+1',
    firstLabel: '🇺🇸 (+1)',
    secondLabel: '🇺🇸 США (+1)',
    title: 'США',
  },
  az: {
    key: 'az',
    code: '+994',
    firstLabel: '🇦🇿 (+994)',
    secondLabel: '🇦🇿 Азербайджан (+994)',
    title: 'Азербайджан',
  },
  by: {
    key: 'by',
    code: '+375',
    firstLabel: '🇧🇾 (+375)',
    secondLabel: '🇧🇾 Белоруссия (+375)',
    title: 'Белоруссия',
  },
  kg: {
    key: 'kg',
    code: '+996',
    firstLabel: '🇰🇬 (+996)',
    secondLabel: '🇰🇬 Кыргызстан (+996)',
    title: 'Кыргызстан',
  },
  tm: {
    key: 'tm',
    code: '+993',
    firstLabel: '🇹🇲 (+993)',
    secondLabel: '🇹🇲 Туркменистан (+993)',
    title: 'Туркменистан',
  },
};

const sortPhoneCodeByTitle = (a: IPhoneCode, b: IPhoneCode) => {
  const nameA = a.title.toUpperCase();
  const nameB = b.title.toUpperCase();

  if (nameA < nameB) {
    return -1;
  }

  if (nameA > nameB) {
    return 1;
  }

  return 0;
};

export const getPhoneCodes = (firstCountryKey?: string, sortByTitle?: boolean): Array<IPhoneCode> => {
  let codes = Object.values(phoneCode);

  if (sortByTitle) {
    codes = codes.sort(sortPhoneCodeByTitle);
  }

  if (firstCountryKey) {
    return [
      phoneCode[firstCountryKey],
      ...codes.filter(item => item.key !== firstCountryKey),
    ];
  }

  return codes;
};
