import React from 'react';

import Paper from '@material-ui/core/Paper/Paper';

import SettingField from '../../Common/FieldWrap';
import SelectItemLIst from '../../Common/SelfSavingFields/SelectItemLIst';
import { autoBooking, cardFieldType } from '../../../config/constants';
import locales from '../../../locales';
import CustomTooltip from '../../Common/CustomTooltip';
import EditField from '../../Common/SelfSavingFields/EditField';
import ReserveSchedule from './ReserveSchedule';
import { TRestaurant } from '../../../types/ICabinetStore';
import { TNewInfo } from '../../../types/IRestaurantInfoStore';
import {
  IFreeDay,
  IRestaurantWorkTimes,
  IScheduleRow,
  TWeekDay,
} from '../../../types/IScheduleStore';
import Title from '../../Common/Title';

interface IProps {
  currentRestaurant: TRestaurant;
  isActiveRestaurantSchedule?: boolean;
  handleSwitchBookingProcessor: (data: TNewInfo) => void;
  handleUpdateRestaurantSettings: (data: TNewInfo) => void;
  handleAddScheduleRow: () => void;
  handelUpdateSchedule: (newScheduleInfo: any) => void;
  handleDeleteScheduleRow: (row: IScheduleRow) => void;
  handleUpdateFreeDays: (days: {
    activate: Array<TWeekDay>,
    deactivate: Array<TWeekDay>
  }) => void;
  handleSwitchReserveSchedule: () => void;
  scheduleList: Array<IScheduleRow>;
  freeDays: Array<IFreeDay>;
  restaurantWorkTimes: IRestaurantWorkTimes;
}

const index: React.FC<IProps> = ({
  currentRestaurant,
  handleSwitchBookingProcessor,
  handleUpdateRestaurantSettings,
  handleAddScheduleRow,
  handelUpdateSchedule,
  handleDeleteScheduleRow,
  handleUpdateFreeDays,
  handleSwitchReserveSchedule,
  freeDays,
  isActiveRestaurantSchedule,
  scheduleList,
  restaurantWorkTimes,
}) => {
  const bookingProcessorText = currentRestaurant.settings.bookingProcessor === autoBooking.api
    ? locales.t('settings.api')
    : locales.t('settings.cashBox');
  return (
    <Paper elevation={3}>
      <Title borderBottom text={locales.t('settings.title')} />
      <div style={{ padding: '12px 0' }}>
        <SettingField>
          <SelectItemLIst
            type={cardFieldType.bookingProcessor}
            label={locales.t('settings.autoBookingSwitchTitle')}
            active={bookingProcessorText}
            items={[locales.t('settings.api'), locales.t('settings.cashBox')]}
            handleChangeList={handleSwitchBookingProcessor}
          />
          <CustomTooltip marginLeft content="" />
        </SettingField>
        <SettingField>
          <EditField
            label={locales.t('settings.bookingDuration.title')}
            value={Math.floor(currentRestaurant.settings.bookingDuration / 60)}
            handleChangeList={handleUpdateRestaurantSettings}
            type={cardFieldType.bookingDuration}
            fieldType="number"
          />
          <CustomTooltip marginLeft content="" />
        </SettingField>
        <ReserveSchedule
          label={locales.t('settings.reserveSchedule.title')}
          isActiveRestaurantSchedule={isActiveRestaurantSchedule}
          freeDays={freeDays}
          scheduleList={scheduleList}
          handleAddScheduleRow={handleAddScheduleRow}
          handelUpdateSchedule={handelUpdateSchedule}
          handleDeleteScheduleRow={handleDeleteScheduleRow}
          handleUpdateFreeDays={handleUpdateFreeDays}
          handleSwitchReserveSchedule={handleSwitchReserveSchedule}
          restaurantWorkTimes={restaurantWorkTimes}
        />
      </div>
    </Paper>
  );
};

export default index;
