import React from 'react';
import Typography from '@material-ui/core/Typography';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';

import CustomTooltip from './CustomTooltip';

const styles = {
  wrap: {
    padding: '16px 24px',
    display: 'flex',
    width: '100%',
    alignItems: 'center',
  },
  borderBottom: {
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
  },
  spaceBetween: {
    justifyContent: 'space-between',
  },
  title: {
    margin: 0,
  },
};

interface Props extends WithStyles<typeof styles>{
  text?: string;
  subTitle?: string;
  borderBottom?: boolean;
  helps?: string | Array<any>;
  padding?: any;
  spaceBetween?: boolean;
}

const TitleComp: React.FC<Props> = ({
  classes, padding, text, borderBottom, helps, spaceBetween, children, subTitle,
}) => {
  const styles = {
    padding,
  };

  return (
    <div
      style={styles}
      className={`${classes.wrap} ${borderBottom && classes.borderBottom} ${spaceBetween && classes.spaceBetween}`}
    >
      <div>
        <Typography className={classes.title} variant="h6" gutterBottom>
          { text }
        </Typography>
        {subTitle && (
          <Typography
            variant="caption"
            dangerouslySetInnerHTML={{__html: subTitle}}
          />
        )}
      </div>
      { children }
      { helps && <CustomTooltip marginLeft content={helps} /> }
    </div>
  );
};

export default withStyles(styles)(TitleComp);
