import React from 'react';

import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography/Typography';

const styles = () => createStyles({
  wrap: {
    paddingBottom: 24,
    width: '100%',
  },
  numWrap: {
    marginTop: 20,
    display: 'flex',
  },
  num: {
    fontSize: '2em',
    fontWeight: 500,
  },
  numMetrics: {
    fontSize: 10,
    paddingTop: 14,
    paddingLeft: 8,
    width: '100%',
  },
  columnHeader: {
    height: 20,
    display: 'flex',
    alignItems: 'center',
  },
});

interface IProps extends WithStyles<typeof styles> {
  type: string;
  stat: number;
  metric?: number;
}

const HomeStatisticItem: React.FC<IProps> = ({
  classes, type, stat, metric,
}) => {
  let color: 'textPrimary' | 'error' | 'primary' = 'textPrimary';
  let metricsColor: 'textPrimary' | 'error' | 'primary' = 'textPrimary';
  let isVisitor = false;

  if (metric && metric > 0) {
    metricsColor = 'primary';
    color = 'primary';
  } else if (metric && metric < 0) {
    metricsColor = 'error';
    color = 'error';
  } else {
    metricsColor = 'textPrimary';
    color = 'textPrimary';
  }

  if ((type === 'increaseVisitors' || type === 'allReserve') && (stat < 0)) {
    color = 'error';
  } else if ((type === 'increaseVisitors') && (stat > 0)) {
    color = 'primary';
    isVisitor = true;
  }

  if ((type === 'waitingGuests') && (stat > 0)) {
    color = 'primary';
  }

  if (type === 'allRejectsReserve') {
    metricsColor = 'textPrimary';
    color = 'textPrimary';
  }

  if (type === 'totalCancelledByRestaurant') {
    metricsColor = 'error';
    color = 'error';
  }

  const percent = type === 'increaseVisitors' ? '%' : '';
  const plus = isVisitor ? '+' : '';

  return (
    <div style={{ borderBottom: 'none', display: 'flex', flex: 1 }}>
      <div>
        <div className={classes.columnHeader}>
          <Typography variant="caption">
            { type }
          </Typography>
        </div>
        <div className={classes.numWrap}>
          <Typography variant="h4" className={classes.num} color={color}>
            { `${plus}${stat}${percent}`}
          </Typography>
          {
            metric
              && (
                <Typography className={classes.numMetrics} variant="caption" color={metricsColor}>
                  {`${(metric > 0) ? '+' : ''}${metric}%`}
                </Typography>
              )
          }
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(HomeStatisticItem);
