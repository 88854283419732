import * as ActionTypes from '../actions/restorePassword/RestorePasswordActionsTypes';
import { IAppActions } from '../types/IAppActions';
import { IRestorePasswordStore } from '../types/IRestorePasswordStore';

export const initState: IRestorePasswordStore = {
  userEmail: null,
  restorePasswordError: null,
  sendNewPasswordError: null,
  restorePasswordSuccess: false,
  sendNewPasswordSuccess: false,
};

export default function RestorePasswordReducer(state = initState, action: IAppActions): IRestorePasswordStore {
  switch (action.type) {
    case ActionTypes.RESTORE_PASSWORD_REQUEST:
      return {
        ...state,
        restorePasswordError: null,
        userEmail: action.payload.email,
      };
    case ActionTypes.RESTORE_PASSWORD_SUCCESS:
      return {
        ...state,
        restorePasswordError: null,
        restorePasswordSuccess: true,
      };
    case ActionTypes.RESTORE_PASSWORD_ERROR:
      return {
        ...state,
        restorePasswordError: action.error,
      };
    case ActionTypes.SEND_NEW_PASSWORD_REQUEST:
      return {
        ...state,
        sendNewPasswordError: null,
      };
    case ActionTypes.SEND_NEW_PASSWORD_SUCCESS:
      return {
        ...state,
        sendNewPasswordError: null,
        sendNewPasswordSuccess: true,
      };
    case ActionTypes.SEND_NEW_PASSWORD_ERROR:
      return {
        ...state,
        sendNewPasswordError: action.error,
      };
    default:
      return state;
  }
}
