import React, { PureComponent } from 'react';

import AutoBookingEnabled from '../../components/ReservesSystem/AutoBookingEnabled';
import { IProps, IState } from './IReservesSystemAutoBooking';
import { TNewInfo } from '../../types/IRestaurantInfoStore';

class SettingsCont extends PureComponent<IProps, IState> {
  /** @description Ручка для редактирования полей ресторана */
  handleUpdateRestaurantSettings = (data: TNewInfo) => {
    const { currentRestaurant, updateRestaurantInfo } = this.props;

    if (currentRestaurant) {
      const updateData = {
        ...data,
        currentRestaurant,
      };

      updateRestaurantInfo(updateData);
    }
  };

  render() {
    const {
      currentRestaurant,
    } = this.props;

    // TODO Убрать после правки в роутах, этот раздел должен быть заблокирован если нет ресторана
    if (!currentRestaurant) {
      return null;
    }

    return (
      <AutoBookingEnabled
        currentRestaurantSettings={currentRestaurant.settings}
        handleUpdateRestaurantSettings={this.handleUpdateRestaurantSettings}
      />
    );
  }
}

export default SettingsCont;
