import React from 'react';

import Switch from '@material-ui/core/Switch';
import Paper from '@material-ui/core/Paper';

import { cardFieldType } from '../../../config/constants';
import AutoBookingFields from './AutoBookingFields';
import locales from '../../../locales';
import { TRestaurantSettings } from '../../../types/ICabinetStore';
import { TNewInfo } from '../../../types/IRestaurantInfoStore';
import Title from '../../Common/Title';

interface IProps {
  currentRestaurantSettings: TRestaurantSettings;
  handleUpdateRestaurantSettings: (data: TNewInfo) => void;
}

const AutoBookingEnabledComp: React.FC<IProps> = ({
  currentRestaurantSettings,
  handleUpdateRestaurantSettings,
}) => (
  <Paper
    elevation={3}
    style={{ background: 'linear-gradient(110deg, rgba(252,245,234,1) 0%, rgba(255,255,255,1) 100%)' }}
  >
    <div>
      <Title
        spaceBetween
        text={locales.t('settings.isAutoBookingEnabled.title')}
        subTitle={locales.t('settings.isAutoBookingEnabled.desc')}
      >
        <Switch
          color="primary"
          checked={currentRestaurantSettings.isAutoBookingEnabled}
          onChange={() => handleUpdateRestaurantSettings({
            value: !currentRestaurantSettings.isAutoBookingEnabled,
            type: cardFieldType.isAutoBookingEnabled,
          })}
        />
      </Title>
    </div>
    {
      currentRestaurantSettings.isAutoBookingEnabled
      && (
        <AutoBookingFields
          currentRestaurantSettings={currentRestaurantSettings}
          handleUpdateRestaurantSettings={handleUpdateRestaurantSettings}
        />
      )
    }
  </Paper>
);

export default AutoBookingEnabledComp;
