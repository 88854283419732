import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { compose } from 'recompose';

import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { Routers } from '../config/constants';
import { confirmEmail, getSession } from '../actions/auth/AuthActions';
import AuthWrapComp from '../components/Auth/AuthWrapComp';
import Logo from '../components/Auth/CommonAuth/Logo';
import UseRules from '../components/Auth/CommonAuth/UseRules';

const styles = {
  contentTitle: {
    marginTop: 34,
    fontSize: 20,
    lineHeight: '24px',
  },
  contentText: {
    marginTop: 20,
    fontSize: 16,
  },
  contentBtn: {
    marginTop: 50,
    height: 46,
    width: 226,
    fontFamily: 'inherit',
  },
};

class ConfirmationPage extends Component {
  componentDidMount() {
    const { confirmEmail, match } = this.props;
    confirmEmail(match.params.key);
  }

  render() {
    const { confirmEmailStatus, confirmEmailProcess } = this.props.auth;
    const { classes } = this.props;

    if (confirmEmailProcess) {
      return (
        <AuthWrapComp>
          <Logo text="Подтверждение email" />
        </AuthWrapComp>
      );
    }
    return (
      <AuthWrapComp>
        <Logo text={confirmEmailStatus ? 'Регистрация завершена' : 'Не удалось подтвердить email'} />
        {confirmEmailStatus
          && (
          <Fragment>
            <Typography align="center" className={classes.contentTitle}>
              Адрес email подтвержден!
            </Typography>
            <Typography align="center" className={classes.contentText}>
              Теперь Вы можете войти в Личный Кабинет
            </Typography>
            <NavLink to={Routers.home}>
              <Button variant="contained" color="primary" className={classes.contentBtn}>
                ВОЙТИ
              </Button>
            </NavLink>
          </Fragment>
          )
          }
        <UseRules />
      </AuthWrapComp>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default compose(
  connect(mapStateToProps, { getSession, confirmEmail }),
  withStyles(styles),
)(ConfirmationPage);
