import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { first } from 'lodash';

import ReserveEditPage from './ReserveEditPage';
import { rejectReserve } from '../../actions/reserveReject/RejectReserveActions';
import { IAppStore } from '../../types/IAppStore';
import { IAppActions } from '../../types/IAppActions';
import { editableReserveStates, reserveModal } from '../../config/constants';
import { customErrorShackBar, customMessageShackBar } from '../../actions/snackbars/SnackBarsActions';
import { changeCurrentTime, changeSectionToFirstItem } from '../Reservation/actions';
import { selectorSectionAvailableOptions } from '../../reducers/selectors';
import { getReserveFormName } from '../Reservation/constants';

import {
  DispatchProps,
  StateProps,
  TEditReserveInfo,
} from './IReserveEditPage';

import {
  closeReserveEditModal,
  getFreeTables,
  mergeTables,
  setActiveTable,
  updateReserve,
} from '../../actions/reservationsPage/ReservationsPageActions';

import {
  selectorFreeTables,
  selectorForm,
  selectorCurrentSectionSchema,
  selectorSelectedAppReserve,
  selectorAlreadySelectedTables,
} from '../Reservation/selectors';

import {
  addAppReserve,
  removeAppReserve,
  openAppReserve,
  closeAppReserve,
  changeAppReserve,
} from '../../actions/appReserves/AppReservesActions';


const formName = getReserveFormName('EDIT');


function mapStateToProps(state: IAppStore): StateProps {
  const {
    activeTables,
    editReserveInfo,
    editReserveModalIsOpen,
  } = state.reserveModal;

  const { tables } = editReserveInfo || {};
  const { currentRestaurant } = state.cabinet;
  const { isSchemasEnabled } = currentRestaurant ? currentRestaurant.settings : { isSchemasEnabled: false };

  const reserveInfo: TEditReserveInfo = {
    type: reserveModal.EDIT,
    id: editReserveInfo ? editReserveInfo.id : 0,
    firstName: editReserveInfo ? editReserveInfo.firstName : '',
    lastName: editReserveInfo ? editReserveInfo.lastName : null,
    phone: editReserveInfo ? editReserveInfo.phone : '',
    locale: state.cabinet.currentRestaurant ? state.cabinet.currentRestaurant.address.countryCode : 'RU',
    duration: editReserveInfo ? editReserveInfo.duration : 0,
    comment: editReserveInfo ? editReserveInfo.comment : '',
    persons: editReserveInfo ? editReserveInfo.persons : 0,
    date: editReserveInfo ? editReserveInfo.date : '',
    // @todo Не хватает source в тех данных, что приходят об открытых резервах?
    source: editReserveInfo && editReserveInfo.source ? editReserveInfo.source.type : '',
    isBanquet: editReserveInfo ? editReserveInfo.isBanquet : false,
    section: ((first(tables) || {}).section || {}).id || 0,

    // Отключаю, так как не нашел, где используется
    // tables: editReserveInfo ? editReserveInfo.tables : undefined,
  };

  let disabled = false;

  const appReserve = selectorSelectedAppReserve(state);
  if (appReserve && appReserve.outerChangeType) {
    disabled = true;
  }

  // @todo Переделать на использование нового appReserve?
  // @todo Собрать все статусы, при которых нельзя редактировать
  if (
    editReserveInfo
      && editReserveInfo.state
      && editableReserveStates.indexOf(editReserveInfo.state) === -1
  ) {
    disabled = true;
  }

  return {
    formName,
    disabled,
    outerChangeType: appReserve && appReserve.outerChangeType,

    activeTables,
    modalOpen: editReserveModalIsOpen,
    isSchemasEnabled,
    schema: selectorCurrentSectionSchema(state),
    sections: selectorSectionAvailableOptions(state),
    freeTables: selectorFreeTables(state),
    workTime: state.schedule.restaurantWorkTimes,
    maxCapacity: state.reserveModal.maxCapacity,
    isMergeTables: state.reserveModal.isMergeTables,

    appReserves: state.appReserves,
    selectedAppReserve: appReserve,
    alreadySelectedTables: selectorAlreadySelectedTables(state),

    currentValue: {
      date: selectorForm(formName)(state, 'date'),
      time: selectorForm(formName)(state, 'time'),
      firstName: selectorForm(formName)(state, 'firstName'),
      phone: selectorForm(formName)(state, 'phone'),
      name: selectorForm(formName)(state, 'name'),
      persons: selectorForm(formName)(state, 'persons'),
      isBanquet: selectorForm(formName)(state, 'isBanquet'),
      hours: selectorForm(formName)(state, 'hours'),
      minutes: selectorForm(formName)(state, 'minutes'),
      section: selectorForm(formName)(state, 'section'),
    },

    currentRestaurant,
    reserveInfo,
  };
}

function mapDispatchToProps(dispatch: Dispatch<IAppActions>): DispatchProps {
  return bindActionCreators({
    closeReserveEditModal,
    getFreeTables,
    updateReserve,
    customErrorShackBar,
    rejectReserve,
    customMessageShackBar,
    setActiveTable,
    changeCurrentTime,
    changeSectionToFirstItem,
    mergeTables,

    addAppReserve,
    removeAppReserve,
    openAppReserve,
    closeAppReserve,
    changeAppReserve,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ReserveEditPage);
