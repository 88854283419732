import React from 'react';
import { useHistory } from 'react-router-dom';
import { Employees } from '../../../entities';
import PageHeaderWarning from '../../../components/Common/PageHeaderWarning';
import { Routers } from '../../../config/constants';
import locales from '../../../locales';

const NoHostessWarning = () => {
  const {
    isLoading: isCurrentLoading,
    data: currentEmployee,
  } = Employees.useCurrentEmployee();

  const hasEmployeesAccess = currentEmployee && Employees.hasAccess(currentEmployee, 'employees:access');
  if (!hasEmployeesAccess) {
    return null;
  }

  const history = useHistory();
  const {
    isLoading: isEmployeesLoading,
    data: employees,
  } = Employees.useEmployees();

  if (
    !isEmployeesLoading
    && !isCurrentLoading
    && history.location.pathname.indexOf(Routers.employees) === -1
    && history.location.pathname !== Routers.profile
    && !Employees.hasActiveHostess(employees)
  ) {
    return (
      <PageHeaderWarning
        onClose={() => history.push(Routers.employees)}
        actionLabel={locales.t('employees.noHostessWarning.actionLabel')}
        title={locales.t('employees.noHostessWarning.title')}
        message={locales.t('employees.noHostessWarning.message')}
      />
    );
  }

  return null;
};

export default NoHostessWarning;
