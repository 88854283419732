import React from 'react';

import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography/Typography';
import IconButton from '@material-ui/core/IconButton/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {
  withStyles, createStyles, WithStyles, Theme,
} from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: '16px 16px 16px 24px',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(),
    top: theme.spacing(),
  },
});

interface IProps extends WithStyles<typeof styles> {
  children: React.ReactChildren | string,
  onClose: (e: React.SyntheticEvent) => void,
}

const DialogTitle: React.FC<IProps> = ({
  classes,
  children,
  onClose,
}) => (
  <MuiDialogTitle
    className={classes.root}
    disableTypography
  >
    <Typography variant="h6" style={{ paddingRight: 48 }}>
      {children}
    </Typography>

    {onClose ? (
      <IconButton
        aria-label="Close"
        // color="primary"
        className={classes.closeButton}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
    ) : null}
  </MuiDialogTitle>
);

export default withStyles(styles)(DialogTitle);
