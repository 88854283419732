import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { formValueSelector } from 'redux-form';
import { first } from 'lodash';

import ReserveConfirmPage from './ReserveConfirmPage';
import { IAppActions } from '../../types/IAppActions';
import { IAppStore } from '../../types/IAppStore';
import { editableReserveStates, reserveModal } from '../../config/constants';
import { selectorGetTimeList } from './selectors';
import { customErrorShackBar } from '../../actions/snackbars/SnackBarsActions';
import { selectorSectionAvailableOptions } from '../../reducers/selectors';
import { changeSectionToFirstItem } from '../Reservation/actions';
import { getReserveFormName } from '../Reservation/constants';

import {
  getFreeTables,
  closeReserveConfirmModal,
  confirmReserve,
  rejectNewReserve,
  setActiveTable,
  mergeTables,
} from '../../actions/reservationsPage/ReservationsPageActions';

import {
  DispatchProps,
  StateProps,
  TConfirmReserveInfo,
} from './IReserveConfirmPage';

import {
  selectorFreeTables,
  selectorCurrentSectionSchema,
  selectorSelectedAppReserve,
  selectorAlreadySelectedTables,
} from '../Reservation/selectors';

import {
  addAppReserve,
  removeAppReserve,
  openAppReserve,
  closeAppReserve,
  changeAppReserve,
} from '../../actions/appReserves/AppReservesActions';


const formName = getReserveFormName('CONFIRM');
const selector = formValueSelector(formName);


function mapStateToProps(state: IAppStore): StateProps {
  const { currentReserve } = state.reserveModal;
  const { tables } = currentReserve || {};
  const { currentRestaurant } = state.cabinet;
  const { isSchemasEnabled } = currentRestaurant ? currentRestaurant.settings : { isSchemasEnabled: false };

  const reserveInfo: TConfirmReserveInfo = {
    type: reserveModal.CONFIRM,
    id: currentReserve ? currentReserve.id : 0,
    firstName: currentReserve ? currentReserve.firstName : '',
    lastName: currentReserve ? currentReserve.lastName : null,
    phone: currentReserve ? currentReserve.phone : '',
    locale: state.cabinet.currentRestaurant ? state.cabinet.currentRestaurant.address.countryCode : 'RU',
    duration: currentReserve ? currentReserve.duration : 2,
    comment: currentReserve ? currentReserve.comment : '',
    persons: currentReserve ? currentReserve.persons : 2,
    date: currentReserve ? currentReserve.date : '',
    // @todo Не хватает source в тех данных, что приходят об открытых резервах?
    source: currentReserve && currentReserve.source ? currentReserve.source.id : '',
    isBanquet: selector(state, 'isBanquet'),
    section: ((first(tables) || {}).section || {}).id || 0,
  };

  let disabled = false;

  const appReserve = selectorSelectedAppReserve(state);
  if (appReserve && appReserve.outerChangeType) {
    disabled = true;
  }

  // @todo Переделать на использование нового appReserve?
  // @todo Собрать все статусы, при которых нельзя редактировать
  if (
    currentReserve
      && currentReserve.state
      && editableReserveStates.indexOf(currentReserve.state) === -1
  ) {
    disabled = true;
  }

  return {
    formName,
    disabled,
    outerChangeType: appReserve && appReserve.outerChangeType,

    activeTable: state.reserveModal.activeTables,
    modalOpen: state.reserveModal.confirmReserveModalIsOpen,
    isSchemasEnabled,
    schema: selectorCurrentSectionSchema(state),
    sections: selectorSectionAvailableOptions(state),
    freeTables: selectorFreeTables(state),
    workTime: selectorGetTimeList(state),
    isMergeTables: state.reserveModal.isMergeTables,

    appReserves: state.appReserves,
    selectedAppReserve: appReserve,
    alreadySelectedTables: selectorAlreadySelectedTables(state),

    reserveInfo,
  };
}

function mapDispatchToProps(dispatch: Dispatch<IAppActions>): DispatchProps {
  return bindActionCreators({
    closeReserveConfirmModal,
    getFreeTables,
    confirmReserve,
    rejectNewReserve,
    setActiveTable,
    mergeTables,
    customErrorShackBar,
    changeSectionToFirstItem,

    addAppReserve,
    removeAppReserve,
    openAppReserve,
    closeAppReserve,
    changeAppReserve,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ReserveConfirmPage);
