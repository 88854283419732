import {
  all, takeLatest, select, call, put,
} from 'redux-saga/effects';

import * as ActionTypes from '../actions/restaurantsWidgets/RestaurantsWidgetsTypes';
import {
  ICreateWidget,
  IDeleteWidget,
  IUpdateWidget,
} from '../actions/restaurantsWidgets/IRestaurantsWidgetsActions';
import { IAppStore } from '../types/IAppStore';
import {
  createWidgetError,
  createWidgetSuccess,
  deleteWidgetError,
  deleteWidgetSuccess,
  getAllWidgetsError,
  getAllWidgetsSuccess, updateWidgetError, updateWidgetSuccess,
} from '../actions/restaurantsWidgets/RestaurantsWidgetsActions';
import {
  createWidgetReq,
  deleteWidgetReq,
  getAllWidgetsReq,
  updateWidgetReq,
} from '../api';
import { IWidgetAdapt } from '../types/IRestaurantWidgetsStore';

/** @description Сага для получения всех виджетов ресторана */
function* getAllWidgetsSaga() {
  const currentRestaurant = yield select((state: IAppStore) => state.cabinet.currentRestaurant);

  try {
    const allWidgets = yield call(getAllWidgetsReq, currentRestaurant.id);
    yield put(getAllWidgetsSuccess(allWidgets.filter((w: IWidgetAdapt) => w.status === 'ACTIVE')));
  } catch (e) {
    yield put(getAllWidgetsError());
  }
}

/** @description Сага для создания нового виджета */
function* createWidgetSaga(action: ICreateWidget) {
  const currentRestaurant = yield select((state: IAppStore) => state.cabinet.currentRestaurant);

  try {
    const newWidget = yield call(createWidgetReq, currentRestaurant.id, action.payload);
    yield put(createWidgetSuccess(newWidget));
  } catch (e) {
    yield put(createWidgetError());
  }
}

/** @description Сага для редактирования выбранного виджета по id */
function* updateWidgetSaga(action: IUpdateWidget) {
  const { widgetId, data } = action.payload;

  try {
    const updatedWidget = yield call(updateWidgetReq, widgetId, data);
    yield put(updateWidgetSuccess(updatedWidget));
  } catch (e) {
    yield put(updateWidgetError());
  }
}

/** @description Сага для редактирования выбранного виджета по id */
function* deleteWidgetSaga(action: IDeleteWidget) {
  try {
    yield call(deleteWidgetReq, action.payload.widgetId);
    yield put(deleteWidgetSuccess(action.payload.widgetId));
  } catch (e) {
    yield put(deleteWidgetError());
  }
}

export default function* saga() {
  yield all([
    takeLatest(ActionTypes.GET_ALL_WIDGETS_REQUEST, getAllWidgetsSaga),
    takeLatest(ActionTypes.CREATE_WIDGET_REQUEST, createWidgetSaga),
    takeLatest(ActionTypes.UPDATE_WIDGET_REQUEST, updateWidgetSaga),
    takeLatest(ActionTypes.DELETE_WIDGET_REQUEST, deleteWidgetSaga),
  ]);
}
