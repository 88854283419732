import React from 'react';
import classNames from 'classnames';

import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import locales from '../../../locales';
import { errorColor } from '../../../theme';

const styles = createStyles({
  pinCodeInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  pinCodeInfo: {
    fontSize: 12,
    color: 'rgba(0, 0, 0, 0.54)',
    textAlign: 'center',
  },
  error: {
    color: errorColor,
  },
  pinCodeView: {
    display: 'flex',
    paddingBottom: 24,
  },
  btnMargin: {
    marginTop: 20,
  },
  pinCodeItem: {
    display: 'flex',
    justifyContent: 'center',
    width: 32,
    fontSize: 36,
    margin: '0 8px',
    borderBottom: 'solid 2px rgba(0, 0, 0, 0.87)',
  },
});

interface IProps extends WithStyles<typeof styles> {
  pinCode?: string;
  minutes: string;
  seconds: string;
  timeCompleted: boolean;
  handleGetPinCode: () => void;
}

const PinCodeView: React.FC<IProps> = ({
  classes, pinCode, minutes, seconds, timeCompleted, handleGetPinCode,
}) => {
  const connectedInfo = locales.t('home.pinCodeInfo', { minutes, seconds });
  const parsePinCode = pinCode
    ? pinCode.split('')
    : ['', '', '', '', '', ''];
  const error = (
    <div className={classes.pinCodeInfoContainer}>
      { locales.t('home.pinCodeError') }
      <Button
        className={classes.btnMargin}
        variant="contained"
        color="primary"
        onClick={handleGetPinCode}
      >
        { locales.t('home.getPinCodeAgainBtn') }
      </Button>
    </div>
  );

  return (
    <div>
      <div className={classes.pinCodeView}>
        { parsePinCode.map((item, index) => (
          <div
            className={classes.pinCodeItem}
            key={index.toString()}
          >
            { item }
          </div>
        )) }
      </div>
      <div className={classNames(classes.pinCodeInfo, { [classes.error]: timeCompleted })}>
        { (timeCompleted) ? error : connectedInfo }
      </div>
    </div>
  );
};

export default withStyles(styles)(PinCodeView);
