import {
  all, takeEvery, put, select,
} from 'redux-saga/effects';
import _ from 'lodash';
import moment from 'moment';

import * as BookingActionsTypes from '../actions/booking/BookingActionsTypes';
import * as ReserveRejectActionsTypes from '../actions/reserveReject/RejectReserveActionsTypes';
import * as ReserveModalActionTypes from '../actions/reservationsPage/ReservationsPageActionsTypes';
import { getAllBookingsByRestaurantId } from '../api';
import { localStorageItems } from '../config/constants';
import {
  SOCKET_RESERVE_CONFIRMATION,
  SOCKET_RESERVE_CREATED,
} from '../actions/webSocketService/WebSocketActionsTypes';
import { TFilterListItem, TReserve, TSort } from '../types/IBookingStore';
import {
  getBookingError,
  getBookingSuccess, initBookingPagesSuccess,
  switchBookingFilter,
  initBookingPageError,
} from '../actions/booking/BookingActions';
import { IFilterBookingListBy } from '../actions/booking/IBookingActions';
import { TRestaurant } from '../types/ICabinetStore';
import { parseReserves } from '../helpers/bookingHelpers';

/** @description Сага для инита страницы резервов */
function* initBookingPageSaga() {
  const storageFilters = localStorage.getItem(localStorageItems.filters);
  const restaurant: TRestaurant = yield select(state => state.cabinet.currentRestaurant);

  const { sort, currentStatisticDate, filters }: {
    sort: TSort;
    currentStatisticDate: string;
    filters: Array<TFilterListItem>;
  } = yield select(state => state.booking);

  const newFilters: Array<TFilterListItem> = storageFilters ? JSON.parse(storageFilters) : filters;
  const onlyActive = storageFilters
    ? _.findIndex(newFilters, { value: 'accepted', active: true }) > -1
    : true;

  try {
    const bookingList: Array<TReserve> = restaurant
      ? yield getAllBookingsByRestaurantId(
        restaurant.id,
        moment(currentStatisticDate).startOf('day'),
        moment(currentStatisticDate).endOf('day'),
        sort,
        onlyActive,
      )
        .then(r => parseReserves(r.data))
      : [];

    yield put(initBookingPagesSuccess(bookingList, newFilters, onlyActive));
  } catch (e) {
    yield put(getBookingError(currentStatisticDate));
    yield put(initBookingPageError(newFilters, onlyActive));
  }
}

/** @description Получение списка резервов для выбранного ресторана */
function* getAllBookingsSaga(action: any) {
  let date: string;
  let active: boolean;
  let sort: TSort;

  // в случае если нет action.payload.date то запрашиваем currentStatisticDate
  const currentDate = yield select(state => state.booking.currentStatisticDate);
  const restaurant = yield select(state => state.cabinet.currentRestaurant);

  if (restaurant) {
    const restaurantId = restaurant.id;

    if (action.type === BookingActionsTypes.SWITCH_BOOKING_ACTIVE) {
      active = action.payload.active;
    } else {
      active = yield select(state => state.booking.onlyActive);
    }

    if (action.type === BookingActionsTypes.SORT_BOOKING_LIST) {
      sort = action.payload.sort;
    } else {
      sort = yield select(state => state.booking.sort);
    }

    // TODO отрефакторить проверку, просмотреть все места откуда может прилетать пустой payload
    if (_.isUndefined(action.payload) || _.isNull(action.payload)) {
      date = currentDate;
    } else {
      date = action.payload.date;
      if (_.isUndefined(date) || _.isNull(date)) {
        date = currentDate;
      }
    }

    try {
      const dateFrom = moment(date).startOf('day');
      const dateTo = moment(date).endOf('day');

      const bookingList: Array<TReserve> = yield getAllBookingsByRestaurantId(
        restaurantId,
        dateFrom,
        dateTo,
        sort,
        active,
      ).then(r => parseReserves(r.data));

      yield put(getBookingSuccess(bookingList, active, sort, date));
    } catch (e) {
      yield put(getBookingError(date));
    }
  }
}

/** @description Сага для манипуляций с переключателем "Только активные" */
function* setFiltersSaga(action: IFilterBookingListBy) {
  const activeFilters = action.payload.filters.filter(i => i.active);
  const isOnlyActive = activeFilters.length === 1 && activeFilters[0].value === 'accepted';

  if (isOnlyActive) {
    yield put(switchBookingFilter(true));
  } else {
    yield put(switchBookingFilter(false));
  }
}

export default function* saga() {
  yield all([
    takeEvery([
      SOCKET_RESERVE_CREATED,
      SOCKET_RESERVE_CONFIRMATION,
      ReserveModalActionTypes.CREATE_RESERVE_SUCCESS,
      ReserveRejectActionsTypes.REJECT_RESERVE_SUCCESS,
      BookingActionsTypes.GET_BOOKING_REQUEST,
      BookingActionsTypes.SWITCH_BOOKING_ACTIVE,
      BookingActionsTypes.SORT_BOOKING_LIST,
    ], getAllBookingsSaga),
    takeEvery(BookingActionsTypes.SET_FILTERS, setFiltersSaga),
    takeEvery(BookingActionsTypes.INIT_BOOKING_PAGE, initBookingPageSaga),
  ]);
}
