import React, { useState } from 'react';

import {
  createStyles,
  WithStyles,
  withStyles,
  TextField,
  List,
  ListItem,
  ListItemText,
  Theme,
} from '@material-ui/core';

import locales from '../../../../locales';

const styles = (theme: Theme) => createStyles({
  listWrapper: {
    width: '100%',
    position: 'relative',
  },
  list: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    position: 'absolute',
    zIndex: 1,
    paddingTop: 0,
  },
});

interface Props extends WithStyles<typeof styles> {
  address: string;
  onChangeAddress: (address: string) => void;
  name: string;
  onChangeName: (name: string) => void;
}

let timeout: any;

const SearchAddress: React.FC<Props> = ({
  address,
  onChangeAddress,
  name,
  onChangeName,
  classes,
}: Props) => {
  const [addressSuggestions, setAddressSuggestions] = useState<Array<string>>([]);

  const onAddressInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    onChangeAddress(value);
    setAddressSuggestions([]);

    window.clearTimeout(timeout);
    if (value.length > 0) {
      timeout = window.setTimeout(() => {
        window.ymaps.suggest(value, { results: 5 }).then((items) => {
          setAddressSuggestions(
            items.map(item => item.displayName),
          );
        });
      }, 1000);
    }
  };

  return (
    <>
      <TextField
        value={name}
        onChange={(event) => {
          onChangeName(event.target.value);
        }}
        margin="normal"
        fullWidth
        label={locales.t('home.addFormInputName')}
        required
      />

      <TextField
        margin="normal"
        label={locales.t('home.addFormInputAddress')}
        fullWidth
        value={address}
        onChange={onAddressInputChange}
        onBlur={() => {
          setTimeout(() => {
            setAddressSuggestions([]);
          }, 200);
        }}
        required
      />

      {addressSuggestions.length > 0 && (
        <div className={classes.listWrapper}>
          <List className={classes.list}>
            { addressSuggestions.map(name => (
              <ListItem
                key={name}
                button
                onClick={() => {
                  onChangeAddress(name);
                  setAddressSuggestions([]);
                }}
              >
                <ListItemText primary={name} />
              </ListItem>
            )) }
          </List>
        </div>
      )}
    </>
  );
};

export default withStyles(styles)(SearchAddress);
