import React, { Fragment, useCallback } from 'react';
import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useHistory } from 'react-router-dom';

import { Routers } from '../config/constants';
import locales from '../locales';
import PageHeaderWrap from '../components/Common/PageHeaderWrap';
import PageContentWrap from '../components/Common/PageContentWrap';
import ReservesSystemInfo from '../containers/ReservesSystemInfo';
import ReservesSystemSettings from '../containers/ReservesSystemSettings';
import ReservesSystemTables from '../containers/ReservesSystemTables';
import ReservesSystemAdditionalNotifications from '../containers/ReservesSystemAdditionalNotifications';
import ReservesSystemAutoBooking from '../containers/ReservesSystemAutoBooking';
import ReservesSystemSchema from '../containers/ReservesSystemSchema';
import DeleteRestaurant from '../containers/DeleteRestaurantCont';
import CashBoxWarningCont from '../containers/CashBoxWarning';
import ReservesSystemWidgets from '../containers/ReservesSystemWidgets';
import EmployeesPage from './EmployeesPage';
import ProfilePage from './ProfilePage';
import { NoHostessWarning, useCashboxSettingsFeature } from '../features';
import { Employees } from '../entities';

const StyledTabs = withStyles({
  root: {
    minHeight: 64,
  },
})(Tabs);

const StyledTab = withStyles({
  root: {
    minWidth: 180,
    fontSize: '16px',
    minHeight: 64,
  },
})(Tab);

const ReservesSystemSettingsPage: React.FC = () => {
  const cashboxSettings = useCashboxSettingsFeature();
  const { data: currentEmployee } = Employees.useCurrentEmployee();

  return (
    <PageContentWrap>
      <Grid item xl={12} lg={9} md={12} sm={12} xs={12}>
        <ReservesSystemInfo />
      </Grid>
      <Grid item xl={12} lg={9} md={12} sm={12} xs={12}>
        <ReservesSystemSettings />
      </Grid>
      <Grid item xl={12} lg={9} md={12} sm={12} xs={12}>
        <ReservesSystemWidgets />
      </Grid>
      <Grid item xl={12} lg={9} md={12} sm={12} xs={12}>
        <ReservesSystemAdditionalNotifications />
      </Grid>
      <Grid item xl={12} lg={9} md={12} sm={12} xs={12}>
        <ReservesSystemAutoBooking />
      </Grid>
      <Grid item xl={12} lg={9} md={12} sm={12} xs={12}>
        <ReservesSystemTables />
      </Grid>
      <Grid item xl={12} lg={9} md={12} sm={12} xs={12}>
        {cashboxSettings}
      </Grid>
      {currentEmployee && Employees.isAdmin(currentEmployee) && (
        <Grid item xl={12} lg={9} md={12} sm={12} xs={12}>
          <div style={{ paddingTop: 24 }}>
            <DeleteRestaurant />
          </div>
        </Grid>
      )}
    </PageContentWrap>
  );
};

const ReservesSystemSchemePage: React.FC = () => (
  <PageContentWrap>
    <Grid item xl={12} lg={9} md={12} sm={12} xs={12}>
      <ReservesSystemSchema />
    </Grid>
  </PageContentWrap>
);


type PageID = 'settings' | 'scheme' | 'employees' | 'profile';

const routeMap: {
  [view: string]: string
} = {
  settings: Routers.reservesSystem,
  scheme: Routers.restaurantScheme,
  employees: Routers.employees,
  profile: Routers.profile,
};

interface SettingsPageProps {
  view: PageID;
}

const SettingsPage: React.FC<SettingsPageProps> = ({ view}) => {
  const history = useHistory();
  const onTabChange = useCallback((event: any, value: string) => {
    if (routeMap[value]) {
      history.push(routeMap[value]);
    }
  }, []);

  const { data: e } = Employees.useCurrentEmployee();
  const isRestaurantSettings = e && Employees.hasAccess(e, 'restaurantSettings:access');
  const isEmployeesPage = e && Employees.hasAccess(e, 'employees:access');

  return (
    <Fragment>
      {view !== 'profile' && (
        <PageHeaderWrap>
          <StyledTabs
            onChange={onTabChange}
            scrollButtons="off"
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            centered
            value={view}
          >
            {isRestaurantSettings && <StyledTab label={locales.t('reservesSystem.title')} value="settings" />}
            {isRestaurantSettings && <StyledTab label={locales.t('restaurantSchema.title')} value="scheme" />}
            {isEmployeesPage && <StyledTab label={locales.t('employees.title')} value="employees" />}

            {/* <StyledTab label={locales.t('common.profile')} value="profile" /> */}
          </StyledTabs>
        </PageHeaderWrap>
      )}

      <CashBoxWarningCont />
      <NoHostessWarning />

      {view === 'settings' && <ReservesSystemSettingsPage />}
      {view === 'scheme' && <ReservesSystemSchemePage />}
      {view === 'employees' && <EmployeesPage />}
      {view === 'profile' && <ProfilePage />}
    </Fragment>
  );
};

export const ReservesSystemPage: React.FC = () => (
  <SettingsPage view="settings" />
);

export const RestaurantSchemePage: React.FC = () => (
  <SettingsPage view="scheme" />
);

export const ReservesSystemEmployeesPage: React.FC = () => (
  <SettingsPage view="employees" />
);

export const ReservesSystemProfilePage: React.FC = () => (
  <SettingsPage view="profile" />
);
