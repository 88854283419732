import React, { useState } from 'react';

import { withStyles, WithStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import Typography from '@material-ui/core/Typography';

import DialogTitle from '../../Common/DialogTitle';

import WidgetSettings from './WidgetSettings';

import {
  IWidgetAdapt,
  IWidgetDataAdapt,
} from '../../../types/IRestaurantWidgetsStore';

import { fallbackValue } from '../../../helpers/widget/constants';

import { isValid, transformData } from './utils';

import locales from '../../../locales';
import styles from './styles';

interface IProps extends WithStyles<typeof styles> {
  isOpen: boolean;
  handleClose: () => void;
  handleUpdateWidget: (widgetId: number, newWidgetData: IWidgetDataAdapt) => void;
  widgetInfo?: IWidgetAdapt;
}

const WidgetEditModal: React.FC<IProps> = ({
  classes,
  isOpen,
  widgetInfo,
  handleClose,
  handleUpdateWidget,
}) => {
  /** @description Стейт полей виджета */
  const [data, changeData] = useState<IWidgetDataAdapt>(transformData(widgetInfo) || fallbackValue);

  /** @description Создание виджета */
  const onClick = () => {
    if (isValid(data) && widgetInfo) {
      handleUpdateWidget(widgetInfo.id, data);
      handleClose();
    }
  };

  const onClose = () => {
    handleClose();
  };

  return (
    <Dialog
      classes={{ paper: classes.paperEdit }}
      open={isOpen}
    >
      <DialogTitle onClose={onClose}>
        { locales.t('settings.widgets.editModal.title') }
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText className={classes.desc}>
          <Typography variant="caption" color="error">
            { locales.t('settings.widgets.editModal.attention') }
          </Typography>
          <Typography variant="caption">
            { locales.t('settings.widgets.editModal.desc') }
          </Typography>
        </DialogContentText>
        <WidgetSettings data={data} changeData={changeData} />
      </DialogContent>
      <DialogActions className={classes.dialogActionsWrap}>
        <Button
          className={classes.btnWrap}
          variant="contained"
          color="secondary"
          onClick={onClose}
        >
          { locales.t('settings.widgets.editModal.declineBtn') }
        </Button>
        <Button
          className={classes.btnWrap}
          variant="contained"
          color="primary"
          onClick={onClick}
        >
          { locales.t('settings.widgets.editModal.saveBtn') }
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(WidgetEditModal);
