import moment from 'moment';
import { createSelector } from 'reselect';

import { IAppStore } from '../../types/IAppStore';
import { getTimeList } from '../../helpers/dateHelpers';

/** @description Получение времени работы ресторана */
export const selectorGetTimeList = createSelector([
  (state: IAppStore) => state.schedule.restaurantWorkTimes,
], workTime => getTimeList(moment(), workTime));
