import React from 'react';
import classNames from 'classnames';

import { withStyles, createStyles, WithStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';

import SchedulePeriodRow from './SchedulePeriodRow';
import BookingPeriodRow from './BookingPeriodRow';
import {
  IFreeDay, IRestaurantWorkTimes,
  IScheduleRow,
  TWeekDay,
} from '../../../types/IScheduleStore';
import locales from '../../../locales';

const styles = createStyles({
  wrap: {
    padding: '12px 24px',
  },
  marginButton: {
    paddingBottom: 12,
  },
  label: {
    fontSize: 11,
    paddingBottom: 2,
    margin: 0,
    color: 'rgba(0, 0, 0, 0.38)',
  },
});

interface IProps extends WithStyles<typeof styles> {
  handleAddScheduleRow: () => void;
  handelUpdateSchedule: (row: IScheduleRow) => void;
  handleDeleteScheduleRow: (row: IScheduleRow) => void;
  handleUpdateFreeDays: (days: {
    activate: Array<TWeekDay>,
    deactivate: Array<TWeekDay>
  }) => void;
  scheduleList: Array<IScheduleRow>;
  label?: string;
  freeDays: Array<IFreeDay>;
  restaurantWorkTimes?: IRestaurantWorkTimes; // Необходим только для расписания приёма автоброни
}

// TODO Переделать лейбл у компонента, у дефолтного InputLabel не корректные шрифты и паддинги
const ScheduleTableComp: React.FC<IProps> = (props) => {
  const {
    classes,
    label,
    handleAddScheduleRow,
    handelUpdateSchedule,
    handleDeleteScheduleRow,
    handleUpdateFreeDays,
    scheduleList,
    freeDays,
    restaurantWorkTimes,
  } = props;

  return (
    <div className={classes.wrap}>
      <div className={classNames({ [classes.marginButton]: !!label })}>
        <span className={classes.label}>
          { label }
        </span>
        {
          scheduleList.map(schedule => (
            restaurantWorkTimes ? (
              <BookingPeriodRow
                key={schedule.id}
                handelUpdateSchedule={handelUpdateSchedule}
                handleDeleteScheduleRow={handleDeleteScheduleRow}
                handleUpdateFreeDays={handleUpdateFreeDays}
                schedule={schedule}
                freeDays={freeDays}
                restaurantWorkTimes={restaurantWorkTimes}
              />
            ) : (
              <SchedulePeriodRow
                key={schedule.id}
                handelUpdateSchedule={handelUpdateSchedule}
                handleDeleteScheduleRow={handleDeleteScheduleRow}
                handleUpdateFreeDays={handleUpdateFreeDays}
                schedule={schedule}
                freeDays={freeDays}
              />
            )
          ))
        }
      </div>
      <Button
        onClick={handleAddScheduleRow}
        color="primary"
        variant="contained"
      >
        { locales.t('reservesSystem.basicInformation.addNewSchedule') }
      </Button>
    </div>
  );
};

export default withStyles(styles)(ScheduleTableComp);
