import React, { Fragment, useState } from 'react';

import {
  TableRow,
  TableCell,
  Typography,
  IconButton,
} from '@material-ui/core';
import { createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import Circle from '@material-ui/icons/FiberManualRecord';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import DropUp from '@material-ui/icons/ArrowDropUp';
import DropDown from '@material-ui/icons/ArrowDropDown';

import EditField from '../Common/SelfSavingFields/EditField';
import { parseTableHall } from '../../helpers/bookingHelpers';
import { ITable, THall } from '../../types/IRestaurantTablesStore';
import FlashActive from '../Common/Icons/FlashActive';
import FlashInactive from '../Common/Icons/FlashInactive';
import { customErrorShackBar } from '../../actions/snackbars/SnackBarsActions';
import locales from '../../locales';

const styles = createStyles({
  hallRow: {
    backgroundColor: '#f6f9f9',
  },
  tableListItemCheckBox: {
    padding: 0,
  },
});

interface OwnProps extends WithStyles<typeof styles> {
  first: boolean;
  hall: THall;
  handleUpdateTable: (item: ITable | Array<ITable>) => void;
  customErrorShackBar: typeof customErrorShackBar;
  isAutoBookingEnabled: boolean;
}

type Props = OwnProps;

/**
 * @param hallList Список столов ресторана
 * @param handleUpdateTable Ручка для редактирования колчества мест у стола
 */
const TableLIstItem: React.FC<Props> = ({
  classes,
  first,
  hall,
  handleUpdateTable,
  customErrorShackBar,
  isAutoBookingEnabled,
}) => {
  const [open, changeList] = useState<boolean>(first);

  const handleOpenList = () => {
    changeList(!open);
  };

  const CircleActive = <Circle color="secondary" style={{ fontSize: 28 }} />;
  const CircleInactive = <Circle color="primary" style={{ fontSize: 28 }} />;
  const IconFlashActive = <FlashActive style={{ fontSize: 20 }} />;
  const IconFlashInactive = <FlashInactive style={{ fontSize: 20 }} />;

  /** @description Редактирование вместимости стола */
  const handleChangeCapacity = (newData: any, table: ITable) => {
    handleUpdateTable({
      ...table,
      capacity: parseInt(newData.value),
    });
  };

  /** @description Переключение типа бронирования для всех столов */
  const handleChangeAllBooking = (type: 'isBookingAllowed' | 'isAutoBookingAllowed') => {
    const newState = !hall[type];

    if (type === 'isAutoBookingAllowed' && !isAutoBookingEnabled) {
      customErrorShackBar(locales.t('settings.errors.updateTables.booking'));
    } else if (type === 'isBookingAllowed' && !newState) {
      const updatedTables = hall.tables.map(i => ({
        ...i,
        [type]: newState,
        isAutoBookingAllowed: false,
      }));

      handleUpdateTable(updatedTables);
    } else {
      const updatedTables = hall.tables.map((i) => {
        if (type === 'isAutoBookingAllowed' && newState) {
          return {
            ...i,
            [type]: i.isBookingAllowed ? newState : false,
          };
        }

        return {
          ...i,
          [type]: newState,
        };
      });

      handleUpdateTable(updatedTables);
    }
  };

  /** @description Переключение типа брониварования для выбранного стола */
  const handleChangeBooking = (
    bookingType: 'isAutoBookingAllowed' | 'isBookingAllowed', table: ITable,
  ) => {
    const newState = !table[bookingType];

    if (bookingType === 'isAutoBookingAllowed' && !isAutoBookingEnabled) {
      customErrorShackBar(locales.t('settings.errors.updateTables.booking'));
    } else if (bookingType === 'isBookingAllowed' && !newState) {
      handleUpdateTable({
        ...table,
        [bookingType]: !table[bookingType],
        isAutoBookingAllowed: false,
      });
    } else {
      handleUpdateTable({
        ...table,
        [bookingType]: !table[bookingType],
      });
    }
  };

  return (
    <Fragment>
      <TableRow className={classes.hallRow}>
        <TableCell colSpan={2}>
          <Typography variant="subtitle2">
            { hall.hall }
          </Typography>
        </TableCell>
        <TableCell align="center" padding="none">
          <Checkbox
            className={classes.tableListItemCheckBox}
            onClick={event => event.stopPropagation()}
            icon={CircleActive}
            checkedIcon={CircleInactive}
            checked={hall.isBookingAllowed}
            onChange={() => handleChangeAllBooking('isBookingAllowed')}
          />
        </TableCell>
        <TableCell align="right" padding="none">
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Checkbox
              className={classes.tableListItemCheckBox}
              onClick={event => event.stopPropagation()}
              icon={IconFlashInactive}
              checkedIcon={IconFlashActive}
              checked={hall.isAutoBookingAllowed}
              onChange={() => handleChangeAllBooking('isAutoBookingAllowed')}
            />
            <div style={{ position: 'relative' }}>
              <IconButton
                style={{ position: 'absolute', left: 10, top: -12 }}
                onClick={handleOpenList}
              >
                { open ? <DropUp /> : <DropDown /> }
              </IconButton>
            </div>
          </div>
        </TableCell>
      </TableRow>
      {
        open && hall.tables.map(item => (
          <TableRow key={item.hall + item.number} tabIndex={-1}>
            <TableCell padding="default">
              {`${parseTableHall(item.hall)} ${item.number}`}
            </TableCell>
            <TableCell padding="default" align="center">
              <EditField
                type={item.number}
                value={item.capacity}
                handleChangeList={(newTableData: any) => handleChangeCapacity(newTableData, item)}
                alignCenter
              />
            </TableCell>
            <TableCell align="center" padding="none">
              <Checkbox
                className={classes.tableListItemCheckBox}
                icon={CircleActive}
                checkedIcon={CircleInactive}
                checked={item.isBookingAllowed}
                onChange={() => handleChangeBooking('isBookingAllowed', item)}
              />
            </TableCell>
            <TableCell align="center" padding="none">
              <Checkbox
                className={classes.tableListItemCheckBox}
                disabled={!item.isBookingAllowed}
                icon={IconFlashInactive}
                checkedIcon={IconFlashActive}
                checked={item.isAutoBookingAllowed}
                onChange={() => handleChangeBooking('isAutoBookingAllowed', item)}
              />
            </TableCell>
          </TableRow>
        ))
      }
    </Fragment>
  );
};

export default withStyles(styles)(TableLIstItem);
