import { all, put, takeLatest } from 'redux-saga/effects';
import _ from 'lodash';

import { getSectionTablesReq } from '../api';

import { TRestaurantSectionAdapt } from '../types/IRestaurantTablesSchemaStore';
import { GET_SECTION_REQUEST } from '../actions/restaurant/RestaurantsActionTypes';
import { IGetSections } from '../actions/restaurant/IRestaurant';
import {
  getSectionError,
  getSectionSuccess,
} from '../actions/restaurant/RestaurantActions';


/** @description Сага на получение залов ресторана */
export function* fetchSections(id: number) {
  try {
    const sections: Array<TRestaurantSectionAdapt> = yield getSectionTablesReq(id).then(
      r => _.sortBy(r, ['name']),
    );

    yield put(getSectionSuccess(sections));
  } catch (e) {
    yield put(getSectionError());
  }
}

function* getSections(action: IGetSections) {
  yield fetchSections(action.payload.restaurantId);
}

export default function* saga() {
  yield all([
    takeLatest(GET_SECTION_REQUEST, getSections),
  ]);
}
