import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import { withStyles, createStyles, WithStyles } from '@material-ui/core/styles';

import RangeDatePicker from '../DatePickers/RangeDatePicker';
import locales from '../../../locales';
import { IDate } from '../../../types/IDate';
import { dashBoardDates } from '../../../config/constants';

// TODO Поправить шрифты для rangeDatePicker
const styles = () => createStyles({
  rangeDatePicker: {
    fontFamily: 'GolosText, Roboto, sans-serif',
  },
  btnWrap: {
    marginTop: 24,
    display: 'flex',
    justifyContent: 'center',
  },
  btnStyle: {
    width: '100%',
    height: 52,
  },
});

interface IProps extends WithStyles<typeof styles> {
  open: boolean;
  customDate: IDate;
  handleGetStatistics: (date: IDate) => void;
  switchCustomDateModal: () => void;
}

const CustomDateModal: React.FC<IProps> = (props) => {
  const {
    classes,
    open,
    customDate,
    handleGetStatistics,
    switchCustomDateModal,
  } = props;

  const [date, changeDate] = useState<{from: string, to: string} | null>(customDate.customDate || null);

  const onSubmit = () => {
    if (date) {
      handleGetStatistics({
        type: dashBoardDates.custom,
        value: date,
        customDate: date,
      });
      switchCustomDateModal();
    }
  };

  return (
    <Dialog
      open={open}
      maxWidth="md"
      aria-labelledby="simple-dialog-title"
    >
      <div className={classes.rangeDatePicker}>
        <RangeDatePicker
          date={customDate.customDate}
          handleDate={changeDate}
        />
      </div>
      <DialogContent className={classes.btnWrap}>
        <Button
          className={classes.btnStyle}
          style={{ marginRight: 24 }}
          onClick={onSubmit}
          variant="contained"
          type="submit"
          color="primary"
        >
          {locales.t('common.apply')}
        </Button>
        <Button
          className={classes.btnStyle}
          variant="outlined"
          onClick={switchCustomDateModal}
          color="secondary"
        >
          {locales.t('common.reject')}
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default withStyles(styles)(CustomDateModal);
