import React, { PureComponent, Fragment } from 'react';

import { IProps } from './IStatisticDate';
import StatisticDateSelect from '../../components/Common/StatisticsDateSelect';
import CustomDateModal from '../../components/Common/CustomDateModal';
import { IDate } from '../../types/IDate';

class HomeCont extends PureComponent<IProps, {}> {
  /** @description Ручка экшена для получения статистики */
  handleGetStatistics = (date: IDate) => {
    const { handleGetStatistics, changeCustomDate } = this.props;
    changeCustomDate(date);
    handleGetStatistics(date);
  };

  render() {
    const {
      customDateModalOpen,
      date,
      switchCustomDateModal,
    } = this.props;

    return (
      <Fragment>
        <StatisticDateSelect
          switchCustomDateModal={switchCustomDateModal}
          handleGetStatistics={this.handleGetStatistics}
          statisticDate={date}
        />

        <CustomDateModal
          open={customDateModalOpen}
          customDate={date}
          switchCustomDateModal={switchCustomDateModal}
          handleGetStatistics={this.handleGetStatistics}
        />
      </Fragment>
    );
  }
}

export default HomeCont;
