import React, { useState } from 'react';
import { Paper, Typography, Button } from '@material-ui/core';
import { createStyles, WithStyles, withStyles } from '@material-ui/core/styles';

import locales from '../../../locales';
import { errorColor } from '../../../theme';
import Confirmation from './confirmation';

const styles = () => createStyles({
  contentWrap: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    padding: '48px 24px',
  },
  button: {
    marginTop: 24,
    color: errorColor,
    border: '1px solid #C42126',
    '&:hover': {
      border: '1px solid #C42126',
    },
    '&:focus': {
      border: '1px solid #C42126',
    },
  },
});

interface IProps extends WithStyles<typeof styles> {
  onDelete: () => void;
}

const SettingsComponent: React.FC<IProps> = ({ classes, onDelete }) => {
  const [isConfirmation, showConfirmation] = useState(false);

  return (
    <>
      {isConfirmation && <Confirmation onClose={() => showConfirmation(false)} onSubmit={onDelete} />}

      <Paper elevation={3}>
        <div className={classes.contentWrap}>
          <Typography variant="h6">
            {locales.t('cashbox.settings.title')}
          </Typography>
          <Typography variant="body2">
            {locales.t('cashbox.settings.description')}
          </Typography>
          <Button
            className={classes.button}
            variant="outlined"
            color="secondary"
            onClick={() => showConfirmation(true)}
          >
            {locales.t('cashbox.settings.actions.delete.title')}
          </Button>
        </div>
      </Paper>
    </>
  );
}

const Settings = withStyles(styles)(SettingsComponent);

export { Settings };
