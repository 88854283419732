import {
  all,
  put,
  takeEvery,
  takeLatest,
  select,
} from 'redux-saga/effects';

import {
  FETCH_RESTAURANT_CALLS_SUCCESS,
  ADD_RESTAURANT_CALL,
} from '../actions/restaurantsCalls/RestaurantsCallsActionsTypes';

import {
  IFetchRestaurantCallsSuccess,
  IAddRestaurantCall,
} from '../actions/restaurantsCalls/IRestaurantsCallsActions';

import {
  FETCH_OPEN_RESERVES_SUCCESS,
  ADD_OPEN_RESERVE,
} from '../actions/reservationsPage/ReservationsPageActionsTypes';

import { CallNotification, ReserveNotification, TNotification } from '../types/INotificationsStore';
import { addNotification, openNotifications } from '../actions/notifications/NotificationsActions';

import {
  IFetchOpenReservesSuccess,
  IAddOpenReserve,
} from '../actions/reservationsPage/IReservationsPageActions';

import {
  ADD_NOTIFICATION,
  IDLE,
} from '../actions/notifications/NotificationsActionsTypes';

// import { dismiss } from '../helpers/callHelpers';
import { audioNotification } from '../helpers/notificationHelpers';
import Reserve from '../types/IAppReservesStore';


// Notifications

function* addNotificationSaga() {
  const isNotificationsOpen: boolean = yield select(state => state.notifications.open);
  const isAppReserveOpen: boolean = yield select(state => state.appReserves.items.some(
    (x: Reserve) => x.open,
  ));

  if (!isAppReserveOpen && !isNotificationsOpen) {
    yield put(openNotifications());
  }
}

// function removeNotificationSaga(action: IRemoveNotification) {
//   if (action.manually) {
//     dismiss(action.key);
//   }
// }

function* playNotificationSaga() {
  const notify: boolean = yield select(state => state.notifications.items.some(
    (n: TNotification) => !n.open,
  ));

  if (notify) {
    audioNotification('new_reserve');
  }
}


// Calls

function* fetchCalls(action: IFetchRestaurantCallsSuccess) {
  for (let i = 0; i < action.calls.length; i += 1) {
    yield put(addNotification(new CallNotification(action.calls[i])));
  }
}

function* addCall(action: IAddRestaurantCall) {
  yield put(addNotification(new CallNotification(action.call)));
}

// function* removeCall(action: IRemoveRestaurantCall) {
//   // @todo Вынести повторяющуюся часть removeCall и removeReserve в общую функцию
//
//   // const notifications = yield select(state => state.notifications.items.filter(
//   //   (x: TNotification) => !(x.type === 'CALL' && x.payload.id === action.id),
//   // ));
//   //
//   // yield put(setNotifications(notifications));
//
//   const notification = yield select(state => state.notifications.items.find(
//     (x: TNotification) => x.type === 'CALL' && x.payload.id === action.id,
//   ));
//
//   if (notification) {
//     yield put(removeNotification(notification.getKey(), action.manually));
//   }
// }


// Reserves

function* fetchReserves(action: IFetchOpenReservesSuccess) {
  for (let i = 0; i < action.reserves.length; i += 1) {
    yield put(addNotification(new ReserveNotification(action.reserves[i])));
  }
}

function* addReserve(action: IAddOpenReserve) {
  yield put(addNotification(new ReserveNotification(action.reserve)));
}

// function* removeReserve(action: IRemoveOpenReserve) {
//   // @todo Вынести повторяющуюся часть removeCall и removeReserve в общую функцию
//
//   if (action.manually) {
//     dismiss(action.notificationKey);
//   }
//
//   const notifications = yield select(state => state.notifications.items.filter(
//     (x: TNotification) => !(x.type === 'RESERVE' && x.payload.id === action.id),
//   ));
//
//   yield put(setNotifications(notifications));
// }


// Gather all & export

export default function* saga() {
  yield all([
    // takeEvery(REMOVE_NOTIFICATION, removeNotificationSaga),

    takeEvery(IDLE, playNotificationSaga),

    takeEvery(FETCH_RESTAURANT_CALLS_SUCCESS, fetchCalls),
    takeEvery(ADD_RESTAURANT_CALL, addCall),
    // takeEvery(REMOVE_RESTAURANT_CALL, removeCall),

    takeEvery(FETCH_OPEN_RESERVES_SUCCESS, fetchReserves),
    takeEvery(ADD_OPEN_RESERVE, addReserve),
    // takeEvery(REMOVE_OPEN_RESERVE, removeReserve),

    takeLatest(ADD_NOTIFICATION, addNotificationSaga),
  ]);
}
