import {
  GET_PROGRAMS_SUCCESS,
  LOAD_PROGRAMS_SUCCESS,
} from '../actions/promo/PromoActionsTypes';
import { IAppActions } from '../types/IAppActions';
import { IPromoStore } from '../types/IPromoStore';

export const initState: IPromoStore = {
  programList: [],
};

export default function reducer(state = initState, action: IAppActions): IPromoStore {
  switch (action.type) {
    case LOAD_PROGRAMS_SUCCESS:
    case GET_PROGRAMS_SUCCESS: {
      return {
        ...state,
        programList: action.payload.programList,
      };
    }

    default:
      return state;
  }
}
