import React from 'react';

import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MessageOutlined } from '@material-ui/icons';

import BodyVisits from '../BodyVisits';
import BodyItem from '../BodyItem';
import locales from '../../../../../locales';
import { ReserveNotification } from '../../../../../types/INotificationsStore';

interface ReserveBodyProps {
  notification: ReserveNotification;
}

const useStyles = makeStyles(() => ({
  comment: {
    color: '#aaaaaa',
    fontSize: 12,
  },
  commentIcon: {
    color: '#6ae9c5',
    fontSize: 18,
  },
}));

function ReserveBody(props: ReserveBodyProps) {
  const classes = useStyles();

  const { notification } = props;
  const { payload } = notification;

  const comment = notification.getComment();
  const visitDate = notification.getVisitDate();

  return (
    <>
      <Box display="flex" mb={2}>
        <BodyItem
          label={locales.t('notifications.properties.persons')}
          value={('persons' in payload && payload.persons.toString()) || '?'}
          rightIndent={3}
        />
        <BodyItem
          label={locales.t('notifications.properties.visitDate')}
          value={visitDate && visitDate.format('lll')}
          rightIndent={3}
        />
        <BodyItem
          label={locales.t('notifications.properties.table')}
          value={notification.getTable() || '?'}
          rightIndent={0}
        />
      </Box>

      {comment && (
        <Box display="flex" mb={2}>
          <Box mr={1} clone>
            <MessageOutlined className={classes.commentIcon} />
          </Box>
          <Box>
            <Typography className={classes.comment}>{comment}</Typography>
          </Box>
        </Box>
      )}

      {notification.open && (
        <>
          <Box mb={2} clone>
            <div
              style={{
                width: 256,
                height: 1,
                backgroundColor: '#c5c5c5',
                margin: '0 auto',
              }}
            />
          </Box>

          <BodyVisits notification={notification} />
        </>
      )}
    </>
  );
}

export default ReserveBody;
