import React from 'react';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { NavLink } from 'react-router-dom';

import * as EmailValidator from 'email-validator';
import Typography from '@material-ui/core/Typography/Typography';
import { Routers } from '../../config/constants';
import TextField from '../Common/TextFieldComp';
import locales from '../../locales';

const RestorePasswordForm: React.FC<InjectedFormProps<{}, {}>> = ({ handleSubmit }) => (
  <form onSubmit={handleSubmit} style={{ marginTop: '30px' }}>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Field
          fullWidth
          name="email"
          component={TextField}
          label={locales.t('common.email')}
        />
      </Grid>
      <Grid item xs={12} container justify="center">
        <Button
          style={{ width: 226, height: 46, marginTop: 42 }}
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
        >
          {locales.t('restorePassword.initRestoreButton')}
        </Button>
      </Grid>
      <Grid item xs={12} container justify="center">
        <NavLink to={Routers.auth}>
          <Typography align="center" variant="caption" style={{ textDecoration: 'underline' }}>
            { locales.t('common.goBack') }
          </Typography>
        </NavLink>
      </Grid>
    </Grid>
  </form>
);

interface IErrors {
  email?: string;
}

const validate = ({ email }: any) => {
  const errors: IErrors = {};
  if (!email) errors.email = locales.t('common.errors.fieldErrors.email.empty');
  else if (!EmailValidator.validate(email)) errors.email = locales.t('common.errors.fieldErrors.email.incorrect');
  return errors;
};

export default reduxForm<{}, {}>({
  form: 'restorePassword',
  validate,
})(RestorePasswordForm);
