import React, { PureComponent, Fragment } from 'react';

import Widgets from '../../components/ReservesSystem/Widgets/WidgetsTable';
import { IProps, IState } from './IReservesSystemWidgets';
import {
  IWidgetAdapt,
  IWidgetDataAdapt,
} from '../../types/IRestaurantWidgetsStore';
import WidgetCreateModal from '../../components/ReservesSystem/Widgets/WidgetCreateModal';
import WidgetEditModal from '../../components/ReservesSystem/Widgets/WidgetEditModal';
import WidgetDeleteModal from '../../components/ReservesSystem/Widgets/WidgetDeleteModal';
import locales from '../../locales';

class ReservesSystemWidgets extends PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      createWidgetModalIsOpen: false,
      editWidgetModalIsOpen: false,
      deleteWidgetModalIsOpen: false,
      widgetInfo: undefined,
    };
  }

  componentDidMount(): void {
    const { getAllWidgets } = this.props;
    getAllWidgets();
  }

  /** @description Ручка для открытия модального окна для создания, редактирования или удаления виджета */
  handleOpenWidgetModal = (type: 'create' | 'edit' | 'delete', widgetInfo?: IWidgetAdapt) => {
    switch (type) {
      case 'create': {
        this.setState({
          createWidgetModalIsOpen: true,
          widgetInfo,
        });
        break;
      }

      case 'edit': {
        this.setState({
          editWidgetModalIsOpen: true,
          widgetInfo,
        });
        break;
      }

      case 'delete': {
        this.setState({
          deleteWidgetModalIsOpen: true,
          widgetInfo,
        });
        break;
      }

      default: break;
    }
  };

  /** @description Ручка для закрытия модального окна для создания, редактирования или удаления виджета */
  handleCloseModal = () => {
    this.setState({
      createWidgetModalIsOpen: false,
      editWidgetModalIsOpen: false,
      deleteWidgetModalIsOpen: false,
      widgetInfo: undefined,
    });
  };

  /** @description Ручка для создания виджета */
  handleCreateWidget = (data: IWidgetDataAdapt) => {
    const { createWidget } = this.props;

    if (data) {
      createWidget(data);
      this.setState({
        createWidgetModalIsOpen: false,
      });
    }
  };

  /** @description Ручка для редактирования виджета */
  handleUpdateWidget = (widgetId: number, newWidgetName: IWidgetDataAdapt) => {
    const { updateWidget } = this.props;

    if (widgetId && newWidgetName) {
      updateWidget(widgetId, newWidgetName);
    }
  };

  /** @description Ручка для удаления виджета */
  handleDeleteWidget = (widgetId: number) => {
    const { deleteWidget } = this.props;

    if (widgetId) {
      deleteWidget(widgetId);
    }
  };

  handleCustomMsg = () => {
    const { customMessageShackBar } = this.props;
    customMessageShackBar(locales.t('settings.widgets.copyLinkMsg'));
  };

  render() {
    const {
      widgets,
      isLoading,
    } = this.props;
    const {
      createWidgetModalIsOpen,
      editWidgetModalIsOpen,
      deleteWidgetModalIsOpen,
      widgetInfo,
    } = this.state;

    return (
      <Fragment>
        <Widgets
          widgets={widgets}
          handleOpenWidgetModal={this.handleOpenWidgetModal}
          handleCustomMsg={this.handleCustomMsg}
        />
        { createWidgetModalIsOpen && (
          <WidgetCreateModal
            isOpen={createWidgetModalIsOpen}
            handleClose={this.handleCloseModal}
            handleCreateWidget={this.handleCreateWidget}
          />
        ) }
        { editWidgetModalIsOpen && (
          <WidgetEditModal
            isOpen={editWidgetModalIsOpen}
            handleClose={this.handleCloseModal}
            widgetInfo={widgetInfo}
            handleUpdateWidget={this.handleUpdateWidget}
          />
        ) }
        { deleteWidgetModalIsOpen && (
          <WidgetDeleteModal
            isOpen={deleteWidgetModalIsOpen}
            handleClose={this.handleCloseModal}
            widgetInfo={widgetInfo}
            handleDeleteWidget={this.handleDeleteWidget}
          />
        ) }
      </Fragment>
    );
  }
}

export default ReservesSystemWidgets;
