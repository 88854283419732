import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import { IAppStore } from '../types/IAppStore';

export const selectorRestaurant = createSelector(
  [(store: IAppStore) => store],
  store => store.cabinet.currentRestaurant,
);

export const selectorRestaurantId = createSelector(
  [selectorRestaurant],
  restaurant => restaurant && restaurant.id,
);

export function useRestaurantId() {
  return useSelector(selectorRestaurantId);
}
