import React, { PureComponent, Fragment } from 'react';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid';

import styles from './styles';
import { IBookingProps } from './IBooking';
import locales from '../../locales';
import { TReserve } from '../../types/IBookingStore';
import { reserveState } from '../../config/constants';
import ReserveList from '../../components/Booking/ReserveList';
import BookingDatePicker from '../../components/Booking/BookingDate';
import PageHeaderWrap from '../../components/Common/PageHeaderWrap';
import PageContentWrap from '../../components/Common/PageContentWrap';
import Reserve from '../../types/IAppReservesStore';
import CashBoxWarningCont from '../CashBoxWarning';
import { NoHostessWarning } from '../../features';

class Booking extends PureComponent<IBookingProps> {
  componentDidMount(): void {
    const { isInit, initBookingPages } = this.props;

    if (!isInit) {
      initBookingPages();
    }
  }

  /** @description Ручка экшена для редактирования выбранного резерва */
  handleOpenEditReserveModal = (reserve: TReserve): void => {
    const { addAppReserve } = this.props;

    switch (reserve.state) {
      case reserveState.ACCEPTED:
      case reserveState.GUEST_IS_LATE:
      case reserveState.NEED_PREPARE_TABLE:
      case reserveState.CREATED:
        addAppReserve(new Reserve('APP', reserve));
        break;

      default:
        break;
    }
  };

  /** @description Ручка для получения списка броней при редактирования текущей даты в списке резервов */
  handleChangeDate = (date: string): void => {
    const { getBookingList, currentRestaurant } = this.props;
    getBookingList(currentRestaurant, date);
  };

  /** @description Ручка для переключения фильтра */
  handleSwitchActives = (): void => {
    const { switchBookingFilter, onlyActive } = this.props;
    switchBookingFilter(!onlyActive, true);
  };

  render() {
    const {
      classes,
      bookingInfoList,
      currentRestaurant,
      currentStatisticDate,
      filterBookingListBy,
      sortBookingList,
      onlyActive,
      filters,
      sort,
      addAppReserve,
    } = this.props;

    return (
      <Fragment>
        <PageHeaderWrap>
          <div className={classes.datePickerWrap}>
            <Typography variant="h6">
              {locales.t('booking.title')}
            </Typography>

            <BookingDatePicker
              currentStatisticDate={currentStatisticDate}
              handleChangeDate={this.handleChangeDate}
            />
          </div>

          <div className={classes.filter}>
            <Typography variant="caption">
              {locales.t('booking.onlyActive')}
            </Typography>
            <Switch
              checked={onlyActive}
              onChange={this.handleSwitchActives}
              color="primary"
            />
          </div>
        </PageHeaderWrap>
        <CashBoxWarningCont />
        <NoHostessWarning />

        <PageContentWrap fullWidth>
          <Grid item xs={12}>
            <ReserveList
              bookingInfoList={bookingInfoList}
              filters={filters}
              sort={sort}
              handleOpenEditReserveModal={this.handleOpenEditReserveModal}
              handleSortBookingList={sortBookingList}
              handleFilterBy={filterBookingListBy}
            />
          </Grid>
        </PageContentWrap>

        <Fab
          className={classes.btnWrap}
          onClick={() => addAppReserve(new Reserve('APP'))}
          color="primary"
          disabled={!currentRestaurant}
        >
          <AddIcon />
        </Fab>
      </Fragment>
    );
  }
}

export default withStyles(styles)(Booking);
