import React, { PureComponent } from 'react';
import moment from 'moment';

import {
  withStyles, createStyles, WithStyles, Theme,
} from '@material-ui/core/styles/index';
import { Divider, ListSubheader } from '@material-ui/core';
import Input from '@material-ui/core/Input/index';
import InputLabel from '@material-ui/core/InputLabel/index';
import MenuItem from '@material-ui/core/MenuItem/index';
import FormControl from '@material-ui/core/FormControl/index';
import Select from '@material-ui/core/Select/index';

import locales from '../../locales';
import { dashBoardDates } from '../../config/constants';
import { monthForPeriodPicker, parseDateForStatisticReq } from '../../helpers/dateHelpers';
import { IDate } from '../../types/IDate';

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(),
    width: 250,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
});

interface IProps extends WithStyles<typeof styles> {
  statisticDate: IDate;
  handleGetStatistics: any;
  switchCustomDateModal: any;
}

class StatisticsDateSelect extends PureComponent<IProps> {
  handleChange = (event: any) => {
    const {
      handleGetStatistics, switchCustomDateModal,
    } = this.props;
    this.setState({ [event.target.name]: { type: event.target.value } }, () => {
      if (event.target.value !== 'custom') {
        handleGetStatistics({
          type: event.target.value,
          value: parseDateForStatisticReq(event.target.value),
        });
      } else {
        switchCustomDateModal();
      }
    });
  };

  onClickCustomDate = () => {
    const { statisticDate, switchCustomDateModal } = this.props;
    if (statisticDate.type === dashBoardDates.custom) {
      switchCustomDateModal();
    }
  };

  render() {
    const { classes, statisticDate } = this.props;

    return (
      <form className={classes.root} autoComplete="off">
        <FormControl className={classes.formControl}>
          <InputLabel shrink htmlFor="statistic-date-placeholder">
            {locales.t('home.statistic.selectDate.title')}
          </InputLabel>
          <Select
            value={statisticDate.type}
            onChange={this.handleChange}
            input={<Input name="statistic-date" id="statistic-date-placeholder" />}
            displayEmpty
            name="statisticDate"
            className={classes.selectEmpty}
          >
            <MenuItem value={dashBoardDates.today}>
              {locales.t('home.statistic.selectDate.today')}
            </MenuItem>
            <MenuItem value={dashBoardDates.tomorrow}>
              {locales.t('home.statistic.selectDate.tomorrow')}
            </MenuItem>
            <ListSubheader>
              <Divider />
            </ListSubheader>
            <MenuItem value={dashBoardDates.seven}>
              {locales.t('home.statistic.selectDate.seven')}
            </MenuItem>
            <MenuItem value={dashBoardDates.fourteen}>
              {locales.t('home.statistic.selectDate.fourteen')}
            </MenuItem>
            <MenuItem value={dashBoardDates.twentyEight}>
              {locales.t('home.statistic.selectDate.twentyEight')}
            </MenuItem>
            <MenuItem value={dashBoardDates.sixty}>
              {locales.t('home.statistic.selectDate.sixty')}
            </MenuItem>
            <ListSubheader>
              <Divider />
            </ListSubheader>
            <MenuItem value={dashBoardDates.firstMonth}>
              { monthForPeriodPicker(dashBoardDates.firstMonth) }
            </MenuItem>
            <MenuItem value={dashBoardDates.secondMonth}>
              { monthForPeriodPicker(dashBoardDates.secondMonth) }
            </MenuItem>
            <MenuItem value={dashBoardDates.currentMonth}>
              { monthForPeriodPicker(dashBoardDates.currentMonth) }
            </MenuItem>
            <ListSubheader>
              <Divider />
            </ListSubheader>
            <MenuItem
              value={dashBoardDates.custom}
              onClick={this.onClickCustomDate}
            >
              {
                statisticDate.customDate
                  ? `${moment(statisticDate.customDate.from).format('D MMM')} - ${moment(statisticDate.customDate.to).format('D MMM')}`
                  : locales.t('home.statistic.selectDate.custom')
              }
            </MenuItem>
          </Select>
        </FormControl>
      </form>
    );
  }
}

export default withStyles(styles)(StatisticsDateSelect);
