import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import AuthCont from './AuthCont';
import { StateProps, DispatchProps } from './IAuthCont';
import { getSession, signIn } from '../../actions/auth/AuthActions';
import { IAppActions } from '../../types/IAppActions';
import { IAppStore } from '../../types/IAppStore';

function mapStateToProps(state: IAppStore): StateProps {
  return {
    authorized: state.auth.authorized,
    error: state.auth.error,
  };
}

function mapDispatchToProps(dispatch: Dispatch<IAppActions>): DispatchProps {
  return bindActionCreators({
    signIn,
    getSession,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthCont);
