import React, { Component } from 'react';

import locales from '../../locales';
import { autoBooking } from '../../config/constants';
import { IProps } from './IReservesSystemSettings';
import { TNewInfo } from '../../types/IRestaurantInfoStore';
import { IScheduleRow, TWeekDay } from '../../types/IScheduleStore';
import ReservesSystemSettings from '../../components/ReservesSystem/ReservesSystemSettings';

class ReservesSystemSettingsCont extends Component<IProps> {
  /** @description Ручка для переключения приёма брони */
  handleSwitchBookingProcessor = (data: TNewInfo) => {
    const { currentRestaurant, switchBookingProcessor } = this.props;
    const processorType = data.value === locales.t('settings.api')
      ? autoBooking.api
      : autoBooking.cashBox;

    if (currentRestaurant) {
      switchBookingProcessor({
        restaurantId: currentRestaurant.id,
        processorType,
      });
    }
  };

  /** @description Ручка для редактирования полей ресторана */
  handleUpdateRestaurantSettings = (data: TNewInfo) => {
    const { currentRestaurant, updateRestaurantInfo } = this.props;

    if (currentRestaurant) {
      const updateData = {
        ...data,
        currentRestaurant,
      };

      updateRestaurantInfo(updateData);
    }
  };

  /** @description Ручка экшена для добавления новой строки в расписание */
  handleAddScheduleRow = () => {
    const { addNewScheduleRow } = this.props;
    addNewScheduleRow('booking');
  };

  /** @description Ручка экшена для редактирования строки расписания */
  handelUpdateSchedule = (newScheduleInfo: any) => {
    const { updateSchedule } = this.props;
    updateSchedule(newScheduleInfo, 'booking');
  };

  /** @description Ручка экшена для удаления строки расписания */
  handleDeleteScheduleRow = (row: IScheduleRow) => {
    const { deleteScheduleRow } = this.props;
    deleteScheduleRow(row, 'booking');
  };

  /** @description Ручка для изменения свободных дней в расписании */
  handleUpdateFreeDays = (days: {
    activate: Array<TWeekDay>,
    deactivate: Array<TWeekDay>
  }) => {
    const { updateFreeDays } = this.props;
    updateFreeDays(days, 'booking');
  };

  /** @description Ручка для переключение типа графика приёма резервов */
  handleSwitchReserveSchedule = () => {
    const { switchReserveSchedule, isActiveRestaurantSchedule } = this.props;
    switchReserveSchedule(!isActiveRestaurantSchedule);
  };

  render() {
    const {
      freeDays,
      scheduleList,
      isActiveRestaurantSchedule,
      currentRestaurant,
      restaurantWorkTimes,
    } = this.props;

    if (!currentRestaurant) {
      return null;
    }

    return (
      <ReservesSystemSettings
        currentRestaurant={currentRestaurant}
        isActiveRestaurantSchedule={isActiveRestaurantSchedule}
        handleSwitchBookingProcessor={this.handleSwitchBookingProcessor}
        handleUpdateRestaurantSettings={this.handleUpdateRestaurantSettings}
        handleAddScheduleRow={this.handleAddScheduleRow}
        handelUpdateSchedule={this.handelUpdateSchedule}
        handleDeleteScheduleRow={this.handleDeleteScheduleRow}
        handleUpdateFreeDays={this.handleUpdateFreeDays}
        handleSwitchReserveSchedule={this.handleSwitchReserveSchedule}
        freeDays={freeDays}
        scheduleList={scheduleList}
        restaurantWorkTimes={restaurantWorkTimes}
      />
    );
  }
}

export default ReservesSystemSettingsCont;
