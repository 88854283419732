import React from 'react';
import { useCashbox, useDeleteMutation } from '../../../entities';
import { Settings } from '../ui';
import { useRestaurantId } from '../../../selectors/RestaurantSelectors';


export function useCashboxSettingsFeature() {
  const cashbox = useCashbox();
  const deleteMutation = useDeleteMutation();
  const restaurantId = useRestaurantId();

  if (cashbox.isLoading || !cashbox.cashbox) {
    return null;
  }

  const onDelete = () => {
    if (!restaurantId || !cashbox.cashbox) {
      return;
    }

    deleteMutation.mutate({
      hwid: cashbox.cashbox.id,
      restaurantId,
    });
  }

  return <Settings onDelete={onDelete} />
}
