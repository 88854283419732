import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import _ from 'lodash';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core';

const styles = {
  formTextInput: {
    textAlign: 'center',
  },
};

/**
 * @classes {Object}
 * @value {string || number} - текущее значение
 * @items {Array<any>} - список доступных зачений
 * @disabled {boolean} - блокировка поля
 * @fieldType {string} - название поля в редактируемой сущности, для ресторана ./config/constants - cardFieldType
 * @handleChangeList {func} - колбек для редактирования сущности
 * @alignCenter {boolean} - центровка текста в поле
*/
class SelectItemLIst extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      type: '',
      initValue: null,
    };
  }

  componentDidMount() {
    const { active, type } = this.props;

    if (!_.isNull(active)) {
      this.setState({
        value: active,
        initValue: active,
      });
    } else {
      this.setState({
        value: ' ',
      });
    }

    this.setState({
      type,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (!_.isNull(nextProps.active)) {
      this.setState({
        value: nextProps.active,
      });
    } else {
      this.setState({
        value: '',
      });
    }

    this.setState({
      type: nextProps.type,
    });
  }

  handleChange = (event) => {
    this.setState({ value: event.target.value });
  };

  handleSetNewChange = () => {
    if (this.state.initValue !== this.state.value) {
      this.props.handleChangeList(this.state);
      this.setState({
        initValue: this.state.value,
      });
    }
  };

  render() {
    const {
      classes, label, items, disabled, alignCenter,
    } = this.props;

    return (
      <FormControl fullWidth>
        { label && <InputLabel htmlFor="age-simple">{label}</InputLabel> }
        <Select
          className={alignCenter && classes.formTextInput}
          disabled={!!disabled}
          onBlur={() => this.handleSetNewChange()}
          fullWidth
          value={this.state.value}
          onChange={this.handleChange}
        >
          {items.map((items, i) => (
            <MenuItem key={i} value={items}>
              {items}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
}

SelectItemLIst.propTypes = {
  value: PropTypes.any,
  type: PropTypes.any,
  handleChangeList: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  fieldType: PropTypes.string,
  alignCenter: PropTypes.bool,
  label: PropTypes.string,
  active: PropTypes.string,
  items: PropTypes.array,
};

export default withStyles(styles)(SelectItemLIst);
