import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import _ from 'lodash';

import { withStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

const styles = {
  formTextInput: {
    textAlign: 'center',
  },
  numberFiled: {
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      opacity: 1,
    },
  },
};

/**
 * classes {Object}
 * label {string} - лейбл поля
 * disabled {boolean} - блокировка поля
 * fieldType {string} - название поля в редактируемой сущности, для ресторана ./config/constants - cardFieldType
 * alignCenter {boolean} - центрирование текста в инпуте
 * handleChangeList {func} - колбек для редактирования сущности
 * maxLength {number} - максимальная длинна строки, по дефолту 255
 * prefix {string} - префикс
 * suffix {string} - окончание
 */
class EditField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      type: '',
      initValue: null,
    };
  }

  componentDidMount() {
    const {
      value, type, prefix, suffix,
    } = this.props;

    if (!_.isNull(value)) {
      this.setState({
        value: `${prefix}${value}${suffix}`,
        initValue: `${prefix}${value}${suffix}`,
      });
    } else {
      this.setState({
        value: `${prefix} ${suffix}`,
      });
    }

    this.setState({
      type,
    });
  }

  componentWillReceiveProps({ value, type }) {
    if (!_.isNull(value)) {
      this.setState({
        value,
      });
    } else {
      this.setState({
        value: ' ',
      });
    }

    this.setState({
      type,
    });
  }

  handleChange = (event) => {
    this.setState({ value: event.target.value });
  };

  handleSetNewChange = () => {
    const { initValue, value } = this.state;
    const { handleChangeList } = this.props;

    if (initValue !== value) {
      handleChangeList(this.state);
      this.setState({
        initValue: value,
      });
    }
  };

  render() {
    const { value } = this.state;
    const {
      classes,
      label,
      disabled,
      fieldType,
      alignCenter,
      maxLength,
      endAdornment,
    } = this.props;

    const EndAdornmentComp = endAdornment && (
      <InputAdornment position="end" style={{ position: 'absolute', right: 30 }}>
        <Typography style={{ color: 'rgba(0, 0, 0, 0.54)' }} variant="caption">
          { endAdornment }
        </Typography>
      </InputAdornment>
    );

    return (
      <TextField
        className={classes.numberFiled}
        type={fieldType}
        label={label}
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
        value={value}
        onChange={this.handleChange}
        margin="none"
        onBlur={() => this.handleSetNewChange()}
        onKeyPress={(ev) => {
          if (ev.key === 'Enter') {
            this.handleSetNewChange();
          }
        }}
        InputProps={{
          endAdornment: EndAdornmentComp,
          classes: {
            input: alignCenter && classes.formTextInput,
          },
          inputProps: {
            maxLength,
            min: 0,
          },
        }}
        disabled={disabled}
      />
    );
  }
}

EditField.propTypes = {
  classes: PropTypes.shape({
    formTextInput: PropTypes.string,
  }).isRequired,
  value: PropTypes.any,
  fieldType: PropTypes.string,
  handleChangeList: PropTypes.func.isRequired,
  endAdornment: PropTypes.any,
  alignCenter: PropTypes.bool,
  disabled: PropTypes.bool,
  maxLength: PropTypes.number,
  label: PropTypes.string,
  type: PropTypes.string,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
};

EditField.defaultProps = {
  prefix: '',
  suffix: '',
};

export default withStyles(styles)(EditField);
