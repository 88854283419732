import { IAppActions } from '../../types/IAppActions';
import * as ActionTypes from './NotificationsActionsTypes';
import { TNotification, TNotifications } from '../../types/INotificationsStore';

export function setNotifications(items: TNotifications): IAppActions {
  return {
    type: ActionTypes.SET_NOTIFICATIONS,
    items,
  };
}

export function resetNotifications(): IAppActions {
  return {
    type: ActionTypes.RESET_NOTIFICATIONS,
  };
}

export function toggleNotifications(): IAppActions {
  return {
    type: ActionTypes.TOGGLE_NOTIFICATIONS,
  };
}

export function openNotifications(): IAppActions {
  return {
    type: ActionTypes.OPEN_NOTIFICATIONS,
  };
}

export function closeNotifications(): IAppActions {
  return {
    type: ActionTypes.CLOSE_NOTIFICATIONS,
  };
}

// @todo Может передавать только payload?
export function addNotification(notification: TNotification): IAppActions {
  return {
    type: ActionTypes.ADD_NOTIFICATION,
    notification,
  };
}

export function removeNotification(key: string, manually: boolean = false): IAppActions {
  return {
    type: ActionTypes.REMOVE_NOTIFICATION,
    key,
    manually,
  };
}

export function toggleNotification(key: string): IAppActions {
  return {
    type: ActionTypes.TOGGLE_NOTIFICATION,
    key,
  };
}

export function idle(): IAppActions {
  return {
    type: ActionTypes.IDLE,
  };
}
