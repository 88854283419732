import { IAppActions } from '../types/IAppActions';
import { IUserStatisticStore } from '../types/IUserStatisticStore';
import {
  GET_USER_STATISTIC_SUCCESS,
  GET_USER_STATISTIC_REQUEST,
} from '../actions/userStatistic/UserStatisticActionsTypes';

const initState: IUserStatisticStore = {
  userInfo: {
    firstVisit: '',
    lastVisit: '',
    isActive: false,
    name: '',
    phone: '',
  },
  statistics: {
    averageReceiptAmount: 0,
    total: 0,
    totalCancelledByGuest: 0,
    totalCancelledByRestaurant: 0,
    totalClosed: 0,
    totalDeclined: 0,
    totalReceiptAmount: 0,
  },
  reserves: [],
};

export default function UStatisticReducer(state = initState, action: IAppActions): IUserStatisticStore {
  switch (action.type) {
    case GET_USER_STATISTIC_REQUEST: {
      return {
        ...state,
        userInfo: {
          firstVisit: '',
          lastVisit: '',
          isActive: false,
          name: '',
          phone: '',
        },
      };
    }
    case GET_USER_STATISTIC_SUCCESS: {
      return {
        ...state,
        statistics: action.payload.userStats,
        userInfo: action.payload.userInfo,
        reserves: action.payload.userReserves,
      };
    }
    default: return state;
  }
}
