import React, { ReactElement } from 'react';
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';

import TextField from '@material-ui/core/TextField';

interface IProps {
  input: WrappedFieldInputProps;
  meta: WrappedFieldMetaProps;
  staticValue: string;
  label: string;
  readOnly?: boolean;
  type: string;
  endAdornment: any;
  disabled?: boolean;
  errorAsHelperText?: boolean;
  helperText?: React.ReactNode;
  required?: boolean;
}

const TextFieldComp: React.FC<IProps> = ({
  input,
  label,
  type,
  staticValue,
  readOnly,
  endAdornment,
  meta: { touched, error },
  disabled,
  errorAsHelperText,
  helperText,
  required,
}) => {
  const isError = touched && !!error;
  let l: ReactElement = <>{label}</>;

  if (isError) {
    if (!errorAsHelperText) l = error;
  } else if (required) {
    l = (
      <>
        {label}
        <sup style={{ color: '#f45858', fontSize: 13 }}>*</sup>
      </>
    );
  }

  return (
    <TextField
      fullWidth
      label={l}
      error={isError}
      type={type}
      helperText={errorAsHelperText && isError ? error : helperText}
      {...input}
      disabled={!!staticValue || disabled}
      InputProps={{
        readOnly: !!readOnly,
        endAdornment,
      }}
    />
  );
};

export default TextFieldComp;
