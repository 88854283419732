import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';

interface BodyItemProps {
  label: string;
  value?: string;
  rightIndent?: number;
}

const useStyles = makeStyles(() => ({
  label: {
    color: '#aaaaaa',
    fontSize: 11,
    textTransform: 'uppercase',
  },
  value: {
    color: '#6ae9c5',
    fontSize: 16,
    fontWeight: 500,
  },
}));

function BodyItem(props: BodyItemProps) {
  const { label, value, rightIndent } = props;
  const classes = useStyles();

  return (
    <Box textAlign="center" mr={rightIndent === undefined ? 2 : rightIndent}>
      <Typography className={classes.value}>{value || '–'}</Typography>
      <Typography className={classes.label}>{label}</Typography>
    </Box>
  );
}

export default BodyItem;
