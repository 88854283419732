export const GET_SCHEDULE_LIST_REQUEST = 'GET_SCHEDULE_LIST_REQUEST';
export const GET_SCHEDULE_LIST_SUCCESS = 'GET_SCHEDULE_LIST_SUCCESS';
export const GET_SCHEDULE_LIST_ERROR = 'GET_SCHEDULE_LIST_ERROR';

export const UPDATE_SCHEDULE_REQUEST = 'UPDATE_SCHEDULE_REQUEST';
export const UPDATE_SCHEDULE_SUCCESS = 'UPDATE_SCHEDULE_SUCCESS';
export const UPDATE_SCHEDULE_ERROR = 'UPDATE_SCHEDULE_ERROR';

export const FORCE_UPDATE_BOOKING_SCHEDULE = 'FORCE_UPDATE_BOOKING_SCHEDULE';
export const FORCE_DELETE_BOOKING_SCHEDULE = 'FORCE_DELETE_BOOKING_SCHEDULE';

export const DELETE_SCHEDULE_ROW_REQUEST = 'DELETE_SCHEDULE_ROW_REQUEST';
export const DELETE_SCHEDULE_ROW_SUCCESS = 'DELETE_SCHEDULE_ROW_SUCCESS';
export const DELETE_SCHEDULE_ROW_ERROR = 'DELETE_SCHEDULE_ROW_ERROR';

export const ADD_SCHEDULE_ROW = 'ADD_SCHEDULE_ROW';
export const UPDATE_FREE_DAYS = 'UPDATE_FREE_DAYS';
