import React from 'react';
import Typography from '@material-ui/core/Typography';
import { WithStyles, withStyles, createStyles } from '@material-ui/core/styles';

import locales from '../../../locales';
import { Routers } from '../../../config/constants';

const styles = createStyles({
  wrap: {
    paddingTop: 20,
    flex: 1,
    display: 'flex',
    flexDirection: 'column-reverse',
  },
  rules: {
    fontSize: '10px',
    textDecoration: 'underline',
  },
});

interface IProps extends WithStyles<typeof styles> {}

const UseRules: React.FC<IProps> = ({ classes }) => (
  <div className={classes.wrap}>
    <a className={classes.rules} href={Routers.rules} target="noopener">
      <Typography variant="caption">
        { locales.t('common.useRules') }
      </Typography>
    </a>
  </div>
);

export default withStyles(styles)(UseRules);
