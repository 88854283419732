import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';

import CashBoxWarningComp from './CashBoxWarning';
import { hideWarning } from '../../actions/cabinet/CabinetActions';
import { IAppStore } from '../../types/IAppStore';
import { IAppActions } from '../../types/IAppActions';
import { StateProps, DispatchProps } from './ICashBoxWarning';
import {
  isSubVersionUpdateRequired,
  isVersionUpdateRequired,
} from './selectors';

const mapStateToProps = (state: IAppStore): StateProps => ({
  isOpen: state.cabinet.warningOpen,
  isInit: state.cabinet.isInit,
  isSubVersionUpdateRequired: isSubVersionUpdateRequired(state),
  isVersionUpdateRequired: isVersionUpdateRequired(state),
});

const mapDispatchToProps = (dispatch: Dispatch<IAppActions>): DispatchProps => bindActionCreators({
  hideWarning,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CashBoxWarningComp);
