import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import ReservesSystemTablesCont from './ReservesSystemTablesCont';

import {
  getTablesForSettings,
  updateRestaurantTable,
} from '../../actions/restaurantsTables/RestaurantsTables';
import { IAppStore } from '../../types/IAppStore';
import { IDispatchProps, IStateProps } from './IResevesSystemTables';
import { IAppActions } from '../../types/IAppActions';
import { customErrorShackBar } from '../../actions/snackbars/SnackBarsActions';
import { selectorGetHallList } from './selectors';

function mapStateToProps(state: IAppStore): IStateProps {
  const { currentRestaurant } = state.cabinet;
  const isAutoBookingEnabled = currentRestaurant
    ? currentRestaurant.settings.isAutoBookingEnabled
    : false;

  return {
    currentRestaurant,
    hallList: selectorGetHallList(state),
    isAutoBookingEnabled,
  };
}

function mapDispatchToProps(dispatch: Dispatch<IAppActions>): IDispatchProps {
  return bindActionCreators({
    getTablesForSettings,
    updateRestaurantTable,
    customErrorShackBar,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ReservesSystemTablesCont);
