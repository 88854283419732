import {
  createStyles, Theme,
} from '@material-ui/core/styles';

import { tabletWidth } from '../../config/constants';

const styles = (theme: Theme) => createStyles({
  datePickerWrap: {
    display: 'flex',
    alignItems: 'center',
    width: '80%',
    [theme.breakpoints.down(tabletWidth)]: {
      width: '100%',
    },
  },
  btnWrap: {
    width: 60,
    height: 60,
    position: 'fixed',
    bottom: 24,
    right: 24,
  },
  leftIcon: {
    marginRight: 21,
  },
  settingsIcon: {
    width: 24,
    height: 24,
    objectFit: 'contain',
    opacity: 0.54,
    cursor: 'pointer',
  },
  filter: {
    display: 'flex',
    alignItems: 'center',
  },
});

export default styles;
