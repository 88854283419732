import React from 'react';

import {
  withStyles,
  WithStyles,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import DialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

import DialogTitle from '../../Common/DialogTitle';
import locales from '../../../locales';
import { IWidgetAdapt } from '../../../types/IRestaurantWidgetsStore';
import styles from './styles';

interface IProps extends WithStyles<typeof styles>{
  isOpen: boolean;
  widgetInfo?: IWidgetAdapt;
  handleClose: () => void;
  handleDeleteWidget: (widgetId: number) => void;
}

const WidgetDeleteModal: React.FC<IProps> = ({
  classes,
  isOpen,
  widgetInfo,
  handleClose,
  handleDeleteWidget,
}) => {
  /** @description Удаление виджета */
  const onClick = () => {
    if (widgetInfo) {
      handleDeleteWidget(widgetInfo.id);
      handleClose();
    }
  };

  return (
    <Dialog
      open={isOpen}
      classes={{ paper: classes.paper }}
    >
      <DialogTitle onClose={handleClose}>
        { locales.t('settings.widgets.deleteModal.title') }
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText className={classes.desc}>
          <Typography variant="caption" color="error">
            { locales.t('settings.widgets.deleteModal.attention') }
          </Typography>
        </DialogContentText>
        <DialogContentText>
          <Typography variant="caption">
            { locales.t('settings.widgets.deleteModal.desc') }
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.dialogActionsWrap}>
        <Button
          className={classes.btnWrap}
          variant="contained"
          color="secondary"
          onClick={handleClose}
        >
          { locales.t('settings.widgets.deleteModal.declineBtn') }
        </Button>
        <Button
          className={classes.btnWrap}
          variant="contained"
          onClick={onClick}
          style={{ backgroundColor: '#f45858' }}
        >
          { locales.t('settings.widgets.deleteModal.deleteBtn') }
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(WidgetDeleteModal);
