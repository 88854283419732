import { IAppActions } from '../types/IAppActions';
import { ENQUEUE_SNACKBAR, REMOVE_SNACKBAR } from '../actions/snackbars/SnackBarsActionsTypes';

const defaultState = {
  notifications: [],
};

function SnackbarsReducer(state = defaultState, action: IAppActions): any {
  switch (action.type) {
    case ENQUEUE_SNACKBAR:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            ...action.notification,
          },
        ],
      };

    case REMOVE_SNACKBAR:
      return {
        ...state,
        notifications: state.notifications.filter(
          // @ts-ignore
          notification => notification.key !== action.key,
        ),
      };

    default:
      return state;
  }
}

export default SnackbarsReducer;
