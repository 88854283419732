import React from 'react';
import moment, { Moment } from 'moment';

import {
  WithStyles,
  withStyles,
  createStyles,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { DatePicker } from '@material-ui/pickers';

import { parseDateFromBookingDatePicker } from '../../../helpers/dateHelpers';

const styles = createStyles({
  textCenter: {
    textAlign: 'center',
    cursor: 'pointer',
  },
});

interface IProps extends WithStyles<typeof styles> {
  currentStatisticDate: string;
  handleChangeDate: (d: any) => void;
}

const BookingDatePicker: React.FC<IProps> = ({ classes, currentStatisticDate, handleChangeDate }) => {
  const date: {
    date: string;
    day?: string;
  } = parseDateFromBookingDatePicker(currentStatisticDate);

  return (
    <DatePicker
      autoOk
      variant="inline"
      value={moment(currentStatisticDate)}
      onChange={(date: Moment | null) => handleChangeDate(date && date.format())}
      TextFieldComponent={(props: any) => (
        <div
          ref={props.inputRef}
          className={classes.textCenter}
          onClick={props.onClick}
        >
          <Typography>
            { date.date }
          </Typography>
          <Typography variant="caption">
            { `(${date.day})` }
          </Typography>
        </div>
      )}
    />
  );
};

export default withStyles(styles)(BookingDatePicker);
