import React, { ChangeEvent, PureComponent } from 'react';
import { Field, WrappedFieldInputProps } from 'redux-form';
import moment from 'moment';

import {
  Box,
  createStyles,
  FormControl,
  FormLabel,
  InputAdornment,
  Tab,
  Tabs,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField/TextField';

import locales from '../../locales';
import TextFieldComp from '../Common/TextFieldComp';
import TextAreaComp from '../Common/TextAreaComp';
import PhoneFieldComp from './PhoneField';
import CircleCheckBox from '../Common/CircleCheckBox';
import { reserveModal, reserveSource } from '../../config/constants';
import { TEditReserveInfo } from '../../containers/ReservationPageEditCont/IReserveEditPage';
import { TConfirmReserveInfo } from '../../containers/ReservationPageConfirmCont/IReserveConfirmPage';
import ReserveSectionSelect from './ReserveSectionSelect';
import { IPropsSelectOption } from '../../reducers/selectors';
import { IWorkTime } from '../../types/IScheduleStore';

import ReserveDatePicker from '../Common/DatePickers/ReserveDatePicker';
import ReservePersonSelect from './ReservePersonSelect';
import ReserveTimeSelect from './ReserveTimeSelect';
import { TUpdateReserveInfo } from '../../types/IReserveModalStore';
import { parseDateForReq } from '../../helpers/dateHelpers';
import normalizeDurationHours from '../../helpers/normalizeDurationHours';

const StyledTab = withStyles({
  root: {
    minWidth: 80,
    fontSize: '16px',
  },
})(Tab);

const styles = (theme: Theme) => createStyles({
  wrap: {
    weight: '40%',
    backgroundColor: '#FFFFFF',
    overflow: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    // height: 'calc(100vh - 226px)',
    height: 'calc(100vh - 203px)',
    width: '100%',
    flexShrink: 0,
    maxWidth: 254,
    minWidth: 230,
    [theme.breakpoints.down('md')]: {
      maxWidth: 254,
      minWidth: 230,
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
      paddingBottom: 16,
      height: '100%',
    },
  },
  wrapContent: {
    padding: '0 24px',
    width: '100%',
  },
  tabs: {
    position: 'sticky',
    top: 0,
    backgroundColor: '#FFFFFF',
    boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.16)',
    zIndex: 1,
  },
  fieldStyle: {
    paddingTop: 24,
  },
  durationFieldStyle: {
    display: 'flex',
  },
  fieldEdit: {
    paddingTop: 30,
  },
});

export type ViewID = 'table' | 'scheme';

interface IProps extends WithStyles<typeof styles> {
  reserveInfo?: TEditReserveInfo | TConfirmReserveInfo | TUpdateReserveInfo;
  getFreeTables: (field: { value: string | number, type: string }) => void;
  isMergeTables: boolean;
  sections: Array<IPropsSelectOption>;
  handleMergeTables: () => void;
  handleErrorMsg?: (msg: string) => void;
  minutes?: number;
  type?: 'online' | 'new' | 'edit';
  locale?: string;
  viewId: ViewID;
  onViewChange: (table: ViewID) => void;

  timeList: Array<IWorkTime>;
  maxCapacity: number;

  onReservePropertyChange: (name: string, value: any) => void;
  disabled?: boolean;
  isBanquet: boolean;
}

class ReservesDataFields extends PureComponent<IProps> {
  onPropertyChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const { onReservePropertyChange } = this.props;

    onReservePropertyChange(name, value);
  }

  render() {
    const {
      disabled,
      reserveInfo,
      classes,
      getFreeTables,
      isMergeTables,
      sections,
      handleMergeTables,
      handleErrorMsg,
      minutes,
      locale,
      viewId,
      onViewChange,
      timeList,
      maxCapacity,
      isBanquet,
    } = this.props;

    const firstName = reserveInfo ? reserveInfo.firstName : null;
    // const lastName = reserveInfo ? reserveInfo.lastName : null;

    const phone = reserveInfo ? reserveInfo.phone : null;
    const type = (reserveInfo && 'type' in reserveInfo && reserveInfo.type) || reserveModal.NEW;
    const source = (reserveInfo && 'source' in reserveInfo && reserveInfo.source) || null;

    const canEdited = reserveInfo
      && source === reserveSource.RESTAURATEUR
      && type !== reserveModal.CONFIRM;

    // TODO Костыль на запрет редактирования банкетов
    const disabledEditBanquet = type === 'edit' && isBanquet;

    const banquetCheckBox = ({ input }: { input: WrappedFieldInputProps }) => (
      <CircleCheckBox
        label={locales.t('common.reserveModal.banquetCheckBox')}
        value={input.value}
        onChange={isBanquet && handleErrorMsg
          ? () => handleErrorMsg(locales.t('common.editReserveModal.banquetErrorMsg'))
          : input.onChange}
        isClickableLabel
        disabled={disabled || disabledEditBanquet}
      />
    );

    const readonly = reserveInfo
      && 'type' in reserveInfo
      && reserveInfo.type === reserveModal.CONFIRM;

    return (
      <div className={classes.wrap}>
        <Tabs
          className={classes.tabs}
          value={viewId}
          onChange={(event: any, value) => onViewChange(value)}
          scrollButtons="off"
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          centered
        >
          <StyledTab
            label={locales.t('common.reserveModal.list')}
            value="table"
          />
          <StyledTab
            label={locales.t('common.reserveModal.schema')}
            value="scheme"
          />
        </Tabs>
        <div className={classes.wrapContent}>
          <div style={{ paddingTop: 16 }}>
            {(readonly && (
              <TextField
                fullWidth
                name="persons"
                disabled
                value={reserveInfo && reserveInfo.persons}
                label={locales.t('common.reserveModal.persons')}
              />
            )) || (
              <Field
                disabled={disabled}
                name="persons"
                component={ReservePersonSelect}
                maxCapacity={maxCapacity}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                  const { onReservePropertyChange } = this.props;
                  const { name, value } = e.target;

                  onReservePropertyChange(name, value);
                  getFreeTables({ value, type: name });
                }}
              />
            )}
          </div>
          <div className={classes.fieldStyle}>
            {((readonly || (
              reserveInfo
                && 'source' in reserveInfo
                && reserveInfo.source !== reserveSource.RESTAURATEUR
            )) && (
              <TextField
                fullWidth
                name="date"
                value={reserveInfo && moment(reserveInfo.date).format('DD.MM.YYYY')}
                disabled
                label={locales.t('common.reserveModal.selectDate')}
              />
            )) || (
              <Field
                disabled={disabled}
                name="date"
                component={ReserveDatePicker}
                onChange={(date: any) => {
                  const { onReservePropertyChange } = this.props;
                  const value = parseDateForReq(date);

                  onReservePropertyChange('date', value);
                  getFreeTables({ type: 'date', value });
                }}
              />
            )}
          </div>
          <div className={classes.fieldStyle}>
            {((readonly || (
              reserveInfo
                && 'source' in reserveInfo
                && reserveInfo.source !== reserveSource.RESTAURATEUR
            )) && (
              <TextField
                fullWidth
                name="time"
                disabled
                value={reserveInfo && moment(reserveInfo.date).format('HH:mm')}
                label={locales.t('common.reserveModal.time')}
              />
            )) || (
              <Field
                name="time"
                component={ReserveTimeSelect}
                time={timeList || []}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                  const { onReservePropertyChange } = this.props;
                  const { name, value } = e.target;

                  onReservePropertyChange(name, value);
                  getFreeTables({ value, type: name });
                }}
              />
            )}
          </div>

          {/* <div style={{ paddingTop: 16 }}> */}
          <div className={classes.fieldStyle}>
            <Field
              disabled={disabled}
              name="section"
              component={ReserveSectionSelect}
              options={sections}
              allOptionDisabled={viewId === 'scheme'}
              onChange={this.onPropertyChange}
            />
          </div>

          {/* <div className={classes.fieldStyle}> */}
          {/*
          <div style={{ paddingTop: 14 }}>
            <Field
              name="name"
              component={TextFieldComp}
              staticValue={!canEdited && name}
              label={locales.t('common.reserveModal.name')}
              disabled={disabledEditBanquet}
            />
          </div>
          */}

          <Box mt={3}>
            <Field
              name="firstName"
              component={TextFieldComp}
              staticValue={!canEdited && firstName}
              disabled={disabled || disabledEditBanquet}
              label={locales.t('common.reserveModal.firstName')}
              required
              onChange={this.onPropertyChange}
            />
            {/*
            <Box width="50%" mr={1}>
              <Field
                name="firstName"
                component={TextFieldComp}
                staticValue={!canEdited && firstName}
                disabled={disabledEditBanquet}
                label={locales.t('common.reserveModal.firstName')}
                required
                onChange={this.onPropertyChange}
              />
            </Box>
            <Box width="50%">
              <Field
                name="lastName"
                component={TextFieldComp}
                staticValue={!canEdited && lastName}
                label={locales.t('common.reserveModal.lastName')}
                disabled={disabledEditBanquet}
                onChange={this.onPropertyChange}
              />
            </Box>
            */}
          </Box>

          <div className={classes.fieldStyle}>
            <Field
              name="phone"
              component={PhoneFieldComp}
              staticValue={!canEdited && phone}
              label={locales.t('common.reserveModal.phone')}
              locale={locale}
              disabled={disabled || disabledEditBanquet}
              required
              onChange={(value: any) => {
                const { onReservePropertyChange } = this.props;
                onReservePropertyChange('phone', value);
              }}
            />
          </div>

          {/* <div className={classes.fieldStyle}> */}
          <Box mt={5}>
            <CircleCheckBox
              label={locales.t('common.reserveModal.tableMerge')}
              // onChange={handleMergeTables}

              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                const { onReservePropertyChange } = this.props;
                const { checked } = e.target;

                onReservePropertyChange('isMergeTables', checked);

                handleMergeTables();
              }}

              value={isMergeTables}
              isClickableLabel
              disabled={disabled || disabledEditBanquet}
            />
          </Box>

          <div style={{ paddingTop: 4 }}>
            <Field
              name="isBanquet"
              component={banquetCheckBox}
              disabled={disabled || disabledEditBanquet}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                const { onReservePropertyChange } = this.props;
                const { checked } = e.target;

                onReservePropertyChange('isBanquet', checked);
              }}
            />
          </div>

          {/* <div className={classes.fieldStyle}> */}
          <Box mt={2}>
            <Field
              name="comment"
              component={TextAreaComp}
              rows={4}
              staticValue={type === reserveModal.CONFIRM}
              multiline
              label={locales.t('common.reserveModal.comment')}
              disabled={disabled || disabledEditBanquet}
              onChange={this.onPropertyChange}
            />
          </Box>

          {/*
          <div className={classes.fieldStyle}>
            <FormControl>
              <FormLabel style={{ fontSize: 10, marginBottom: 6 }}>
                {locales.t('common.reserveModal.duration')}
              </FormLabel>
              <div className={classes.durationFieldStyle}>
                <div style={{ marginRight: 12, width: '100%' }}>
                  <Field
                    name="hours"
                    component={TextFieldComp}
                    type="number"
                    onChange={
                      (e: ChangeEvent<HTMLSelectElement>) => getFreeTables({
                        value: e.target.value,
                        type: e.target.name,
                      })
                    }
                    staticValue={type === reserveModal.CONFIRM}
                    normalize={normalizeDurationHours}
                    endAdornment={(
                      <InputAdornment
                        position="end"
                      >
                        час.
                      </InputAdornment>
                    )}
                    disabled={disabledEditBanquet}
                  />
                </div>
                {/~~*
                <Field
                  name="minutes"
                  component={TextFieldComp}
                  type="number"
                  onChange={
                    (e: ChangeEvent<HTMLSelectElement>) => getFreeTables({ value: e.target.value, type: e.target.name })
                  }
                  staticValue
                  endAdornment={<InputAdornment position="end">мин.</InputAdornment>}
                />
                *~~/}
                <TextField
                  fullWidth
                  value={minutes || 0}
                  type={type}
                  disabled
                  InputProps={{
                    endAdornment: <InputAdornment position="end">мин.</InputAdornment>,
                  }}
                />
              </div>
            </FormControl>
          </div>
          */}

          <Box mt={2} mb={4}>
            <FormControl>
              <FormLabel style={{ fontSize: 10 }}>
                {locales.t('common.reserveModal.duration')}
              </FormLabel>
              <Box display="flex">
                <Box width="50%" mr={1}>
                  <Field
                    name="hours"
                    component={TextFieldComp}
                    type="number"
                    onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                      const { onReservePropertyChange } = this.props;
                      const { name, value } = e.target;

                      onReservePropertyChange(name, value);
                      getFreeTables({ value, type: name });
                    }}
                    staticValue={type === reserveModal.CONFIRM}
                    normalize={normalizeDurationHours}
                    endAdornment={<InputAdornment position="end">час.</InputAdornment>}
                    disabled={disabled || disabledEditBanquet}
                    errorAsHelperText
                  />
                </Box>
                <Box width="50%" pt={2}>
                  <TextField
                    fullWidth
                    value={minutes || 0}
                    type={type}
                    disabled
                    InputProps={{
                      endAdornment: <InputAdornment position="end">мин.</InputAdornment>,
                    }}
                    onChange={this.onPropertyChange}
                  />
                </Box>
              </Box>
            </FormControl>
          </Box>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ReservesDataFields);
