import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import {
  // Theme,
  makeStyles,
  Button,
} from '@material-ui/core';

import Reserve from '../../../types/IAppReservesStore';
import { parsePhoneNumber } from '../../../helpers/reserveHelpers';

// const useStyles = makeStyles((theme: Theme) => ({
const useStyles = makeStyles(() => ({
  actionButton: {
    boxShadow: 'none',
    borderRadius: 0,
  },
  buttonBorder: {
    borderRight: '1px solid #e7ebee',
  },
  reserveButton: {
    backgroundColor: '#e7ebee',

    '& .MuiButton-label': {
      color: 'rgba(50, 50, 50, 0.5)',
      width: '100%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'inline-block',
    },

    '&:hover .MuiButton-label': {
      color: 'rgba(50, 50, 50, 1)',
    },
  },
  activeReserve: {
    backgroundColor: 'white',
    '& .MuiButton-label': {
      color: 'rgba(50, 50, 50, 1)',
    },
  },
  reserveWarning: {
    backgroundColor: 'rgba(244, 88, 88, 0.2)',
    transition: 'background 3s ease',
    '& .MuiButton-label': {
      transition: 'color 3s ease',
      color: 'rgba(50, 50, 50, 1)',
    },
  },
  reserveWarningOn: {
    backgroundColor: '#e7ebee',
    '& .MuiButton-label': {
      color: 'rgba(50, 50, 50, 0.5)',
    },
  },
  addButton: {
    '& svg': {
      fontSize: 28,
    },
  },
}));

interface IProps {
  reserve: Reserve;
  onClick: () => void;
  open: boolean;
  width?: number;
}


const NavItem: React.FunctionComponent<IProps> = ({
  reserve,
  onClick,
  open,
  width,
}) => {
  const [params, setParams] = useState({
    attention: false,
    warning: false,
  });

  const { attention, warning } = params;

  useEffect(() => {
    setParams(prevState => ({
      ...prevState,
      attention: !open && reserve.type === 'WIDGET' && reserve.outerChangeType !== 'CANCELED',
    }));
  }, [open, reserve.type]);

  useEffect(() => {
    const interval = setInterval(() => {
      setParams(prevState => ({
        ...prevState,
        warning: prevState.attention,
      }));

      setTimeout(() => {
        setParams(prevState => ({
          ...prevState,
          warning: false,
        }));
      }, 3000);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const {
    actionButton,
    buttonBorder,
    reserveButton,
    activeReserve,
    reserveWarning,
    reserveWarningOn,
  } = useStyles();

  const cssNames = classNames([
    actionButton,
    buttonBorder,
    reserveButton,
    open && activeReserve,
    attention && reserveWarning,
    attention && warning && reserveWarningOn,
  ]);

  const title = [];
  const name = [];

  if (reserve.payload) {
    const { firstName, lastName, phone } = reserve.payload;

    if (firstName) {
      name.push(firstName);
    }

    if (lastName) {
      name.push(lastName);
    }

    if (name.length || phone) {
      title.push(name.join(' '));
      title.push((phone && parsePhoneNumber(phone)) || '');
    }
  }

  return (
    <Button
      onClick={() => onClick()}
      disabled={open}
      className={cssNames}
      style={width ? { width } : {}}
    >
      {(title && title.join(', ')) || 'Новый резерв'}
    </Button>
  );
};

export default NavItem;
