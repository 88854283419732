export const OPEN_EDIT_RESERVE_MODAL = 'OPEN_EDIT_RESERVE_MODAL';
export const CLOSE_EDIT_RESERVE_MODAL = 'CLOSE_EDIT_RESERVE_MODAL';

export const UPDATE_RESERVE_REQUEST = 'UPDATE_RESERVE_REQUEST';
export const UPDATE_RESERVE_SUCCESS = 'UPDATE_RESERVE_SUCCESS';
export const UPDATE_RESERVE_ERROR = 'UPDATE_RESERVE_ERROR';

export const GET_FREE_TABLES_REQUEST = 'GET_FREE_TABLES_REQUEST';
export const GET_FREE_TABLES_SUCCESS = 'GET_FREE_TABLES_SUCCESS';
export const GET_FREE_TABLES_ERROR = 'GET_FREE_TABLES_ERROR';

export const OPEN_CREATE_RESERVE_MODAL = 'OPEN_CREATE_RESERVE_MODAL';
export const CLOSE_CREATE_RESERVE_MODAL = 'CLOSE_CREATE_RESERVE_MODAL';

export const OPEN_CONFIRM_RESERVE_MODAL = 'OPEN_CONFIRM_RESERVE_MODAL';
export const CLOSE_CONFIRM_RESERVE_MODAL = 'CLOSE_CONFIRM_RESERVE_MODAL';

export const CREATE_RESERVE_REQUEST = 'CREATE_RESERVE_REQUEST';
export const CREATE_RESERVE_ERROR = 'CREATE_RESERVE_ERROR';
export const CREATE_RESERVE_SUCCESS = 'CREATE_RESERVE_SUCCESS';

export const CONFIRM_RESERVE_REQUEST = 'CONFIRM_RESERVE_REQUEST';
export const CONFIRM_RESERVE_SUCCESS = 'CONFIRM_RESERVE_SUCCESS';
export const CONFIRM_RESERVE_ERROR = 'CONFIRM_RESERVE_ERROR';

export const GET_RESERVE_INFO_SUCCESS = 'GET_RESERVE_INFO_SUCCESS';
export const GET_RESERVE_INFO_ERROR = 'GET_RESERVE_INFO_ERROR';

// export const SET_RESERVE_TO_QUEUE = 'SET_RESERVE_TO_QUEUE';
// export const UPDATE_RESERVE_QUEUE = 'UPDATE_RESERVE_QUEUE';

export const REJECT_NEW_RESERVE_REQUEST = 'REJECT_NEW_RESERVE_REQUEST';
export const REJECT_NEW_RESERVE_SUCCESS = 'REJECT_NEW_RESERVE_SUCCESS';
export const REJECT_NEW_RESERVE_ERROR = 'REJECT_NEW_RESERVE_ERROR';

export const SET_ACTIVE_TABLE_REQ = 'SET_ACTIVE_TABLE_REQ';
export const SET_ACTIVE_TABLE_SUCCESS = 'SET_ACTIVE_TABLE_SUCCESS';
export const SET_ACTIVE_TABLE_ERROR = 'SET_ACTIVE_TABLE_ERROR';
export const RESET_ACTIVE_TABLE = 'RESET_ACTIVE_TABLE';

export const SET_MAX_CAPACITY = 'SET_MAX_CAPACITY';

export const MERGE_TABLES_REQ = 'MERGE_TABLES_REQ';
export const MERGE_TABLES_SUCCESS = 'MERGE_TABLES_SUCCESS';
export const MERGE_TABLES_ERROR = 'MERGE_TABLES_ERROR';

export const FETCH_OPEN_RESERVES = 'FETCH_OPEN_RESERVES';
export const FETCH_OPEN_RESERVES_SUCCESS = 'FETCH_OPEN_RESERVES_SUCCESS';
export const FETCH_OPEN_RESERVES_ERROR = 'FETCH_OPEN_RESERVES_ERROR';
export const RESET_OPEN_RESERVES = 'RESET_OPEN_RESERVES';

export const ADD_OPEN_RESERVE = 'ADD_OPEN_RESERVE';
export const REMOVE_OPEN_RESERVE = 'REMOVE_OPEN_RESERVE';

export const CONFIRM_OPEN_RESERVE = 'CONFIRM_OPEN_RESERVE';
export const CONFIRM_OPEN_RESERVE_SUCCESS = 'CONFIRM_OPEN_RESERVE_SUCCESS';
export const CONFIRM_OPEN_RESERVE_ERROR = 'CONFIRM_OPEN_RESERVE_ERROR';

export const REJECT_OPEN_RESERVE = 'REJECT_OPEN_RESERVE';
export const REJECT_OPEN_RESERVE_SUCCESS = 'REJECT_OPEN_RESERVE_SUCCESS';
export const REJECT_OPEN_RESERVE_ERROR = 'REJECT_OPEN_RESERVE_ERROR';
