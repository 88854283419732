const audio = require('../audio/GuestMe-Bell.wav');

// type notification = 'new_reserve' | 'new_call';
type notification = 'new_reserve';

class Sound {
  sound: HTMLAudioElement;

  constructor(src: string) {
    this.sound = document.createElement('audio');
    this.sound.src = src;
    this.sound.setAttribute('preload', 'auto');
    this.sound.setAttribute('controls', 'none');
    this.sound.style.display = 'none';

    document.body.appendChild(this.sound);
  }

  play() {
    return this.sound.play();
  }

  stop() {
    this.sound.pause();
  }

  destroy() {
    document.body.removeChild(this.sound);
  }
}

/** @description Аудио оповещение */
export function audioNotification(type: notification) {
  switch (type) {
    // case 'new_call':
    case 'new_reserve': {
      const ring = new Sound(audio);
      const playPromise:Promise<void> = ring.play();

      if (playPromise !== undefined) {
        playPromise
          .then(() => setTimeout(() => ring.destroy(), 3000))
          .catch(() => ring.destroy());
      }

      break;
    }
    default: break;
  }
}
