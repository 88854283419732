import {
  put,
  takeLatest,
  // takeLeading,
  all,
  call,
  select,
  // delay,
} from 'redux-saga/effects';

import * as Sentry from '@sentry/react';

import {
  GET_RESTAURANT_LIST_SUCCESS,
  SET_CURRENT_RESTAURANT,
} from '../actions/cabinet/CabinetActionsTypes';

import {
  FETCH_RESTAURANT_CALLS,
  REMOVE_RESTAURANT_CALL,
  // ADD_RESTAURANT_CALL,
  // NOTIFY_RESTAURANT_CALL,
  // INCREASE_NOTIFICATION_INTERVAL_RESTAURANT_CALL,
} from '../actions/restaurantsCalls/RestaurantsCallsActionsTypes';

import {
  IFetchRestaurantCalls,
  IRemoveRestaurantCall,
} from '../actions/restaurantsCalls/IRestaurantsCallsActions';

// import { IRestaurantCall, TCalls } from '../types/IRestaurantCallsStore';
import { getRestaurantCallsReq } from '../api';
// import { audioNotification } from '../helpers/notificationHelpers';
// import { isDismissed } from '../helpers/callHelpers';

import {
  fetchRestaurantCalls,
  fetchRestaurantCallsSuccess,
  fetchRestaurantCallsError,
  resetRestaurantCalls,
  // notifyRestaurantCall,
  // increaseCallNotificationInterval,
} from '../actions/restaurantsCalls/RestaurantsCallsActions';

// import { callsMockSliced } from '../api/mocks/calls';

import { removeNotification } from '../actions/notifications/NotificationsActions';
import { TNotification } from '../types/INotificationsStore';
import { selectorRestaurantId } from '../selectors/RestaurantSelectors';

function* fetchRestaurantCallsSaga(action: IFetchRestaurantCalls) {
  try {
    const {
      restaurantId,
      states,
      page,
      perPage,
    } = action.payload;

    const response = yield call(getRestaurantCallsReq, restaurantId, states, page, perPage);
    if (!response || !response.content) {
      Sentry.captureMessage('Unexpected response');
      yield put(fetchRestaurantCallsError('Unexpected response'));
      return;
    }

    // // @todo Убрать после тестирования
    // if (process.env.NODE_ENV === 'development' && response.content.length === 0) {
    //   response.content = callsMockSliced;
    // }

    yield put(fetchRestaurantCallsSuccess(response.content));

    // if (relevantCalls.length) {
    //   yield put(notifyRestaurantCall());
    // }
  } catch (e) {
    Sentry.captureException(e);
    yield put(fetchRestaurantCallsError(e.message));
  }
}

function* removeRestaurantCallSaga(action: IRemoveRestaurantCall) {
  const notification = yield select(state => state.notifications.items.find(
    (x: TNotification) => x.type === 'CALL' && x.payload.id === action.id,
  ));

  if (notification) {
    yield put(removeNotification(notification.getKey(), action.manually));
  }
}

function* updateCurrentRestaurantCallsSaga() {
  const restaurantId = yield select(selectorRestaurantId);
  if (restaurantId) {
    yield put(
      fetchRestaurantCalls({
        restaurantId: restaurantId,
        states: ['ALERTING', 'ACTIVE'],
      }),
    );
  } else {
    yield put(resetRestaurantCalls());
  }
}

export default function* saga() {
  yield all([
    takeLatest(FETCH_RESTAURANT_CALLS, fetchRestaurantCallsSaga),
    takeLatest(REMOVE_RESTAURANT_CALL, removeRestaurantCallSaga),

    takeLatest([
      GET_RESTAURANT_LIST_SUCCESS,
      SET_CURRENT_RESTAURANT,
    ], updateCurrentRestaurantCallsSaga),
  ]);
}
