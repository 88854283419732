import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton/IconButton';

// eslint-disable-next-line import/no-unresolved
import { IGeoObject } from 'yandex-maps';

import { IProps, IState } from './ICreateRestaurantModal';
import locales from '../../../locales';
import styles from './styles';

import SearchAddress from './Yandex/SearchAddress';
import YandexMapComponent from './Yandex/Map';
import { IRestaurantAddress } from '../../../types/ICabinetStore';


let geocoderTimeout: any;

const defaultState: IState = {
  address: '',
  name: '',
  yandexGeoObject: null,
  coordinates: null,
};

class CreateRestaurantModalComp extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      ...defaultState,
    };
  }

  componentWillUnmount() {
    window.clearTimeout(geocoderTimeout);
  }

  setName = (name: string) => {
    this.setState({ name });
  };

  setAddress = (address: string) => {
    this.setState({ address });

    window.clearTimeout(geocoderTimeout);
    if (address.length > 0) {
      geocoderTimeout = window.setTimeout(() => {
        const geocoder = window.ymaps.geocode(address, { results: 1 });
        geocoder.then((result: any) => {
          const objects = result.geoObjects;
          const geoObject: IGeoObject = objects.get(0);
          const metaDataProperty = geoObject.properties.get(
            'metaDataProperty.GeocoderMetaData',
            {
              precision: 'other',
            },
          );

          // @ts-ignore
          const { precision } = metaDataProperty;

          if (precision === 'exact') {
            this.setState({
              yandexGeoObject: geoObject,
              coordinates: objects.properties.get('metaDataProperty.GeocoderResponseMetaData.Point.coordinates'),
            });
          } else {
            this.setState({
              yandexGeoObject: null,
              coordinates: null,
            });
          }
        });
      }, 1000);
    }
  };

  handleAddRestaurant = (): void => {
    const { name, yandexGeoObject, coordinates } = this.state;
    const { addRestaurant, closeCreateModal } = this.props;

    if (!name || !yandexGeoObject || !coordinates || coordinates.length !== 2) {
      return;
    }

    const geoData = yandexGeoObject.properties.get(
      'metaDataProperty.GeocoderMetaData',
      {},
    );

    // @ts-ignore
    // eslint-disable-next-line no-prototype-builtins
    if (!geoData.hasOwnProperty('Address') || !geoData.hasOwnProperty('kind') || geoData.kind !== 'house') {
      return;
    }

    // @ts-ignore
    const geoAddress = geoData.Address;

    const address: IRestaurantAddress = {
      country: '',
      countryCode: geoAddress.country_code,
      city: '',
      street: '',
      home: '',
      coordinates: {
        lon: coordinates[0],
        lat: coordinates[1],
      },
    };

    geoAddress.Components.forEach((c: any) => {
      if (c.kind === 'country') {
        address.country = c.name;
      } else if (c.kind === 'locality') {
        address.city = c.name;
      } else if (c.kind === 'street') {
        address.street = c.name;
      } else if (c.kind === 'house') {
        address.home = c.name;
      }
    });

    addRestaurant({ name, address });
    closeCreateModal();
  };

  handleCloseDialog = () => {
    const { closeCreateModal } = this.props;

    closeCreateModal();
  };

  render() {
    const { modalOpen, classes, createRestaurantError } = this.props;
    const { address, name, yandexGeoObject } = this.state;

    return (
      <Dialog
        open={modalOpen}
        aria-labelledby="form-dialog-title"
        maxWidth="xl"
      >
        <div className={classes.contentWrap}>
          <DialogTitle id="form-dialog-title">
            {locales.t('home.addFormTitle')}
            <IconButton className={classes.closeButton} aria-label="Close" onClick={this.handleCloseDialog}>
              <CloseIcon color="primary" />
            </IconButton>
          </DialogTitle>
          <DialogContent className={classes.content}>
            <SearchAddress
              address={address}
              onChangeAddress={this.setAddress}
              name={name}
              onChangeName={this.setName}
            />
            <YandexMapComponent geoObject={yandexGeoObject} />
          </DialogContent>

          {createRestaurantError && (
            <div className={classes.error}>
              <Typography variant="body1">{createRestaurantError}</Typography>
            </div>
          )}

          <DialogActions className={classes.actions}>
            <Button
              onClick={this.handleCloseDialog}
              className={`${classes.button} ${classes.reject}`}
              variant="contained"
            >
              {locales.t('common.reject')}
            </Button>
            <Button
              onClick={this.handleAddRestaurant}
              color="primary"
              className={classes.button}
              variant="contained"
              disabled={!name || !yandexGeoObject}
            >
              {locales.t('common.add')}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    );
  }
}

export default withStyles(styles)(CreateRestaurantModalComp);
