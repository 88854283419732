import React, { useEffect, useState, useCallback } from 'react';
import Countdown from 'react-countdown-now';
import { primaryColor } from '../theme';

type IProps = {
  onReconnect: () => void
}

/**
 * @description Simple wrapper around Countdown, remove delay before initial call
 */
const CountdownWrapper: React.FC<IProps> = ({
  onReconnect,
}) => {
  const [isInit, setInit] = useState<boolean>(true);
  const onComplete = useCallback(() => {
    onReconnect();
    setInit(false);
  }, []);
  useEffect(() => {
    if (isInit) onComplete();
  }, []);

  return (
    <Countdown
      date={Date.now() + (isInit ? 1 : 5000)}
      renderer={(props:any) => (<span style={{ color: primaryColor }}>{ `${props.seconds}c` }</span>)}
      onComplete={onComplete}
    />
  );
};

export default CountdownWrapper;
