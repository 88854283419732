import _ from 'lodash';

import { emptyTime, reserveState } from '../config/constants';
import { TReserve } from '../types/IBookingStore';
import { getBookingStatus } from './dateHelpers';
import { ITable } from '../types/IRestaurantTablesStore';
import { TReserveFormCurrentData } from '../components/Reservations';
import { TConfirmReserveInfo } from '../containers/ReservationPageConfirmCont/IReserveConfirmPage';

/** @description Парсинг номера стола
 *  - если число добавляем "Зал :"
 *  - если строка отображаем как есть
 */
export function parseTableHall(hall?: string): string {
  if (!hall) {
    return '';
  }

  return `${Number.isNaN(parseInt(hall)) ? hall : `Зал ${hall}`}`;
}

/** @description Парсинг объеденнёных столов */
export function parsingMergedTables(tables: Array<ITable> | undefined) {
  if (tables === undefined || tables.length === 0) {
    return '-';
  }

  return tables
    .reduce(
      (s, t, i) => `${s} ${t.number}${i > tables.length || i < tables.length - 1 ? ',' : ''}`,
      `${tables[0].hall}: `,
    );
}

/** @description Проверка на завтра */
export function itsTomorrow(open: string, reserveTime: string): boolean {
  return parseInt(open) > parseInt(reserveTime);
}

/** @description Проверка что все необходимые поля заполнены */
export const validateReserveFields = ({
  date, persons, hours, time,
}: TReserveFormCurrentData) => (
  date && date !== emptyTime
  && time && time !== emptyTime
  && persons
  && hours
);

/** @description Проверка что все необходимые поля заполнены для запроса на подтверждение */
export const validateConfirmReserveFields = ({
  date, duration, persons,
}: TConfirmReserveInfo) => (
  date && date !== emptyTime
  && persons
  && duration
);

/** @description Парсинг списка резервов из респонса
 * - Выставляем статусы
 * - Назначаем резерву группу для фильтрации
 */
export function parseReserves(reserves: Array<TReserve>): Array<TReserve> {
  return reserves.map((reserve: TReserve) => {
    switch (reserve.state) {
      case reserveState.CANCELING:
      case reserveState.CANCELED: {
        const canceledBy = reserve.canceledBy || reserveState.UNKNOWN;
        return {
          ...reserve,
          state: `${canceledBy}_DECLINE`,
          group: 'declined',
        };
      }
      case reserveState.ACCEPTED: {
        return {
          ...reserve,
          state: getBookingStatus(reserve),
          group: 'accepted',
        };
      }
      case reserveState.DECLINED: {
        return {
          ...reserve,
          group: 'rejected',
        };
      }
      case reserveState.IN_PROGRESS: {
        return {
          ...reserve,
          group: 'open',
        };
      }
      case reserveState.CLOSED: {
        return {
          ...reserve,
          group: 'closed',
        };
      }
      case reserveState.ERROR:
      case reserveState.CREATED:
      case reserveState.SENT:
      case reserveState.PATCHING: {
        return {
          ...reserve,
          group: 'service',
        };
      }
      default: {
        return reserve;
      }
    }
  });
}

/**
 * @description Сравнение списка столов
 * @param a
 * @param b
 */
export function compareTables(a: ITable[], b?: ITable[]): boolean {
  if (!a || !b) {
    return false;
  }

  const f = a.map(t => t.id).filter((f, s) => f > s);
  const s = b.map(t => t.id).filter((f, s) => f > s);

  return _.isEqual(f, s);
}
