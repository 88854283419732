const host = process.env.REACT_APP_BACKEND_URL_VAR;
const api = `${host}/api`;
const account = `${api}/account/current`;
const token = `${host}/oauth/token`;
const confirmEmail = `${api}/account/confirmation/email`;
const confirmEmailResend = `${api}/account/confirmation/email/request`;
const registerRestaurateur = `${api}/user/restaurateur`;
const restaurant = `${api}/restaurant`;
const logout = `${api}/logout`;
const restaurantTable = `${api}/restaurant-table/restaurant`;
const restaurateur = `${api}/restaurateur`;
const restaurateurBooking = `${restaurateur}/booking`;
const restaurantWidget = `${api}/restaurant-widget`;
const bookingJournal = `${restaurateur}/restaurant`;
const statistic = `${restaurateur}/restaurant`;
const statisticSummary = `${statistic}`;
const statisticPie = `${statistic}`;
const statisticGuest = `${statistic}`;
const userInfo = `${api}/user/phone`;
const websocket = `${host}/websocket`;
const programs = `${api}/program`;
const loadsPrograms = `${programs}/restaurant`;
const menuRestaurant = `${api}/menu/restaurant`;
const restaurantSchedule = `${api}/restaurant-schedule`;
const restaurantScheduleGroup = `${restaurantSchedule}/group`;
const restaurantScheduleGroups = `${restaurantSchedule}/groups`;
const restaurantScheduleExclusions = `${restaurantSchedule}/group/common-with-exclusion`;
const restorePasswordInit = `${api}/account/reset-password/init`;
const restorePasswordFinish = `${api}/account/reset-password/finish`;
const sendConfirmCode = `${api}/account/confirmation/code/request`;
const widgetBooking = `${api}/widget/booking`;
const restaurantCalls = `${api}/restaurant`;

export {
  account,
  bookingJournal,
  confirmEmail,
  confirmEmailResend,
  logout,
  loadsPrograms,
  menuRestaurant,
  programs,
  restaurant,
  restaurateur,
  restaurateurBooking,
  restaurantTable,
  restaurantSchedule,
  restaurantScheduleGroup,
  restaurantScheduleGroups,
  restaurantScheduleExclusions,
  restaurantWidget,
  registerRestaurateur,
  restorePasswordInit,
  restorePasswordFinish,
  statistic,
  statisticSummary,
  statisticPie,
  statisticGuest,
  sendConfirmCode,
  token,
  userInfo,
  websocket,
  widgetBooking,
  restaurantCalls,
};
