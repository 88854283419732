import { all, put, select, takeLatest } from 'redux-saga/effects';
import _ from 'lodash';

import * as SchedulesActionTypes from '../actions/schedules/SchedulesActionTypes';
import {
  deleteScheduleRowError,
  deleteScheduleRowSuccess,
  getScheduleListError,
  getScheduleListSuccess,
  updateScheduleError,
  getScheduleList,
  updateScheduleSuccess,
  forceUpdateBookingSchedule,
  forceDeleteBookingSchedule,
} from '../actions/schedules/SchedulesAction';
import { IAppStore } from '../types/IAppStore';
import { IScheduleGroup, IScheduleRow } from '../types/IScheduleStore';
import {
  createScheduleGroupReq,
  getScheduleGroupReq,
  deleteScheduleGroupReq,
  updateScheduleGroupReq,
} from '../api';
import {
  getSchedulesRows,
  getNewGroupForCreate,
  getScheduleForDelete,
  getGroupsForUpdate,
  getBookingGroupsForForceUpdate,
} from '../helpers/scheduleHelpers';
import {
  IDeleteScheduleRow,
  IForceDeleteBookingSchedule,
  IForceUpdateBookingSchedule,
  IGetScheduleList,
  IUpdateSchedule,
} from '../actions/schedules/ISchedulesAction';
import { customMessageShackBar } from '../actions/snackbars/SnackBarsActions';
import { GET_RESTAURANT_LIST_SUCCESS, SET_CURRENT_RESTAURANT } from '../actions/cabinet/CabinetActionsTypes';
import { GET_SCHEDULE_LIST_REQUEST } from '../actions/schedules/SchedulesActionTypes';
import { selectorRestaurantId } from '../selectors/RestaurantSelectors';

/** @description Загрузка расписания ресторана. */
function* getScheduleSaga(action: IGetScheduleList) {
  let restaurantId = yield select(selectorRestaurantId);

  if (action.type === GET_SCHEDULE_LIST_REQUEST && action.payload.restaurantId) {
    restaurantId = action.payload.restaurantId;
  }

  try {
    const { restaurantSchedules, bookingSchedules } = yield getScheduleGroupReq(restaurantId)
      .then((r: Array<IScheduleGroup>) => r.reduce((obj: {
        restaurantSchedules: Array<IScheduleGroup>;
        bookingSchedules: Array<IScheduleGroup>;
      }, schedule: IScheduleGroup) => {
        if (schedule && !schedule.date) {
          if (schedule.type === 'RESTAURANT') {
            return {
              ...obj,
              restaurantSchedules: obj.restaurantSchedules.concat([schedule]),
            };
          }

          return {
            ...obj,
            bookingSchedules: obj.bookingSchedules.concat([schedule]),
          };
        }
        return {
          ...obj,
        };
      }, { restaurantSchedules: [], bookingSchedules: [] }));

    yield put(getScheduleListSuccess({
      bookingSchedules,
      restaurantSchedules,
      bookingRows: getSchedulesRows(bookingSchedules),
      restaurantRows: getSchedulesRows(restaurantSchedules),
    }));
  } catch (e) {
    yield put(getScheduleListError());
  }
}

/** @description Сага удаления расписания. */
function* deleteScheduleRowSaga(action: IDeleteScheduleRow) {
  const { row, field } = action.payload;
  const scheduleGroupField = `${field}Schedules`;

  const groups = yield select(state => state.schedule[scheduleGroupField]);

  try {
    const ids = row.days.reduce((ids: Array<number>, d: string) => {
      const group = _.find(groups, { day: d });

      if (group.id) {
        return [...ids, group.id];
      }

      return ids;
    }, []);

    if (ids.length > 0) {
      yield deleteScheduleGroupReq(ids);
    }

    yield put(deleteScheduleRowSuccess(
      action.payload.row,
      action.payload.field,
    ));
  } catch (e) {
    yield put(deleteScheduleRowError());
  }

  // Если удаляем строку из расписания работы ресторана то удаляем те же дни и для автоброни
  if (scheduleGroupField === 'restaurantSchedules') {
    yield put(forceDeleteBookingSchedule(row.days));
  }
}

/** @description Сага для создания и редактирования расписания. */
function* updateScheduleSaga(action: IUpdateSchedule) {
  const { row, field } = action.payload;
  const groupType = field === 'booking' ? 'BOOKING' : 'RESTAURANT';
  const restaurantId = yield select(state => state.cabinet.currentRestaurant.id);
  const initRow: IScheduleRow = yield select(
    state => _.find(state.schedule[`${field}Rows`], { id: row.id }),
  );
  const scheduleGroups: Array<IScheduleGroup> = yield select(
    state => state.schedule[`${field}Schedules`],
  );

  const groupsForCreate = getNewGroupForCreate(row, initRow.days, restaurantId, groupType);
  const groupsIdForDelete = getScheduleForDelete(row.days, initRow.days, scheduleGroups);
  const groupsForUpdate = getGroupsForUpdate(row, scheduleGroups, initRow);

  try {
    // Редактирование группы расписания
    if (!_.isEmpty(groupsForUpdate)) {
      const updatedSchedules = yield updateScheduleGroupReq(groupsForUpdate);

      yield put(updateScheduleSuccess(
        field,
        row,
        _.unionBy(updatedSchedules, scheduleGroups, 'id'),
      ));

      // Редактируем расписание автоброни
      if (field === 'restaurant') {
        const days = groupsForUpdate.map(d => d.day); // выбираем только дни недели;

        yield put(forceUpdateBookingSchedule({
          type: 'update',
          days,
          times: {
            start: row.start,
            end: row.end,
          },
        }));
      }
    }

    // Создание новой группы расписания
    if (!_.isEmpty(groupsForCreate)) {
      const newGroups = yield createScheduleGroupReq(groupsForCreate);
      yield put(updateScheduleSuccess(
        field,
        row,
        scheduleGroups
          .concat(newGroups),
      ));
    }

    // Удаление группы расписания
    if (!_.isEmpty(groupsIdForDelete)) {
      yield deleteScheduleGroupReq(groupsIdForDelete);

      yield put(updateScheduleSuccess(
        field,
        row,
        scheduleGroups
          .filter(g => g.id && groupsIdForDelete.indexOf(g.id) === -1),
      ));

      // Удаляем из расписания автоброни дни которые были удалены из основного расписания
      if (field === 'restaurant') {
        const days = scheduleGroups
          .filter((g: any) => groupsIdForDelete.indexOf(g.id) !== -1) // Находим группы по id
          .map(d => d.day); // Возвращаем только дни недели

        yield put(forceUpdateBookingSchedule({
          type: 'delete',
          days,
        }));
      }
    }

    yield put(customMessageShackBar('Расписание отредактировано'));
  } catch (e) {
    // Обработка ошибки
    if (e.response.data[0].code === 'time_range_overlap') {
      yield put(updateScheduleError(
        'Расписание пересекатеся с ранее созданным',
        row.id,
        field,
      ));
    } else {
      // Дефолтная ошибка
      yield put(updateScheduleError());
      // Перезапрашиваем список расписаний
      yield put(getScheduleList());
    }
  }
}

/** @description Сага для удаления расписания автоброни в кейсах с редактирования времени работы ресторана */
function* forceDeleteBookingScheduleSaga(action: IForceDeleteBookingSchedule) {
  const { days } = action.payload;

  const groups = yield select(state => state.schedule.bookingSchedules);

  const ids = days.reduce((ids: Array<number>, d: string) => {
    const group = _.find(groups, { day: d });

    if (group && group.id) {
      return [...ids, group.id];
    }

    return ids;
  }, []);

  if (ids.length > 0) {
    yield deleteScheduleGroupReq(ids);
  }

  yield put(getScheduleList());
}

/** @description Сага для обновления расписания автоброни в кейсах с редактирования времени работы ресторана */
function* forceUpdateBookingScheduleSaga(action: IForceUpdateBookingSchedule) {
  const groups = yield select((state: IAppStore) => state.schedule.bookingSchedules);

  if (action.payload.type === 'delete') {
    const { days } = action.payload;
    const idForDelete = groups
      .filter((g: any) => days.indexOf(g.day) > -1)
      .map((g: any) => g.id);

    if (idForDelete.length > 0) {
      yield deleteScheduleGroupReq(idForDelete);

      yield put(getScheduleList());
    }
  }

  if (action.payload.type === 'update') {
    const { days, times } = action.payload;

    if (days.length > 0) {
      const changedDays = groups.filter((g: any) => days.indexOf(g.day) > -1);

      if (changedDays.length > 0) {
        const groupsForForceUpdate = getBookingGroupsForForceUpdate(days, groups, times);

        if (groupsForForceUpdate.length > 0) {
          yield updateScheduleGroupReq(groupsForForceUpdate);
          yield put(getScheduleList());
        }
      }
    }
  }
}

export default function* saga() {
  yield all([
    takeLatest([
      SchedulesActionTypes.GET_SCHEDULE_LIST_REQUEST,
      SET_CURRENT_RESTAURANT,
      GET_RESTAURANT_LIST_SUCCESS,
    ], getScheduleSaga),

    takeLatest(SchedulesActionTypes.DELETE_SCHEDULE_ROW_REQUEST, deleteScheduleRowSaga),
    takeLatest(SchedulesActionTypes.UPDATE_SCHEDULE_REQUEST, updateScheduleSaga),
    takeLatest(SchedulesActionTypes.FORCE_DELETE_BOOKING_SCHEDULE, forceDeleteBookingScheduleSaga),
    takeLatest(SchedulesActionTypes.FORCE_UPDATE_BOOKING_SCHEDULE, forceUpdateBookingScheduleSaga),
  ]);
}
