import React, { PureComponent } from 'react';

import ReservesSystemSchema
  from '../../components/ReservesSystem/ReservesSystemSchema';
import { IProps } from './IReservesSystemInfo';
import { TNewInfo } from '../../types/IRestaurantInfoStore';

class ReservesSystemSchemaCont extends PureComponent<IProps, any> {
  componentDidMount() {
    const { currentRestaurant } = this.props;
    if (currentRestaurant) {
      this.handleGetSectionsList(currentRestaurant.id);
    }
  }

  /** @description Запрос списка столов для в случае изменения выбранного рестоарана */
  componentDidUpdate(prevProps: IProps): void {
    const { currentRestaurant } = this.props;
    if (currentRestaurant && prevProps.currentRestaurant !== currentRestaurant) {
      this.handleGetSectionsList(currentRestaurant.id);
    }
  }

  /** @description Ручка для экшена для получения всех залов для выбранного ресторана */
  handleGetSectionsList = (id: number) => {
    const { getSections } = this.props;
    getSections(id);
  };

  /** @description Ручка для редактирования полей ресторана */
  handleUpdateRestaurantSettings = (data: TNewInfo) => {
    const { currentRestaurant, updateRestaurantInfo } = this.props;

    if (currentRestaurant) {
      const updateData = {
        ...data,
        currentRestaurant,
      };

      updateRestaurantInfo(updateData);
    }
  };

  handleSwitchSection = (
    event: React.ChangeEvent<{ name?: string; value: number }>,
  ) => {
    const { switchSectionId } = this.props;
    if (event && event.target) {
      switchSectionId(event.target.value);
    }
  };

  render() {
    const {
      currentRestaurant, sections,
      sectionId, schema,
    } = this.props;

    if (!currentRestaurant) {
      return null;
    }

    return (
      <ReservesSystemSchema
        currentRestaurant={currentRestaurant}
        handleUpdateRestaurantSettings={this.handleUpdateRestaurantSettings}
        sections={sections}
        sectionId={sectionId}
        schema={schema}
        handleSwitchSection={this.handleSwitchSection}
      />
    );
  }
}

export default ReservesSystemSchemaCont;
