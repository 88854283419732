import _ from 'lodash';
import { TMenuCategory } from '../types/IMenuStore';

/** @description Парсим вес позиций из меню w - вес, t - тип */
export function weightParsing(w: number, t: string): string {
  switch (t) {
    case 'KILOGRAM': {
      return `${w * 1000} г`;
    }

    case 'PORTION': {
      return `${w * 1000} г`;
    }

    case 'LITER': {
      return `${w * 1000} мл`;
    }

    default: {
      if (!w) {
        return '-';
      }
      return `${w * 1000} г`;
    }
  }
}

/** @description Парсим меню */
// TODO Отрефакторить
export function mergeMenu(menuT: any): Array<TMenuCategory> {
  const menu = menuT;

  menu.categories = Object.assign(
    {}, ..._.map(menu.categories, category => (
      { [category.id]: { ...category, open: false, items: [] } }
    )),
  );

  _.map(menu.items, (item) => {
    menu.categories[item.categoryId].items = [...menu.categories[item.categoryId].items, item];
  });

  return Object.keys(menu.categories).map((key) => {
    if (menu.categories[key].parentCategoryId && menu.categories[menu.categories[key].parentCategoryId]) {
      menu.categories[menu.categories[key].parentCategoryId].items = [
        ...menu.categories[menu.categories[key].parentCategoryId].items,
        menu.categories[key],
      ];
    }

    if (!menu.categories[key].parentCategoryId) {
      return menu.categories[key];
    }

    return null;
  }).filter(i => i);
}

/** @description Рекурсивная функция для изменения видимости категории */
export function changeVisibleById(menuList: TMenuCategory, id: number): TMenuCategory {
  if (menuList.id === id) {
    return {
      ...menuList,
      open: !menuList.open,
    };
  }

  if (menuList.items) {
    return {
      ...menuList,
      items: menuList.items.map((i: any) => changeVisibleById(i, id)),
    };
  }

  return menuList;
}

/** @description Рекурсивная функция для изменения видимости всех категорий */
export function changeVisibleForAll(menuList: TMenuCategory, open: boolean): TMenuCategory {
  if (menuList.items) {
    return {
      ...menuList,
      open,
      items: menuList.items.map((i: any) => changeVisibleForAll(i, open)),
    };
  }

  return {
    ...menuList,
    open,
  };
}
