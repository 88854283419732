import React, { useEffect, useState } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import {
  withStyles,
  createStyles,
  WithStyles,
  Theme,
} from '@material-ui/core/styles';

import {
  Badge,
  Tooltip,
  Menu,
  MenuItem,
  Avatar,
  IconButton,
} from '@material-ui/core';

import { EditOutlined as EditIcon } from '@material-ui/icons';
// import { SquareEditOutline as EditIcon } from 'mdi-material-ui';

import { Routers } from '../../../config/constants';
import locales from '../../../locales';
import { signOut } from '../../../actions/auth/AuthActions';
import { openCreateModal, setCurrentRestaurant } from '../../../actions/cabinet/CabinetActions';
import { TRestaurant } from '../../../types/ICabinetStore';
import { TUser } from '../../../types/IAuthStore';
import { IAppStore } from '../../../types/IAppStore';
import RestaurantSelect from './RestaurantSelect';
import UserIcon from '../Icons/UserIcon';
import PushIcon from '../Icons/PushIcon';
import PlaceholdersIcon from '../Icons/PlaceholdersIcon';
// import HeaderHelpIcon from '../Icons/HeaderHelpIcon';
// import EditIcon from '../Icons/EditIcon';

import { toggleNotifications } from '../../../actions/notifications/NotificationsActions';
import { toggleAppReserves, addAppReserve } from '../../../actions/appReserves/AppReservesActions';
import { selectorOpenModal } from '../../../containers/Reservation/selectors';
import Reserve from '../../../types/IAppReservesStore';
import { Employees } from '../../../entities';

const styles = (theme: Theme) => createStyles({
  wrap: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: 1,
  },
  ItemWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
  },
  NameStyle: {
    marginRight: '5px',
  },
  restaurantSelectorIcon: {
    display: 'none',
    [theme.breakpoints.down(600)]: {
      display: 'block',
    },
  },
  headerIcon: {
    fontSize: 18,
    [theme.breakpoints.up(600)]: {
      fontSize: 24,
    },
  },
  headerUserIcon: {
    fontSize: 24,
    [theme.breakpoints.up(600)]: {
      fontSize: 32,
    },
  },

  // @todo Проверить стили, размеры
  headerEditIcon: {
    color: '#e7ebee',
    opacity: 0.7,

    fontSize: 20,
    [theme.breakpoints.up(600)]: {
      fontSize: 26,
    },
  },
  badge: {
    color: 'white',
  },

  '@keyframes shake': {
    '10%, 90%': {
      transform: 'translate(9px, -10px)',
    },
    '20%, 80%': {
      transform: 'translate(11px, -10px)',
    },
    '30%, 50%, 70%': {
      transform: 'translate(7px, -10px)',
    },
    '40%, 60%': {
      transform: 'translate(13px, -10px)',
    },
  },

  shake: {
    animation: '$shake 0.82s',
    transform: 'translate(10px, -10px)',
  },
});

interface IDispatchToProps {
  openCreateModal: typeof openCreateModal;
  setCurrentRestaurant: typeof setCurrentRestaurant;
  signOut: typeof signOut;
  toggleNotifications: typeof toggleNotifications;
  toggleAppReserves: typeof toggleAppReserves;
  addAppReserve: typeof addAppReserve;
}

interface IMapsToPops {
  restaurantList: Array<TRestaurant>;
  currentRestaurant?: TRestaurant;
  user?: TUser;
  notificationsOpen: boolean;
  notificationsCount: number;
  appReservesOpen: boolean;
  appReservesCount: number;
  widgetAppReservesCount: number;
}

interface IProps extends
  RouteComponentProps,
  WithStyles<typeof styles>,
  IDispatchToProps,
  IMapsToPops
{
  isRegistrationCompleted: boolean;
}

interface IState {}

function Header(props: IProps & IState) {
  const {
    classes,
    user,
    signOut,
    currentRestaurant,
    restaurantList,
    setCurrentRestaurant,
    toggleNotifications,
    notificationsOpen,
    notificationsCount,
    appReservesCount,
    appReservesOpen,
    toggleAppReserves,
    addAppReserve,
    location,
    history,
    widgetAppReservesCount,
    openCreateModal,
    isRegistrationCompleted,
  } = props;

  const { data: currentEmployee } = Employees.useCurrentEmployee();
  const hasNotificationsAccess = currentEmployee && (
    Employees.hasAccess(currentEmployee, 'callNotifications:access')
    || Employees.hasAccess(currentEmployee, 'widgetNotifications:access')
  );

  const isProfilePage = () => location.pathname.indexOf(Routers.profile) === 0;

  // const { anchorEl, shakeReserves, shakeNotifications } = this.state;
  const [anchorEl, setAnchorEl] = useState<null | HTMLAnchorElement>(null);
  const [shakeReserves, setShakeReserves] = useState(false);
  const [shakeNotifications, setShakeNotifications] = useState(false);

  const handleMenuClose = () => setAnchorEl(null);
  const handleProfileMenuOpen = (event: any) => setAnchorEl(event.currentTarget);

  const handleClickProfile = () => {
    history.push(Routers.profile);
    handleMenuClose();
  };

  const scheduleShakeReserves = () => {
    if (widgetAppReservesCount > 0 && !appReservesOpen) {
      setShakeReserves(true);
      setTimeout(() => setShakeReserves(false), 5000);
    }
  };

  const scheduleShakeNotifications = () => {
    if (notificationsCount > 0 && !notificationsOpen) {
      setShakeNotifications(true);
      setTimeout(() => setShakeNotifications(false), 5000);
    }
  };

  let shakeReservesInterval: number | undefined;
  let shakeNotificationsInterval: number | undefined;

  useEffect(() => {
    if (!hasNotificationsAccess) {
      return undefined;
    }

    // @todo Зачем тут вложенность запусков?
    window.setTimeout(() => {
      shakeReservesInterval = window.setInterval(() => {
        scheduleShakeReserves();
      }, 10000);
    }, 5000);

    shakeNotificationsInterval = window.setInterval(() => {
      scheduleShakeNotifications();
    }, 10000);

    return () => {
      clearInterval(shakeReservesInterval);
      clearInterval(shakeNotificationsInterval);
    };
  }, [hasNotificationsAccess]);

  const isMenuOpen = Boolean(anchorEl);
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      autoFocus={false}
    >
      {currentEmployee && (
        <MenuItem
          onClick={handleClickProfile}
          disabled={isProfilePage()}
        >
          { locales.t('common.profile') }
        </MenuItem>
      )}

      <MenuItem onClick={signOut}>{ locales.t('common.logOut') }</MenuItem>
    </Menu>
  );

  const reservesBadgeColor = appReservesCount === 0 ? 'secondary' : 'error';
  const notificationBadgeColor = notificationsCount === 0 ? 'secondary' : 'error';

  return (
    <div className={classes.wrap}>
      {restaurantList.length > 0 && (
        <RestaurantSelect
          currentRestaurant={currentRestaurant}
          restaurantList={restaurantList}
          setCurrentRestaurant={setCurrentRestaurant}
          onCreateRestaurant={openCreateModal}
        />
      )}
      <div className={classes.ItemWrap}>
        <IconButton className={classes.restaurantSelectorIcon}>
          <PlaceholdersIcon className={classes.headerIcon} />
        </IconButton>

        {/*
        <IconButton>
          <HeaderHelpIcon className={classes.headerIcon} />
        </IconButton>
        */}

        {isRegistrationCompleted && currentEmployee && Employees.hasAccess(currentEmployee, 'reserves:access') && (
          <Tooltip title={locales.t('common.reserveQueue.appBarTooltip')}>
            <span>
              <IconButton
                // disabled={appReservesCount === 0}
                onClick={() => {
                  if (appReservesCount === 0) {
                    addAppReserve(new Reserve('APP'));
                  } else {
                    toggleAppReserves();
                  }
                }}
              >
                <Badge
                  badgeContent={appReservesCount}
                  color={appReservesCount > 0 && appReservesOpen ? 'primary' : reservesBadgeColor}
                  classes={{
                    colorError: classes.badge,
                    badge: (shakeReserves && classes.shake) || undefined,
                  }}
                  // showZero
                >
                  <EditIcon className={classes.headerEditIcon} />
                </Badge>
              </IconButton>
            </span>
          </Tooltip>
        )}

        {isRegistrationCompleted && hasNotificationsAccess && (
          <IconButton
            disabled={notificationsCount === 0}
            onClick={() => toggleNotifications()}
          >
            <Badge
              badgeContent={notificationsCount}
              color={
                notificationsCount > 0 && notificationsOpen ? 'primary' : notificationBadgeColor
              }
              classes={{
                colorError: classes.badge,
                badge: (shakeNotifications && classes.shake) || undefined,
              }}
              // showZero
            >
              <PushIcon className={classes.headerIcon} />
            </Badge>
          </IconButton>
        )}

        <IconButton onClick={handleProfileMenuOpen}>
          {user && user.imageUrl
            ? <Avatar className={classes.headerUserIcon} />
            : <UserIcon className={classes.headerUserIcon} />}
        </IconButton>
      </div>
      { renderMenu }
    </div>
  );
}

const mapStateToProps = (state: IAppStore): IMapsToPops => {
  const reserves = state.appReserves.items || [];

  return {
    restaurantList: state.cabinet.restaurantList,
    currentRestaurant: state.cabinet.currentRestaurant,
    user: state.auth.user,

    notificationsOpen: state.notifications.open,
    notificationsCount: state.notifications.items.length,

    appReservesOpen: !!selectorOpenModal(state),
    appReservesCount: reserves.length || 0,
    widgetAppReservesCount: reserves.filter((item: Reserve) => item.type === 'WIDGET').length || 0,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => bindActionCreators({
  openCreateModal,
  setCurrentRestaurant,
  signOut,
  toggleNotifications,
  toggleAppReserves,
  addAppReserve,
}, dispatch);

export default withStyles(styles)(
  withRouter(
    connect(mapStateToProps, mapDispatchToProps)(
      Header,
    ),
  ),
);
