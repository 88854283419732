import React from 'react';
import Paper from '@material-ui/core/Paper';
import { Pie } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';

import Title from '../../Common/Title';
import locales from '../../../locales';
import { TPieElement } from '../../../types/IStatisticStore';
import { errorColor, primaryColor } from '../../../theme';

type Props = {
  pie: Array<TPieElement>;
};

const index: React.FC<Props> = ({ pie }) => {
  const data = {
    labels: pie.map(element => element.name),
    datasets: [
      {
        data: pie.map(element => element.total),
        backgroundColor: [
          primaryColor,
          '#f7d794',
          errorColor,
          '#79919d',
        ],
      }],
  };

  const options = {
    responsive: true,
    legend: {
      position: 'right',
      align: 'middle',
      labels: {
        boxWidth: 12,
      },
    },
    plugins: {
      datalabels: {
        color: 'white',
      },
    },
  };

  const placeholderData = {
    labels: [''],
    datasets: [
      {
        data: [100],
        backgroundColor: [
          '#cdcdcd',
        ],
      }],
  };

  const placeholderOptions = {
    responsive: true,
    legend: {
      position: 'right',
      align: 'middle',
      labels: {
        boxWidth: 12,
      },
    },
    tooltips: {
      enabled: false,
    },
  };

  return (
    <Paper elevation={3} style={{ height: '100%', alignItems: 'center' }}>
      <Title text={locales.t('statistics.diagram.title')} borderBottom />
      <div style={{
        padding: 24, display: 'flex', alignItems: 'center', height: 'calc(100% - 64px)',
      }}
      >
        <Pie
          data={pie.length > 0 ? data : placeholderData}
          options={pie.length > 0 ? options : placeholderOptions}
        />
      </div>
    </Paper>
  );
};

export default index;
