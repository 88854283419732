import React from 'react';
import { connect } from 'react-redux';
import { confirmEmail, getSession } from '../actions/auth/AuthActions';

const NotFoundPage = () => (
  <div style={{
    display: 'flex', height: '100vh', alignItems: 'center', justifyContent: 'center',
  }}
  >
    <h1>404 - Страница не найдена</h1>
  </div>
);

export default connect(null, { getSession, confirmEmail })(NotFoundPage);
