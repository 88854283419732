import { Employees } from '../../../entities';

export function mapResponseToEmployee(responseEntity: GM.Employees.Response.Employee): GM.Employees.Employee {
  return {
    ...responseEntity,
    createdDate: new Date(responseEntity.createdDate),
    lastModifiedDate: new Date(responseEntity.lastModifiedDate),
  };
}

export function isAdmin(employee: GM.Employees.Employee) {
  return employee.authorities.indexOf('ADMINISTRATOR') !== -1;
}

export function isHostess(employee: GM.Employees.Employee) {
  return employee.authorities.indexOf('HOSTESS') !== -1;
}

export function isActive(employee: GM.Employees.Employee) {
  return employee.status === 'ACTIVE';
}

// @todo Использовать reselect
export function employeeCanBeDeleted(id: number, employees: GM.Employees.Employees | null): boolean {
  if (!employees || !employees[id]) {
    return false;
  }

  if (!isAdmin(employees[id]) || !isActive(employees[id])) {
    return true;
  }

  const activeAdmins = Object.values(employees).filter(
    (e: GM.Employees.Employee) => isAdmin(e) && isActive(e)
  );

  return activeAdmins.length > 1;
}

export function hasActiveHostess(employees: GM.Employees.Employees | null): boolean {
  if (!employees) {
    return false;
  }

  const activeHostesses = Object.values(employees).filter(
    (e: GM.Employees.Employee) => isHostess(e) && isActive(e)
  );

  return activeHostesses.length >= 1;
}

export function getEmployeeResources(employee: GM.Employees.Employee): Array<GM.Employees.ResourceAction> {
  let employeeResources: Array<GM.Employees.ResourceAction> = [];

  employee.authorities.forEach((authority: GM.Employees.Authority) => {
    if (Employees.employeeAuthorities.hasOwnProperty(authority)) {
      employeeResources = [
        ...employeeResources,
        // @ts-ignore
        ...Employees.employeeAuthorities[authority].resources,
      ]
    }
  });

  return employeeResources;
}
