import { createSelector } from 'reselect';
import _ from 'lodash';

import { IAppStore } from '../../types/IAppStore';
import { ITable, THallList } from '../../types/IRestaurantTablesStore';

/** @description Получение списко залов и столов */
export const selectorGetHallList = createSelector([
  (state: IAppStore) => state.tables.tableList,
], (tables) => {
  const sortTables = _.sortBy(tables, ['hall', i => parseInt(i.number)]);
  const groupToHall = sortTables.reduce((hallList: THallList, table: ITable) => {
    if (table && table.hall) {
      const hallIndex = _.findIndex(hallList, i => i.hall === table.hall);

      if (hallIndex !== -1) {
        return hallList.map(
          (hall, i) => {
            if (i === hallIndex) {
              return {
                ...hall,
                tables: hall.tables.concat(table),
                isBookingAllowed: hall.isBookingAllowed && table.isBookingAllowed
                  ? table.isBookingAllowed
                  : false,
              };
            }

            return hall;
          },
        );
      }

      return hallList.concat([{
        hall: table.hall,
        tables: [table],
        isAutoBookingAllowed: table.isAutoBookingAllowed,
        isBookingAllowed: table.isBookingAllowed,
      }]);
    }

    return [];
  }, []);

  return groupToHall.map((hall) => {
    const allBookingAndAutoBooking = hall.tables.filter(
      t => t.isBookingAllowed && t.isAutoBookingAllowed,
    );
    const onlyBooking = hall.tables.filter(t => t.isBookingAllowed);

    return {
      ...hall,
      isAutoBookingAllowed: allBookingAndAutoBooking.length > 0
        && _.isEqual(allBookingAndAutoBooking, onlyBooking),
    };
  });
});
