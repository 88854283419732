import * as ActionTypes from './RestaurantsTablesTypes';
import { IRestaurantsTablesActions } from './IRestaurantsTablesActions';
import { ITable } from '../../types/IRestaurantTablesStore';

/** @description Экшен на получение списка столов у выбранного ресторана */
export function getTablesForSettings(restaurantId: number): IRestaurantsTablesActions {
  return {
    type: ActionTypes.GET_ALL_TABLE_REQUEST,
    payload: {
      restaurantId,
    },
  };
}

/** @description Экшен для обработки успешного получения списка столов */
export function getTablesForSettingsSuccess(tableList: Array<ITable>): IRestaurantsTablesActions {
  return {
    type: ActionTypes.GET_ALL_TABLE_SUCCESS,
    payload: {
      tableList,
    },
  };
}

/** @description Экшен для обработки ошибки получения списка столов */
export function getTablesForSettingsError() {
  return {
    type: ActionTypes.GET_ALL_TABLE_ERROR,
    error: 'Не удалось получить список столов',
  };
}

/** @description Экшен для редактирования выбранного стола */
export function updateRestaurantTable(table: ITable | Array<ITable>): IRestaurantsTablesActions {
  return {
    type: ActionTypes.UPDATE_RESTAURANT_TABLE_REQUEST,
    payload: {
      table,
    },
  };
}

/** @description Экшен для редактирования выбранного стола */
export function updateRestaurantTableSuccess(message: string): IRestaurantsTablesActions {
  return {
    type: ActionTypes.UPDATE_RESTAURANT_TABLE_SUCCESS,
    message,
  };
}

/** @description Экшен для обработки ошибки редактирования выбранного стола */
export function updateRestaurantTableError(): IRestaurantsTablesActions {
  return {
    type: ActionTypes.UPDATE_RESTAURANT_TABLE_ERROR,
    error: 'Не удалось отредактировать параметры стола',
  };
}
