import React, { Component } from 'react';
import moment from 'moment';

import Reservations from '../../components/Reservations';
import {
  getTimeList,
  getSelectedTimeFromByNewDate,
  parseDateForReq,
} from '../../helpers/dateHelpers';
import locales from '../../locales';
import { IState, IProps, TCreateReserveFields } from './IReserveCreatePage';
import { TCreateReserveInfo } from '../../types/IReserveModalStore';
import { validateReserveFields } from '../../helpers/bookingHelpers';
import { ITable } from '../../types/IRestaurantTablesStore';
import { emptyTime } from '../../config/constants';
import { IAppReserveCloseOptions } from '../../actions/appReserves/IAppReservesActions';
import genDurationFromHours from '../../helpers/genDurationFromHours';

export default class ReserveCreatePage extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      timeList: getTimeList(moment(), props.workTime),
    };
  }

  componentDidUpdate() {
    const { freeTables } = this.props;

    if (!freeTables) {
      window.clearTimeout(this.getTablesTimeout);

      this.getTablesTimeout = window.setTimeout(() => {
        this.handleGetFreeTables();
      }, 200);
    }
  }

  componentWillUnmount() {
    window.clearTimeout(this.getTablesTimeout);
  }

  getTablesTimeout: number | undefined;

  /** @description Закрытие модального окна */
  handleCloseDialog = (options?: IAppReserveCloseOptions): void => {
    const { closeAppReserve, selectedAppReserve } = this.props;

    if (options && options.remove) {
      // Нажали на кнопку формы «Отмена»: нужно закрыть таб
      closeAppReserve(
        selectedAppReserve && selectedAppReserve.key,
        options,
      );
    } else {
      // Нажали на кнопку «Назад»: таб нужно сохранить,
      //   только спрятать форму
      closeAppReserve(undefined, options);
    }
  };

  /** @description Выбор стола для резерва */
  handleSelectTable = (activeTable: ITable): void => {
    const { setActiveTable } = this.props;
    setActiveTable(activeTable);
  };

  /** @description Обновление списка столов */
  handleGetFreeTables = (field?: {value: any, type: string}): void => {
    const {
      formName,
      currentValue,
      getFreeTables,
      workTime,
      changeCurrentTime,
      isMergeTables,
      changeAppReserve,
    } = this.props;

    const { timeList } = this.state;

    if (field && field.type === 'date') {
      const newTimeList = getTimeList(field.value, workTime);

      if (currentValue.time !== emptyTime) {
        const newTime = getSelectedTimeFromByNewDate(timeList, newTimeList, currentValue.time);
        changeAppReserve('time', newTime);
        changeCurrentTime(formName, newTime);
      }

      this.setState({
        timeList: newTimeList,
      });
    }

    if (validateReserveFields({
      ...currentValue,
      ...(field ? { [field.type]: [field.value] } : {}),
    })) {
      const newData = {
        // date: currentValue.time,
        date: parseDateForReq(currentValue.date),
        persons: currentValue.persons,
        duration: genDurationFromHours(currentValue.hours),
      };

      if (field) {
        switch (field.type) {
          case 'date': {
            const newTimeList = getTimeList(field.value, workTime);
            newData.date = getSelectedTimeFromByNewDate(
              timeList,
              newTimeList,
              currentValue.time,
            );
            break;
          }
          case 'time': {
            newData.date = field.value;
            break;
          }
          case 'persons': {
            newData.persons = field.value;
            break;
          }
          case 'hours': {
            newData.duration = parseInt(field.value) * 60;
            break;
          }
          default:
            break;
        }
      }

      if (isMergeTables) {
        newData.persons = 0;
      }

      if (newData.duration && newData.date !== emptyTime) {
        getFreeTables(newData.date, newData.persons, newData.duration);
      }
    }
  };

  /** @description Отправка запроса на создание новой брони */
  handleSendReq = (reserveInfo: TCreateReserveFields): void => {
    const {
      createReserve,
      activeTables,
      customErrorShackBar,
      closeAppReserve,
      selectedAppReserve,
    } = this.props;

    const activeTablesIds = Object.keys(activeTables).map(id => parseInt(id));

    if (activeTablesIds.length > 0) {
      const createReserveInfo: TCreateReserveInfo = {
        firstName: reserveInfo.firstName,
        lastName: reserveInfo.lastName,
        comment: reserveInfo.comment,
        phone: reserveInfo.phone,
        duration: parseInt(reserveInfo.hours) * 60,
        persons: reserveInfo.persons,
        date: parseDateForReq(reserveInfo.time),
        tables: activeTablesIds,
        isBanquet: reserveInfo.isBanquet,
      };

      createReserve(createReserveInfo);

      closeAppReserve(selectedAppReserve && selectedAppReserve.key, {
        remove: true,
        openNext: true,
        // skipModalClosing: true,
      });
    } else {
      customErrorShackBar(locales.t('common.reserveModal.emptyActiveTables'));
    }
  };

  /** @description Переключение в режим объеденения столов */
  handleMergeTables = () => {
    const {
      mergeTables,
      isMergeTables,
      currentValue,
    } = this.props;

    const newTableType = !isMergeTables;

    if (newTableType && validateReserveFields(currentValue)) {
      mergeTables(newTableType, {
        date: currentValue.time,
        persons: 0,
        duration: genDurationFromHours(currentValue.hours),
      });
    } else if (!newTableType && validateReserveFields(currentValue)) {
      mergeTables(newTableType, {
        date: currentValue.time,
        persons: currentValue.persons,
        duration: genDurationFromHours(currentValue.hours),
      });
    }
  };

  handleViewChange = (sectionId: number) => {
    const { changeSectionToFirstItem, formName } = this.props;

    changeSectionToFirstItem(formName, sectionId);
  };

  render() {
    const {
      formName,
      disabled,

      modalOpen,
      // reserveInfo,
      initialData,
      freeTables,
      // bookingDuration,
      activeTables,
      maxCapacity,
      isMergeTables,
      isSchemasEnabled,
      sections,
      schema,
      currentValue,
      locale,

      selectedAppReserve,
      appReserves,
      addAppReserve,
      removeAppReserve,
      openAppReserve,
      closeAppReserve,
      changeAppReserve,
      appReserveFormValues,
      alreadySelectedTables,
    } = this.props;

    const { timeList } = this.state;

    if (!modalOpen) {
      return null;
    }

    return (
      <Reservations
        form={formName}
        disabled={disabled}
        locale={locale}
        maxCapacity={maxCapacity}

        title={locales.t('common.reserveModal.title')}
        reserveBtnText={locales.t('common.reserveModal.reserve')}
        rejectBtnText={locales.t('common.reject')}
        freeTables={freeTables}
        isSchemasEnabled={isSchemasEnabled}
        sections={sections}
        section={currentValue.section}
        schema={schema}
        persons={currentValue.persons}
        // @ts-ignore
        onSubmit={this.handleSendReq}
        handleCloseDialog={this.handleCloseDialog}
        handleReject={() => {
          this.handleCloseDialog({
            remove: true,
            openNext: true,
          });
        }}
        handleSelectTable={this.handleSelectTable}
        handleGetFreeTables={this.handleGetFreeTables}
        handleMergeTables={this.handleMergeTables}
        handleViewChange={this.handleViewChange}
        isMergeTables={isMergeTables}
        activeTables={activeTables}
        timeList={currentValue && currentValue.date ? timeList : []}
        initialValues={initialData}
        enableReinitialize

        selectedAppReserve={selectedAppReserve}
        appReserves={appReserves}
        addAppReserve={addAppReserve}
        removeAppReserve={removeAppReserve}
        openAppReserve={openAppReserve}
        closeAppReserve={closeAppReserve}
        changeAppReserve={changeAppReserve}
        appReserveFormValues={appReserveFormValues}
        alreadySelectedTables={alreadySelectedTables}

        isBanquet={!!(selectedAppReserve
          && selectedAppReserve.originPayload
          && selectedAppReserve.originPayload.isBanquet)}
      />
    );
  }
}
