import React from 'react';

// import { TCreateReserveInitialData } from '../../../types/IReserveModalStore';

import {
  TNotifications,
  TNotification,
  CallNotification,
  ReserveNotification,
  IWindowSize,
  // TNotificationPayload,
} from '../../../types/INotificationsStore';

import { useWindowSize } from '../../../helpers/callHelpers';

import Snackbar from './Snackbar/index';
import CallBody from './Snackbar/Call/Body';
import CallFooter from './Snackbar/Call/Footer';
import ReserveBody from './Snackbar/Reserve/Body';
import ReserveFooter from './Snackbar/Reserve/Footer';
import Reserve from '../../../types/IAppReservesStore';

interface Props {
  items: TNotifications;
  onToggle: (key: string) => void;

  onRemoveCall: (notificationKey: string, itemId: number) => void;
  onCreateReserveFromCall: (notificationKey: string, itemId: number) => void;

  onRemoveReserve: (notificationKey: string, itemId: number) => void;
  onConfirmReserve: (notificationKey: string, itemId: number) => void;
  // onEditReserve: (notificationKey: string, itemId: number) => void;
  onEditReserve: (notificationKey: string, reserve: Reserve) => void;
}

// @todo Происходит ненужный render. Заметно с включенным таймером нотификаций
// @todo Сделать container для карточек звонка, в котором будет и логика таймера,
//   чтобы не прокидывать по дереву частые изменения?
const Notifications: React.FunctionComponent<Props> = ({
  items,
  onToggle,

  onRemoveCall,
  onCreateReserveFromCall,

  onRemoveReserve,
  onConfirmReserve,
  onEditReserve,
}) => {
  if (!items.length) {
    return null;
  }

  const size = useWindowSize();
  if (!size.width || !size.height) {
    return null;
  }

  const windowSize: IWindowSize = {
    width: size.width,
    height: size.height,
  };

  // const headerOffset = 66;
  let offset = 66;
  let fiftyFiftyOffset = offset;
  // let maxOffset = offset;
  const margin = 2;

  const notificationsWithOffset = items.map((notification: TNotification, index: number) => {
    const item = {
      ...notification,
      offset,
      fiftyFiftyOffset,
      // maxOffset,
    };

    const foldHeight = notification.getFoldHeight(windowSize);
    const unfoldHeight = notification.getUnfoldHeight(windowSize);

    offset += (notification.open ? unfoldHeight : foldHeight) + margin;
    fiftyFiftyOffset += (index % 2 === 0 ? unfoldHeight : foldHeight) + margin;
    // maxOffset += unfoldHeight + margin;

    return item;
  });

  const visibleCalls = notificationsWithOffset.filter(
    x => size.height && x.fiftyFiftyOffset + x.getUnfoldHeight() < size.height,
  );

  return (
    <>
      {visibleCalls.map((x) => {
        switch (x.type) {
          case 'CALL':
            return (
              <Snackbar
                key={x.getKey()}
                notification={x}
                onToggle={onToggle}

                body={(
                  <CallBody notification={x as CallNotification} />
                )}

                footer={(
                  <CallFooter
                    notification={x as CallNotification}
                    onRemove={onRemoveCall}
                    onCreateReserve={onCreateReserveFromCall}
                  />
                )}
              />
            );

          case 'RESERVE':
            return (
              <Snackbar
                key={x.getKey()}
                notification={x}
                onToggle={onToggle}

                body={(
                  <ReserveBody notification={x as ReserveNotification} />
                )}

                footer={(
                  <ReserveFooter
                    notification={x as ReserveNotification}
                    onRemove={onRemoveReserve}
                    onConfirm={onConfirmReserve}
                    onEdit={onEditReserve}
                  />
                )}
              />
            );

          default:
            return null;
        }
      })}
    </>
  );
};

export default Notifications;
