import { createStyles, Theme } from '@material-ui/core/styles';


const tabletWidth = 1280;
const drawerWidth = 270;
const menuBackgroundColor = '#f7f8fb';

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
  },
  appBar: {
    backgroundColor: '#323232',
    [theme.breakpoints.up(tabletWidth)]: {
      width: '100%',
    },
    [theme.breakpoints.down(600)]: {
      height: 56,
    },
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: 'white',
    [theme.breakpoints.up(tabletWidth)]: {
      width: '100%',
    },
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
    color: 'rgb(197, 197, 197)',
    [theme.breakpoints.up('lg')]: {
      width: 240,
    },
    [theme.breakpoints.up('xl')]: {
      width: 270,
    },
    [theme.breakpoints.down('lg')]: {
      marginRight: 48,
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: 20,
    },
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    [theme.breakpoints.down(tabletWidth)]: {
      position: 'fixed',
      zIndex: 1200,
      opacity: 0.9,
    },
  },
  drawerOpen: {
    width: drawerWidth,
    backgroundColor: menuBackgroundColor,
    border: 0,
    boxShadow: '2px 0 6px 0 rgba(31, 48, 63, 0.3);',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    paddingLeft: 24,
    ...theme.mixins.toolbar,
    backgroundColor: menuBackgroundColor,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    marginTop: 64,
  },
  snackbarContent: {
    width: 360,
  },
  logo: {
    width: 145,
  },
  isDisabled: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    zIndex: 1,
    backgroundColor: 'black',
    opacity: 0.5,
  },
});

export default styles;
