import React from 'react';
import { Typography } from '@material-ui/core';

import locales from '../../../locales';
import DialogComponent from './index';
import ButtonComponent from '../../Common/Button';
import DangerButtonComponent from '../../Common/Button/Danger';

export default function DeleteDialog(props: {
  onClose: () => void;
  onDelete: () => void;
}) {
  const { onClose, onDelete } = props;

  function onSubmit() {
    onClose();
    onDelete();
  }

  return (
    <DialogComponent
      title={locales.t('employees.deletion')}
      onClose={onClose}
      actions={(
        <>
          <ButtonComponent
            // autoFocus
            onClick={onClose}
            title={locales.t('employees.cancel')}
          />

          {/*
          <SolidButtonComponent
            onClick={onSubmit}
            title={locales.t('employees.approveDeletion')}
          />
          */}

          <DangerButtonComponent
            onClick={onSubmit}
            title={locales.t('employees.approveDeletion')}
          />
        </>
      )}
    >
      <Typography gutterBottom>
        {locales.t('employees.deleteConfirmation')}
      </Typography>
    </DialogComponent>
  );
}
