import React from 'react';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography/Typography';

const styles = () => createStyles({
  wrap: {
    borderBottom: 'none',
    flex: 1,
  },
});

interface IProps extends WithStyles<typeof styles> {
  type: string;
  stat: number | string;
  color?: 'error' | 'primary';
  textAlign?: 'center' | 'left';
}

const UserStatisticTableItem: React.FC<IProps> = ({
  classes, type, stat, textAlign = 'left',
}) => {
  // @ts-ignore
  const parseStat = stat > 999 ? stat.toLocaleString() : stat;
  // const textColor: any = color || 'textPrimary';

  return (
    <div className={classes.wrap}>
      <div style={{ textAlign }}>
        <Typography variant="h6">
          { parseStat }
        </Typography>
        <Typography variant="caption" style={{ textTransform: 'uppercase', whiteSpace: 'pre' }}>
          { type }
        </Typography>
      </div>
    </div>
  );
};

export default withStyles(styles)(UserStatisticTableItem);
