import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { ExpandLess, ExpandMore, AccountCircle } from '@material-ui/icons';

import {
  Box,
  Theme,
  IconButton,
  Typography,
} from '@material-ui/core';

import { parsePhoneNumber } from '../../../../helpers/reserveHelpers';

const useStyles = makeStyles((theme: Theme) => ({
  fakeAvatar: {
    fontSize: 77,
    color: '#c5c5c5',
    margin: '-3px 12px 0 -6px',
  },
  clickableAvatar: {
    cursor: 'pointer',
  },
  name: {
    fontSize: 24,
    fontWeight: 500,
    color: 'white',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: 300,
    [theme.breakpoints.up('lg')]: {
      width: 320,
    },
  },
  clickableName: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  phone: {
    fontSize: 16,
    color: 'white',
    opacity: 0.65,
  },
}));

interface SnackbarHeaderProps {
  id: string;
  name: string;
  phone: string;
  open: boolean;
  clickPerson: (phone: string) => void;
  onToggle: (key: string) => void;
  isGuest: boolean;
}

function Header(props: SnackbarHeaderProps) {
  const {
    id,
    name,
    phone,
    open,
    clickPerson,
    onToggle,
    isGuest,
  } = props;

  const classes = useStyles();
  const nameClassNames = [classes.name];
  const avatarClassNames = [classes.fakeAvatar];

  if (!isGuest) {
    nameClassNames.push(classes.clickableName);
    avatarClassNames.push(classes.clickableAvatar);
  }

  return (
    <Box display="flex" mb={1}>
      <Box clone>
        <AccountCircle
          className={avatarClassNames.join(' ')}
          onClick={() => clickPerson(phone)}
        />
      </Box>
      <Box flexGrow={1} alignSelf="center">
        <Typography
          className={nameClassNames.join(' ')}
          title={name.length > 20 ? name : ''}
          onClick={() => clickPerson(phone)}
        >
          {name}
        </Typography>
        <Typography className={classes.phone}>
          {parsePhoneNumber(phone)}
        </Typography>
      </Box>
      <Box clone alignSelf="start">
        <IconButton
          onClick={() => onToggle(id)}
          style={{ color: '#c5c5c5', marginTop: -9 }}
        >
          {open ? <ExpandLess color="inherit" /> : <ExpandMore color="inherit" />}
        </IconButton>
      </Box>
    </Box>
  );
}

export default Header;
