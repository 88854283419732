import * as ActionTypes from './RestaurantsInfoActionsTypes';
import {
  IUpdateInfo,
} from '../../types/IRestaurantInfoStore';
import { IAppActions } from '../../types/IAppActions';

/** @description Экшен на редактирование полей у выбранного ресторана */
export function updateRestaurantInfo(restaurantInfo: IUpdateInfo): IAppActions {
  return {
    type: ActionTypes.UPDATE_RESTAURANT_INFO_REQUEST,
    payload: restaurantInfo,
  };
}

/** @description Экшен на обработку успешного редактирования ресторана */
export function updateRestaurantInfoSuccess(): IAppActions {
  return {
    type: ActionTypes.UPDATE_RESTAURANT_INFO_SUCCESS,
    message: 'Поле отредактировано',
  };
}

/** @description Экшен на обработку ошибки редактирования ресторана */
export function updateRestaurantInfoError(): IAppActions {
  return {
    type: ActionTypes.UPDATE_RESTAURANT_INFO_ERROR,
    error: 'Не удалось отредактировать поле',
  };
}
