import React, { Fragment } from 'react';
import _ from 'lodash';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import FieldWrap from '../../Common/FieldWrap';
import ScheduleTable from '../../Common/ScheduleTable';
import locales from '../../../locales';
import { IFreeDay, IRestaurantWorkTimes, IScheduleRow } from '../../../types/IScheduleStore';
import CustomTooltip from '../../Common/CustomTooltip';

interface IProps {
  isActiveRestaurantSchedule?: boolean;
  scheduleList: Array<IScheduleRow>;
  label?: string;
  freeDays: Array<IFreeDay>;
  handleAddScheduleRow: () => void;
  handelUpdateSchedule: (row: IScheduleRow) => void;
  handleDeleteScheduleRow: (row: IScheduleRow) => void;
  handleUpdateFreeDays: any;
  handleSwitchReserveSchedule: any;
  restaurantWorkTimes: IRestaurantWorkTimes;
}

/**
 * @param classes
 * @param handleAddScheduleRow - Ручка для добавления строки расписания
 * @param handelUpdateSchedule - Ручка для редактирования строки расписания
 * @param handleDeleteScheduleRow - Ручка для удаления строки расписания
 * @param handleUpdateFreeDays - Ручка для обновления списка дней для которых нету расписания
 * @param handleSwitchReserveSchedule - Ручка для переключения типа приёма расписания приёма резервов
 */
const AutoBookingSwitch: React.FC<IProps> = ({
  scheduleList,
  freeDays,
  label,
  isActiveRestaurantSchedule,
  handleAddScheduleRow,
  handelUpdateSchedule,
  handleDeleteScheduleRow,
  handleUpdateFreeDays,
  handleSwitchReserveSchedule,
  restaurantWorkTimes,
}) => {
  const activeType = isActiveRestaurantSchedule === undefined ? ''
    : isActiveRestaurantSchedule
      ? locales.t('settings.reserveSchedule.restaurantSchedule')
      : locales.t('settings.reserveSchedule.customSettings');

  const disable = _.isEmpty(restaurantWorkTimes);

  return (
    <Fragment>
      <FieldWrap>
        <FormControl fullWidth>
          <InputLabel>{ label }</InputLabel>
          <Select
            disabled={isActiveRestaurantSchedule === undefined}
            onChange={handleSwitchReserveSchedule}
            value={activeType}
          >
            {
              [
                locales.t('settings.reserveSchedule.restaurantSchedule'),
                locales.t('settings.reserveSchedule.customSettings'),
              ]
                .map((items, i) => (
                  <MenuItem
                    disabled={disable ? i === 1 : false}
                    key={i}
                    value={items}
                  >
                    {items}
                  </MenuItem>
                ))
            }
          </Select>
        </FormControl>
        <CustomTooltip marginLeft content="" />
      </FieldWrap>
      {
        (isActiveRestaurantSchedule === false)
        && (
          <ScheduleTable
            scheduleList={scheduleList}
            handleAddScheduleRow={handleAddScheduleRow}
            handelUpdateSchedule={handelUpdateSchedule}
            handleDeleteScheduleRow={handleDeleteScheduleRow}
            handleUpdateFreeDays={handleUpdateFreeDays}
            freeDays={freeDays}
            restaurantWorkTimes={restaurantWorkTimes}
          />
        )
      }
    </Fragment>
  );
};

export default AutoBookingSwitch;
