import * as ActionTypes from './PhotoActionsTypes';
import { IAppActions } from '../../types/IAppActions';

/** @description Экшен на получение всех изображений ресторана */
export function getImages(restaurantId: number): IAppActions {
  return {
    type: ActionTypes.GET_IMAGES_REQUEST,
    payload: {
      restaurantId,
    },
  };
}

/** @description Экшен на обработку успешного запроса изображений */
export function getImagesSuccess(imagesList: Array<any>): IAppActions {
  return {
    type: ActionTypes.GET_IMAGES_SUCCESS,
    payload: {
      imagesList,
    },
  };
}

/** @description Экшен на обработку ошибки запроса */
export function getImagesError(): IAppActions {
  return {
    type: ActionTypes.GET_IMAGES_ERROR,
  };
}

/** @description Экшен для загрузки изображения */
export function uploadImage(restaurantId: number, image: any): IAppActions {
  return {
    type: ActionTypes.UPLOAD_IMAGE_REQUEST,
    payload: {
      restaurantId,
      image,
    },
  };
}

/** @description Экшен для обработки успешной загрузки изображения */
export function uploadImageSuccess(): IAppActions {
  return {
    type: ActionTypes.UPLOAD_IMAGE_SUCCESS,
  };
}

/** @description Экшен для обработки ошибки загрузки изображения */
export function uploadImageError(): IAppActions {
  return {
    type: ActionTypes.UPLOAD_IMAGE_ERROR,
  };
}

/** @description Экшен для редактирования видимости изображения */
export function hideImage(restaurantId: number, imageId: number): IAppActions {
  return {
    type: ActionTypes.HIDE_IMAGE_REQUEST,
    payload: {
      restaurantId,
      imageId,
    },
  };
}

/** @description Экшен для обработки успешного редактирования видимости изображения */
export function hideImageSuccess(imageId: number): IAppActions {
  return {
    type: ActionTypes.HIDE_IMAGE_SUCCESS,
    payload: {
      imageId,
    },
  };
}

/** @description Экшен для обработки ошибки редактирования видимости изображения */
export function hideImageError(): IAppActions {
  return {
    type: ActionTypes.HIDE_IMAGE_ERROR,
  };
}

/** @description Экшен для редактирования видимости изображения */
export function showImage(restaurantId: number, imageId: number): IAppActions {
  return {
    type: ActionTypes.SHOW_IMAGE_REQUEST,
    payload: {
      restaurantId,
      imageId,
    },
  };
}

/** @description Экшен для обработки успешного редактирования видимости изображения */
export function showImageSuccess(imageId: number): IAppActions {
  return {
    type: ActionTypes.SHOW_IMAGE_SUCCESS,
    payload: {
      imageId,
    },
  };
}

/** @description Экшен для обработки ошибки редактирования видимости изображения */
export function showImageError(): IAppActions {
  return {
    type: ActionTypes.HIDE_IMAGE_ERROR,
  };
}

/** @description Экшен для установки фото в качестве обложки */
export function setCover(restaurantId: number, imageId: number): IAppActions {
  return {
    type: ActionTypes.SET_COVER_REQUEST,
    payload: {
      restaurantId,
      imageId,
    },
  };
}

/** @description Экшен для обработки успешной смены обложки */
export function setCoverSuccess(newMainId: number): IAppActions {
  return {
    type: ActionTypes.SET_COVER_SUCCESS,
    payload: {
      newMainId,
    },
  };
}

/** @description Экшен для обработки ошибки при смене обложки */
export function setCoverError(): IAppActions {
  return {
    type: ActionTypes.SET_COVER_ERROR,
  };
}

/** @description Экшен для изменения видимости обложки */
export function hideMainImage(
  restaurantId: number,
  mainImgId: number,
  secondImgId: number,
): IAppActions {
  return {
    type: ActionTypes.HIDE_MAIN_IMAGE_REQUEST,
    payload: {
      restaurantId,
      mainImgId,
      secondImgId,
    },
  };
}

/** @description Экшен для обработки успешного изменения видимости обложки */
export function hideMainImageSuccess(newMainId: number, hideImgId: number): IAppActions {
  return {
    type: ActionTypes.HIDE_MAIN_IMAGE_SUCCESS,
    payload: {
      newMainId,
      hideImgId,
    },
  };
}

/** @description Экшен для обработки ошибки изменения видимости обложки */
export function hideMainImageError(): IAppActions {
  return {
    type: ActionTypes.HIDE_MAIN_IMAGE_ERROR,
  };
}
