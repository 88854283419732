import * as ActionsTypes from './StatisticsActionsTypes';
import { IStatisticsActions } from './IStatisticsActions';
import { TOrder, TPages } from '../../types/IStatisticStore';
import { IDate } from '../../types/IDate';
import { string } from 'prop-types';

/** @description Экшен для запроса на получение сводной статистики */
export function getStatistics(
  sort: { orderBy: string, order: string },
  page: number,
  size: number,
  date?: IDate,
): IStatisticsActions {
  return {
    type: ActionsTypes.GET_SUMMARY_STATISTICS_REQUEST,
    payload: {
      date,
      sort,
      page,
      size,
    },
  };
}

/** @description Экшен для обработки запроса на получение сводной статистики */
export function getStatisticsSuccess(summary: any, pie: any, reserves: any, pages: TPages): IStatisticsActions {
  return {
    type: ActionsTypes.GET_SUMMARY_STATISTICS_SUCCESS,
    payload: {
      summary,
      pie,
      reserves,
      pages,
    },
  };
}

/** @description Экшен для обработки ошибки запроса на получение сводной статистики */
export function getStatisticsError(): IStatisticsActions {
  return {
    type: ActionsTypes.GET_SUMMARY_STATISTICS_ERROR,
  };
}

/** @description Экшен для запроса деталей резерва */
export function openBookingDetails(bookingId: number): IStatisticsActions {
  return {
    type: ActionsTypes.OPEN_BOOKING_DETAILS,
    payload: {
      bookingId,
    },
  };
}

/** @description Экшен для открытия деталей резерва */
export function openBookingDetailsSuccess(bookingId: number, order: TOrder): IStatisticsActions {
  return {
    type: ActionsTypes.OPEN_BOOKING_DETAILS_SUCCESS,
    payload: {
      bookingId,
      order,
    },
  };
}

/** @description Экшен для открытия деталей резерва */
export function openBookingDetailsError(error: string): IStatisticsActions {
  return {
    type: ActionsTypes.OPEN_BOOKING_DETAILS_ERROR,
    error,
  };
}
