import { IAppActions } from '../types/IAppActions';
import { IStatisticStore } from '../types/IStatisticStore';
import {
  GET_SUMMARY_STATISTICS_SUCCESS, OPEN_BOOKING_DETAILS_SUCCESS,
} from '../actions/statistics/StatisticsActionsTypes';

const initState: IStatisticStore = {
  summary: {
    averageReceiptAmount: 0,
    total: 0,
    totalCancelledByGuest: 0,
    totalCancelledByRestaurant: 0,
    totalClosed: 0,
    totalDeclined: 0,
    totalGuests: 0,
    totalNewGuests: 0,
    totalReceiptAmount: 0,
  },
  pie: [],
  reserves: [],
  pages: {
    current: 0,
    size: 0,
    first: true,
    last: true,
    totalPages: 0,
    totalElements: 0,
  },
  bookingsOrders: {},
};

export default function StatisticReducer(state = initState, action: IAppActions): IStatisticStore {
  switch (action.type) {
    case GET_SUMMARY_STATISTICS_SUCCESS: {
      return {
        ...state,
        // Если на бэке в поле нет данных, то прилетает null.
        summary: {
          averageReceiptAmount: action.payload.summary.averageReceiptAmount || 0,
          total: action.payload.summary.total || 0,
          totalCancelledByGuest: action.payload.summary.totalCancelledByGuest || 0,
          totalCancelledByRestaurant: action.payload.summary.totalCancelledByRestaurant || 0,
          totalClosed: action.payload.summary.totalClosed || 0,
          totalDeclined: action.payload.summary.totalDeclined || 0,
          totalGuests: action.payload.summary.totalGuests || 0,
          totalNewGuests: action.payload.summary.totalNewGuests || 0,
          totalReceiptAmount: action.payload.summary.totalReceiptAmount || 0,
        },
        pie: action.payload.pie,
        reserves: action.payload.reserves,
        pages: action.payload.pages,
      };
    }

    case OPEN_BOOKING_DETAILS_SUCCESS: {
      return {
        ...state,
        bookingsOrders: {
          ...state.bookingsOrders,
          [action.payload.bookingId]: action.payload.order,
        },
      };
    }

    default: return state;
  }
}
