export const GET_IMAGES_REQUEST = 'GET_IMAGES_REQUEST';
export const GET_IMAGES_SUCCESS = 'GET_IMAGES_SUCCESS';
export const GET_IMAGES_ERROR = 'GET_IMAGES_ERROR';
export const UPLOAD_IMAGE_REQUEST = 'UPLOAD_IMAGE_REQUEST';
export const UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_ERROR = 'UPLOAD_IMAGE_ERROR';
export const HIDE_IMAGE_REQUEST = 'HIDE_IMAGE_REQUEST';
export const HIDE_IMAGE_SUCCESS = 'HIDE_IMAGE_SUCCESS';
export const HIDE_IMAGE_ERROR = 'HIDE_IMAGE_ERROR';
export const SHOW_IMAGE_REQUEST = 'SHOW_IMAGE_REQUEST';
export const SHOW_IMAGE_SUCCESS = 'SHOW_IMAGE_SUCCESS';
export const SHOW_IMAGE_ERROR = 'SHOW_IMAGE_ERROR';
export const SET_COVER_REQUEST = 'SET_COVER_REQUEST';
export const SET_COVER_SUCCESS = 'SET_COVER_SUCCESS';
export const SET_COVER_ERROR = 'SET_COVER_ERROR';
export const HIDE_MAIN_IMAGE_REQUEST = 'HIDE_MAIN_IMAGE_REQUEST';
export const HIDE_MAIN_IMAGE_SUCCESS = 'HIDE_MAIN_IMAGE_SUCCESS';
export const HIDE_MAIN_IMAGE_ERROR = 'HIDE_MAIN_IMAGE_ERROR';
