import * as ActionTypes from './SourceStatisticActionsTypes';
import { ISourceStatistic } from './ISourceStatisticActions';
import { TReserve } from '../../types/IBookingStore';
import { TPages } from '../../types/IStatisticStore';
import { TSourceInfo } from '../../types/ISourceStatistic';
import { IDate } from '../../types/IDate';

/** @description Экшен на получение статистики источника */
export function getSourceStatistic(
  source: string,
  date?: IDate,
): ISourceStatistic {
  return {
    type: ActionTypes.GET_SOURCE_STATISTIC_REQUEST,
    payload: {
      source,
      date,
    },
  };
}

/** @description Экшен на обработку получения статистики источника */
export function getSourceStatisticSuccess(sourceStats: any): ISourceStatistic {
  return {
    type: ActionTypes.GET_SOURCE_STATISTIC_SUCCESS,
    payload: {
      sourceStats,
    },
  };
}

/** @description Экшен на обработку ошибки получения статистики источника */
export function getSourceStatisticsError(): ISourceStatistic {
  return {
    type: ActionTypes.GET_SOURCE_STATISTIC_ERROR,
  };
}

/** @description Экшен на полученеи списка резервов с выборкой по источнику */
export function getReservesBySource(
  source: string,
  sort: {
    orderBy: string;
    order: string;
  },
  page: number,
  size: number,
  date?: IDate,
): ISourceStatistic {
  return {
    type: ActionTypes.GET_RESERVES_BY_SOURCE_REQUEST,
    payload: {
      source,
      sort,
      date,
      page,
      size,
    },
  };
}

/** @description Экшен на обработку получения списка резервов с выборкой по источнку */
export function getReservesBySourceSuccess(
  reserveList: Array<TReserve>, pages: TPages,
): ISourceStatistic {
  return {
    type: ActionTypes.GET_RESERVES_BY_SOURCE_SUCCESS,
    payload: {
      reserveList,
      pages,
    },
  };
}

/** @description Экшен на обработку ошибки при получение списка резервов с выборкой по источнку */
export function getReservesBySourceError(): ISourceStatistic {
  return {
    type: ActionTypes.GET_RESERVES_BY_SOURCE_ERROR,
  };
}

/** @description Экшен для получения инормации по источнику */
export function getSourceInfo(source: string): ISourceStatistic {
  return {
    type: ActionTypes.GET_SOURCE_INFO_REQUEST,
    payload: {
      source,
    },
  };
}

/** @description Экшен для обработки успешного запроса */
export function getSourceInfoSuccess(source: TSourceInfo): ISourceStatistic {
  return {
    type: ActionTypes.GET_SOURCE_INFO_SUCCESS,
    payload: {
      source,
    },
  };
}

/** @description Экшен для обработки ошибки */
export function getSourceError(): ISourceStatistic {
  return {
    type: ActionTypes.GET_SOURCE_INFO_ERROR,
  };
}
