import React, { Fragment } from 'react';
import wrapWithPath from './wrapSvgPath';

const arrowLeftPath = (
  <Fragment>
    <path fill="#554d56" d="M8.7 14.3c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0l-7-7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4L2.4 8l6.3 6.3z" />
  </Fragment>
);

export default wrapWithPath(arrowLeftPath, '-3 0 17 17');
