import locales from '../../../locales';
import { getEmployeeResources } from '../model';

export const employeeAuthorities: GM.Employees.Authorities = {
  'ADMINISTRATOR': {
    id: 'ADMINISTRATOR',
    resources: [
      'stats:access',
      'restaurantSettings:access',
      'restaurants:access',
      'employees:access',
      'reserves:access',
    ],
  },
  'MANAGER': {
    id: 'MANAGER',
    resources: [
      'stats:access',
      'restaurantSettings:access',
      'reserves:access',
    ],
  },
  'HOSTESS': {
    id: 'HOSTESS',
    resources: [
      'reserves:access',
      'widgetNotifications:access',
      'callNotifications:access',
    ],
  },
};

export const hasAccess = (employee: GM.Employees.Employee | null, resourceAction: GM.Employees.ResourceAction): boolean => {
  return !!employee && getEmployeeResources(employee).indexOf(resourceAction) !== -1;
};

export const getName = (employee: GM.Employees.Employee) => {
  const names = [];

  if (employee.lastName) {
    names.push(employee.lastName);
  }

  if (employee.firstName) {
    names.push(employee.firstName);
  }

  return (names && names.join(' ')) || locales.t('employees.no_name');
};
