import { IAppActions } from '../types/IAppActions';
import { TNotification, INotificationsStore } from '../types/INotificationsStore';
import * as ActionTypes from '../actions/notifications/NotificationsActionsTypes';

export const initState: INotificationsStore = {
  items: [],
  open: false,
};

export default function reducer(state: INotificationsStore = initState, action: IAppActions): INotificationsStore {
  switch (action.type) {
    case ActionTypes.SET_NOTIFICATIONS:
      return {
        ...state,
        items: action.items,
      };

    case ActionTypes.RESET_NOTIFICATIONS:
      return {
        ...state,
        items: initState.items,
      };

    case ActionTypes.TOGGLE_NOTIFICATIONS:
      return {
        ...state,
        open: !state.open,
      };

    case ActionTypes.OPEN_NOTIFICATIONS:
      return {
        ...state,
        open: true,
      };

    case ActionTypes.CLOSE_NOTIFICATIONS:
      return {
        ...state,
        open: false,
      };

    case ActionTypes.ADD_NOTIFICATION:
      if (state.items.find(item => item.getKey() === action.notification.getKey())) {
        return {
          ...state,
          items: state.items.map((item: TNotification) => {
            if (item.getKey() === action.notification.getKey()) {
              return {
                ...item,
                payload: action.notification.payload,
              };
            }

            return item;
          }),
        };
      }

      return {
        ...state,
        items: [
          ...state.items,
          action.notification,
        ],
      };

    case ActionTypes.REMOVE_NOTIFICATION:
      return {
        ...state,
        items: state.items.filter(item => item.getKey() !== action.key),
      };

    case ActionTypes.TOGGLE_NOTIFICATION:
      return {
        ...state,
        items: state.items.map((item: TNotification) => {
          if (item.getKey() === action.key) {
            return {
              ...item,
              open: !item.open,
            };
          }

          return item;
        }),
      };

    default:
      return state;
  }
}
