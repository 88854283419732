import React, { PureComponent, Fragment } from 'react';

import Grid from '@material-ui/core/Grid/Grid';

import { IProps } from './ISourceStatistic';
import locales from '../../locales';
import SourceCard from '../../components/SourceStatictic/SourceCard';
import SourceStatisticTable from '../../components/SourceStatictic/SourceStatisticTable';
import SourceReserveTable from '../../components/SourceStatictic/SourceReserveTable';
import BackButton from '../../components/Common/BackButton';
import PageHeaderWrap from '../../components/Common/PageHeaderWrap';
import PageContentWrap from '../../components/Common/PageContentWrap';
import CashBoxWarningCont from '../CashBoxWarning';
import StatisticDateCont from '../StatisticDateCont';
import { IDate } from '../../types/IDate';
import { NoHostessWarning } from '../../features';

class UserStatisticCont extends PureComponent<IProps, {}> {
  componentDidMount(): void {
    const {
      getSourceStatistic,
      getReservesBySource,
      getSourceInfo,
      match,
      pages,
    } = this.props;
    const { source } = match.params;

    getSourceInfo(source);

    getSourceStatistic(source);

    getReservesBySource(
      source,
      { orderBy: 'date', order: 'desc' },
      pages.current,
      pages.size,
    );
  }

  /** @description Ручка для запроса списка резервов */
  handleGetReserves = (page: number, size: number, sortType: 'desc' | 'asc'): void => {
    const {
      getReservesBySource,
      match,
    } = this.props;

    getReservesBySource(
      match.params.source,
      { orderBy: 'date', order: sortType },
      page,
      size,
    );
  };

  /** @description Ручка для полчения статистики */
  handleGetStatistics = (date: IDate): void => {
    const {
      getSourceStatistic,
      getReservesBySource,
      match,
      pages,
    } = this.props;

    getSourceStatistic(
      match.params.source,
      date,
    );

    getReservesBySource(
      match.params.source,
      { orderBy: 'date', order: 'desc' },
      pages.current,
      pages.size,
      date,
    );
  };

  /** @description Открытие детальной инфы резерва */
  handleOpenBookingDetails = (bookingId: number) => {
    const { openBookingDetails } = this.props;

    openBookingDetails(bookingId);
  };

  render() {
    const {
      reserves, statistic, sourceInfo, loading, isInit, pages, bookingOrders, moneySymbol,
    } = this.props;

    return (
      <Fragment>
        <PageHeaderWrap>
          <BackButton text={locales.t('userStatistic.title')} />
          <StatisticDateCont handleGetStatistics={this.handleGetStatistics} />
        </PageHeaderWrap>
        <CashBoxWarningCont />
        <NoHostessWarning />
        {
          !isInit && loading
            ? null
            : (
              <PageContentWrap fullWidth>
                <Grid item xs={12} md={6}>
                  <SourceCard sourceInfo={sourceInfo} moneySymbol={moneySymbol} statistic={statistic} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <SourceStatisticTable moneySymbol={moneySymbol} statistic={statistic} />
                </Grid>
                <Grid item xs={12} lg={12}>
                  <SourceReserveTable
                    reserves={reserves}
                    pages={pages}
                    moneySymbol={moneySymbol}
                    bookingOrders={bookingOrders}
                    handleOpenBookingDetails={this.handleOpenBookingDetails}
                    handleGetReserves={this.handleGetReserves}
                  />
                </Grid>
              </PageContentWrap>
            )
        }
      </Fragment>
    );
  }
}

export default UserStatisticCont;
