import React from 'react';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography/Typography';

const styles = () => createStyles({
  wrap: {
    paddingBottom: 24,
    width: '100%',
  },
  numWrap: {
    marginTop: 20,
    display: 'flex',
  },
  num: {
    fontSize: '2em',
    fontWeight: 500,
  },
  numMetrics: {
    fontSize: 10,
    paddingTop: 14,
    paddingLeft: 8,
    width: '100%',
  },
  columnHeader: {
    height: 20,
    display: 'flex',
    alignItems: 'center',
  },
});

interface IProps extends WithStyles<typeof styles> {
  type: string;
  stat: number | string;
  color?: 'error' | 'primary';
}

const StatisticItem: React.FC<IProps> = ({
  classes, type, stat, color,
}) => {
  // @ts-ignore
  const parseStat = stat > 999 ? stat.toLocaleString() : stat;
  const textColor: any = color || 'initial';

  return (
    <div style={{ borderBottom: 'none', display: 'flex', flex: 1 }}>
      <div>
        <div className={classes.columnHeader}>
          <Typography variant="caption">
            { type }
          </Typography>
        </div>
        <div className={classes.numWrap}>
          <Typography variant="h4" className={classes.num} color={textColor}>
            { parseStat }
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(StatisticItem);
