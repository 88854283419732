// @todo Назвать типы и интерфейсы без путаницы

import moment, { Moment } from 'moment';

import { IRestaurantCall } from './IRestaurantCallsStore';
import { TOpenReserve } from './IBookingStore';
import { ITable } from './IRestaurantTablesStore';

export type TNotificationType = 'CALL' | 'RESERVE';
export type TNotificationPayload = IRestaurantCall | TOpenReserve;

export interface INotification {
  type: TNotificationType;
  payload: TNotificationPayload;
  open: boolean;
  offset?: number;
  fiftyFiftyOffset?: number;
  // maxOffset?: number;
}

export interface IWindowSize {
  width: number;
  height: number;
}

class Notification implements INotification {
  type: TNotificationType;

  payload: TNotificationPayload;

  open: boolean;

  offset: number | undefined;

  fiftyFiftyOffset: number | undefined;

  getKey = (): string => `${this.type}_${this.payload.id}`;

  getDate = (): Date => (this.payload.createdDate ? new Date(this.payload.createdDate) : new Date());

  getFoldHeight = (size?: IWindowSize): number => 158;

  getUnfoldHeight = (size?: IWindowSize): number => this.getFoldHeight(size) + 53;

  getLastVisit = (): Moment | undefined => (
    'guestLastVisitDate' in this.payload
    && this.payload.guestLastVisitDate
    && moment(this.payload.guestLastVisitDate)
  ) || undefined;

  getTotalVisits = (): number => ('guestTotalVisits' in this.payload && this.payload.guestTotalVisits) || 0;

  constructor(type: TNotificationType, payload: TNotificationPayload) {
    this.type = type;
    this.open = false;
    this.payload = payload;
    this.offset = undefined;
    this.fiftyFiftyOffset = undefined;
  }
}

export class CallNotification extends Notification {
  constructor(payload: IRestaurantCall) {
    super('CALL', payload);
  }

  getSortOrder = (): string => [
    // Активный звонок показывается самым первым
    this.payload.state === 'ACTIVE' ? '0' : '2',
    this.getDate().valueOf(),
  ].join(' ');

  getFirstName = (): string | undefined => (
    'guestFirstName' in this.payload && this.payload.guestFirstName
  ) || undefined;

  getLastName = (): string | undefined => (
    'guestLastName' in this.payload && this.payload.guestLastName
  ) || undefined;
}

export class ReserveNotification extends Notification {
  constructor(payload: TOpenReserve) {
    super('RESERVE', payload);
  }

  getSortOrder = (): string => ['1', this.getDate().valueOf()].join(' ');

  getFirstName = (): string | undefined => ('firstName' in this.payload && this.payload.firstName) || undefined;

  getLastName = (): string | undefined => ('lastName' in this.payload && this.payload.lastName) || undefined;

  getComment = (): string => {
    if ('comment' in this.payload && this.payload.comment) {
      const { comment } = this.payload;
      const noTailSpaces = comment.replace(/\s+$/, '');

      if (noTailSpaces.length > 140) {
        const clippedComment = noTailSpaces
          .substr(0, 136)
          .replace(/\s+$/, '');

        return `${clippedComment}...`;
      }

      return comment;
    }

    return '';
  }

  // @todo Длина строки подобрана на глаз. Можно лучше, так как будут варианты
  //   ширины окна и кол-ва текста, при которых будет рассчитываться неправильно?
  getCommentLines = (size?: IWindowSize): number => Math.ceil(
    this.getComment().length / (size && size.width < 600 && size.width > 520 ? 80 : 60),
  );

  getFoldHeight = (size?: IWindowSize): number => {
    const lines = this.getCommentLines(size);
    const baseHeight = 255;

    if (lines) {
      const indent = 16;
      const lineHeight = 16;

      // Если комментариев на одну строку, то высота иконки больше, чем высота строки,
      //  поэтому нужно отступить дополнительно
      return baseHeight + lines * lineHeight + indent + (lines === 1 ? 2 : 0);
    }

    return baseHeight;
  };

  // getUnfoldHeight = (size?: IWindowSize): number => this.getFoldHeight(size) + 53 + 17;
  getUnfoldHeight = (size?: IWindowSize): number => this.getFoldHeight(size) + 53 + 1;

  getVisitDate = (): Moment | undefined => {
    if ('date' in this.payload && this.payload.date) {
      return moment(this.payload.date);
    }

    return undefined;
  };

  isTable = (): boolean => !!(
    'tables' in this.payload
      && this.payload.tables
      && this.payload.tables.length > 0
      && this.payload.tables[0]
  );

  // isTable = (): boolean => {
  //   const payload:TOpenReserve = this.payload as TOpenReserve;
  //
  //   if (!payload) {
  //     return false;
  //   }
  //
  //   const { tables } = payload;
  //
  //   return !!(tables && tables.length && tables[0]);
  // };

  getTable = (): string => {
    if (this.isTable()) {
      // @ts-ignore
      return this.payload.tables.map((table: ITable) => `${table.hall}, ${table.number}`).join('; ');
    }

    return '';
  };
}

export type TNotification = CallNotification | ReserveNotification;

export type TNotifications = Array<TNotification>;

export interface INotificationsStore {
  items: TNotifications;
  open: boolean;
}
