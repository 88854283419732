import * as ActionTypes from '../actions/restaurantsTables/RestaurantsTablesTypes';
import { IAppActions } from '../types/IAppActions';
import { IRestaurantTablesStore } from '../types/IRestaurantTablesStore';

export const initState: IRestaurantTablesStore = {
  updateError: null,
  tableList: [],
  getTableError: null,
};

export default function reducer(state: IRestaurantTablesStore = initState, action: IAppActions): any {
  switch (action.type) {
    case ActionTypes.UPDATE_RESTAURANT_TABLE_SUCCESS: {
      return {
        ...state,
        getTableError: null,
      };
    }

    case ActionTypes.UPDATE_RESTAURANT_TABLE_ERROR:
      return {
        ...state,
        updateError: action.error,
      };

    case ActionTypes.GET_ALL_TABLE_SUCCESS:
      return {
        ...state,
        tableList: action.payload.tableList,
        getTableError: null,
      };

    case ActionTypes.GET_ALL_TABLE_ERROR:
      return {
        ...state,
        getTableError: action.error,
      };

    default:
      return state;
  }
}
