import React from 'react';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import locales from '../../locales';

interface IProps {
  handleClickOpen: () => void;
}

const InitMessages: React.FC<IProps> = ({ handleClickOpen }) => (
  <Paper
    elevation={3}
    style={{ padding: 24 }}
  >
    <div style={{ paddingBottom: 24 }}>
      <Typography
        variant="h6"
        gutterBottom
      >
        { locales.t('home.addRestaurantTitle') }
      </Typography>
      <Typography
        variant="body1"
        color="textSecondary"
        gutterBottom
      >
        { locales.t('home.initText1') }
      </Typography>
    </div>
    <Button
      type="submit"
      variant="contained"
      color="primary"
      onClick={handleClickOpen}
    >
      { locales.t('home.initBtnAdd') }
    </Button>
  </Paper>
);

export default InitMessages;
