import React from 'react';

import Paper from '@material-ui/core/Paper/Paper';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';

import Title from '../../Common/Title';
import locales from '../../../locales';
import UserStatisticTableItem from '../../Common/Statistic/StatisticTable/UserStatisticTableItem.tsx';

interface Props {
  statistic: {
    averageReceiptAmount: number;
    total: number;
    totalCancelledByGuest: number;
    totalCancelledByRestaurant: number;
    totalClosed: number;
    totalDeclined: number;
    totalReceiptAmount: number;
  };
}

const styles = createStyles({
  statisticsWrap: {
    display: 'flex',
    flexDirection: 'column',
    padding: 24,
    paddingTop: 16,
    width: '80%',
  },
  statisticRow: {
    display: 'flex',
    justifyContent: 'left',
    paddingBottom: 40,
  },
});

interface IProps extends Props, WithStyles<typeof styles> {}

const index: React.FC<IProps> = ({ statistic, classes }) => (
  <Paper elevation={3} style={{ height: '100%' }}>
    <Title text={locales.t('userStatistic.statisticTable.title')} />
    <div className={classes.statisticsWrap}>
      <div className={classes.statisticRow}>
        <UserStatisticTableItem
          type={locales.t('userStatistic.statisticTable.reservesRow.summary')}
          stat={statistic.total}
        />
        <UserStatisticTableItem
          type={locales.t('userStatistic.statisticTable.reservesRow.success')}
          color="primary"
          stat={statistic.totalClosed}
        />
        <UserStatisticTableItem
          type={locales.t('userStatistic.statisticTable.reservesRow.canceledByRestaurant')}
          stat={statistic.totalCancelledByRestaurant}
          color="error"
        />
      </div>
      <div className={classes.statisticRow}>
        <UserStatisticTableItem
          type={locales.t('userStatistic.statisticTable.reservesRow.declinedByRestaurant')}
          stat={statistic.totalDeclined}
        />
        <UserStatisticTableItem
          type={locales.t('userStatistic.statisticTable.reservesRow.canceledByGuest')}
          stat={statistic.totalCancelledByGuest}
        />
        <UserStatisticTableItem
          type={locales.t('userStatistic.statisticTable.reservesRow.guestDidNotCame')}
          stat={0}
        />
      </div>
    </div>
  </Paper>
);

export default withStyles(styles)(index);
