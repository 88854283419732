import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import StatisticsCont from './StatisticsCont';
import { IAppStore } from '../../types/IAppStore';
import { IDispatchProps, IStateProps } from './IStatistics';
import { IAppActions } from '../../types/IAppActions';
import { getStatistics, openBookingDetails } from '../../actions/statistics/StatisticsActions';

function mapStateToProps(state: IAppStore): IStateProps {
  return {
    currentRestaurantId: state.cabinet.currentRestaurant && state.cabinet.currentRestaurant.id,
    moneySymbol: state.cabinet.moneySymbol,
    summary: state.statistic.summary,
    pie: state.statistic.pie,
    reserves: state.statistic.reserves,
    pages: state.statistic.pages,
    bookingsOrders: state.statistic.bookingsOrders,
  };
}

function mapDispatchToProps(dispatch: Dispatch<IAppActions>): IDispatchProps {
  return bindActionCreators({
    getStatistics,
    openBookingDetails,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(StatisticsCont);
