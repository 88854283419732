import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import DeleteRestaurant from './DeleteRestaurant';
import { IAppStore } from '../../types/IAppStore';
import { IDispatchProps, IStateProps } from './IDeleteRestaurantCont';
import { IAppActions } from '../../types/IAppActions';
import { deleteRestaurant } from '../../actions/restaurant/RestaurantActions';

function mapStateToProps(state: IAppStore): IStateProps {
  const { currentRestaurant } = state.cabinet;
  return {
    currentRestaurant,
  };
}

function mapDispatchToProps(dispatch: Dispatch<IAppActions>): IDispatchProps {
  return bindActionCreators({
    deleteRestaurant,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteRestaurant);
