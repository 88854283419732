import React from 'react';
import { Group, Rect, Text } from 'react-konva';

import Konva from 'konva';
import {
  TSectionTableScheme,
} from '../../../../types/IRestaurantTablesSchemaStore';
import { ITable } from '../../../../types/IRestaurantTablesStore';
import rotateAroundCenter from './rotateHelper';
import { textCenter } from './textCenterHelper';
import { setCursor } from './utils';

const colorsMap = {
  selected: '#6ae9c5',
  disabled: '#707070',
  regular: '#ffffff',
};

interface IProps {
  data: TSectionTableScheme;
  handleSelect: (table: ITable) => void;
  active?: boolean;
  disabled?: boolean;
}

function TableElement(props: IProps) {
  const {
    handleSelect, data, active, disabled,
  } = props;
  const {
    x, y, angle, width, height,
  } = data;
  const text = String(data.table.number);

  const shapeRef = React.useRef(null);
  const textRef = React.useRef(null);
  React.useEffect(() => {
    textCenter(textRef.current, shapeRef.current);
  }, [
    textRef, shapeRef,
  ]);
  React.useEffect(() => {
    rotateAroundCenter(shapeRef.current, angle);
  }, [shapeRef, angle]);

  const onClick = (e: Konva.KonvaEventObject<MouseEvent>) => {
    e.evt.preventDefault();
    handleSelect(data.table);
  };

  const getFill = (): string => {
    if (active) return colorsMap.selected;
    if (disabled) return colorsMap.disabled;

    return colorsMap.regular;
  };

  const setCursorPointer = (e: Konva.KonvaEventObject<MouseEvent>) => {
    if (disabled) {
      setCursor('default')(e);
    } else {
      setCursor('pointer')(e);
    }
  };

  return (
    <Group>
      <Rect
        ref={shapeRef}
        x={x}
        y={y}
        width={width}
        height={height}
        fill={getFill()}
        onClick={onClick}
        onTap={onClick}
        stroke="#323232"
        strokeScaleEnabled
        strokeWidth={2}
        onMouseEnter={setCursorPointer}
        onMouseLeave={setCursor('default')}
      />
      <Text
        ref={textRef}
        x={x}
        y={y}
        text={text}
        fontFamily="GolosText, Microsoft Sans Serif"
        fontStyle="600"
        lineHeight={1.15}
        fontSize={20}
        align="center"
        verticalAlign="center"
        fill="#323232"
        offsetX={-(width / 2)}
        offsetY={-(height / 2)}
        onClick={onClick}
        onMouseEnter={setCursorPointer}
        onMouseLeave={setCursor('default')}
      />
    </Group>
  );
}

export default TableElement;
