import React from 'react';
import classNames from 'classnames';

import {
  withStyles,
  createStyles,
  WithStyles,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import Title from '../../Common/Title';
import { IWidgetAdapt } from '../../../types/IRestaurantWidgetsStore';
import locales from '../../../locales';
import EditIcon from '../../Common/Icons/EditIcon';
import TrashIcon from '../../Common/Icons/TrashIcon';
import CopyIcon from '../../Common/Icons/CopyIcon';

const styles = createStyles({
  link: {
    padding: 8,
    backgroundColor: '#f7f8fb',
    borderRadius: 5,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  pL: {
    paddingLeft: 24,
  },
  bntWrap: {
    padding: '10px 24px 24px 24px',
  },
  icon: {
    cursor: 'pointer',
  },
  trashIcon: {
    marginLeft: 12,
    cursor: 'pointer',
  },
  deleteBorder: {
    borderBottom: 'none',
  },
  firstCln: {
    width: '30%',
  },
  secondCln: {
    width: '60%',
  },
  thirdCln: {
    paddingRight: 24,
    width: '10%',
  },
});

interface Props extends WithStyles<typeof styles> {
  widgets: Array<IWidgetAdapt>;
  handleOpenWidgetModal: (type: 'create' | 'edit' | 'delete', widgetInfo?: IWidgetAdapt) => void;
  handleCustomMsg: () => void;
}

// TODO Поправить стили
const widgetsTable: React.FC<Props> = ({
  classes,
  widgets,
  handleOpenWidgetModal,
  handleCustomMsg,
}) => {
  const copyLink = (link: string) => {
    handleCustomMsg();
    navigator.clipboard.writeText(link);
  };

  return (
    <Paper elevation={3}>
      <Title
        text={locales.t('settings.widgets.title')}
        subTitle={locales.t('settings.widgets.desc')}
      />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classNames(classes.pL, classes.deleteBorder, classes.firstCln)}>
              { locales.t('settings.widgets.table.name') }
            </TableCell>
            <TableCell className={classNames(classes.deleteBorder, classes.secondCln)}>
              { locales.t('settings.widgets.table.link') }
            </TableCell>
            <TableCell className={classNames(classes.deleteBorder, classes.thirdCln)}>
              { locales.t('settings.widgets.table.action') }
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            widgets.map(w => (
              <TableRow key={w.name}>
                <TableCell className={classNames(classes.pL, classes.deleteBorder)} style={{ maxWidth: '0rem' }}>
                  <Typography noWrap style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    { w.name }
                  </Typography>
                </TableCell>
                <TableCell className={classes.deleteBorder}>
                  <div className={classes.link}>
                    { w.url }
                    <CopyIcon
                      className={classes.icon}
                      onClick={() => copyLink(w.url)}
                    />
                  </div>
                </TableCell>
                <TableCell className={classes.deleteBorder}>
                  <EditIcon
                    className={classes.icon}
                    onClick={() => handleOpenWidgetModal('edit', w)}
                  />
                  <TrashIcon
                    className={classes.trashIcon}
                    onClick={() => handleOpenWidgetModal('delete', w)}
                  />
                </TableCell>
              </TableRow>
            ))
          }
        </TableBody>
      </Table>
      <div className={classes.bntWrap}>
        <Button
          onClick={() => handleOpenWidgetModal('create')}
          variant="contained"
          color="primary"
        >
          { locales.t('settings.widgets.btn') }
        </Button>
      </div>
    </Paper>
  );
};

export default withStyles(styles)(widgetsTable);
