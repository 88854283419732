import * as ActionTypes from '../actions/restaurantsInfo/RestaurantsInfoActionsTypes';
import { IAppActions } from '../types/IAppActions';
import { IRestaurantInfoStore } from '../types/IRestaurantInfoStore';

export const initState: IRestaurantInfoStore = {
  updateError: null,
  updateProcess: false,
  updateStatus: false,
  connectProcess: false,
  connectStatus: false,
  error: undefined,
  connectError: undefined,
};

export default function reducer(state: IRestaurantInfoStore = initState, action: IAppActions): IRestaurantInfoStore {
  switch (action.type) {
    case ActionTypes.UPDATE_RESTAURANT_INFO_REQUEST:
      return {
        ...state,
        connectProcess: true,
      };

    case ActionTypes.UPDATE_RESTAURANT_INFO_SUCCESS:
      return {
        ...state,
        connectStatus: true,
        connectProcess: false,
        error: undefined,
      };

    case ActionTypes.UPDATE_RESTAURANT_INFO_ERROR:
      return {
        ...state,
        connectProcess: false,
        connectError: action.error,
      };

    default:
      return state;
  }
}
