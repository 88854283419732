import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';

import ReserveCreatePage from './ReserveCreatePage';
import { StateProps, DispatchProps } from './IReserveCreatePage';
import { IAppStore } from '../../types/IAppStore';
import { IAppActions } from '../../types/IAppActions';
import { customErrorShackBar } from '../../actions/snackbars/SnackBarsActions';
import { changeCurrentTime, changeSectionToFirstItem } from '../Reservation/actions';
import { selectorSectionAvailableOptions } from '../../reducers/selectors';
import { getReserveFormName } from '../Reservation/constants';

import {
  getFreeTables,
  closeReserveCreateModal,
  createReserve,
  setActiveTable,
  mergeTables,
} from '../../actions/reservationsPage/ReservationsPageActions';

import {
  selectorFreeTables,
  selectorForm,
  selectorCurrentSectionSchema,
  selectorSelectedAppReserve, selectorAlreadySelectedTables,
} from '../Reservation/selectors';

import {
  addAppReserve,
  removeAppReserve,
  openAppReserve,
  closeAppReserve,
  changeAppReserve,
} from '../../actions/appReserves/AppReservesActions';


const formName = getReserveFormName('CREATE');


function mapStateToProps(state: IAppStore): StateProps {
  const {
    activeTables,
    createReserveModalInitialData,
    createReserveModalIsOpen,
  } = state.reserveModal;

  const payload = createReserveModalInitialData || {};

  const { currentRestaurant } = state.cabinet;
  const { bookingDuration, isSchemasEnabled } = currentRestaurant
    ? currentRestaurant.settings
    : { bookingDuration: 2, isSchemasEnabled: false };

  return {
    formName,
    disabled: false,

    activeTables,
    modalOpen: createReserveModalIsOpen,
    isSchemasEnabled,
    schema: selectorCurrentSectionSchema(state),
    sections: selectorSectionAvailableOptions(state),
    freeTables: selectorFreeTables(state),
    workTime: state.schedule.restaurantWorkTimes,
    maxCapacity: state.reserveModal.maxCapacity,
    isMergeTables: state.reserveModal.isMergeTables,

    appReserves: state.appReserves,
    selectedAppReserve: selectorSelectedAppReserve(state),
    alreadySelectedTables: selectorAlreadySelectedTables(state),

    currentValue: {
      date: selectorForm(formName)(state, 'date'),
      time: selectorForm(formName)(state, 'time'),
      firstName: selectorForm(formName)(state, 'firstName'),
      phone: selectorForm(formName)(state, 'phone'),
      name: selectorForm(formName)(state, 'name'),
      persons: selectorForm(formName)(state, 'persons'),
      isBanquet: selectorForm(formName)(state, 'isBanquet'),
      hours: selectorForm(formName)(state, 'hours'),
      minutes: selectorForm(formName)(state, 'minutes'),
      section: selectorForm(formName)(state, 'section'),
    },

    initialData: {
      persons: payload.persons || 2,
      date: payload.date,
      time: payload.time || payload.date,
      section: payload.section || 0,
      firstName: payload.firstName,
      lastName: payload.lastName,
      phone: payload.phone,
      isBanquet: payload.isBanquet,
      comment: payload.comment,
      hours: payload.hours || Math.ceil(bookingDuration / 60).toString(),
    },

    locale: (currentRestaurant && currentRestaurant.address.countryCode) || 'RU',
    bookingDuration,
  };
}

function mapDispatchToProps(dispatch: Dispatch<IAppActions>): DispatchProps {
  return bindActionCreators({
    closeReserveCreateModal,
    createReserve,
    getFreeTables,
    setActiveTable,
    changeCurrentTime,
    changeSectionToFirstItem,
    mergeTables,
    customErrorShackBar,

    addAppReserve,
    removeAppReserve,
    openAppReserve,
    closeAppReserve,
    changeAppReserve,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ReserveCreatePage);
