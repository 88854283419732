export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const RESET_NOTIFICATIONS = 'RESET_NOTIFICATIONS';
export const TOGGLE_NOTIFICATIONS = 'TOGGLE_NOTIFICATIONS';
export const CLOSE_NOTIFICATIONS = 'CLOSE_NOTIFICATIONS';
export const OPEN_NOTIFICATIONS = 'OPEN_NOTIFICATIONS';

export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
export const TOGGLE_NOTIFICATION = 'TOGGLE_NOTIFICATION';

export const IDLE = 'IDLE';
