import React from 'react';
import classNames from 'classnames';

import AccountCircle from '@material-ui/icons/AccountCircle';
import Paper from '@material-ui/core/Paper';
import {
  Typography, createStyles, withStyles, WithStyles,
} from '@material-ui/core';
import moment from 'moment';

import locales from '../../../locales';
import { parsePhoneNumber } from '../../../helpers/reserveHelpers';
import UserStatisticTableItem
  from '../../Common/Statistic/StatisticTable/UserStatisticTableItem.tsx';

interface Props {
  userInfo: {
    firstVisit: string;
    lastVisit: string;
    isActive: boolean;
    name: string;
    phone: string;
  };
  statistic: {
    averageReceiptAmount: number;
    total: number;
    totalCancelledByGuest: number;
    totalCancelledByRestaurant: number;
    totalClosed: number;
    totalDeclined: number;
    totalReceiptAmount: number;
  };
  moneySymbol: string;
}

const styles = () => createStyles({
  wrap: {
    height: '100%',
    minHeight: 302,
  },
  roleWrap: {
    padding: '24px 24px 16px 24px',
    display: 'flex',
  },
  roleTitle: {
    backgroundColor: '#f7f8fb',
    padding: '0px 24px',
    height: 24,
    display: 'flex',
    alignItems: 'center',
  },
  activityDateWrap: {
    paddingTop: 12,
  },
  infoWrap: {
    display: 'flex',
    padding: '0 16px',
  },
  userInfoWrap: {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'column',
  },
  statusWrap: {
    padding: '4px 8px',
    textTransform: 'uppercase',
    backgroundColor: 'rgb(106, 233, 197, 0.3)',
    color: 'black',
  },
  statusWrap__deactivated: {
    backgroundColor: 'rgb(244, 88, 88, 0.3)',
  },
  userIcon: {
    width: 77,
    height: 77,
    marginRight: 14,
  },
  userStatWrap: {
    display: 'flex',
    padding: '50px 24px 50px 24px',
  },
});

interface IProps extends Props, WithStyles<typeof styles> {}

// userInfo && userInfo.isActive ? 'primary' : 'error'

const index: React.FC<IProps> = ({ userInfo, classes, statistic, moneySymbol }) => {
  const firstVisit = userInfo.firstVisit ? moment(userInfo.firstVisit).format('DD MMMM YYYY г.') : '—';
  const lastVisit = userInfo.lastVisit ? moment(userInfo.lastVisit).format('DD MMMM YYYY г.') : '—';

  const isActive = userInfo.isActive ? (
    <Typography className={classes.statusWrap} variant="overline">
      { locales.t('userStatistic.userCard.status.active') }
    </Typography>
  ) : (
    <Typography className={classNames(classes.statusWrap, classes.statusWrap__deactivated)} variant="overline">
      { locales.t('userStatistic.userCard.status.notActive') }
    </Typography>
  );

  return (
    <Paper elevation={3} className={classes.wrap}>
      <div>
        <div className={classes.roleWrap}>
          <div className={classes.roleTitle}>
            <Typography variant="body1">
              { locales.t('userStatistic.userCard.guest') }
            </Typography>
          </div>
        </div>
        <div className={classes.infoWrap}>
          <AccountCircle
            color="secondary"
            className={classes.userIcon}
          />
          <div>
            <Typography variant="h4">
              { userInfo ? userInfo.name : '—' }
            </Typography>
            <Typography variant="body1">
              { userInfo ? parsePhoneNumber(userInfo.phone) : '—' }
            </Typography>
            <div className={classes.userInfoWrap}>
              <div>
                { isActive }
              </div>
              <div className={classes.activityDateWrap}>
                <Typography variant="body2">
                  { `${locales.t('userStatistic.userCard.firstVisit')} ${firstVisit}` }
                </Typography>
                <Typography variant="body2">
                  { `${locales.t('userStatistic.userCard.lastVisit')} ${lastVisit}` }
                </Typography>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.userStatWrap}>
          <UserStatisticTableItem
            type={locales.t('userStatistic.statisticTable.checkRow.average')}
            stat={`${statistic.averageReceiptAmount.toFixed()} ${moneySymbol}`}
            color="primary"
            textAlign="center"
          />
          <UserStatisticTableItem
            type={locales.t('userStatistic.statisticTable.checkRow.totalVisit')}
            stat={`${statistic.total.toFixed()}`}
            textAlign="center"
          />
          <UserStatisticTableItem
            type={locales.t('userStatistic.statisticTable.checkRow.summary')}
            stat={`${statistic.totalReceiptAmount.toFixed()} ${moneySymbol}`}
            textAlign="center"
          />
        </div>
      </div>
    </Paper>
  );
};

export default withStyles(styles)(index);
