import { IAppActions } from '../types/IAppActions';
import { ISourceStatistic } from '../types/ISourceStatistic';
import {
  GET_SOURCE_STATISTIC_SUCCESS,
  GET_RESERVES_BY_SOURCE_SUCCESS,
  GET_SOURCE_INFO_SUCCESS,
} from '../actions/sourceStatistic/SourceStatisticActionsTypes';

const initState: ISourceStatistic = {
  isInit: false,
  sourceInfo: {
    createdDate: '',
    lastReserve: '',
    isActive: false,
    name: '',
    description: '',
  },
  statistic: {
    total: 0,
    totalCancelledByGuest: 0,
    totalCancelledByRestaurant: 0,
    totalDeclined: 0,
    totalClosed: 0,
    totalReceiptAmount: 0,
    averageReceiptAmount: 0,
    commissionAmount: 0,
  },
  reserves: [],
  pages: {
    current: 0,
    size: 10,
    first: true,
    last: true,
    totalPages: 0,
    totalElements: 0,
  },
};

export default function UStatisticReducer(state = initState, action: IAppActions): ISourceStatistic {
  switch (action.type) {
    case GET_SOURCE_STATISTIC_SUCCESS: {
      return {
        ...state,
        statistic: action.payload.sourceStats,
        isInit: true,
      };
    }
    case GET_RESERVES_BY_SOURCE_SUCCESS: {
      return {
        ...state,
        reserves: action.payload.reserveList,
        pages: action.payload.pages,
      };
    }
    case GET_SOURCE_INFO_SUCCESS: {
      return {
        ...state,
        sourceInfo: action.payload.source,
      };
    }
    default: return state;
  }
}
