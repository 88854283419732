import React from 'react';

import { withStyles, createStyles, WithStyles } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import Circle from '@material-ui/icons/FiberManualRecord';

const styles = createStyles({
  formWrap: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: '-8px',
  },
});

interface IProps extends WithStyles<typeof styles> {
  onChange: any;
  label: string;
  value?: boolean;
  isClickableLabel?: boolean;
  disabled?: boolean;
}

const CircleCheckBox: React.FC<IProps> = ({
  classes, onChange, label, value, isClickableLabel, disabled,
}) => {
  const CircleActive = <Circle color="secondary" style={{ fontSize: 36 }} />;
  const CircleInactive = <Circle color="primary" style={{ fontSize: 36 }} />;

  if (isClickableLabel) {
    return (
      <div>
        <label className={classes.formWrap}>
          <Checkbox
            style={{ padding: 0 }}
            color="primary"
            checked={!!value}
            onChange={onChange}
            icon={CircleActive}
            checkedIcon={CircleInactive}
            disabled={disabled}
          />
          <div style={{ paddingLeft: 8, cursor: 'pointer' }}>
            <Typography variant="body1">
              { label }
            </Typography>
          </div>
        </label>
      </div>
    );
  }

  return (
    <FormControl className={classes.formWrap}>
      <Checkbox
        style={{ padding: 0 }}
        color="primary"
        checked={!!value}
        onChange={onChange}
        icon={CircleActive}
        checkedIcon={CircleInactive}
        disabled={disabled}
      />
      <div style={{ paddingLeft: 8, cursor: 'pointer' }}>
        <Typography variant="body1">
          { label }
        </Typography>
      </div>
    </FormControl>
  );
};

export default withStyles(styles)(CircleCheckBox);
