import React from 'react';
import moment from 'moment';

import { createStyles, WithStyles, withStyles } from '@material-ui/core';

import locales from '../../../../locales';

const styles = createStyles({
  wrap: {
    display: 'flex',
    position: 'relative',
  },
  banquetLabel: {
    position: 'absolute',
    top: '-14px',
    fontSize: 11,
    color: '#f45858',
  },
});

interface IProps extends WithStyles<typeof styles> {
  reserveInfo: any;
}

const ReserveTime: React.FC<IProps> = ({ classes, reserveInfo }) => (
  <div className={classes.wrap}>
    {
      reserveInfo.isBanquet && (
        <span className={classes.banquetLabel}>
          { locales.t('booking.banquet') }
        </span>
      )
    }
    { moment(reserveInfo.date).format('DD MMMM YYYYг. HH:mm') }
  </div>
);

export default withStyles(styles)(ReserveTime);
