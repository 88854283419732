import * as ActionsTypes from './HomeActionTypes';
import { IHomeActions } from './IHomeActions';
import { IDate } from '../../types/IDate';

/** @description Экшен на получение пинкода */
export function getRestaurantPinCode(restaurantId: number): IHomeActions {
  return {
    type: ActionsTypes.GET_RESTAURANT_PIN_CODE_REQUEST,
    payload: {
      restaurantId,
    },
  };
}

/** @description Экшен на успешного получения пинкода */
export function getRestaurantPinCodeSuccess(pinCode: string, timeLeft: number): IHomeActions {
  return {
    type: ActionsTypes.GET_RESTAURANT_PIN_CODE_SUCCESS,
    payload: {
      pinCode,
      timeLeft,
    },
  };
}

/** @description Экшен для обработки ошибки при получении пинкода */
export function getRestaurantPinCodeError(error: string): IHomeActions {
  return {
    type: ActionsTypes.GET_RESTAURANT_PIN_CODE_ERROR,
    error,
  };
}

/** @description Экшен оповещения что ресторан подключен успешно */
export function connectRestaurantSuccess(): IHomeActions {
  return {
    type: ActionsTypes.CONNECT_RESTAURANT_SUCCESS,
    message: 'Ресторан успешно подключен',
  };
}

/** @description Сброс коннект статуса */
export function resetConnectStatus(): IHomeActions {
  return {
    type: ActionsTypes.RESET_CONNECT_STATUS,
  };
}

/** @description Экшен для отправки запроса для того что бы открыть модальное окно с пинкодом */
export function switchPinCodeModal(): IHomeActions {
  return {
    type: ActionsTypes.SWITCH_PIN_CODE_MODAL,
  };
}

/** @description Открытие модального окна для получения пинкода */
export function openPinCodeModal(): IHomeActions {
  return {
    type: ActionsTypes.OPEN_PIN_CODE_MODAL_STATE,
  };
}

/** @description Закрытие модального окна для получения пинкода */
export function closePinCodeModal(): IHomeActions {
  return {
    type: ActionsTypes.CLOSE_PIN_CODE_MODAL_STATE,
  };
}

/** @description Экшен на переключение приема резервов на стороне ресторана/кассы */
export function switchBookingProcessor(
  {
    restaurantId,
    processorType,
  }: {
    restaurantId: number;
    processorType: string
  },
): IHomeActions {
  return {
    type: ActionsTypes.SWITCH_RESERVE_PROCESSOR_REQUEST,
    payload: {
      restaurantId,
      processorType,
    },
  };
}

/** @description Экшен обработку переключения приема резервов на стороне ресторана/кассы */
export function switchBookingProcessorSuccess(): IHomeActions {
  return {
    type: ActionsTypes.SWITCH_RESERVE_PROCESSOR_SUCCESS,
  };
}

/** @description Экшен обработку ошибки переключения приема резервов на стороне ресторана/кассы */
export function switchBookingProcessorError(): IHomeActions {
  return {
    type: ActionsTypes.SWITCH_RESERVE_PROCESSOR_ERROR,
    error: 'Не удалось изменить место для приёма резервов',
  };
}

/** @description Экшен для авторизации в системе IIKO */
export function authFromIiko(authData: {login: string; password: string}, restaurantId: number): IHomeActions {
  return {
    type: ActionsTypes.AUTH_FORM_IIKO_REQUEST,
    payload: {
      authData,
      restaurantId,
    },
  };
}

/** @description Экшен для обработки успешного авторизации в IIKO */
export function authFromIikoSuccess(organizationList: Array<any>): IHomeActions {
  return {
    type: ActionsTypes.AUTH_FORM_IIKO_SUCCESS,
    message: 'Авторизация в сервисе iiko',
    payload: organizationList,
  };
}

/** @description Экшен для обработки ошибки авторизации в IIKO */
export function authFromIikoError(): IHomeActions {
  return {
    type: ActionsTypes.AUTH_FORM_IIKO_ERROR,
    error: 'Некорректные данные пользователя. Возможно, неправильно введен Логин или Пароль. Проверьте данные и повторите попытку. Для регистрации на портале перейдите на сайт http://iiko.biz/',
  };
}

/** @description Экшен для обработки ошибки получения списка организаций при авторизации в IIKO */
export function getOrganizationError(): IHomeActions {
  return {
    type: ActionsTypes.GET_ORGANIZATION_LIST_FORM_IIKO_ERROR,
    error: 'Не удалось получить список организаций в сервисе iiko',
  };
}

/** @description Экшен для добавления выбранной организаци */
export function selectOrganization(restaurantId: number, organizationId: string): IHomeActions {
  return {
    type: ActionsTypes.SELECT_ORGANIZATION_REQUEST,
    payload: {
      restaurantId,
      organizationId,
    },
  };
}

/** @description Экшен для обработки выбора организации */
export function selectOrganizationSuccess(): IHomeActions {
  return {
    type: ActionsTypes.SELECT_ORGANIZATION_SUCCESS,
    message: 'Выбранная организация успешно добавлена',
  };
}

/** @description Экшен для обработки ошибки выбора организации */
export function selectOrganizationError(): IHomeActions {
  return {
    type: ActionsTypes.SELECT_ORGANIZATION_ERROR,
    error: 'Не удалось добавить организацию',
  };
}

/** @description Экшен для получение статистики ресторана */
export function getRestaurantStatistics(id: number, date?: IDate): IHomeActions {
  return {
    type: ActionsTypes.GET_STATISTIC_REQUEST,
    payload: {
      id,
      date,
    },
  };
}

/** @description Экшен для успешного получения статистики ресторана */
export function getRestaurantStatisticsSuccess(statistic: any): IHomeActions {
  return {
    type: ActionsTypes.GET_STATISTIC_SUCCESS,
    payload: statistic,
  };
}

/** @description Экшен для обработки ошибки при получение статистики ресторана */
export function getRestaurantStatisticsError(): IHomeActions {
  return {
    type: ActionsTypes.GET_STATISTIC_ERROR,
  };
}

/** @description Экшен для получения данных загруженности ресторана */
export function getRestaurantAttendances(id: number, date?: IDate): IHomeActions {
  return {
    type: ActionsTypes.GET_ATTENDANCE_REQUEST,
    payload: {
      id,
      date,
    },
  };
}

/** @description Экшен для обработки получения данных загруженности ресторана */
export function getRestaurantAttendancesSuccess(attendance: any): IHomeActions {
  return {
    type: ActionsTypes.GET_ATTENDANCE_SUCCESS,
    payload: attendance,
  };
}

/** @description Экшен для обработки ошибки при получение данных загруженности ресторана */
export function getRestaurantAttendancesError(): IHomeActions {
  return {
    type: ActionsTypes.GET_ATTENDANCE_ERROR,
  };
}
