import {
  createStyles, Theme,
} from '@material-ui/core/styles';

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    marginTop: 64,
    [theme.breakpoints.down('xs')]: {
      marginTop: 54,
    },
  },
});

export default styles;
