import { reduce } from 'lodash';
import {
  IWidgetColor,
  IWidgetColorsAdapt,
  IWidget,
  IWidgetAdapt, IWidgetDataAdapt, IWidgetData,
} from '../../types/IRestaurantWidgetsStore';
import { fallbackValue } from './constants';


export function adapt(data: IWidget): IWidgetAdapt {
  const { colors } = data.settings || {
    colors: [],
  };
  return {
    ...data,
    settings: {
      ...Object.assign({}, fallbackValue.settings, data.settings),
      colors: reduce(colors,
        (result: IWidgetColorsAdapt, value: IWidgetColor): IWidgetColorsAdapt => ({
          ...result,
          [value.variable]: value.color,
        }),
        fallbackValue.settings ? fallbackValue.settings.colors : {}),
    },
  };
}

export function transform(data: IWidgetDataAdapt): IWidgetData {
  return {
    ...data,
    settings: {
      ...Object.assign({}, {
        isShowHeader: true,
        isEmailRequired: false,
        isLastNameRequired: false,
      }, data.settings),
      colors: reduce(
        (data.settings || {}).colors || {},
        (result: IWidgetColor[], value, key): IWidgetColor[] => {
          if (value) {
            result.push({
              variable: key,
              color: value,
            });
          }
          return result;
        }, [],
      ),
    },
  };
}
