import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import SocketErrorScreen from './SocketErrorScreen';
import { IAppStore } from '../../types/IAppStore';
import { IDispatchProps, IStateProps } from './ISocketErrorScreen';
import { IAppActions } from '../../types/IAppActions';
import { socketReconnect } from '../../actions/webSocketService/WebSocketActions';

function mapStateToProps(state: IAppStore): IStateProps {
  return {
    socketIsConnect: state.websocket.socketIsConnect,
    socketIsReconnect: state.websocket.socketIsReconnect,
  };
}

function mapDispatchToProps(dispatch: Dispatch<IAppActions>): IDispatchProps {
  return bindActionCreators({
    socketReconnect,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SocketErrorScreen);
