import React, { PureComponent, Fragment } from 'react';

import Grid from '@material-ui/core/Grid/Grid';

import { IProps, IState } from './IUserStatisticCont';
import locales from '../../locales';
import UserStatisticTable from '../../components/UserStatistic/UserStatisticTable';
import UserCard from '../../components/UserStatistic/UserCard';
import UserReserveTable from '../../components/UserStatistic/UserReserveTable';
import BackButton from '../../components/Common/BackButton';
import PageHeaderWrap from '../../components/Common/PageHeaderWrap';
import PageContentWrap from '../../components/Common/PageContentWrap';
import CashBoxWarningCont from '../CashBoxWarning';

class UserStatisticCont extends PureComponent<IProps, IState> {
  componentDidMount(): void {
    const { getUserStatistic, match } = this.props;
    getUserStatistic(match.params.phone, { orderBy: 'date', order: 'desc' });
  }

  handelChangeSort = (order: 'desc' | 'asc') => {
    const { getUserStatistic, match } = this.props;
    getUserStatistic(match.params.phone, { orderBy: 'date', order });
  };

  /** @description Открытие детальной инфы резерва */
  handleOpenBookingDetails = (bookingId: number) => {
    const { openBookingDetails } = this.props;

    openBookingDetails(bookingId);
  };

  render() {
    const {
      reserves, statistic, userInfo, loading, bookingsOrders, moneySymbol,
    } = this.props;

    return (
      <Fragment>
        <PageHeaderWrap>
          <BackButton text={locales.t('userStatistic.title')} />
        </PageHeaderWrap>
        <CashBoxWarningCont />
        {
          loading
            ? null
            : (
              <PageContentWrap fullWidth>
                <Grid item xs={12} md={6}>
                  <UserCard
                    userInfo={userInfo}
                    statistic={statistic}
                    moneySymbol={moneySymbol}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <UserStatisticTable statistic={statistic} />
                </Grid>
                <Grid item xs={12}>
                  <UserReserveTable
                    reserves={reserves}
                    handelChangeSort={this.handelChangeSort}
                    bookingsOrders={bookingsOrders}
                    handleOpenBookingDetails={this.handleOpenBookingDetails}
                    moneySymbol={moneySymbol}
                  />
                </Grid>
              </PageContentWrap>
            )
        }
      </Fragment>
    );
  }
}

export default UserStatisticCont;
