import React from 'react';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Typography from '@material-ui/core/Typography/Typography';
import Button from '@material-ui/core/Button/Button';
import { withStyles } from '@material-ui/core';

import history from '../../redux/history';

const GoBackButton = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '0',
    lineHeight: 1.5,
    '&:hover': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
    },
    '&:focus': {
      boxShadow: 'none',
    },
  },
})(Button);

const ButtonText = withStyles({
  root: {
    fontSize: 20,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.2,
    letterSpacing: 'normal',
    color: 'rgba(0, 0, 0, 0.54)',
  },
})(Typography);

interface OwnProps {
  text: string;
}

type Props = OwnProps;

const BackButton: React.FC<Props> = ({ text }) => (
  <GoBackButton onClick={history.goBack}>
    <ArrowBack fontSize="default" color="action" style={{ marginRight: 20 }} />
    <ButtonText>
      { text }
    </ButtonText>
  </GoBackButton>
);

export default BackButton;
