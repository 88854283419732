import React, { PureComponent } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';

import CountdownWrapper from '../../components/CountdownWrapper';

import { IProps } from './ISocketErrorScreen';
import locales from '../../locales';

class SocketErrorScreen extends PureComponent<IProps, {}> {
  socketReconnect = () => {
    const { socketReconnect, socketIsReconnect, socketIsConnect } = this.props;
    if (!socketIsReconnect && !socketIsConnect) socketReconnect();
  }

  render() {
    const { socketIsConnect, socketIsReconnect } = this.props;

    const msg = !socketIsReconnect ? (
      <DialogContentText>
        { locales.t('common.errors.websocket.badConnection') }
        <CountdownWrapper
          onReconnect={this.socketReconnect}
        />
      </DialogContentText>
    ) : (
      <CircularProgress />
    );

    return (
      <Dialog
        fullWidth
        maxWidth="sm"
        open={socketIsConnect === false}
      >
        <DialogTitle style={{ textAlign: 'center', padding: '24px 0 0 0' }}>
          {
            socketIsReconnect
              ? locales.t('common.errors.websocket.reconnect')
              : locales.t('common.errors.websocket.title')
          }
        </DialogTitle>
        <DialogContent style={{ textAlign: 'center' }}>
          { msg }
        </DialogContent>
        <DialogActions />
      </Dialog>
    );
  }
}

export default SocketErrorScreen;
