import {
  takeLatest, put, select, all,
} from 'redux-saga/effects';
import moment from 'moment';
import _ from 'lodash';

import * as HomeActionTypes from '../actions/home/HomeActionTypes';
import {
  authIikoReq,
  getRestaurantPinCodeReg,
  getRestaurantStatistic,
  switchBookingProcessorReq,
  selectOrganizationReq,
  getRestaurantAttendance, getOrganizationFromIiko,
} from '../api';
import { dashBoardDates } from '../config/constants';
import {
  getPeriod,
  getDateFormat,
} from '../helpers/dateHelpers';
import { getPercentDiff } from '../helpers/helpers';
import {
  getRestaurantPinCodeSuccess,
  getRestaurantPinCodeError,
  openPinCodeModal,
  closePinCodeModal,
  authFromIikoSuccess,
  authFromIikoError,
  getOrganizationError,
  selectOrganizationSuccess,
  selectOrganizationError,
  getRestaurantStatisticsSuccess,
  getRestaurantStatisticsError,
  getRestaurantAttendancesSuccess,
  getRestaurantAttendancesError,
  switchBookingProcessorSuccess,
  switchBookingProcessorError,
} from '../actions/home/HomeActions';
import { getRestaurantList } from '../actions/cabinet/CabinetActions';

/** @description Получение пинкода ресторана */
function* getRestaurantPinCodeSaga(action: any) {
  const { restaurantId } = action.payload;

  try {
    const response = yield getRestaurantPinCodeReg(restaurantId);
    const { pinCode, timeLeft } = response.data;

    yield put(getRestaurantPinCodeSuccess(pinCode, timeLeft));
  } catch (e) {
    yield put(getRestaurantPinCodeError(e));
  }
}

/** @description Открытие/закрытие модального окна с пинкодом. */
function* switchPinCodeModalSaga() {
  const modalOpen = yield select(state => state.home.pinCodeModal);
  const connectStatus = yield select(state => state.home.connectStatus);

  if (connectStatus) {
    yield put(closePinCodeModal());
  } else if (!modalOpen) {
    const restaurantId = yield select(state => state.cabinet.currentRestaurant.id);

    const response = yield getRestaurantPinCodeReg(restaurantId);
    const { pinCode, timeLeft } = response.data;

    yield put(getRestaurantPinCodeSuccess(pinCode, timeLeft));
    yield put(openPinCodeModal());
  } else {
    yield put(closePinCodeModal());
  }
}

/** @description Авторизация и получениее списка организаций в системе iiko */
function* authRestaurantToIikoSaga(action: any) {
  const { authData, restaurantId } = action.payload;
  try {
    yield authIikoReq(restaurantId, authData);
    try {
      const organizationList = yield getOrganizationFromIiko(restaurantId);
      yield put(authFromIikoSuccess(organizationList));
    } catch (e) {
      yield put(getOrganizationError());
    }
  } catch (e) {
    yield put(authFromIikoError());
  }
}

/** @description Отправка выбранной организации */
function* selectOrganizationFromIikoSaga(action: any) {
  const { restaurantId, organizationId } = action.payload;
  try {
    yield selectOrganizationReq(restaurantId, organizationId);
    yield put(selectOrganizationSuccess());
  } catch (e) {
    yield put(selectOrganizationError());
  }
}

/** @description Сага для переключения типа приёма резервов у ресторана */
function* switchBookingProcessorSaga(action: any) {
  const { restaurantId, processorType } = action.payload;

  try {
    yield switchBookingProcessorReq({
      restaurantId,
      processorType,
    });
    yield put(switchBookingProcessorSuccess());
    yield put(getRestaurantList());
  } catch (e) {
    yield put(switchBookingProcessorError());
  }
}

/** @description Получение статистики для выбранного ресторана */
function* getRestaurantStatisticSaga(action: any) {
  const { id, date } = action.payload;
  const defaultDate = yield select(state => state.cabinet.StatisticDate.value);

  try {
    const response = yield getRestaurantStatistic(id, (date && date.value) || defaultDate);
    const { data } = response;

    const totalSuccessful = {
      period: data.period.totalExpectingGuests + data.period.totalClosed,
      previous: data.previous.totalExpectingGuests + data.previous.totalClosed,
    };

    const totalCanceled = {
      period: data.period.totalCancelledByGuest + data.period.totalCancelledByRestaurant + data.period.totalSkipped,
      previous: data.previous.totalCancelledByGuest + data.previous.totalCancelledByRestaurant + data.previous.totalSkipped,
    };

    const statistic = {
      period: {
        ...data.period,
        averageReceiptAmount: data.period.averageReceiptAmount.toFixed(),
        totalSuccessful: totalSuccessful.period,
        totalCanceled: totalCanceled.period,
      },
      previous: {
        ...data.previous,
        averageReceiptAmount: data.period.averageReceiptAmount.toFixed(),
        totalSuccessful: totalSuccessful.previous,
        totalCanceled: totalCanceled.previous,
      },
      percent: {
        totalSuccessful: getPercentDiff(totalSuccessful.previous, totalSuccessful.period),
        totalCanceled: getPercentDiff(totalCanceled.previous, totalCanceled.period),
        averageReceiptAmount: getPercentDiff(
          data.previous.averageReceiptAmount,
          data.period.averageReceiptAmount,
        ),
        totalCancelledByGuest: getPercentDiff(
          data.previous.totalCancelledByGuest,
          data.period.totalCancelledByGuest,
        ),
        totalCancelledByRestaurant: getPercentDiff(
          data.previous.totalCancelledByRestaurant,
          data.period.totalCancelledByRestaurant,
        ),
      },
    };

    yield put(getRestaurantStatisticsSuccess(statistic));
  } catch (e) {
    yield put(getRestaurantStatisticsError());
  }
}

/** @description Получение данных загруженности для выбранного ресторатора */
function* getRestaurantAttendanceSaga(action: any) {
  const { id, date: reqDate } = action.payload;
  const defaultDate = yield select(state => state.cabinet.StatisticDate);
  const date = reqDate || defaultDate;

  let attendanceDate = date.value;
  let period: string;
  let dateFormat: string;

  moment.locale('ru');
  switch (date.type) {
    case dashBoardDates.today:
    case dashBoardDates.tomorrow: {
      period = 'HOUR';
      dateFormat = 'HH:mm';
      break;
    }
    case dashBoardDates.seven:
    case dashBoardDates.fourteen:
    case dashBoardDates.twentyEight:
    case dashBoardDates.sixty: {
      period = 'DAY';
      dateFormat = 'DD.MM';
      break;
    }
    case dashBoardDates.firstMonth:
    case dashBoardDates.secondMonth:
    case dashBoardDates.currentMonth: {
      period = 'MONTH';
      dateFormat = 'MM.YYYY';
      break;
    }
    case dashBoardDates.custom: {
      attendanceDate = date.value;
      period = getPeriod(attendanceDate);
      dateFormat = getDateFormat(attendanceDate);
      break;
    }

    default:
      period = 'HOUR';
      dateFormat = 'HH:mm';
  }

  try {
    const response = yield getRestaurantAttendance(
      id, attendanceDate.from, attendanceDate.to, period,
    );
    const { data } = response;

    const attendance = {
      labels: _.map(data, item => moment(item.date).format(dateFormat)),
      total: _.map(data, 'total'),
    };

    yield put(getRestaurantAttendancesSuccess(attendance));
  } catch (e) {
    yield put(getRestaurantAttendancesError());
  }
}

export default function* saga() {
  yield all([
    takeLatest(HomeActionTypes.AUTH_FORM_IIKO_REQUEST, authRestaurantToIikoSaga),
    takeLatest(HomeActionTypes.SELECT_ORGANIZATION_REQUEST, selectOrganizationFromIikoSaga),
    takeLatest(HomeActionTypes.SWITCH_RESERVE_PROCESSOR_REQUEST, switchBookingProcessorSaga),
    takeLatest(HomeActionTypes.GET_STATISTIC_REQUEST, getRestaurantStatisticSaga),
    takeLatest(HomeActionTypes.GET_ATTENDANCE_REQUEST, getRestaurantAttendanceSaga),
    takeLatest(HomeActionTypes.SWITCH_PIN_CODE_MODAL, switchPinCodeModalSaga),
    takeLatest(HomeActionTypes.GET_RESTAURANT_PIN_CODE_REQUEST, getRestaurantPinCodeSaga),
  ]);
}
