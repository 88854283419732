import React, { Component, Fragment } from 'react';
import Typography from '@material-ui/core/Typography/Typography';
import Button from '@material-ui/core/Button/Button';

import locales from '../../locales';
import UseRules from '../../components/Auth/CommonAuth/UseRules';
import Logo from '../../components/Auth/CommonAuth/Logo';
import RestorePasswordForm from '../../components/RestorePassword/RestorePasswordForm';
import AuthWrapComp from '../../components/Auth/AuthWrapComp';
import { IPropsResetPassword } from './IResetPassord';

class ResetPassword extends Component<IPropsResetPassword, {}> {
  /** @description Ручка экшена для сброса пароля */
  handleRestorePassword = ({ email }: any) => {
    const { restorePassword } = this.props;
    restorePassword(email);
  };

  /** @description Ручка повторной отправки экшена для сброса пароля */
  handleResend = () => {
    const { restorePassword, userEmail } = this.props;
    if (userEmail) {
      restorePassword(userEmail);
    }
  };

  render() {
    const { restorePasswordError, restorePasswordSuccess, userEmail } = this.props;

    const init = (
      <Fragment>
        <Logo text={locales.t('restorePassword.initTitle')} />
        <RestorePasswordForm onSubmit={this.handleRestorePassword} />
      </Fragment>
    );

    const success = (
      <Fragment>
        <Logo text={locales.t('restorePassword.finishTitle')} />
        <Typography variant="h6" align="center" style={{ marginTop: 20 }}>
          { locales.t('restorePassword.startMessage') }
          { userEmail || 'boris@mail.ru' }
          { locales.t('restorePassword.endMessage') }
        </Typography>
        <Typography align="center" variant="subtitle1" style={{ marginTop: 20 }}>
          { locales.t('restorePassword.subMessage') }
        </Typography>
        <Typography align="center" variant="subtitle1" style={{ marginTop: 20 }}>
          { locales.t('restorePassword.resendButtonDescription') }
        </Typography>
        <Button
          style={{ width: 226, height: 46, marginTop: 42 }}
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          onClick={this.handleResend}
        >
          { locales.t('restorePassword.resendButton') }
        </Button>
      </Fragment>
    );

    const content = !restorePasswordSuccess ? init : success;

    return (
      <AuthWrapComp>
        { content }
        { restorePasswordError && (
          <Typography style={{ marginTop: '20px' }} color="error" variant="subtitle1">
            { restorePasswordError }
          </Typography>)
        }
        <UseRules />
      </AuthWrapComp>
    );
  }
}

export default ResetPassword;
