import { IAppActions } from '../types/IAppActions';
import { IMenuStore } from '../types/IMenuStore';
import {
  CHANGE_VISIBLE_MENU_LIST_ITEM,
  GET_MENU_FROM_IIKO_SUCCESS,
  GET_MENU_SUCCESS,
  OPEN_ALL_MENU,
  CLOSE_ALL_MENU,
} from '../actions/menu/MenuActionsTypes';
import { changeVisibleById, changeVisibleForAll } from '../helpers/menuHelpers';


export const initState: IMenuStore = {
  id: 0,
  description: '',
  name: '',
  menuList: [],
};

export default function reducer(state = initState, action: IAppActions): IMenuStore {
  switch (action.type) {
    case GET_MENU_FROM_IIKO_SUCCESS:
    case GET_MENU_SUCCESS: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case CHANGE_VISIBLE_MENU_LIST_ITEM: {
      const id = action.payload;

      return {
        ...state,
        menuList: state.menuList.map(i => changeVisibleById(i, id)),
      };
    }

    case OPEN_ALL_MENU: {
      return {
        ...state,
        menuList: state.menuList.map(i => changeVisibleForAll(i, true)),
      };
    }

    case CLOSE_ALL_MENU: {
      return {
        ...state,
        menuList: state.menuList.map(i => changeVisibleForAll(i, false)),
      };
    }

    default:
      return state;
  }
}
