import Konva from 'konva';
// Source: https://konvajs.org/docs/posts/Position_vs_Offset.html

const rotatePoint = ({ x, y }: { x: number; y: number }, rad: number) => {
  const rcos = Math.cos(rad);
  const rsin = Math.sin(rad);
  return { x: x * rcos - y * rsin, y: y * rcos + x * rsin };
};

/**
 * Rotate node around it center, instead of top-left.
 * will work for shapes with top-left origin, like rectangle
 * Note: don't use `rotation` property, otherwise you can't get difference
 * @param node
 * @param rotation
 */
export default function rotateAroundCenter(
  node: Konva.Node | null,
  rotation: number,
) {
  if (!node) return;

  // current rotation origin (0, 0) relative to
  // desired origin - center (node.width() / 2, node.height() / 2)
  const topLeft = { x: -node.width() / 2, y: -node.height() / 2 };
  // @ts-ignore
  const current = rotatePoint(topLeft, Konva.getAngle(node.rotation()));
  // @ts-ignore
  const rotated = rotatePoint(topLeft, Konva.getAngle(rotation));
  const dx = rotated.x - current.x;
  const dy = rotated.y - current.y;

  node.rotation(rotation);
  node.x(node.x() + dx);
  node.y(node.y() + dy);
}
