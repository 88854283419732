import { createSelector } from 'reselect';
import { formValueSelector } from 'redux-form';
import { map } from 'lodash';
import moment from 'moment';

import { IAppStore } from '../../types/IAppStore';
import { ITable } from '../../types/IRestaurantTablesStore';

import {
  TSectionAdaptSchema,
  TRestaurantSectionAdapt,
} from '../../types/IRestaurantTablesSchemaStore';

import { selectorTableSectionsAvailable } from '../../reducers/selectors';
// import { isDismissed, sortNotifications } from '../../helpers/callHelpers';
import { sortNotifications } from '../../helpers/callHelpers';

import Reserve, { IAppReservesStore, TAppReserves } from '../../types/IAppReservesStore';
import { TModalTypes } from '../../pages/ReservationsPage';
import { getReserveFormName } from './constants';


export const selectorOpenModal = createSelector(
  [(state: IAppStore) => state.reserveModal],
  (reserveModal): TModalTypes => {
    const { createReserveModalIsOpen, confirmReserveModalIsOpen, editReserveModalIsOpen } = reserveModal;

    if (createReserveModalIsOpen) {
      return 'CREATE';
    }

    if (editReserveModalIsOpen) {
      return 'EDIT';
    }

    if (confirmReserveModalIsOpen) {
      return 'CONFIRM';
    }

    return null;
  },
);


/** @description Форма */
export const selectorForm = (formName: string) => formValueSelector(formName);

// const sectionIdSelector = (state: IAppStore) => selectorForm('aaa')(state, 'section');
export const sectionIdSelector = createSelector([
  (state: IAppStore) => state,
  selectorOpenModal,
], (state, openModal: TModalTypes) => selectorForm(getReserveFormName(openModal))(state, 'section'));


/** @description Текущая схема зала */
export const selectorCurrentSection = createSelector([
  selectorTableSectionsAvailable,
  sectionIdSelector,
], (sections: Array<TRestaurantSectionAdapt> | void, sectionId: number | void): TRestaurantSectionAdapt | void => {
  if (typeof sectionId === 'number' && Number(sectionId) > 0) {
    return (sections || []).find(d => d.id === sectionId);
  }
  return undefined;
});

/** @description Свободные столы для брони */
export const selectorFreeTables = createSelector([
  (state: IAppStore) => state.reserveModal.freeTables,
  selectorCurrentSection,
], (freeTables: Array<ITable> | void, section: TRestaurantSectionAdapt | void) => {
  if (section) {
    const ids = map(section.tables, table => table.id);
    return freeTables ? freeTables.filter((d) => {
      const sameSection = d.section ? d.section.id === section.id : false;
      return sameSection && ids.includes(d.id);
    }) : undefined;
  }
  return freeTables;
});

/** @description Текущая схема зала */
export const selectorCurrentSectionSchema = createSelector(
  [selectorCurrentSection],
  (section: TRestaurantSectionAdapt | void): TSectionAdaptSchema | void => (section ? section.schema : undefined),
);


// Уведомления

const notifications = (state: IAppStore) => state.notifications.items;

// @todo Проверить перерисовку
export const notificationsSelector = createSelector(
  notifications,
  (notifications) => {
    if (notifications) {
      return notifications
        // .filter(n => !isDismissed(n.getKey()))
        .sort(sortNotifications);
    }

    return [];
  },
);


// Открытые на редактирование резервы

export const selectorSelectedAppReserve = createSelector(
  [(state: IAppStore) => state.appReserves.items],
  (reserves: TAppReserves) => reserves.find((reserve: Reserve) => reserve.open),
);

export const selectorLastAppReserve = createSelector(
  [(state: IAppStore) => state.appReserves],
  (appReserves: IAppReservesStore) => {
    const { items, lastKey } = appReserves;
    return lastKey && items.find((i: Reserve) => i.key === lastKey);
  },
);

export const selectorAlreadySelectedTables = createSelector(
  [(state: IAppStore) => state.appReserves, selectorSelectedAppReserve],
  (appReserves: IAppReservesStore, selectedReserve?: Reserve) => {
    const { items } = appReserves;

    let selectedTableDate: string;
    if (selectedReserve && selectedReserve.payload.date) {
      selectedTableDate = moment(selectedReserve.payload.date).format('L');
    }

    return items
      .filter((item: Reserve) => {
        if (item.open || !item.payload.date || !selectedTableDate) {
          return false;
        }

        return moment(item.payload.date).format('L') === selectedTableDate;
      })
      .reduce((accumulator: Array<number>, item: Reserve) => {
        if (item.payload && item.payload.tables) {
          return accumulator.concat(
            item.payload.tables.map((j: ITable) => j.id),
          );
        }

        return accumulator;
      }, []);
  },
);
