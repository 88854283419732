import React, { Fragment, useState, useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import { withStyles, WithStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import CloseIcon from '@material-ui/icons/Close';
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

import styles from './styles';
import Header from '../Header/Header';
import SideBar from '../../SideBar/SideBar';
import logo from '../../../images/logo.svg';
import { Routers } from '../../../config/constants';
import { Employees } from '../../../entities';

interface IProps extends WithStyles<typeof styles> {
  isRegistrationCompleted: boolean;
  pathname: string;
  handleOpenReserveCreateModal?: () => void;
  reservationsIsOpen: boolean;
}

const DrawerAndHeaderComp: React.FC<IProps> = ({
  isRegistrationCompleted,
  pathname,
  classes,
  handleOpenReserveCreateModal,
  reservationsIsOpen,
}) => {
  const [open, changeVisible] = useState(false);

  const { data: currentEmployee } = Employees.useCurrentEmployee();

  /** @description Ручка для открытия/закрытия главного меню */
  const handleSwitchDrawerOpen = useCallback(() => {
    changeVisible(!open);
  }, [open]);

  /** @description Ручка для закрытия главного меню */
  const handleSwitchDrawerClose = useCallback(() => {
    changeVisible(false);
  }, []);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Fragment>
      <AppBar
        position="fixed"
        className={classes.appBar}
      >
        <Toolbar style={{ padding: '0 12px 0 16px' }}>
          {currentEmployee && (
            <IconButton
              aria-label="Open drawer"
              onClick={handleSwitchDrawerOpen}
              className={classes.menuButton}
            >
              {
                open
                  ? <CloseIcon style={{ fontSize: 32 }} />
                  : <MenuIcon style={{ fontSize: 32 }} />
              }
            </IconButton>
          )}

          <Header isRegistrationCompleted={isRegistrationCompleted} />
        </Toolbar>
      </AppBar>
      <Drawer
        variant={matches ? 'permanent' : 'temporary'}
        className={classNames({ [classes.drawerOpen]: matches })}
        classes={{ paper: classes.drawerOpen }}
        open={open}
        onClose={handleSwitchDrawerClose}
      >
        { reservationsIsOpen && <div className={classes.isDisabled} /> }
        <div className={classes.toolbar}>
          <NavLink to={Routers.home}>
            <img className={classes.logo} src={logo} alt="logo" />
          </NavLink>
        </div>

        <SideBar
          isRegistrationCompleted={isRegistrationCompleted}
          pathname={pathname}
          handleSwitchDrawerClose={handleSwitchDrawerClose}
          handleOpenReserveCreateModal={handleOpenReserveCreateModal}
        />
      </Drawer>
    </Fragment>
  );
};

export default withStyles(styles, { withTheme: true })(DrawerAndHeaderComp);
