import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Box, Button } from '@material-ui/core';

import locales from '../../../../../locales';
import { ReserveNotification } from '../../../../../types/INotificationsStore';
import { errorHoverColor, errorColor } from '../../../../../theme';
import Reserve from '../../../../../types/IAppReservesStore';
import { TOpenReserve } from '../../../../../types/IBookingStore';

interface ReserveFooterProps {
  notification: ReserveNotification;
  onRemove: (notificationKey: string, itemId: number) => void;
  onConfirm: (notificationKey: string, itemId: number) => void;
  // onEdit: (notificationKey: string, itemId: number) => void;
  onEdit: (notificationKey: string, reserve: Reserve) => void;
}

const useStyles = makeStyles(theme => ({
  secondaryButtonWrapper: {
    width: '50%',
    paddingRight: theme.spacing(1),
    '& button': {
      height: 48,
      width: '100%',
      backgroundColor: errorColor,
      // color: errorContrastText,
      color: 'white',
    },
    '& button:hover': {
      backgroundColor: errorHoverColor,
    },
  },
  buttonWrapper: {
    width: '50%',
    paddingLeft: theme.spacing(1),
    '& button': {
      height: 48,
      width: '100%',
    },
  },
}));

function ReserveFooter(props: ReserveFooterProps) {
  const {
    notification: n,
    onRemove,
    onConfirm,
    onEdit,
  } = props;

  const classes = useStyles();

  return (
    <>
      <Box display="flex" mb={1}>
        <Box className={classes.secondaryButtonWrapper}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => onRemove(n.getKey(), n.payload.id)}
          >
            {locales.t('notifications.reserve.actions.cancel')}
          </Button>
        </Box>
        <Box className={classes.buttonWrapper}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => onConfirm(n.getKey(), n.payload.id)}
            disabled={!n.isTable()}
          >
            {locales.t('notifications.reserve.actions.confirm')}
          </Button>
        </Box>
      </Box>
      <Box display="flex" justifyContent="center">
        <Button
          style={{
            textTransform: 'uppercase',
            color: '#e7ebee',
          }}
          onClick={() => onEdit(
            n.getKey(),
            new Reserve('WIDGET', n.payload as TOpenReserve),
          )}
        >
          {locales.t(n.isTable()
            ? 'notifications.reserve.actions.anotherTable'
            : 'notifications.reserve.actions.table')}
        </Button>
      </Box>
    </>
  );
}

export default ReserveFooter;
