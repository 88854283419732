import React, { Fragment } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { SnackbarProvider } from 'notistack';
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
} from '@material-ui/core';

import Routers from '../../routers';

const styles = (theme: Theme) => createStyles({
  info: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  error: {
    color: theme.palette.error.contrastText,
  },
});

interface IProps extends WithStyles<typeof styles> {}

const AppComp: React.FC<IProps> = ({ classes }) => (
  <SnackbarProvider
    maxSnack={100}
    classes={{
      variantInfo: classes.info,
    }}
  >
    <Fragment>
      <CssBaseline />
      <Routers />
    </Fragment>
  </SnackbarProvider>
);

export default withStyles(styles)(AppComp);
