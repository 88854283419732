import React, { Fragment } from 'react';
import wrapSvgPath from './wrapSvgPath';

const placeholderPath = (
  <Fragment>
    <g id="placeholders" transform="translate(-1.221)">
      <path
        id="Path_611"
        color="#c5c5c5"
        d="M5.468 7.3C3 7.3 1.587 8.439 1.278 10.685a7.141 7.141 0 0 0 .133 2.515 17.213 17.213 0 0 0 4.057 7.924A17.2 17.2 0 0 0 9.525 13.2a7.141 7.141 0 0 0 .133-2.512C9.349 8.439 7.94 7.3 5.468 7.3zm0 6.268a2.584 2.584 0 0 1-2.639-2.519 2.584 2.584 0 0 1 2.639-2.517 2.584 2.584 0 0 1 2.639 2.519 2.584 2.584 0 0 1-2.639 2.518z"
        data-name="Path 611"
        transform="translate(0 -5.122)"
      />
      <path
        id="Path_612"
        color="#c5c5c5"
        d="M34 3.384C33.688 1.138 32.279 0 29.807 0c-2.275 0-3.648.967-4.093 2.871a4.3 4.3 0 0 1 1.242 2.634 7.576 7.576 0 0 1-.139 2.663c-.048.217-.1.426-.156.635a16.6 16.6 0 0 0 3.146 5.017 17.2 17.2 0 0 0 4.057-7.92A7.148 7.148 0 0 0 34 3.384zm-4.19 2.884a2.583 2.583 0 0 1-2.642-2.518 2.642 2.642 0 0 1 5.278 0 2.584 2.584 0 0 1-2.639 2.518z"
        data-name="Path 612"
        transform="translate(-16.833)"
      />
    </g>
  </Fragment>
);

export default wrapSvgPath(placeholderPath, ('0 0 18 18'));
