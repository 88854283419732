import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, Dialog, Typography } from '@material-ui/core';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';

import locales from '../../../locales';
import { errorColor } from '../../../theme';
import DialogTitle from '../../../components/Common/DialogTitle';

const DialogContent = withStyles(() => ({
  root: {
    display: 'flex',
    padding: 24,
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: 24,
  },
}))(MuiDialogActions);

interface IProps {
  onClose: () => void;
  onSubmit: () => void;
}

const Confirmation: React.FC<IProps> = ({ onClose, onSubmit }) => (
  <Dialog open={true} fullWidth maxWidth="md">
    <DialogTitle onClose={onClose}>
      {locales.t('cashbox.settings.actions.delete.confirmation.title')}
    </DialogTitle>
    <DialogContent>
      <Typography>
        {locales.t('cashbox.settings.actions.delete.confirmation.description')}
      </Typography>
    </DialogContent>
    <DialogActions>
      <Button
        variant="contained"
        onClick={onClose}
        color="secondary"
        style={{
          color: 'white',
          width: 108,
          textTransform: 'uppercase',
        }}
      >
        {locales.t('cashbox.settings.actions.delete.confirmation.cancel')}
      </Button>
      <Button
        variant="contained"
        onClick={onSubmit}
        style={{
          color: 'white',
          backgroundColor: errorColor,
          textTransform: 'uppercase',
        }}
      >
        {locales.t('cashbox.settings.actions.delete.confirmation.submit')}
      </Button>
    </DialogActions>
  </Dialog>
);

export default Confirmation;
