import * as ActionsTypes from './RestaurantsWidgetsTypes';
import { IRestaurantsWidgetsActions } from './IRestaurantsWidgetsActions';
import {
  IWidgetAdapt,
  IWidgetDataAdapt,
} from '../../types/IRestaurantWidgetsStore';

/** @description Создание виджета */
export function createWidget(data: IWidgetDataAdapt): IRestaurantsWidgetsActions {
  return {
    type: ActionsTypes.CREATE_WIDGET_REQUEST,
    payload: data,
  };
}

/** @description Обработка успешного оздание виджета */
export function createWidgetSuccess(newWidget: IWidgetAdapt): IRestaurantsWidgetsActions {
  return {
    type: ActionsTypes.CREATE_WIDGET_SUCCESS,
    payload: {
      newWidget,
    },
    message: 'Создан новый виджет',
  };
}

/** @description Обработка ошибки при создании виджета */
export function createWidgetError(): IRestaurantsWidgetsActions {
  return {
    type: ActionsTypes.CREATE_WIDGET_ERROR,
    error: 'Не удалось создать виджет',
  };
}

/** @description Редактирования выбранного виджета */
export function updateWidget(
  widgetId: number,
  data: IWidgetDataAdapt,
): IRestaurantsWidgetsActions {
  return {
    type: ActionsTypes.UPDATE_WIDGET_REQUEST,
    payload: {
      widgetId,
      data,
    },
  };
}

/** @description Обработка успешного редактирования виджета */
export function updateWidgetSuccess(widget: IWidgetAdapt): IRestaurantsWidgetsActions {
  return {
    type: ActionsTypes.UPDATE_WIDGET_SUCCESS,
    payload: {
      widget,
    },
    message: 'Виджет отредактирован',
  };
}

/** @description Обработка ошибки при попытке отредактировать виджет */
export function updateWidgetError(): IRestaurantsWidgetsActions {
  return {
    type: ActionsTypes.UPDATE_WIDGET_ERROR,
    error: 'Не удалось отредактировать виджет',
  };
}

/** @description Запрос на получение всех виджетов */
export function getAllWidgets(): IRestaurantsWidgetsActions {
  return {
    type: ActionsTypes.GET_ALL_WIDGETS_REQUEST,
  };
}

/** @description Обработка успешного получения всех виджетов */
export function getAllWidgetsSuccess(widgets: Array<IWidgetAdapt>): IRestaurantsWidgetsActions {
  return {
    type: ActionsTypes.GET_ALL_WIDGETS_SUCCESS,
    payload: {
      widgets,
    },
  };
}

/** @description Обработка ошибки при получении всех виджетов */
export function getAllWidgetsError(): IRestaurantsWidgetsActions {
  return {
    type: ActionsTypes.GET_ALL_WIDGETS_ERROR,
    error: 'Не удалось получить список виджетов',
  };
}

/** @description Удаление выбранного виджета */
export function deleteWidget(widgetId: number): IRestaurantsWidgetsActions {
  return {
    type: ActionsTypes.DELETE_WIDGET_REQUEST,
    payload: {
      widgetId,
    },
  };
}

/** @description Обработка успешного удаление выбранного виджета */
export function deleteWidgetSuccess(widgetId: number): IRestaurantsWidgetsActions {
  return {
    type: ActionsTypes.DELETE_WIDGET_SUCCESS,
    payload: {
      widgetId,
    },
    message: 'Виджет удален',
  };
}

/** @description Обработка ошибки при удаление выбранного виджета */
export function deleteWidgetError(): IRestaurantsWidgetsActions {
  return {
    type: ActionsTypes.DELETE_WIDGET_ERROR,
    error: 'Не удалось удалить виджет',
  };
}
