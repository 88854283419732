import {
  all, put, takeLatest, select, call
} from 'redux-saga/effects';

import * as Api from '../api';
import * as ActionTypes from '../actions/statistics/StatisticsActionsTypes';
import {
  getStatisticsSuccess,
  getStatisticsError,
  openBookingDetailsSuccess, openBookingDetailsError,
} from '../actions/statistics/StatisticsActions';
import locales from '../locales';
import { parseReserveList } from '../helpers/reserveHelpers';
import { TReserve } from '../types/IBookingStore';
import { customErrorShackBar } from '../actions/snackbars/SnackBarsActions';
import { IGetStatistics, IOpenBookingDetailsReq } from '../actions/statistics/IStatisticsActions';

/** @description Сага для получение статистики */
function* getStatisticSaga(action: IGetStatistics) {
  const {
    date: dateReq, sort, page, size,
  } = action.payload;
  const restaurantId = yield select(state => state.cabinet.currentRestaurant.id);
  const defaultDate = yield select(state => state.cabinet.StatisticDate.value);
  const date = (dateReq && dateReq.value) || defaultDate;

  let summary;
  let pie;
  let reserves;

  try {
    summary = yield Api.getSummaryStatistic(restaurantId, date);
  } catch (e) {
    yield put(customErrorShackBar(locales.t('common.errors.getStatistic.summary')));
  }

  try {
    pie = yield Api.getStatisticPie(restaurantId, date);
  } catch (e) {
    yield put(customErrorShackBar(locales.t('common.errors.getStatistic.pie')));
  }

  try {
    reserves = yield Api.getAllBookingsByRestaurantIdWithPageable(restaurantId, date, sort, page, size);
  } catch (e) {
    yield put(customErrorShackBar(locales.t('common.errors.getStatistic.reserve')));
  }

  if (summary !== undefined || pie !== undefined || reserves !== undefined) {
    const parseReserves = reserves.content.map((i: TReserve) => parseReserveList(i));

    yield put(getStatisticsSuccess(
      summary,
      pie,
      parseReserves,
      {
        current: reserves.number,
        size: reserves.size,
        first: reserves.first,
        last: reserves.last,
        totalPages: reserves.totalPages,
        totalElements: reserves.totalElements,
      },
    ));
  } else {
    yield put(getStatisticsError());
  }
}

/** @description Сага для получения инфы по резерву для отображения в статистике */
export function* openBookingDetails(action: IOpenBookingDetailsReq) {
  try {
    const bookingOrders = yield call(Api.getOrderByBookingIdReq, action.payload.bookingId);
    yield put(openBookingDetailsSuccess(action.payload.bookingId, bookingOrders));
  } catch (e) {
    yield put(openBookingDetailsError('Не удалось получить состав резерва'));
  }
}

export default function* saga() {
  yield all([
    takeLatest(ActionTypes.GET_SUMMARY_STATISTICS_REQUEST, getStatisticSaga),
    takeLatest(ActionTypes.OPEN_BOOKING_DETAILS, openBookingDetails),
  ]);
}
