import React from 'react';
import moment from 'moment';
import { WrappedFieldProps } from 'redux-form';

import { DatePicker } from '@material-ui/pickers';
import TextField from '@material-ui/core/TextField';

import locales from '../../../locales';
import { emptyTime } from '../../../config/constants';
import { errorColor } from '../../../theme';

interface IProps extends WrappedFieldProps {}

// TODO Переписать логику отображения значений для "Сегодня" и "Выберете дату"
const ReserveDatePicker: React.FC<IProps> = ({
  input,
  meta: { touched, error },
}) => {
  const renderDate = input.value !== emptyTime && moment(input.value).isSame(moment(), 'day')
    ? ''
    : input.value;

  const isError = touched && error;

  const FieldComp = (props: any) => {
    const {
      error, label, value, onClick, inputRef,
    } = props;
    const colorError = error ? { color: errorColor } : {};

    return (
      <TextField
        inputRef={inputRef}
        value={value}
        onClick={onClick}
        label={label}
        error={error}
        fullWidth
        InputProps={{
          style: colorError,
        }}
      />
    );
  };

  return (
    <DatePicker
      {...input}
      autoOk
      variant="inline"
      value={!input.value || input.value === emptyTime ? null : renderDate}
      label={locales.t('common.reserveModal.selectDate')}
      disablePast
      format="DD.MM.YYYY"
      error={isError}
      invalidLabel={locales.t('common.rangeDatePicker.todayLabel')}
      emptyLabel={locales.t('common.rangeDatePicker.blank')}
      TextFieldComponent={FieldComp}
    />
  );
};

export default ReserveDatePicker;
