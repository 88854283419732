import Konva from 'konva';

const getOffset = (height: number, textHeight: number): number => -(height / 2) + textHeight / 2;


/**
 * Center text within { width, height }
 * @param textNode
 * @param { width: number, height : number }
 */
export function textCenterBox(
  textNode: Konva.Text | null,
  { width, height }: {
    width: number, height : number
  },
) {
  if (!textNode || !width || !height) return;

  const offsetX = getOffset(width, textNode.width());
  const offsetY = getOffset(height, textNode.height());
  textNode.offsetX(offsetX);
  textNode.offsetY(offsetY);
}
/**
 * Center text within Shape
 * @param textNode
 * @param shapeNode
 */
export function textCenter(
  textNode: Konva.Text | null,
  shapeNode: Konva.Node | null,
) {
  if (!textNode || !shapeNode) return;

  const offsetX = getOffset(shapeNode.width(), textNode.width());
  const offsetY = getOffset(shapeNode.height(), textNode.height());
  textNode.offsetX(offsetX);
  textNode.offsetY(offsetY);
}

export default {
  textCenterBox,
  textCenter,
};
