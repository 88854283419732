export const CONNECT_RESTAURANT_SUCCESS = 'REGISTER_RESTAURANT_SUCCESS';
export const RESET_CONNECT_STATUS = 'RESET_CONNECT_STATUS';

export const AUTH_FORM_IIKO_REQUEST = 'AUTH_FORM_IIKO_REQUEST';
export const AUTH_FORM_IIKO_SUCCESS = 'AUTH_FORM_IIKO_SUCCESS';
export const AUTH_FORM_IIKO_ERROR = 'AUTH_FORM_IIKO_ERROR';

export const GET_ORGANIZATION_LIST_FORM_IIKO_ERROR = 'GET_ORGANIZATION_LIST_FORM_IIKO_ERROR';
export const SELECT_ORGANIZATION_REQUEST = 'SELECT_ORGANIZATION_REQUEST';
export const SELECT_ORGANIZATION_SUCCESS = 'SELECT_ORGANIZATION_SUCCESS';
export const SELECT_ORGANIZATION_ERROR = 'SELECT_ORGANIZATION_ERROR';

export const SWITCH_PIN_CODE_MODAL = 'SWITCH_PIN_CODE_MODAL';
export const CLOSE_PIN_CODE_MODAL_STATE = 'CLOSE_PIN_CODE_MODAL_STATE';
export const OPEN_PIN_CODE_MODAL_STATE = 'OPEN_PIN_CODE_MODAL_STATE';

export const SWITCH_RESERVE_PROCESSOR_REQUEST = 'SWITCH_RESERVE_PROCESSOR_REQUEST';
export const SWITCH_RESERVE_PROCESSOR_SUCCESS = 'SWITCH_RESERVE_PROCESSOR_SUCCESS';
export const SWITCH_RESERVE_PROCESSOR_ERROR = 'SWITCH_RESERVE_PROCESSOR_ERROR';

export const GET_STATISTIC_REQUEST = 'GET_STATISTIC_REQUEST';
export const GET_STATISTIC_SUCCESS = 'GET_STATISTIC_SUCCESS';
export const GET_STATISTIC_ERROR = 'GET_STATISTIC_ERROR';

export const GET_ATTENDANCE_REQUEST = 'GET_ATTENDANCE_REQUEST';
export const GET_ATTENDANCE_SUCCESS = 'GET_ATTENDANCE_SUCCESS';
export const GET_ATTENDANCE_ERROR = 'GET_ATTENDANCE_ERROR';

export const GET_RESTAURANT_PIN_CODE_REQUEST = 'GET_RESTAURANT_PIN_CODE_REQUEST';
export const GET_RESTAURANT_PIN_CODE_SUCCESS = 'GET_RESTAURANT_PIN_CODE_SUCCESS';
export const GET_RESTAURANT_PIN_CODE_ERROR = 'GET_RESTAURANT_PIN_CODE_ERROR';
