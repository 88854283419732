import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';

import {
  updateRestaurantInfo,
} from '../../actions/restaurantsInfo/RestaurantsInfoActions';
import ReservesSystemInfoCont from './ReservesSystemInfoCont';
import { IAppStore } from '../../types/IAppStore';
import { IAppActions } from '../../types/IAppActions';
import { IDispatchProps, IStateProps } from './IReservesSystemInfo';
import {
  addNewScheduleRow,
  deleteScheduleRow,
  getScheduleList,
  updateFreeDays,
  updateSchedule,
} from '../../actions/schedules/SchedulesAction';
import { freeDaysSelectors, rowsSelector } from './selectors';

function mapStateToProps(state: IAppStore): IStateProps {
  return {
    restaurantList: state.cabinet.restaurantList,
    currentRestaurant: state.cabinet.currentRestaurant,
    scheduleList: rowsSelector(state),
    freeDays: freeDaysSelectors(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch<IAppActions>): IDispatchProps {
  return bindActionCreators({
    updateRestaurantInfo,
    getScheduleList,
    addNewScheduleRow,
    updateSchedule,
    deleteScheduleRow,
    updateFreeDays,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ReservesSystemInfoCont);
