import React from 'react';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';

import TableLIstItem from './TableLIstItem';
import locales from '../../locales';
import Title from '../Common/Title';
import { ITable, THallList } from '../../types/IRestaurantTablesStore';
import { customErrorShackBar } from '../../actions/snackbars/SnackBarsActions';

interface IProps {
  hallList: THallList;
  handleUpdateTable: (item: ITable | Array<ITable>) => void;
  customErrorShackBar: typeof customErrorShackBar;
  isAutoBookingEnabled: boolean;
}

/**
 * @param classes
 * @param hallList Список столов ресторана
 * @param handleUpdateTable Ручка для редактирования колчества мест у стола
 */
const Index: React.FC<IProps> = ({
  handleUpdateTable,
  hallList,
  customErrorShackBar,
  isAutoBookingEnabled,
}) => (
  <Paper elevation={3}>
    <Title
      text={locales.t('settings.tableList.title')}
      borderBottom
      helps={locales.t('settings.tableList.tooltip')}
    />
    <Table>
      <TableHead>
        <TableRow>
          <TableCell style={{ width: '30%' }} padding="default">
            {locales.t('settings.tableList.number')}
          </TableCell>
          <TableCell padding="default" align="center">
            {locales.t('settings.tableList.capacity')}
          </TableCell>
          <TableCell padding="default" align="center">
            {locales.t('settings.tableList.availableForBooking')}
          </TableCell>
          <TableCell padding="none" align="center">
            {locales.t('settings.tableList.autoBooking')}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          hallList.map((h, i) => (
            <TableLIstItem
              key={h.hall}
              hall={h}
              first={i === 0}
              handleUpdateTable={handleUpdateTable}
              customErrorShackBar={customErrorShackBar}
              isAutoBookingEnabled={isAutoBookingEnabled}
            />
          ))
        }
        <TableRow>
          <TableCell style={{ height: 54 }}>...</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </Paper>
);

export default Index;
