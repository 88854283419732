import React from 'react';
import { Image } from 'react-konva';
import useImage from 'use-image';
import rotateAroundCenter from './rotateHelper';

import { TSectionImageScheme } from '../../../../types/IRestaurantTablesSchemaStore';

interface IProps {
  data: TSectionImageScheme;
}

function ImageElement(props: IProps) {
  const {
    data,
  } = props;
  const {
    x, y, angle, image, ...imageProps
  } = data;
  if (!image) return null;

  const [imageData] = useImage(image.accessUrl);
  const shapeRef = React.useRef(null);
  React.useEffect(() => {
    rotateAroundCenter(shapeRef.current, angle);
  }, [shapeRef, angle]);

  return (
    <Image
      ref={shapeRef}
      image={imageData}
      x={x}
      y={y}
      width={imageProps.width}
      height={imageProps.height}
    />
  );
}

export default ImageElement;
