import {
  put, all, takeLatest, select,
} from 'redux-saga/effects';
import _ from 'lodash';

import {
  getAllTableForRestaurantReq,
  updateTableReq,
} from '../api';
import * as ActionTypes from '../actions/restaurantsTables/RestaurantsTablesTypes';
import * as ICardSettingsActions from '../actions/restaurantsTables/IRestaurantsTablesActions';
import {
  getTablesForSettingsSuccess,
  getTablesForSettings,
  updateRestaurantTableSuccess,
  updateRestaurantTableError,
  getTablesForSettingsError,
} from '../actions/restaurantsTables/RestaurantsTables';

/** @description Получение всех столов для выбранного ресторана */
function* getAllTablesSaga(action: ICardSettingsActions.IGetAllTables) {
  try {
    const { restaurantId } = action.payload;

    const tableList = yield getAllTableForRestaurantReq(restaurantId).then(r => r.data);
    const sortTableList = _.sortBy(tableList, ['hall', i => parseInt(i.number)]);

    yield put(getTablesForSettingsSuccess(sortTableList));
  } catch (e) {
    yield put(getTablesForSettingsError());
  }
}

/** @description Сага для редактирование стола */
function* updateRestaurantTableSaga(action: ICardSettingsActions.IUpdateRestaurantTable) {
  const restaurantId = yield select(state => state.cabinet.currentRestaurant.id);

  try {
    const { table } = action.payload;
    yield updateTableReq(restaurantId, Array.isArray(table) ? table : [table]);

    yield put(updateRestaurantTableSuccess(
      Array.isArray(table) ? 'Столы отредактированы' : 'Стол отредактирован',
    ));

    yield put(getTablesForSettings(restaurantId));
  } catch (e) {
    yield put(updateRestaurantTableError());
    yield put(getTablesForSettings(restaurantId));
  }
}

export default function* saga() {
  yield all([
    takeLatest(ActionTypes.UPDATE_RESTAURANT_TABLE_REQUEST, updateRestaurantTableSaga),
    takeLatest(ActionTypes.GET_ALL_TABLE_REQUEST, getAllTablesSaga),
  ]);
}
