import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import { IAppStore } from '../types/IAppStore';

export const selectorAuth = createSelector(
  [(store: IAppStore) => store],
  store => store.auth,
);

export const selectorIsAuthorized = createSelector(
  [selectorAuth],
  auth => auth && auth.authorized,
);

export function useIsAuthorized() {
  return useSelector(selectorIsAuthorized);
}

export const selectorAuthUserId = createSelector(
  [selectorAuth],
  auth => auth && auth.user && auth.user.id,
);

export function useAuthUserId() {
  return useSelector(selectorAuthUserId);
}
