import React from 'react';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { NavLink } from 'react-router-dom';

import Typography from '@material-ui/core/Typography/Typography';
import { Routers } from '../../config/constants';
import TextField from '../Common/TextFieldComp';
import locales from '../../locales';

const NewPasswordForm: React.FC<InjectedFormProps<{}, {}>> = ({ handleSubmit }) => (
  <form onSubmit={handleSubmit} style={{ marginTop: '30px' }}>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Field
          fullWidth
          name="password1"
          component={TextField}
          label={locales.t('sendNewPassword.newPasswordField')}
          type="password"
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          fullWidth
          name="password2"
          component={TextField}
          label={locales.t('sendNewPassword.confirmNewPasswordField')}
          type="password"
        />
      </Grid>
      <Grid item xs={12} container justify="center">
        <Button
          style={{ width: 226, height: 46, marginTop: 42 }}
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
        >
          {locales.t('sendNewPassword.newPasswordBtn')}
        </Button>
      </Grid>
      <Grid item xs={12} container justify="center">
        <NavLink to={Routers.auth}>
          <Typography align="center" variant="caption" style={{ textDecoration: 'underline' }}>
            { locales.t('common.goBack') }
          </Typography>
        </NavLink>
      </Grid>
    </Grid>
  </form>
);

interface IErrors {
  password1?: string;
  password2?: string;
}

const validate = ({ password1, password2 }: any): IErrors => {
  const errors: IErrors = {};

  if (!password1) errors.password1 = locales.t('common.errors.fieldErrors.password.empty');
  else if (password1.length < 8) errors.password1 = locales.t('common.errors.fieldErrors.password.short');

  if (!password2) errors.password2 = locales.t('common.errors.fieldErrors.password.empty');
  else if (password2.length < 8) errors.password2 = locales.t('common.errors.fieldErrors.password.short');
  else if (password1 !== password2) errors.password2 = locales.t('common.errors.fieldErrors.password.notMatch');

  return errors;
};

export default reduxForm<{}, {}>({
  form: 'newPassword',
  validate,
})(NewPasswordForm);
