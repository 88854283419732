import { put, all, takeLatest } from 'redux-saga/effects';
import _ from 'lodash';

import {
  getRestaurantMenuReq,
  getRestaurantMenuFromIikoReq,
} from '../api';
import {
  getRestaurantMenuSuccess,
  getRestaurantMenuError,
  getRestaurantMenuFromIikoSuccess,
  getRestaurantMenuFromIikoError,
} from '../actions/menu/MenuActions';
import { GET_MENU_REQUEST, GET_MENU_FROM_IIKO_REQUEST } from '../actions/menu/MenuActionsTypes';
import { mergeMenu } from '../helpers/menuHelpers';
import {
  IGetRestaurantMenu,
  IGetRestaurantMenuFromIiko,
} from '../actions/menu/IMenuActions';

/** @description Сага для получения меню ресторана */
function* getMenuRestaurantSaga(action: IGetRestaurantMenu) {
  try {
    const menu = yield getRestaurantMenuReq(action.payload);

    if (!_.isEmpty(menu)) {
      const menuList = mergeMenu(menu);

      yield put(getRestaurantMenuSuccess({
        id: menu.id,
        menuList,
        name: menu.name,
        description: menu.description,
      }));
    }
  } catch (e) {
    yield put(getRestaurantMenuError());
  }
}

/** @description Сага для получение меню из сервиса iiko */
function* getRestaurantMenuFromIikoSaga(action: IGetRestaurantMenuFromIiko) {
  try {
    const menu = yield getRestaurantMenuFromIikoReq(action.payload);
    const menuList = mergeMenu(menu);

    yield put(getRestaurantMenuFromIikoSuccess({
      id: menu.id,
      menuList,
      name: menu.name,
      description: menu.description,
    }));
  } catch (e) {
    yield put(getRestaurantMenuFromIikoError());
  }
}

export default function* saga() {
  yield all([
    takeLatest(GET_MENU_REQUEST, getMenuRestaurantSaga),
    takeLatest(GET_MENU_FROM_IIKO_REQUEST, getRestaurantMenuFromIikoSaga),
  ]);
}
