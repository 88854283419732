import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { SubmissionError } from 'redux-form';

import RegistrationForm from '../../components/Auth/RegistrationForm';
import { Routers } from '../../config/constants';
import locales from '../../locales';
import UseRules from '../../components/Auth/CommonAuth/UseRules';
import Logo from '../../components/Auth/CommonAuth/Logo';
import AuthWrapComp from '../../components/Auth/AuthWrapComp';
import { IProps } from './IRegistrationCont';

class RegisterCont extends Component<IProps, {}> {
  /** @description Ручка для экшена регистрации */
  handleSignUp = ({
    firstName,
    lastName,
    email,
    password,
    acceptRules,
  }: any) => {
    const { signUp } = this.props;

    if (!acceptRules) {
      throw new SubmissionError({
        acceptRules: 'Согласие с правилами обязательно',
        _error: 'Согласие с правилами обязательно',
      });
    } else {
      signUp(firstName, lastName, email, password);
    }
  };

  render() {
    const { regError } = this.props;

    return (
      <AuthWrapComp>
        <Logo text={locales.t('common.registrationWelcome')} />
        <RegistrationForm onSubmit={this.handleSignUp} regError={regError} />
        <Typography variant="caption" style={{ marginTop: 10 }}>
          <NavLink to={Routers.login}>{ locales.t('common.loginLink') }</NavLink>
        </Typography>
        <UseRules />
      </AuthWrapComp>
    );
  }
}


export default RegisterCont;
