import React, { PureComponent } from 'react';
import _ from 'lodash';

import ReservesSystemInfo from '../../components/ReservesSystem/ReservesSystemInfo';
import { IProps } from './IReservesSystemInfo';
import { IScheduleRow, TWeekDay } from '../../types/IScheduleStore';

class ReservesSystemInfoCont extends PureComponent<IProps, any> {
  /** @description Запрос расписания ресторана */
  componentDidMount() {
    const { currentRestaurant, getScheduleList } = this.props;
    if (currentRestaurant) {
      getScheduleList(currentRestaurant.id);
    }
  }

  /** @description Запрос расписания ресторана в случае изменения текущего ресторана */
  componentDidUpdate(prevProps: any) {
    const { currentRestaurant, getScheduleList } = this.props;

    if (currentRestaurant && _.isUndefined(prevProps.currentRestaurant)) {
      getScheduleList(currentRestaurant.id);
    } else if (currentRestaurant && currentRestaurant.id !== prevProps.currentRestaurant.id) {
      getScheduleList(currentRestaurant.id);
    }
  }

  /** @description Ручка для экшена для редактирования информации у выбранного ресторана */
  handleUpdateRestaurant = (info: {
    value: string | number, initValue: string, type: any
  }) => {
    const { currentRestaurant, updateRestaurantInfo } = this.props;

    if (currentRestaurant) {
      const updateData = {
        ...info,
        currentRestaurant,
      };

      updateRestaurantInfo(updateData);
    }
  };

  /** @description Ручка экшена для добавления новой строки в расписание */
  handleAddScheduleRow = () => {
    const { addNewScheduleRow } = this.props;
    addNewScheduleRow('restaurant');
  };

  /** @description Ручка экшена для редактирования строки расписания */
  handelUpdateSchedule = (newScheduleInfo: any) => {
    const { updateSchedule } = this.props;
    updateSchedule(newScheduleInfo, 'restaurant');
  };

  /** @description Ручка экшена для удаления строки расписания */
  handleDeleteScheduleRow = (row: IScheduleRow) => {
    const { deleteScheduleRow } = this.props;
    deleteScheduleRow(row, 'restaurant');
  };

  /** @description Ручка для изменения свободных дней в расписании */
  handleUpdateFreeDays = (days: {
    activate: Array<TWeekDay>,
    deactivate: Array<TWeekDay>
  }) => {
    const { updateFreeDays } = this.props;
    updateFreeDays(days, 'restaurant');
  };

  render() {
    const {
      currentRestaurant,
      scheduleList,
      freeDays,
    } = this.props;

    if (!currentRestaurant) {
      return null;
    }

    return (
      <ReservesSystemInfo
        currentRestaurant={currentRestaurant}
        scheduleList={scheduleList}
        freeDays={freeDays}
        updateRestaurant={this.handleUpdateRestaurant}
        handleAddScheduleRow={this.handleAddScheduleRow}
        handelUpdateSchedule={this.handelUpdateSchedule}
        handleDeleteScheduleRow={this.handleDeleteScheduleRow}
        handleUpdateFreeDays={this.handleUpdateFreeDays}
      />
    );
  }
}

export default ReservesSystemInfoCont;
