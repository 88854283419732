import React, { Suspense, useEffect } from 'react';
import * as Sentry from '@sentry/react';

import {
  IControl,
  IGeoObject,
  Map,
  // eslint-disable-next-line import/no-unresolved
} from 'yandex-maps';

import useScript from './useScript';
import systems from '../../../../config/systems';


const Loading = () => <div>Загрузка карты...</div>;

let yandexMap: Map;
let yandexMapZoomControl: IControl;

interface Props {
  height: number | string;
  width: number | string;
  apiKey: string;
  center: [number, number];
  zoom: number;
  geoObject: null | IGeoObject,
}

const MapComponent = ({
  apiKey,
  center,
  zoom,
  height,
  width,
  geoObject,
}: Props) => {
  useEffect(() => {
    if (!yandexMap) {
      return;
    }

    yandexMap.geoObjects.removeAll();

    if (geoObject) {
      yandexMap.geoObjects.add(geoObject);
      const bounds = yandexMap.geoObjects.getBounds();

      if (bounds !== null) {
        yandexMap.setBounds(bounds, {
          // checkZoomRange: true,
        }).catch((e) => {
          Sentry.captureException(e);
        });

        yandexMap.setZoom(17).catch((e) => {
          Sentry.captureException(e);
        });

        if (!yandexMapZoomControl) {
          yandexMapZoomControl = new window.ymaps.control.ZoomControl();
          yandexMap.controls.add(yandexMapZoomControl);
        }
      }
    } else {
      yandexMap.setCenter([center[0], center[1]], zoom);
    }
  }, [geoObject]);

  const [loaded, error] = useScript(
    `https://api-maps.yandex.ru/2.1/?lang=ru_RU&apikey=${apiKey}`,
  );

  const id = 'yandex-map';

  useEffect(() => {
    if (loaded) {
      window.ymaps.ready(() => {
        yandexMap = new window.ymaps.Map(id, {
          center: [center[0], center[1]],
          zoom,
          controls: [
            // 'geolocationControl',
            // 'fullscreenControl',
            // 'zoomControl',
          ],
        }, {
          suppressMapOpenBlock: true,
          yandexMapDisablePoiInteractivity: true,
        });

        yandexMap.behaviors.disable('scrollZoom');
      });
    }
  }, [loaded]);

  if (error) {
    return <div>{error.toString}</div>;
  }

  if (!loaded) {
    return <Loading />;
  }

  const w = typeof width === 'number' ? `${width}px` : width;
  const h = typeof height === 'number' ? `${height}px` : height;

  return (
    <div id={id} style={{ width: w, height: h }} />
  );
};

const YandexMapComponent = (props: {
  geoObject: null | IGeoObject,
}) => {
  const { geoObject } = props;

  return (
    <Suspense fallback={<Loading />}>
      <MapComponent
        width="100%"
        height={282}
        apiKey={systems.yandexMapAPIKey}
        center={[59, 70]}
        zoom={3}
        geoObject={geoObject}
      />
    </Suspense>
  );
};

export default YandexMapComponent;
