import { ILoadingStore } from '../types/ILoadingStore';
import { IAppActions } from '../types/IAppActions';
import * as BookingActionsTypes from '../actions/booking/BookingActionsTypes';
import * as CabinetActionsTypes from '../actions/cabinet/CabinetActionsTypes';
import * as ReserveModalActionsTypes from '../actions/reservationsPage/ReservationsPageActionsTypes';
import * as ReservesSystemActionsTypes from '../actions/restaurantsInfo/RestaurantsInfoActionsTypes';
import * as PhotoActionsTypes from '../actions/photo/PhotoActionsTypes';
import * as HomeActionTypes from '../actions/home/HomeActionTypes';
import * as SettingsActionTypes from '../actions/restaurantsTables/RestaurantsTablesTypes';
import * as StatisticsActionsTypes from '../actions/statistics/StatisticsActionsTypes';
import * as SourceStatisticActionsTypes from '../actions/sourceStatistic/SourceStatisticActionsTypes';
import * as SchedulesActionTypes from '../actions/schedules/SchedulesActionTypes';
import * as UserStatisticActionsTypes from '../actions/userStatistic/UserStatisticActionsTypes';


const initState: ILoadingStore = {
  isLoading: false,
  loadingRequests: [],
};

export default function reducer(state = initState, action: IAppActions): ILoadingStore {
  switch (action.type) {
    case SettingsActionTypes.GET_ALL_TABLE_REQUEST:
    case SettingsActionTypes.UPDATE_RESTAURANT_TABLE_REQUEST:
    case CabinetActionsTypes.GET_RESTAURANT_LIST_REQUEST:
    case CabinetActionsTypes.CREATE_RESTAURANT_REQUEST:
    case CabinetActionsTypes.CASHBOX_CONNECT_STATUS_REQUEST:
    case ReserveModalActionsTypes.CREATE_RESERVE_REQUEST:
    case ReserveModalActionsTypes.UPDATE_RESERVE_REQUEST:
    case HomeActionTypes.GET_STATISTIC_REQUEST:
    case HomeActionTypes.SELECT_ORGANIZATION_REQUEST:
    case HomeActionTypes.AUTH_FORM_IIKO_REQUEST:
    case PhotoActionsTypes.GET_IMAGES_REQUEST:
    case PhotoActionsTypes.UPLOAD_IMAGE_REQUEST:
    case PhotoActionsTypes.SHOW_IMAGE_REQUEST:
    case PhotoActionsTypes.HIDE_IMAGE_REQUEST:
    case PhotoActionsTypes.HIDE_MAIN_IMAGE_REQUEST:
    case PhotoActionsTypes.SET_COVER_REQUEST:
    case BookingActionsTypes.GET_BOOKING_REQUEST:
    case StatisticsActionsTypes.GET_SUMMARY_STATISTICS_REQUEST:
    case UserStatisticActionsTypes.GET_USER_STATISTIC_REQUEST:
    case SchedulesActionTypes.GET_SCHEDULE_LIST_REQUEST:
    case SchedulesActionTypes.UPDATE_SCHEDULE_REQUEST:
    case SchedulesActionTypes.DELETE_SCHEDULE_ROW_REQUEST:
    case SourceStatisticActionsTypes.GET_SOURCE_STATISTIC_REQUEST:
    case SourceStatisticActionsTypes.GET_RESERVES_BY_SOURCE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        loadingRequests: state.loadingRequests.concat(
          action.type.split('_').slice(0, -1).join('_'),
        ),
      };
    }

    case SettingsActionTypes.GET_ALL_TABLE_SUCCESS:
    case SettingsActionTypes.GET_ALL_TABLE_ERROR:
    case SettingsActionTypes.UPDATE_RESTAURANT_TABLE_SUCCESS:
    case SettingsActionTypes.UPDATE_RESTAURANT_TABLE_ERROR:
    case CabinetActionsTypes.CASHBOX_CONNECT_STATUS_SUCCESS:
    case CabinetActionsTypes.SWITCH_IIKO_MODAL:
    case CabinetActionsTypes.CREATE_RESTAURANT_ERROR:
    case CabinetActionsTypes.GET_RESTAURANT_LIST_SUCCESS:
    case CabinetActionsTypes.GET_RESTAURANT_LIST_ERROR:
    case CabinetActionsTypes.CASHBOX_CONNECT_STATUS_ERROR:
    case CabinetActionsTypes.CREATE_RESTAURANT_SUCCESS:
    case ReserveModalActionsTypes.CREATE_RESERVE_SUCCESS:
    case ReserveModalActionsTypes.CREATE_RESERVE_ERROR:
    case ReserveModalActionsTypes.CONFIRM_RESERVE_ERROR:
    case ReserveModalActionsTypes.CONFIRM_RESERVE_SUCCESS:
    case ReserveModalActionsTypes.UPDATE_RESERVE_SUCCESS:
    case ReserveModalActionsTypes.UPDATE_RESERVE_ERROR:
    case HomeActionTypes.AUTH_FORM_IIKO_SUCCESS:
    case HomeActionTypes.SELECT_ORGANIZATION_SUCCESS:
    case HomeActionTypes.GET_STATISTIC_SUCCESS:
    case HomeActionTypes.GET_STATISTIC_ERROR:
    case HomeActionTypes.AUTH_FORM_IIKO_ERROR:
    case HomeActionTypes.SELECT_ORGANIZATION_ERROR:
    case BookingActionsTypes.GET_BOOKING_SUCCESS:
    case BookingActionsTypes.GET_BOOKING_ERROR:
    case PhotoActionsTypes.GET_IMAGES_SUCCESS:
    case PhotoActionsTypes.GET_IMAGES_ERROR:
    case PhotoActionsTypes.UPLOAD_IMAGE_SUCCESS:
    case PhotoActionsTypes.UPLOAD_IMAGE_ERROR:
    case PhotoActionsTypes.SHOW_IMAGE_SUCCESS:
    case PhotoActionsTypes.SHOW_IMAGE_ERROR:
    case PhotoActionsTypes.HIDE_IMAGE_SUCCESS:
    case PhotoActionsTypes.HIDE_IMAGE_ERROR:
    case PhotoActionsTypes.HIDE_MAIN_IMAGE_SUCCESS:
    case PhotoActionsTypes.HIDE_MAIN_IMAGE_ERROR:
    case PhotoActionsTypes.SET_COVER_SUCCESS:
    case PhotoActionsTypes.SET_COVER_ERROR:
    case StatisticsActionsTypes.GET_SUMMARY_STATISTICS_SUCCESS:
    case StatisticsActionsTypes.GET_SUMMARY_STATISTICS_ERROR:
    case UserStatisticActionsTypes.GET_USER_STATISTIC_SUCCESS:
    case UserStatisticActionsTypes.GET_USER_STATISTIC_ERROR:
    case SchedulesActionTypes.GET_SCHEDULE_LIST_SUCCESS:
    case SchedulesActionTypes.GET_SCHEDULE_LIST_ERROR:
    case SchedulesActionTypes.UPDATE_SCHEDULE_SUCCESS:
    case SchedulesActionTypes.UPDATE_SCHEDULE_ERROR:
    case SchedulesActionTypes.DELETE_SCHEDULE_ROW_SUCCESS:
    case SchedulesActionTypes.DELETE_SCHEDULE_ROW_ERROR:
    case SourceStatisticActionsTypes.GET_SOURCE_STATISTIC_SUCCESS:
    case SourceStatisticActionsTypes.GET_SOURCE_STATISTIC_ERROR:
    case SourceStatisticActionsTypes.GET_RESERVES_BY_SOURCE_ERROR:
    case SourceStatisticActionsTypes.GET_RESERVES_BY_SOURCE_SUCCESS: {
      const newLoadingRequests = state.loadingRequests
        .filter(i => i !== action.type.split('_')
          .slice(0, -1)
          .join('_'));

      return {
        ...state,
        isLoading: newLoadingRequests.length > 0,
        loadingRequests: newLoadingRequests,
      };
    }

    default:
      return state;
  }
}
