import React, { Fragment, MouseEvent } from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
} from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

const styles = (theme: Theme) => createStyles({
  item: {
    height: 46,
    padding: '0 6px',
    margin: '8px 0',
    borderRadius: 4,
    '&:click': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  active: {
    backgroundColor: 'rgb(106, 233, 197, 0.3)',
    '&:focus': {
      backgroundColor: 'rgb(106, 233, 197, 0.3)',
    },
  },
  text: {
    color: '#323232',
  },
  subMenuItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 6px',
    margin: '0 0 8px 0',
    borderRadius: 4,
    height: 48,
  },
  subMenuText: {
    paddingLeft: 50,
    fontSize: 14,
  },
  wrapList: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 0,
  },
});

interface IProps extends WithStyles<typeof styles> {
  text: string;
  icon: React.ReactElement;
  route: string;
  pathname: string;
  subMenu?: Array<{ name: string; route: string; }>;
  showSubMenu?: boolean;
  checkIsRestaurantItem?: (pathname: string) => void;
  handleSwitchSubMenu?: (event: MouseEvent<HTMLButtonElement>) => void;
  handleSwitchDrawerClose?: () => void;
  disabled?: boolean;
}

const SideBarItem: React.FC<IProps> = ({
  classes,
  text,
  icon,
  route,
  pathname,
  subMenu,
  showSubMenu,
  checkIsRestaurantItem,
  handleSwitchSubMenu,
  handleSwitchDrawerClose,
  disabled,
}) => {
  const isRestaurantItem = checkIsRestaurantItem && checkIsRestaurantItem(pathname);

  const subMenuList = showSubMenu && (
    <Collapse
      className={classes.wrapList}
      in={showSubMenu}
      timeout="auto"
      unmountOnExit
    >
      <List
        // @ts-ignore
        component="div"
        disablePadding
      >
        {subMenu && subMenu.map(element => (
          <ListItem
            disabled={disabled}
            className={classNames(classes.subMenuItem, {
              [classes.active]: element.route === pathname,
            })}
            onClick={handleSwitchDrawerClose}
            // @ts-ignore
            component={NavLink}
            to={element.route}
            key={element.name}
            button
          >
            <ListItemText
              primary={(
                <Typography
                  className={classNames(classes.text, classes.subMenuText)}
                >
                  {element.name}
                </Typography>
              )}
            />
          </ListItem>
        ))}
      </List>
    </Collapse>
  );

  return (
    <Fragment>
      <ListItem
        disabled={disabled}
        className={classNames(classes.item, {
          [classes.active]: route === pathname || isRestaurantItem,
        })}
        // @ts-ignore
        component={NavLink}
        onClick={handleSwitchDrawerClose}
        to={route}
        button
      >
        <ListItemIcon style={{ minWidth: 48 }}>
          { icon }
        </ListItemIcon>
        <ListItemText primary={(
          <Typography
            variant="body1"
            className={classes.text}
          >
            { text }
          </Typography>
          )}
        />

        {subMenu && (
          <IconButton
            className={classes.text}
            onClick={handleSwitchSubMenu}
          >
            {showSubMenu ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        )}
      </ListItem>
      {subMenuList}
      <Divider />
    </Fragment>
  );
};

export default withStyles(styles)(SideBarItem);
