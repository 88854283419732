import {
  IWidgetDataAdapt,
} from '../../types/IRestaurantWidgetsStore';

// eslint-disable-next-line import/prefer-default-export
export const fallbackValue: IWidgetDataAdapt = {
  name: '',
  settings: {
    isShowHeader: true,
    isEmailRequired: false,
    isLastNameRequired: false,
    colors: {
      mainColor: '#6ae9c5',
      formBackground: '#ffffff',
      pageBackground: '#737577',
      formHeaderColor: '#323232',
      formBaseColor: '#323232',
      formFieldColor: '#aaaaaa',
    },
  },
};
