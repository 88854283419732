import React from 'react';
import { Typography } from '@material-ui/core';

import locales from '../../../locales';
import DialogComponent from '../../../components/Employees/Dialog/index';
import ButtonComponent from '../../../components/Common/Button';

export function UnableToDeleteAlert(props: { onClose: () => void; }) {
  const { onClose } = props;

  return (
    <DialogComponent
      title={locales.t('employees.deletion')}
      onClose={onClose}
      actions={(
        <ButtonComponent
          autoFocus
          onClick={onClose}
          title={locales.t('employees.cancel')}
        />
      )}
    >
      <Typography gutterBottom>
        {locales.t('employees.unableToDelete')}
      </Typography>
    </DialogComponent>
  );
}
