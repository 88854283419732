import React from 'react';
import { connect } from 'react-redux';

import { confirmEmail, getSession } from '../actions/auth/AuthActions';

const RulesPage = () => (
  <div>
    <h1>Правила</h1>
  </div>
);

export default connect(null, { getSession, confirmEmail })(RulesPage);
