import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import {
  addRestaurant, closeCreateModal, openCreateModal,
} from '../../../actions/cabinet/CabinetActions';
import CreateRestaurantModalComp from './CreateRestaurantModalComp';
import { IAppStore } from '../../../types/IAppStore';
import { IAppActions } from '../../../types/IAppActions';
import { IDispatchProps, IStateProps } from './ICreateRestaurantModal';

function mapStateToProps(state: IAppStore): IStateProps {
  return {
    modalOpen: state.cabinet.modalOpen,
    createRestaurantError: state.cabinet.createRestaurantError,
  };
}

function mapDispatchToProps(dispatch: Dispatch<IAppActions>): IDispatchProps {
  return bindActionCreators({
    openCreateModal,
    closeCreateModal,
    addRestaurant,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateRestaurantModalComp);
