/** @description Экшен для обновления списка резервов */
import { IAppActions } from '../../types/IAppActions';
import * as ActionTypes from './WebSocketActionsTypes';

export function socketEventReserveCreated(): IAppActions {
  return {
    type: ActionTypes.SOCKET_RESERVE_CREATED,
  };
}

// TODO Сейчас вызывает обновление списка резервов, после реализации фичи с новыми резервовом пересмотреть актуальность.
export function socketEventReserveConfirmation(): IAppActions {
  return {
    type: ActionTypes.SOCKET_RESERVE_CONFIRMATION,
  };
}

/** @description Экшен для переподключения вебсокета  */
export function socketReconnect(): IAppActions {
  return {
    type: ActionTypes.SOCKET_RECONNECT,
  };
}

/** @description Экшен для редактирования статуса вебсокета */
export function setSocketConnectStatus(isConnect: boolean) {
  return {
    type: ActionTypes.SET_SOCKET_CONNECT_STATUS,
    payload: {
      isConnect,
    },
  };
}
