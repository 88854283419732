import { createStyles } from '@material-ui/core/styles';
import Theme, {primaryColor, primaryHoverColor} from '../../../theme';

const styles = (theme: any) => createStyles({
  contentWrap: {
    width: 720,
    // minHeight: 677,
  },
  content: {
    borderTop: '1px solid #cbcbcb',
    borderBottom: '1px solid #cbcbcb',
    paddingBottom: theme.spacing(5),
  },
  closeButton: {
    position: 'absolute',
    right: 12,
    top: 12,
  },
  titleWrap: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: '20px',
  },
  actions: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  button: {
    width: 200,
    height: 52,
    marginLeft: `${theme.spacing(3)}px !important`,
    '&:disabled': {
      backgroundColor: primaryColor,
    },
  },
  reject: {
    backgroundColor: theme.palette.buttonGrey.main,
    '&:hover': {
      backgroundColor: theme.palette.buttonGrey.hover,
    },
  },
  // mr22: {
  //   marginRight: 22,
  // },
  error: {
    color: Theme.palette.error.main,
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(3),
  },
});

export default styles;
