import React from 'react';
import { Text } from 'react-konva';
import { getColor } from '../../../../helpers/scheme/schemeHelpers';

import {
  TSectionMarkScheme,
} from '../../../../types/IRestaurantTablesSchemaStore';
import rotateAroundCenter from './rotateHelper';
import { textCenterBox } from './textCenterHelper';

interface IProps {
  data: TSectionMarkScheme;
}

function TableElement(props: IProps) {
  const { data } = props;
  const {
    x, y, text, angle, color, font, width, height,
  } = data;

  const textRef = React.useRef(null);
  React.useEffect(() => {
    rotateAroundCenter(textRef.current, angle);
  }, [textRef, angle]);
  React.useEffect(() => {
    textCenterBox(textRef.current, { width, height });
  }, [textRef, width, height]);

  return (
    <Text
      ref={textRef}
      text={text}
      fill={getColor(color)}
      fontFamily={font.fontFamily}
      fontSize={font.size}
      fontStyle={font.fontStyle ? String(font.fontStyle).toLowerCase() : undefined}
      x={x}
      y={y}
      align="center"
    />
  );
}

export default TableElement;
