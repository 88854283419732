import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import RegisterCont from './RegisterCont';
import { IAppStore } from '../../types/IAppStore';
import { IAppActions } from '../../types/IAppActions';
import { StateProps, DispatchProps } from './IRegistrationCont';
import { signUp } from '../../actions/auth/AuthActions';

function mapStateToProps(state: IAppStore): StateProps {
  return {
    regError: state.auth.error,
  };
}

function mapDispatchToProps(dispatch: Dispatch<IAppActions>): DispatchProps {
  return bindActionCreators({
    signUp,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterCont);
