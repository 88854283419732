import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Box, Button } from '@material-ui/core';

import locales from '../../../../../locales';
import { CallNotification } from '../../../../../types/INotificationsStore';

interface CallFooterProps {
  notification: CallNotification;
  onRemove: (notificationKey: string, itemId: number) => void;
  // onCreateReserve: (callId: number, payloadInitialData: TCreateReserveInitialData) => void;
  onCreateReserve: (notificationKey: string, itemId: number) => void;
}

const useStyles = makeStyles(theme => ({
  secondaryButtonWrapper: {
    width: '50%',
    paddingRight: theme.spacing(1),
    '& button': {
      height: 48,
      width: '100%',
      backgroundColor: '#e7ebee',
    },
  },
  buttonWrapper: {
    width: '50%',
    paddingLeft: theme.spacing(1),
    '& button': {
      height: 48,
      width: '100%',
    },
  },
}));

function CallFooter(props: CallFooterProps) {
  const { notification: n, onRemove, onCreateReserve } = props;
  const classes = useStyles();

  return (
    <Box display="flex">
      <Box className={classes.secondaryButtonWrapper}>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => onRemove(n.getKey(), n.payload.id)}
        >
          {locales.t('notifications.call.actions.close')}
        </Button>
      </Box>
      <Box className={classes.buttonWrapper}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => onCreateReserve(n.getKey(), n.payload.id)}
        >
          {locales.t('notifications.call.actions.reserve')}
        </Button>
      </Box>
    </Box>
  );
}

export default CallFooter;
