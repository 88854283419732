import React from 'react';

import {
  withStyles, createStyles, WithStyles,
} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography/Typography';
import Grid from '@material-ui/core/Grid/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import locales from '../../../locales';
import { cardFieldType } from '../../../config/constants';
import { TRestaurant } from '../../../types/ICabinetStore';
import { TNewInfo } from '../../../types/IRestaurantInfoStore';
import { TSectionAdaptSchema } from '../../../types/IRestaurantTablesSchemaStore';
import { IPropsSelectOption } from '../../../reducers/selectors';
import RestaurantSchema from '../../Reservations/View/RestaurantSchema';
import FieldWrap from '../../Common/FieldWrap';


const styles = () => createStyles({
  contentMap: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: 'calc(100vh - 226px)',
    overflow: 'hidden',
    padding: '0 24px',
  },
  contentWrap: {
    padding: '16px 0',
    flexGrow: 1,
  },
  fieldBlock: {
    padding: '0 12px',
  },
  fieldWrap: {
    padding: '12px',
  },
  fieldLabelStart: {
    marginLeft: '0',
  },
  msgWrap: {
    height: '85%',
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
});

interface IProps extends WithStyles<typeof styles> {
  currentRestaurant: TRestaurant;
  handleUpdateRestaurantSettings: (arg: TNewInfo) => void;
  handleSwitchSection: any;
  sectionId?: number;
  sections: Array<IPropsSelectOption>;
  schema?: TSectionAdaptSchema;
}

const Index: React.FC<IProps> = ({
  classes,
  currentRestaurant,
  handleUpdateRestaurantSettings,
  handleSwitchSection,
  sectionId,
  sections,
  schema,
}) => (
  <Paper elevation={3}>
    <div className={classes.contentWrap}>
      <Grid container className={classes.fieldBlock}>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <FieldWrap classes={{ section: classes.fieldWrap }}>
            <FormControl margin="normal" fullWidth>
              <InputLabel
                htmlFor="section"
              >
                { locales.t('common.reserveModal.sections') }
              </InputLabel>
              <Select
                id="section"
                value={sectionId || ''}
                onChange={handleSwitchSection}
              >
                {
                  sections.map((t, i) => (
                    <MenuItem
                      alignItems="center"
                      key={t.value + i.toString()}
                      value={t.value}
                    >
                      { t.label }
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </FieldWrap>
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <FieldWrap classes={{ section: classes.fieldWrap }}>
            <FormControlLabel
              classes={{
                labelPlacementStart: classes.fieldLabelStart,
              }}
              labelPlacement="start"
              control={(
                <Switch
                  checked={currentRestaurant.settings.isSchemasEnabled}
                  onChange={(event, checked: boolean) => {
                    handleUpdateRestaurantSettings({
                      value: checked,
                      type: cardFieldType.isSchemasEnabled,
                    });
                  }}
                  name={cardFieldType.isSchemasEnabled}
                  color="primary"
                />
              )}
              label={locales.t('restaurantSchema.note.updateInfo')}
              style={{ color: '#aaaaaa' }}
            />
          </FieldWrap>
        </Grid>
      </Grid>
      <Grid container>
        <Grid className={classes.contentMap} item xl={12}>
          { (schema && currentRestaurant.settings.isSchemasEnabled) ? (
            <RestaurantSchema
              activeTables={[]}
              data={schema}
              tables={[]}
              persons={0}
              readOnly
            />
          ) : (
            <div className={classes.msgWrap}>
              { currentRestaurant.settings.isSchemasEnabled
                ? <Typography>{locales.t('restaurantSchema.note.empty')}</Typography>
                : <Typography>{locales.t('restaurantSchema.note.schemasDisabled')}</Typography> }
            </div>
          ) }
        </Grid>
      </Grid>
    </div>
  </Paper>
);

export default withStyles(styles)(Index);
