import * as ActionTypes from './SchedulesActionTypes';
import { IAppActions } from '../../types/IAppActions';
import {
  IScheduleGroup,
  IScheduleRow,
  TWeekDay,
  TScheduleFieldType, TForceUpdate, TForceDelete,
} from '../../types/IScheduleStore';

/** @description Экшен на получение расписания ресторана */
export function getScheduleList(restaurantId?: number): IAppActions {
  return {
    type: ActionTypes.GET_SCHEDULE_LIST_REQUEST,
    payload: {
      restaurantId,
    },
  };
}

/** @description Экшен на обработку успешного получения расписания ресторана */
export function getScheduleListSuccess(schedule: {
  restaurantSchedules: Array<IScheduleGroup>,
  restaurantRows: Array<IScheduleRow>,
  bookingSchedules: Array<IScheduleGroup>,
  bookingRows: Array<IScheduleRow>,
}): IAppActions {
  return {
    type: ActionTypes.GET_SCHEDULE_LIST_SUCCESS,
    payload: schedule,
  };
}

/** @description Экшен на обработку ошибки при получения расписания ресторана */
export function getScheduleListError(): IAppActions {
  return {
    type: ActionTypes.GET_SCHEDULE_LIST_ERROR,
    error: 'Не удалось загрузить расписание ресторана',
  };
}

/** @description Экшен для обновления списка дней для графика работы ресторана */
export function updateFreeDays(days: {
  activate: Array<TWeekDay>,
  deactivate: Array<TWeekDay>
}, field: TScheduleFieldType): IAppActions {
  return {
    type: ActionTypes.UPDATE_FREE_DAYS,
    payload: {
      days,
      field,
    },
  };
}

/** @description Экшен на добавление нового, пустого, поля в список расписания */
export function addNewScheduleRow(field: TScheduleFieldType): IAppActions {
  return {
    type: ActionTypes.ADD_SCHEDULE_ROW,
    payload: {
      field,
    },
  };
}

/** @description Экшен на редактирование расписания выбранного ресторана */
export function updateSchedule(row: IScheduleRow, field: TScheduleFieldType): IAppActions {
  return {
    type: ActionTypes.UPDATE_SCHEDULE_REQUEST,
    payload: {
      row,
      field,
    },
  };
}

/** @description Экшен для обработки успешного редактирования расписания */
export function updateScheduleSuccess(
  field: TScheduleFieldType, row: IScheduleRow, schedules: Array<IScheduleGroup>,
): IAppActions {
  return {
    type: ActionTypes.UPDATE_SCHEDULE_SUCCESS,
    payload: {
      field,
      row,
      schedules,
    },
  };
}

/** @description Экшен для обработки ошибки при редактировании расписания */
export function updateScheduleError(
  error?: string, rowId?: number, field?: TScheduleFieldType,
): IAppActions {
  return {
    type: ActionTypes.UPDATE_SCHEDULE_ERROR,
    error: error || 'Не удалось обновить расписание',
    payload: {
      rowId,
      field,
    },
  };
}

/** @description Экшен на удаление выбранного периода и всех дней в нём */
export function deleteScheduleRow(row: IScheduleRow, field: TScheduleFieldType): IAppActions {
  return {
    type: ActionTypes.DELETE_SCHEDULE_ROW_REQUEST,
    payload: {
      row,
      field,
    },
  };
}

/** @description Экшен на обработку успешного удаления строки расписания */
export function deleteScheduleRowSuccess(row: IScheduleRow, field: TScheduleFieldType): IAppActions {
  return {
    type: ActionTypes.DELETE_SCHEDULE_ROW_SUCCESS,
    payload: {
      row,
      field,
    },
  };
}

/** @description Экшен на обработку ошибки при удаления строки расписания */
export function deleteScheduleRowError(): IAppActions {
  return {
    type: ActionTypes.DELETE_SCHEDULE_ROW_ERROR,
    error: 'Не удалось удалить расписание',
  };
}

/** @description Экшен для актуализации расписания автобронирования во время редактирования графика работы ресторана */
export function forceUpdateBookingSchedule(payload: TForceUpdate | TForceDelete): IAppActions {
  return {
    type: ActionTypes.FORCE_UPDATE_BOOKING_SCHEDULE,
    payload,
  };
}

/** @description Экшен для удаления рсписания автоброниварования во время редактирования графика работы */
export function forceDeleteBookingSchedule(days: Array<TWeekDay>) {
  return {
    type: ActionTypes.FORCE_DELETE_BOOKING_SCHEDULE,
    payload: {
      days,
    },
  };
}
