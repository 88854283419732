import _ from 'lodash';
import moment from 'moment';

import { groupBy } from './helpers';
import {
  TWeekDay,
  IScheduleGroup,
  IScheduleRow,
  IRestaurantWorkTimes,
} from '../types/IScheduleStore';
import { emptyString, weekDays } from '../config/constants';
import { parseTimeByString } from './dateHelpers';

/**
 * Сортировка расписания по дням недели.
 * @param scheduleList
 * @returns {this | void}
 */
function sortWeekdays(scheduleList: Array<string>) {
  const sorter: {[days: string]: number} = {
    MONDAY: 1,
    TUESDAY: 2,
    WEDNESDAY: 3,
    THURSDAY: 4,
    FRIDAY: 5,
    SATURDAY: 6,
    SUNDAY: 7,
  };

  return scheduleList.sort((a, b) => sorter[a] - sorter[b]);
}

/**
 * @description Парсим дату из строки в инт
 * @param {string} time
 * @return {number}
 */
function parseTimeToInt(time: string): number {
  const parseTime = time
    .split(':')
    .slice(0, 2)
    .join('');

  return parseInt(parseTime);
}

/** @description Функция для получения следующего дня недели */
function getNextWeekDay(day: string): string {
  switch (day) {
    case weekDays.MONDAY:
      return weekDays.TUESDAY;
    case weekDays.TUESDAY:
      return weekDays.WEDNESDAY;
    case weekDays.WEDNESDAY:
      return weekDays.THURSDAY;
    case weekDays.THURSDAY:
      return weekDays.FRIDAY;
    case weekDays.FRIDAY:
      return weekDays.SATURDAY;
    case weekDays.SATURDAY:
      return weekDays.SUNDAY;
    case weekDays.SUNDAY:
      return weekDays.MONDAY;
    default: return emptyString;
  }
}

/** @description Мержим расписание для формирования списка времени работы ресторана */
function getMergedSchedule(scheduleList: Array<IScheduleGroup>): IRestaurantWorkTimes {
  return scheduleList.reduce((schedule: any, group) => {
    if (group.records.length > 1) {
      const nextDay = getNextWeekDay(group.day);
      const time = {
        start: '',
        end: '',
      };

      group.records.forEach(i => i.day === group.day
        ? time.start = i.start
        : time.end = i.end);

      return {
        ...schedule,
        [group.day]: {
          ...schedule[group.day],
          start: time.start.slice(0, 5),
          end: time.end.slice(0, 5),
        },
        [nextDay]: {
          ...schedule[nextDay],
          previous: time.end.slice(0, 5),
        },
      };
    }

    return {
      ...schedule,
      [group.day]: {
        ...schedule[group.day],
        start: group.records[0].start.slice(0, 5),
        end: group.records[0].end.slice(0, 5),
      },
    };
  }, {});
}

/** @description Получаем группы букинга для редактирования */
function getBookingGroupsForForceUpdate(
  days: Array<string>,
  groups: Array<IScheduleGroup>,
  newTimes: {
    start: string;
    end: string;
  },
) {
  const mergedGroups = getMergedSchedule(groups);

  return groups.reduce((list: Array<IScheduleGroup>, group) => {
    if (days.indexOf(group.day) > -1) {
      const schedule = mergedGroups[group.day];

      const parseScheduleStart = parseTimeToInt(schedule.start);
      const parseNewStart = parseTimeToInt(newTimes.start);
      const parseScheduleEnd = parseTimeToInt(schedule.end);
      const parseNewEnd = parseTimeToInt(newTimes.end);

      if (parseScheduleStart < parseNewStart || parseScheduleEnd > parseNewEnd) {
        const overMidnight = group.records.length > 1;
        const newEndOverMidnight = parseNewStart > parseNewEnd;

        // Если время работы до полуночи и открытие позже чем было
        if (!overMidnight && parseScheduleStart < parseNewStart) {
          return [
            ...list, {
              ...group,
              records: [{
                ...group.records[0],
                start: newTimes.start,
              }],
            },
          ];
        }

        // Если время работы после полуночи и открытие позже чем было
        if (overMidnight && parseScheduleStart < parseNewStart) {
          return [
            ...list, {
              ...group,
              records: group.records.map((r) => {
                if (r.day === group.day) {
                  return {
                    ...r,
                    start: newTimes.start,
                  };
                }

                return r;
              }),
            },
          ];
        }

        // Если время работы до полуночи и закрытие раньше чем было
        if (!overMidnight && parseScheduleEnd > parseNewEnd) {
          return [
            ...list, {
              ...group,
              records: [{
                ...group.records[0],
                end: !newEndOverMidnight ? newTimes.end : group.records[0].end,
              }],
            },
          ];
        }

        // Если время работы после полуночи и закрытие раньше чем было
        if (overMidnight && parseScheduleEnd > parseNewEnd) {
          // Если в новом расписание время работы до полуночи
          if (parseScheduleStart < parseNewEnd) {
            return [
              ...list, {
                ...group,
                records: [{
                  ...group.records[0],
                  end: !newEndOverMidnight ? newTimes.end : group.records[0].end,
                }],
              },
            ];
          }

          return [
            ...list, {
              ...group,
              records: group.records.map((r) => {
                if (r.day !== group.day) {
                  return {
                    ...r,
                    end: newTimes.end,
                  };
                }

                return r;
              }),
            },
          ];
        }

        return list;
      }

      return list;
    }

    return list;
  }, []);
}

/** @description Получение списка групп для апдейта */
function getGroupsForUpdate(
  row: IScheduleRow,
  groups: Array<IScheduleGroup>,
  initRow: IScheduleRow,
): Array<IScheduleGroup> {
  if (row.start === initRow.start && row.end === initRow.end) {
    return [];
  }

  return row.days.reduce((g: Array<IScheduleGroup>, d: string) => {
    const group = _.find(groups, { day: d });

    if (group) {
      const start = parseTimeToInt(row.start);
      const end = parseTimeToInt(row.end);
      const overMidnight = group.records.length > 1;

      // Если в новом и старом расписание время работы после полуночи
      if (overMidnight && (start > end)) {
        return [...g, {
          ...group,
          records: group.records.map(i => i.day === group.day
            ? { ...i, start: row.start, id: undefined }
            : { ...i, end: row.end, id: undefined }),
        }];
      }

      // Если только в новом расписание время работы после полуночи
      if (!overMidnight && (start > end)) {
        const records = group.records.map(i => ({
          ...i,
          start: row.start,
          end: '23:59:59',
          id: undefined,
        }));

        return [...g, {
          ...group,
          records: [...records, {
            start: '00:00:00',
            end: row.end,
            isWorkingTime: true,
            day: getNextWeekDay(group.day),
            type: group.type,
          }],
        }];
      }

      // Если только в старом расписание время работы после полуночи
      if (overMidnight && (start < end)) {
        const schedule = _.find(group.records, { day: group.day });
        return [...g, {
          ...group,
          records: schedule ? [{
            ...schedule,
            start: row.start,
            end: row.end,
            id: undefined,
          }] : [{
            start: row.start,
            end: row.end,
            isWorkingTime: true,
            day: group.day,
            type: group.type,
          }],
        }];
      }

      // По дефолту считаем что оба расписания до полуночи
      return [...g, {
        ...group,
        records: [{
          ...group.records[0],
          start: row.start,
          end: row.end,
          id: undefined,
        }],
      }];
    }

    return g;
  }, []);
}

/** @description Список новых дней которые нужно создать */
function getNewGroupForCreate(
  newRow: IScheduleRow,
  initRows: Array<TWeekDay>,
  restaurantId: number,
  type: 'BOOKING' | 'RESTAURANT',
): Array<IScheduleGroup> {
  return _
    .difference(newRow.days, initRows)
    .map(day => ({
      day,
      restaurantId,
      type,
      records: parseTimeToInt(newRow.start) < parseTimeToInt(newRow.end)
        ? [{
          start: newRow.start,
          end: newRow.end,
          isWorkingTime: true,
          day,
          type,
        }]
        : [
          {
            start: newRow.start,
            end: '23:59:59',
            isWorkingTime: true,
            day,
            type,
          },
          {
            start: '00:00:00',
            end: newRow.end,
            isWorkingTime: true,
            day: getNextWeekDay(day),
            type,
          },
        ],
    }));
}

/** @description Список ID дней которые нужно удалить */
function getScheduleForDelete(
  days: Array<TWeekDay>,
  initDays: Array<TWeekDay>,
  groups: Array<IScheduleGroup>,
): Array<number> {
  return _
    .difference(initDays, days)
    .reduce((ids: Array<number>, day: string) => {
      const group = _.find(groups, { day });

      if (group && group.id) {
        return [...ids, group.id];
      }

      return ids;
    }, []);
}

function getWorkTimeForAutoBooking(workDays: Array<String>, restaurantWorkTimes: IRestaurantWorkTimes): Array<string> {
  if (!workDays || !restaurantWorkTimes || _.isEmpty(restaurantWorkTimes)) {
    return [];
  }

  const workPeriod = workDays.reduce((period: any, day, i) => {
    // @ts-ignore
    const d = restaurantWorkTimes[day];

    if (!d || !d.start || !d.end) {
      return period;
    }

    if (i === 0) {
      return {
        start: d.start,
        end: d.end,
        parseStart: parseInt(d.start.replace(/[^0-9]/g)),
        parseEnd: parseInt(d.end.replace(/[^0-9]/g)),
      };
    }

    if (period.start !== d.start || period.end !== d.end) {
      const parseNewStart = parseInt(d.start.replace(/[^0-9]/g));
      const parseNewEnd = parseInt(d.end.replace(/[^0-9]/g));

      return {
        start: period.parseStart > parseNewStart ? period.start : d.start,
        end: period.parseEnd <= parseNewEnd ? period.end : d.end,
        parseStart: parseNewStart,
        parseEnd: parseNewEnd,
      };
    }

    return period;
  }, {
    start: '',
    end: '',
    parseStart: 0,
    parseEnd: 0,
  });

  if (workPeriod.start === '' || workPeriod.end === '' || !workPeriod.start || !workPeriod.end) {
    return [];
  }

  const timeList = [];
  const start = parseTimeByString(moment(), workPeriod.start);
  const end = parseTimeByString(moment(), workPeriod.end);

  const stop = end > start
    ? end
      .diff(start, 'minutes') / 15
    : end
      .add(1, 'day')
      .diff(start, 'minutes') / 15;

  for (let i = 0; i <= stop; i += 1) {
    start.add(i === 0 ? 0 : 15, 'minutes');
    timeList.push(start.format('HH:mm'));
  }

  return timeList;
}

/** @description Формирование строк расписания */
function getSchedulesRows(groups: Array<IScheduleGroup>): Array<IScheduleRow> {
  const parseGroup = groups.map((g) => {
    const { records } = g;

    if (records.length > 1) {
      const [thisDay] = records.filter(s => s.day === g.day);
      const [nextDay] = records.filter(s => s.day !== g.day);
      return {
        ...g,
        start: thisDay ? thisDay.start : '00:00:00',
        end: nextDay ? nextDay.end : thisDay.end,
      };
    }

    return {
      ...g,
      start: records[0].start,
      end: records[0].end,
    };
  });

  return groupBy({ Group: parseGroup, By: ['start', 'end'] })
    .map(r => ({
      id: Math.floor(Math.random() * 10000000),
      start: r[0].start.slice(0, 5),
      end: r[0].end.slice(0, 5),
      days: r.map(d => d.day),
    }));
}

export {
  getBookingGroupsForForceUpdate,
  getSchedulesRows,
  getMergedSchedule,
  getNewGroupForCreate,
  getScheduleForDelete,
  getGroupsForUpdate,
  getWorkTimeForAutoBooking,
  parseTimeToInt,
  sortWeekdays,
};
