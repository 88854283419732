import React, { Fragment, useState } from 'react';

import {
  withStyles,
  createStyles,
  WithStyles,
  Theme,
} from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

import locales from '../../../locales';
import { TIIkoOrganization } from '../../../types/ICabinetStore';

const styles = (theme: Theme) => createStyles({
  linkColor: {
    color: theme.palette.primary.main,
  },
  wrap: {
    maxWidth: 600,
    minHeight: 350,
  },
});

interface IProps extends WithStyles<typeof styles> {
  organizationList: Array<TIIkoOrganization>;
  handleCloseDialog: () => void;
  handleSendSelectReq: (organizationId: string) => void;
}

const SecondStep: React.FC<IProps> = ({
  classes,
  organizationList,
  handleCloseDialog,
  handleSendSelectReq,
}) => {
  const [organizationId, selectOrg] = useState(organizationList[0].id);

  /** @description Выбор организаци из списка */
  const onChange = (event: any) => {
    selectOrg(event.target.value);
  };

  /** @description Отправка запроса */
  const onSend = () => {
    handleSendSelectReq(organizationId);
  };

  return (
    <Fragment>
      <div className={classes.wrap}>
        <DialogContent>
          <DialogContentText>
            <span style={{ fontWeight: 'bold' }}>{ locales.t('home.authIiko.secondStep.step') }</span>
            { locales.t('home.authIiko.secondStep.stepDescription1') }
          </DialogContentText>
          <FormControl margin="normal" fullWidth>
            <Select
              value={organizationList[0].id}
              onChange={onChange}
              inputProps={{
                name: 'age',
                id: 'demo-controlled-open-select',
              }}
            >
              {
                organizationList.map(org => (
                  <MenuItem key={org.id} value={org.id}>
                    { `${org.name} ${org.address}` }
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </DialogContent>
      </div>
      <div>
        <DialogActions>
          <Button
            color="secondary"
            onClick={handleCloseDialog}
          >
            { locales.t('common.reject') }
          </Button>
          <Button
            color="primary"
            onClick={onSend}
          >
            { locales.t('home.connectBtn') }
          </Button>
        </DialogActions>
      </div>
    </Fragment>
  );
};

export default withStyles(styles)(SecondStep);
