import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import ReservesSystemWidgets from './ReservesSystemWidgets';

import { IAppStore } from '../../types/IAppStore';
import { IStateProps, IDispatchProps } from './IReservesSystemWidgets';
import { IAppActions } from '../../types/IAppActions';
import {
  createWidget,
  deleteWidget,
  getAllWidgets,
  updateWidget,
} from '../../actions/restaurantsWidgets/RestaurantsWidgetsActions';
import { customMessageShackBar } from '../../actions/snackbars/SnackBarsActions';

function mapStateToProps(state: IAppStore): IStateProps {
  const { widgets, isLoading } = state.widgets;

  return {
    widgets,
    isLoading,
  };
}

function mapDispatchToProps(dispatch: Dispatch<IAppActions>): IDispatchProps {
  return bindActionCreators({
    createWidget,
    getAllWidgets,
    deleteWidget,
    updateWidget,
    customMessageShackBar,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ReservesSystemWidgets);
