import React, { useState } from 'react';
import { reduxForm } from 'redux-form';
import { compose } from 'recompose';
import { Moment } from 'moment';

import { withStyles, WithStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import RestaurantView from './View';
import UserFields, { ViewID } from './ReservesDataFields';
import styles from './styles';
import { ITable } from '../../types/IRestaurantTablesStore';
import { IWorkTime } from '../../types/IScheduleStore';
import { TEditReserveInfo } from '../../containers/ReservationPageEditCont/IReserveEditPage';
import { TConfirmReserveInfo } from '../../containers/ReservationPageConfirmCont/IReserveConfirmPage';
import { TActiveTables, TUpdateReserveInfo } from '../../types/IReserveModalStore';
import { TSectionAdaptSchema } from '../../types/IRestaurantTablesSchemaStore';
import { emptyTime, reserveModal } from '../../config/constants';
import locales from '../../locales';
import { IPropsSelectOption } from '../../reducers/selectors';

import Reserve, { IAppReservesStore, TAppReserveOuterChangeType, TNewReserveFields }
  from '../../types/IAppReservesStore';

import { IAppReserveCloseOptions } from '../../actions/appReserves/IAppReservesActions';
import Nav from './Nav';

interface IProps extends WithStyles<typeof styles> {
  disabled?: boolean;
  form: string;
  outerChangeType?: TAppReserveOuterChangeType;

  title: string;
  type?: 'online' | 'new' | 'edit';
  isSchemasEnabled: boolean,
  sections: Array<IPropsSelectOption>;
  persons: number;
  section: number;
  schema: TSectionAdaptSchema | void;
  freeTables: Array<ITable> | void;
  handleCloseDialog: (options?: IAppReserveCloseOptions) => void;
  handleSelectTable: (table: ITable) => void;
  handleSubmit?: (props: any) => void;
  handleReject?: () => void;
  handleMergeTables: () => void;
  handleGetFreeTables: (field?: { value: string | number, type: string }) => void;
  handleErrorMsg?: (msg: string) => void;
  handleViewChange: (sectionId: number) => void;
  activeTables: TActiveTables;
  timeList: Array<IWorkTime>;
  reserveInfo?: TEditReserveInfo | TConfirmReserveInfo | TUpdateReserveInfo;
  reserveBtnText?: string;
  rejectBtnText?: string;
  maxCapacity: number;
  isMergeTables: boolean;
  minutes?: number;
  locale?: string;

  appReserves: IAppReservesStore;
  selectedAppReserve?: Reserve;
  addAppReserve: (reserve: Reserve) => void;
  removeAppReserve: (key: string) => void;
  openAppReserve: (key: string) => void;
  closeAppReserve: (key?: string, options?: IAppReserveCloseOptions) => void;
  changeAppReserve: (name: string, value?: any) => void;
  appReserveFormValues?: TNewReserveFields;
  alreadySelectedTables: Array<number>;
  isBanquet: boolean;
}

// TODO Отказаться от redux-form
const ReserveModal: React.FC<IProps> = ({
  activeTables,
  type,
  classes,
  isSchemasEnabled,
  freeTables,
  sections,
  section,
  schema,
  persons,
  handleCloseDialog,
  handleSelectTable,
  handleSubmit,
  handleGetFreeTables,
  handleMergeTables,
  handleErrorMsg,
  handleViewChange,
  timeList,
  reserveInfo,
  handleReject,
  reserveBtnText,
  rejectBtnText,
  maxCapacity,
  isMergeTables,
  minutes,
  locale,

  appReserves,
  selectedAppReserve,
  // addAppReserve,
  // openAppReserve,
  closeAppReserve,
  changeAppReserve,
  // appReserveFormValues,
  alreadySelectedTables,
  disabled,
  outerChangeType,

  isBanquet,
}) => {
  const isEdit = reserveInfo && 'type' in reserveInfo && reserveInfo.type === reserveModal.EDIT;
  const isEditStyles = isEdit ? classes.btnEdit : '';

  const [viewId, onViewChange] = useState<ViewID>('table');
  React.useEffect(() => {
    if (viewId === 'scheme' && typeof handleViewChange === 'function') {
      // reset Section to first item in list if option "All sections" selected
      if (!section && sections && sections.length) {
        handleViewChange(sections[0].value);
      }
    }
  }, [viewId]);

  const closeButton = (
    <Button
      style={{ marginLeft: 12 }}
      className={classes.btnStyle}
      variant="outlined"
      onClick={() => handleCloseDialog({
        remove: true,
        openNext: true,
      })}
    >
      { locales.t('common.editReserveModal.close') }
    </Button>
  );

  let buttons = (
    <>
      <Button
        className={`${classes.btnStyle} ${isEditStyles}`}
        style={{ marginRight: 12 }}
        variant={isEdit ? 'contained' : 'outlined'}
        onClick={handleReject}
      >
        { rejectBtnText }
      </Button>
      <Button
        style={{ marginLeft: 12 }}
        className={classes.btnStyle}
        variant="contained"
        type="submit"
        color="primary"
      >
        { reserveBtnText }
      </Button>
    </>
  );

  if (outerChangeType === 'CANCELED') {
    buttons = (
      <>
        {closeButton}
        <div className={classes.banquetError}>
          <Typography
            variant="body2"
            color="error"
            className={classes.banquetErrorText}
          >
            { locales.t('common.editReserveModal.remoteCancel') }
          </Typography>
        </div>
      </>
    );
  } else if (disabled) {
    buttons = closeButton;
  } else if (isBanquet && type === 'edit') {
    buttons = (
      <>
        <div className={classes.banquetError}>
          <Typography
            variant="body2"
            color="error"
            className={classes.banquetErrorText}
          >
            { locales.t('common.editReserveModal.banquetMsg') }
          </Typography>
        </div>
        {closeButton}
      </>
    );
  }

  return (
    <form onSubmit={handleSubmit} className={classes.formWrap}>
      <Nav
        reserves={appReserves ? appReserves.items : []}
        onBack={() => closeAppReserve()}
        onClickReserve={(reserve: Reserve) => {
          if (selectedAppReserve) {
            closeAppReserve(
              selectedAppReserve.key,
              { openNextKey: reserve.key },
            );
          }
        }}
        onAddReserve={() => {
          if (selectedAppReserve) {
            closeAppReserve(
              selectedAppReserve.key,
              { openNewReserve: new Reserve('APP') },
            );
          }

          // addAppReserve(new Reserve('APP'));
        }}
      />

      <div className={classes.content}>
        <UserFields
          disabled={disabled}
          type={type}
          reserveInfo={reserveInfo}
          locale={locale}
          isMergeTables={isMergeTables}
          getFreeTables={handleGetFreeTables}
          handleMergeTables={handleMergeTables}
          sections={sections}
          viewId={viewId}
          onViewChange={onViewChange}
          handleErrorMsg={handleErrorMsg}
          minutes={minutes}
          timeList={timeList}
          maxCapacity={maxCapacity}
          onReservePropertyChange={(name, value: any) => changeAppReserve(name, value)}
          isBanquet={isBanquet}
        />

        <RestaurantView
          activeTables={activeTables}
          isMergeTables={isMergeTables}
          handleSelectTable={handleSelectTable}
          tables={freeTables}
          schema={schema}
          viewId={viewId}
          isSchemasEnabled={isSchemasEnabled}
          persons={isMergeTables ? 0 : persons}
          alreadySelectedTables={alreadySelectedTables}
        />
      </div>

      <div className={classes.actions}>{buttons}</div>
    </form>
  );
};

export type TReserveFormCurrentData = {
  firstName: string;
  persons: number;
  phone: string;
  name: string;
  date: Moment | typeof emptyTime;
  time: string;
  hours: string | number;
  minutes: string;
  section: number;
  isBanquet: boolean;
}

const validate = ({
  firstName,
  phone,
  hours,
  time,
  persons,
  date,
}: TReserveFormCurrentData) => {
  const errors: any = {};

  if (time === emptyTime) errors.time = true;
  if (date === emptyTime) errors.date = true;
  if (persons === 0) errors.persons = true;
  if (!firstName) errors.firstName = locales.t('common.reserveModal.formErrors.nameError');
  if (!phone) errors.phone = locales.t('common.reserveModal.formErrors.phoneError');
  if (!hours) errors.hours = locales.t('common.reserveModal.formErrors.durationError');
  return errors;
};

export default compose<any, IProps>(
  withStyles(styles),
  reduxForm({
    validate,
  }),
)(ReserveModal);
