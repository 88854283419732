import { createStyles } from '@material-ui/core/styles';

const styles = createStyles({
  wrap: {
    position: 'relative',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
    overflow: 'hidden',
  },
  buttonRight: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    top: '16px',
    right: '16px',
  },
  buttonGroup: {
    marginTop: '4px',
  },
  buttonShadow: {
    boxShadow: '0 -3px 6px 0 rgba(0, 0, 0, 0.16)',
  },
  button: {
    '&:disabled': {
      color: 'rgba(50, 50, 50, 0.4)',
    },
    '&:hover': {
      backgroundColor: '#ffffff',
    },
    zIndex: 10,
    backgroundColor: '#ffffff',
    border: 'solid 0.5px #c5c5c5',
    color: '#323232',
  },
});

export default styles;
