import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { IStateProps, IDispatchProps } from './IReservesSystemAutoBooking';
import ReservesSystemAutoBookingCont from './ReservesSystemAutoBookingCont';
import {
  updateRestaurantInfo,
} from '../../actions/restaurantsInfo/RestaurantsInfoActions';
import { switchBookingProcessor } from '../../actions/home/HomeActions';
import { IAppStore } from '../../types/IAppStore';
import { IAppActions } from '../../types/IAppActions';

function mapStateToProps(state: IAppStore): IStateProps {
  const { currentRestaurant } = state.cabinet;
  const isAutoBookingEnabled = currentRestaurant
    ? currentRestaurant.settings.isAutoBookingEnabled
    : false;

  return {
    currentRestaurant,
    isAutoBookingEnabled,
  };
}

function mapDispatchToProps(dispatch: Dispatch<IAppActions>): IDispatchProps {
  return bindActionCreators({
    updateRestaurantInfo,
    switchBookingProcessor,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ReservesSystemAutoBookingCont);
