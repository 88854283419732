// eslint-disable-next-line no-use-before-define
import React from 'react';
import { cloneDeep, set } from 'lodash';
import cl from 'classnames';
import {
  withStyles, WithStyles, Grid, TextField, Checkbox,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography/Typography';
import FormControlLabel
  from '@material-ui/core/FormControlLabel/FormControlLabel';
import {
  IWidgetColorName,
  IWidgetSettingsNames,
  IWidgetDataAdapt,
} from '../../../types/IRestaurantWidgetsStore';

import { fallbackValue } from '../../../helpers/widget/constants';
import { getDefaultColor, isValidColor, isValidName } from './utils';

import CustomField from './CustomField';

import locales from '../../../locales';
import styles from './styles';

const firstRow: IWidgetColorName[] = [
  'mainColor',
  'formBackground',
  'pageBackground',
];

const secondRow: IWidgetColorName[] = [
  'formHeaderColor',
  'formBaseColor',
  'formFieldColor',
];

const thirdRow: IWidgetSettingsNames[] = [
  'isShowHeader',
  /*
  // TODO: uncomment for step 2
  'isEmailRequired',
  'isLastNameRequired',
  */
];

const fieldLabelMap: {
  [f: string]: string
} = {
  mainColor: 'settings.widgets.widgetHeaderAndButton',
  formBackground: 'settings.widgets.widgetFormBackground',
  pageBackground: 'settings.widgets.widgetBodyBackground',
  formHeaderColor: 'settings.widgets.widgetHeaderAndButton',
  formBaseColor: 'settings.widgets.widgetFontBaseColor',
  formFieldColor: 'settings.widgets.widgetFormFieldCaptions',
  isShowHeader: 'settings.widgets.widgetShowHeader',
  isEmailRequired: 'settings.widgets.widgetSetEmailRequired',
  isLastNameRequired: 'settings.widgets.widgetSetSurnameRequired',
};

const setFieldState = (field: string, value: any) => (state: React.SetStateAction<IWidgetDataAdapt>) => {
  const newState = cloneDeep(state);
  set(newState, field, value);
  return newState;
};

interface IProps extends WithStyles<typeof styles> {
  data: IWidgetDataAdapt;
  changeData: React.Dispatch<React.SetStateAction<IWidgetDataAdapt>>;
}

const WidgetSettings: React.FC<IProps> = ({
  classes,
  data,
  changeData,
}) => {
  /** @description Состояние полей виджета */
  const {
    settings = {
      // TODO: upgrade react-scripts to support new babel features, e.g.:
      //  @babel/plugin-proposal-optional-chaining
      // @ts-ignore: default settings is not empty,
      colors: fallbackValue.settings.colors,
    },
  } = data || fallbackValue;
  const { colors } = settings;


  /** @description Редактирование полей */
  const onChange = (field: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.target) {
      const { value } = e.target;
      changeData(setFieldState(field, value));
    }
  };

  const onChangeBoolean = (field: string) => (e: React.ChangeEvent<HTMLElement>, checked: boolean) => {
    changeData(setFieldState(field, checked));
  };

  /** @description Редактирование CustomField полей */
  const onChangeColor = (field: string) => (value: string) => {
    changeData(setFieldState(field, value));
  };

  const renderCustomField = (field: IWidgetColorName) => {
    const id = `settings.colors.${field}`;
    return (
      <Grid item key={field}>
        <CustomField
          id={id}
          label={locales.t(fieldLabelMap[field])}
          onChange={onChangeColor(id)}
          defaultValue={getDefaultColor(field)}
          value={colors[field]}
          error={!isValidColor(colors[field])}
        />
      </Grid>
    );
  };

  const renderCheckbox = (field: IWidgetSettingsNames) => {
    const id = `settings.${field}`;
    return (
      <FormControlLabel
        control={(
          <Checkbox
            checked={data.settings ? data.settings[field] : false}
            onChange={onChangeBoolean(id)}
            color="primary"
          />
        )}
        label={(
          <Typography variant="body1">
            {locales.t(fieldLabelMap[field])}
          </Typography>
        )}
      />
    );
  };

  return (
    <>
      <div className={classes.textField}>
        <TextField
          label={locales.t('settings.widgets.widgetName')}
          fullWidth
          autoFocus
          onChange={onChange('name')}
          value={data.name || ''}
          error={!isValidName(data.name)}
        />
      </div>
      <Grid container direction="row" className={classes.textFields} xs={12}>
        <Grid
          item
          sm={3}
          xs={4}
          className={cl(classes.textFieldsGroupName, classes.textFieldsGroup)}
        >
          <span
            className={classes.textFiledGroup}
          >
            {locales.t('settings.widgets.widgetFormColor')}
          </span>
        </Grid>
        <Grid item sm={9} xs={8} className={classes.textFieldsGroup}>
          <Grid
            container
            direction="row"
            spacing={3}
          >
            {firstRow.map(renderCustomField)}
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        className={classes.textFields}
        xs={12}
      >
        <Grid
          item
          sm={3}
          xs={4}
          className={cl(
            classes.textFieldsGroupName,
            classes.textFieldsGroup,
            classes.textFieldsGroupColors,
          )}
        >
          <span
            className={classes.textFiledGroup}
          >
            {locales.t('settings.widgets.widgetFontColor')}
          </span>
        </Grid>
        <Grid
          item
          sm={9}
          xs={8}
          className={cl(classes.textFieldsGroup, classes.textFieldsGroupColors)}
        >
          <Grid
            container
            direction="row"
            spacing={3}
          >
            {secondRow.map(renderCustomField)}
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        className={classes.textFields}
        xs={12}
      >
        <Grid
          item
          sm={3}
          xs={4}
          className={classes.textFieldsGroupName}
        />
        <Grid item sm={9} xs={8} className={classes.textFieldsGroup}>
          <Grid
            container
            direction="row"
            spacing={3}
          >
            {thirdRow.map(renderCheckbox)}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default withStyles(styles)(WidgetSettings);
