import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import HomeCont from './HomeCont';
import {
  // getCashBoxConnectStatus,
  openCreateModal,
  switchAuthIikoModal,
} from '../../actions/cabinet/CabinetActions';
import {
  resetConnectStatus,
  switchPinCodeModal,
  switchBookingProcessor,
  getRestaurantStatistics,
  getRestaurantAttendances,
  getRestaurantPinCode,
} from '../../actions/home/HomeActions';
import { IAppStore } from '../../types/IAppStore';
import { IStateProps, IDispatchProps } from './IHomeCont';

function mapStateToProps(state: IAppStore): IStateProps {
  return {
    restaurantList: state.cabinet.restaurantList,
    currentRestaurant: state.cabinet.currentRestaurant,
    home: state.home,
    needAuthFromIiko: state.cabinet.iikoAuth.needAuthFromIiko,
    cashBoxStatus: state.cabinet.cashBoxStatus,
    currentRestaurantStatistic: state.home.currentRestaurantStatistic,
    currentRestaurantAttendance: state.home.currentRestaurantAttendance,
    loading: state.loading.isLoading,
    moneySymbol: state.cabinet.moneySymbol,
  };
}

function mapDispatchToProps(dispatch: Dispatch): IDispatchProps {
  return bindActionCreators({
    openCreateModal,
    resetConnectStatus,
    switchAuthIikoModal,
    switchPinCodeModal,
    switchBookingProcessor,
    // getCashBoxConnectStatus,
    getRestaurantStatistics,
    getRestaurantAttendances,
    getRestaurantPinCode,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeCont);
