import React, { Component, Fragment } from 'react';
import Typography from '@material-ui/core/Typography/Typography';
import Button from '@material-ui/core/Button/Button';

import { IPropsSendNewPassword } from './ISendNewPassword';
import AuthWrapComp from '../../components/Auth/AuthWrapComp';
import Logo from '../../components/Auth/CommonAuth/Logo';
import NewPasswordForm from '../../components/RestorePassword/NewPasswordForm';
import locales from '../../locales';
import UseRules from '../../components/Auth/CommonAuth/UseRules';
import History from '../../redux/history';

class SendNewPassword extends Component<IPropsSendNewPassword, {}> {
  /** @description Ручка для отправки нового пароля */
  handleSendNewPassword = ({ password1, password2 }: any): void => {
    const { sendNewPassword, secretKey } = this.props;
    if (password1 === password2) {
      sendNewPassword(secretKey, password1);
    }
  };

  /** @description Ручка для редиректа на страницу логина */
  handelRedirectToLogin = (): void => {
    History.push('/');
  };

  render() {
    const { sendNewPasswordError, sendNewPasswordSuccess } = this.props;

    const init = (
      <Fragment>
        <Logo text={locales.t('sendNewPassword.title')} />
        <NewPasswordForm onSubmit={this.handleSendNewPassword} />
      </Fragment>
    );

    const finish = (
      <Fragment>
        <Logo text={locales.t('sendNewPassword.finishTitle')} />
        <Typography style={{ marginTop: '20px' }} variant="h6">
          { locales.t('sendNewPassword.finishMessage') }
        </Typography>
        <Typography style={{ marginTop: '20px' }} variant="subtitle1">
          { locales.t('sendNewPassword.goToCabinet') }
        </Typography>
        <Button
          style={{ width: 226, height: 46, marginTop: 42 }}
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          onClick={this.handelRedirectToLogin}
        >
          {locales.t('sendNewPassword.goToCabinetBtn')}
        </Button>
      </Fragment>
    );

    const content = sendNewPasswordSuccess ? finish : init;

    return (
      <AuthWrapComp>
        { content }
        { sendNewPasswordError && (
          <Typography style={{ marginTop: '20px' }} color="error" variant="subtitle1">
            { sendNewPasswordError }
          </Typography>
        )
        }
        <UseRules />
      </AuthWrapComp>
    );
  }
}

export default SendNewPassword;
