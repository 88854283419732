import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import _ from 'lodash';
import { createSelector } from 'reselect';

import Booking from './Booking';
import { DispatchProps, StateProps } from './IBooking';
import { rejectReserve } from '../../actions/reserveReject/RejectReserveActions';
import { openReserveEditModal, openReserveCreateModal } from '../../actions/reservationsPage/ReservationsPageActions';
import {
  initBookingPages,
  getBookingList,
  sortBookingList,
  switchBookingFilter,
  filterBookingListBy,
} from '../../actions/booking/BookingActions';
import { IAppActions } from '../../types/IAppActions';
import { IAppStore } from '../../types/IAppStore';

import { addAppReserve } from '../../actions/appReserves/AppReservesActions';

/** @description Сортировка списка резервов */
const bookingSelector = createSelector([
  (state: IAppStore) => state.booking.filters,
  (state: IAppStore) => state.booking.bookingList,
], (filters, bookingList) => {
  const activeFilter = filters.filter(f => f.active);
  return activeFilter.length > 0 ? bookingList.filter(
    r => _.findIndex(activeFilter, f => f.value === r.group) !== -1,
  ) : bookingList;
});

function mapStateToProps(state: IAppStore): StateProps {
  const {
    isInit, filters, onlyActive, sort,
  } = state.booking;

  return {
    isInit,
    bookingInfoList: bookingSelector(state),
    currentRestaurant: state.cabinet.currentRestaurant ? state.cabinet.currentRestaurant.id : 0,
    currentStatisticDate: state.booking.currentStatisticDate,
    onlyActive,
    sort,
    filters,
  };
}

function mapDispatchToProps(dispatch: Dispatch<IAppActions>): DispatchProps {
  return bindActionCreators({
    initBookingPages,
    getBookingList,
    openReserveEditModal,
    openReserveCreateModal,
    rejectReserve,
    sortBookingList,
    switchBookingFilter,
    filterBookingListBy,
    addAppReserve,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Booking);
