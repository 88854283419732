import React, { Fragment } from 'react';
import moment from 'moment';

import {
  withStyles,
  createStyles,
  WithStyles,
} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';

import locales from '../../../../locales';
import { TReserve } from '../../../../types/IBookingStore';
import { TOrder } from '../../../../types/IStatisticStore';
import { parsingMergedTables } from '../../../../helpers/bookingHelpers';

const styles = createStyles({
  row: {
    borderBottom: 'none',
    height: 18,
  },
  cell: {
    borderBottom: 'none',
    padding: '6px 0',
  },
});

interface Props extends WithStyles<typeof styles> {
  info: TReserve;
  order: TOrder;
  classes: any;
  moneySymbol: string;
}

const ReserveDetails: React.FC<Props> = ({
  info,
  classes,
  order,
  moneySymbol,
}) => {
  const reserveData = [
    {
      title: locales.t('statistics.reserves.details.reserveData.id'),
      value: info.id || '—',
    },
    {
      title: locales.t('statistics.reserves.details.reserveData.dateOfCreation'),
      value: info.createdDate ? moment(info.createdDate).format('DD MMMM YYYYг. HH:mm') : '—',
    },
    {
      title: locales.t('statistics.reserves.details.reserveData.guests'),
      value: info.persons || '—',
    },
    {
      title: locales.t('statistics.reserves.details.reserveData.table'),
      value: parsingMergedTables(info.tables),
    },
    {
      title: locales.t('statistics.reserves.details.reserveData.date'),
      value: info.date ? moment(info.date).format('DD MMMM YYYYг. HH:mm') : '—',
    },
    {
      title: locales.t('statistics.reserves.details.reserveData.source'),
      value: info.source ? info.source.name : '—',
    },
    {
      title: locales.t('statistics.reserves.details.reserveData.openingDate'),
      value: info.openedTableDate
        ? moment(info.openedTableDate).format('DD MMMM YYYYг. HH:mm')
        : '—',
    },
  ];

  const checkData = [
    {
      title: locales.t('statistics.reserves.details.checkData.tableClosed'),
      value: info.completedDate ? moment(info.completedDate).format('DD MMMM YYYYг. HH:mm') : '—',
    },
    {
      title: locales.t('statistics.reserves.details.checkData.paidType'),
      value: '—',
    },
    {
      title: locales.t('statistics.reserves.details.checkData.paidDate'),
      value: info.paidDate ? moment(info.paidDate).format('DD MMMM YYYYг. HH:mm') : '—',
    },
    {
      title: locales.t('statistics.reserves.details.checkData.paidSum'),
      value: info.receiptAmount ? `${info.receiptAmount} ${moneySymbol}` : '—',
    },
  ];

  const renderStatTable = (title: string, params: Array<{title: string, value: string | number}>) => (
    <Fragment>
      <TableRow className={classes.row}>
        <TableCell colSpan={2} className={classes.cell}>
          <Typography variant="subtitle2">{ title }</Typography>
        </TableCell>
      </TableRow>
      {
        params.map(p => (
          <TableRow className={classes.row} key={p.title}>
            <TableCell align="left" padding="none" className={classes.cell}>
              <span>{ p.title }</span>
            </TableCell>
            <TableCell align="left" colSpan={2} padding="none" className={classes.cell}>
              <span>{ p.value }</span>
            </TableCell>
          </TableRow>
        ))
      }
    </Fragment>
  );

  const renderOrders = order ? (
    <Fragment>
      <TableRow className={classes.row}>
        <TableCell colSpan={3} className={classes.cell}>
          <Typography variant="subtitle2">
            { locales.t('statistics.reserves.details.order.title') }
          </Typography>
        </TableCell>
      </TableRow>
      {
        order.map((p, i: number) => (
          <TableRow className={classes.row} key={i}>
            <TableCell align="left" className={classes.cell} style={{ width: '45%' }}>
              <span>{ p.name }</span>
            </TableCell>
            <TableCell align="left" className={classes.cell} style={{ width: '25%' }}>
              <span>{ p.amount }</span>
            </TableCell>
            <TableCell align="left" className={classes.cell} style={{ width: '30%' }}>
              <span>{ `${p.resultSum} ${moneySymbol}` }</span>
            </TableCell>
          </TableRow>
        ))
      }
    </Fragment>
  ) : (
    <Fragment>
      <TableRow className={classes.row}>
        <TableCell colSpan={3} className={classes.cell}>
          <Typography variant="subtitle2">
            { locales.t('statistics.reserves.details.order.title') }
          </Typography>
        </TableCell>
      </TableRow>
    </Fragment>
  );

  const renderCommentData = (
    <Fragment>
      <TableRow className={classes.row}>
        <TableCell colSpan={3} className={classes.cell}>
          <Typography variant="subtitle2">
            { locales.t('statistics.reserves.details.comment.title') }
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow className={classes.row}>
        <TableCell align="left" padding="none" className={classes.cell}>
          <span>{ info.comment }</span>
        </TableCell>
      </TableRow>
    </Fragment>
  );

  return (
    <TableRow style={{ backgroundColor: '#f6f9f9' }}>
      <TableCell style={{ padding: '12px 24px' }} colSpan={7}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Table>
              <TableBody>
                { renderStatTable(locales.t('statistics.reserves.details.reserveData.title'), reserveData) }
              </TableBody>
            </Table>
          </Grid>
          <Grid item xs={6}>
            <Table>
              <TableBody>
                { renderStatTable(locales.t('statistics.reserves.details.checkData.title'), checkData) }
              </TableBody>
            </Table>
          </Grid>
          <Grid item xs={6}>
            <Table>
              <TableBody>
                { renderOrders }
              </TableBody>
            </Table>
          </Grid>
          <Grid item xs={6}>
            <Table>
              <TableBody>
                { renderCommentData }
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  );
};

export default withStyles(styles)(ReserveDetails);
