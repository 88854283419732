import React, { memo } from 'react';

import { Box } from '@material-ui/core';

import BodyItem from './BodyItem';
import locales from '../../../../locales';
import { TNotification } from '../../../../types/INotificationsStore';

interface BodyVisitsProps {
  notification: TNotification;
}

function BodyVisits(props: BodyVisitsProps) {
  const { notification } = props;

  const lastVisit = notification.getLastVisit();
  const totalVisits = notification.getTotalVisits();

  return (
    <Box display="flex" mb={2}>
      <BodyItem
        label={locales.t('notifications.properties.visits')}
        value={(totalVisits && totalVisits.toString()) || undefined}
      />

      <BodyItem
        label={locales.t('notifications.properties.lastVisit')}
        value={(lastVisit && lastVisit.format('DD.MM.YY')) || undefined}
        rightIndent={0}
      />
    </Box>
  );
}

export default memo(BodyVisits);
