import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { IStateProps, IDispatchProps } from './IReservesSystemAdditionalNotifications';
import ReservesSystemAdditionalNotificationsContainer from './ReservesSystemAdditionalNotificationsContainer';
import { updateRestaurantInfo } from '../../actions/restaurantsInfo/RestaurantsInfoActions';
import { IAppStore } from '../../types/IAppStore';
import { IAppActions } from '../../types/IAppActions';

function mapStateToProps(state: IAppStore): IStateProps {
  const { currentRestaurant } = state.cabinet;
  return { currentRestaurant };
}

function mapDispatchToProps(dispatch: Dispatch<IAppActions>): IDispatchProps {
  return bindActionCreators({ updateRestaurantInfo }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ReservesSystemAdditionalNotificationsContainer);
