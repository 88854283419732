import { PhoneNumberUtil, PhoneNumberFormat as PNF, PhoneNumber } from 'google-libphonenumber';
import { getPhoneCodes } from '../config/constants';

const phoneUtil = PhoneNumberUtil.getInstance();

/**
 * Возвращает разницу в процентах
 * @param {number} previous
 * @param {number} current
 * @returns {number}
 */
const getPercentDiff = (previous: number, current: number): number => {
  const previousResult = previous || 1;
  const currentResult = current || 1;

  return Math.round(100 * (currentResult - previousResult) / previousResult);
};


/**
 * Группировка массива по переданным параметрам
 * @param array - группируемый массив
 * @param props - groupByProperties = ['firstParam', 'secondSecond, ...']
 */
function groupBy<T>({ Group: array, By: props }: { Group: Array<T>; By: Array<string>; }): Array<Array<T>> {
  const getGroupedItems = (item: any) => {
    const returnArray = [];
    let i;
    for (i = 0; i < props.length; i += 1) {
      returnArray.push(item[props[i]]);
    }
    return returnArray;
  };

  const groups: any = {};

  let i;

  for (i = 0; i < array.length; i += 1) {
    const arrayRecord = array[i];
    const group: string = JSON.stringify(getGroupedItems(arrayRecord));
    groups[group] = groups[group] || [];
    groups[group].push(arrayRecord);
  }

  return Object.keys(groups).map(group => groups[group]);
}

/** @description Функция для валютного символа */
function getMoneySymbol(countryCode: string): '₽' | '₴' {
  switch (countryCode) {
    case 'RU':
      return '₽';
    case 'UA':
      return '₴';
    default: return '₽';
  }
}

const saveCurrentRestaurantIdToLocalStorage = (id: number | null | undefined) => {
  const name = 'currentRestaurant';

  if (id) {
    localStorage.setItem(name, JSON.stringify(id));
  } else {
    localStorage.removeItem(name);
  }
};

const getCurrentRestaurantIdFromLocalStorage = (): number | null => {
  const name = 'currentRestaurant';
  const storage = localStorage.getItem(name);

  if (storage) {
    const id = JSON.parse(storage);
    if (!isNaN(id) && id) {
      return id;
    }
  }

  return null;
};

const parsePhone = (input: string, region?: string) => {
  const regions = region ? [region] : getPhoneCodes().map(phoneCode => phoneCode.key.toUpperCase());

  for (let i = 0; i < regions.length; i += 1) {
    let phoneNumber: PhoneNumber | false;

    try {
      phoneNumber = phoneUtil.parseAndKeepRawInput(input, regions[i]);
    } catch (error) {
      phoneNumber = false;
    }

    if (phoneNumber && phoneUtil.isValidNumberForRegion(phoneNumber, regions[i])) {
      return phoneNumber;
    }
  }

  return false;
};

// const formatPhone = (phoneNumber: PhoneNumber) => phoneUtil.format(phoneNumber, PNF.INTERNATIONAL);
const formatPhone = (phoneNumber: PhoneNumber) => phoneUtil.format(phoneNumber, PNF.E164);

export {
  getPercentDiff,
  groupBy,
  getMoneySymbol,
  saveCurrentRestaurantIdToLocalStorage,
  getCurrentRestaurantIdFromLocalStorage,
  parsePhone,
  formatPhone,
};
