import React, { Component } from 'react';

import AuthIkkoModal from '../../components/Common/AuthIikoModal';
import { IAuthIikoModalProps } from './IAuthIikoModal';

class AuthIikoModalComp extends Component<IAuthIikoModalProps> {
  constructor(props: IAuthIikoModalProps) {
    super(props);
    this.state = {};
  }

  /** @description Отправка запроса на авторизацию в iiko */
  handleSendAuthReq = (authData: { login: string; password: string }) => {
    const { authFromIiko, currentRestaurantId } = this.props;
    authFromIiko(authData, currentRestaurantId);
  };

  /** @description Отправка запроса на выбор организации в iiko */
  handleSendSelectReq = (organizationId: string) => {
    const { selectOrganization, currentRestaurantId } = this.props;
    selectOrganization(currentRestaurantId, organizationId);
  };

  render() {
    const { modalOpen, switchAuthIikoModal, iikoAuth } = this.props;

    return (
      <AuthIkkoModal
        modalOpen={modalOpen}
        switchAuthIikoModal={switchAuthIikoModal}
        handleSendAuthReq={this.handleSendAuthReq}
        handleSendSelectReq={this.handleSendSelectReq}
        iikoAuth={iikoAuth}
      />
    );
  }
}

export default AuthIikoModalComp;
