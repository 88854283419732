import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { IAppStore } from '../../types/IAppStore';
import { IAppActions } from '../../types/IAppActions';
import { sendNewPassword } from '../../actions/restorePassword/RestorePasswordActions';
import SendNewPassword from './SendNewPassword';
import { StateProps, DispatchProps } from './ISendNewPassword';

function mapStateToProps(state: IAppStore, ownProps: any): StateProps {
  return {
    sendNewPasswordError: state.restorePassword.sendNewPasswordError,
    sendNewPasswordSuccess: state.restorePassword.sendNewPasswordSuccess,
    secretKey: ownProps.match.params.key,
  };
}

function mapDispatchToProps(dispatch: Dispatch<IAppActions>): DispatchProps {
  return bindActionCreators({
    sendNewPassword,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SendNewPassword);
