import React from 'react';
import Paper from '@material-ui/core/Paper/Paper';
import Table from '@material-ui/core/Table/Table';
import TableBody from '@material-ui/core/TableBody/TableBody';
import TableRow from '@material-ui/core/TableRow/TableRow';
import TableCell from '@material-ui/core/TableCell/TableCell';
import withStyles from '@material-ui/core/styles/withStyles';

import Title from '../../Common/Title';
import locales from '../../../locales';
import StatisticItem from './StatisticItem';

type Props = any;

const styles = () => ({
  statistics: {
    display: 'flex',
    justifyContent: 'center',
    minHeight: 140,
  },
  statisticItem: {
    padding: '24px 10px',
  },
  tableHeader: {
    fontWeight: 500,
    padding: 24,
  },
});

const index: React.FC<Props> = ({ statistic, classes, moneySymbol }) => (
  <Paper elevation={3}>
    <Title text={locales.t('statistics.table.title')} borderBottom />
    <div className={classes.statistics}>
      <Table>
        <TableBody>
          <TableRow key="1">
            <TableCell
              className={classes.tableHeader}
              component="th"
              scope="row"
            >
              { locales.t('statistics.table.guestsRow.title') }
            </TableCell>
            <TableCell className={classes.statisticItem}>
              <StatisticItem
                type={locales.t('statistics.table.guestsRow.profit')}
                stat={`${statistic.totalReceiptAmount.toFixed()} ${moneySymbol}`}
                color="primary"
              />
            </TableCell>
            <TableCell className={classes.statisticItem}>
              <StatisticItem
                type={locales.t('statistics.table.guestsRow.check')}
                stat={`${statistic.averageReceiptAmount.toFixed()} ${moneySymbol}`}
              />
            </TableCell>
            <TableCell className={classes.statisticItem}>
              <StatisticItem
                type={locales.t('statistics.table.guestsRow.guests')}
                stat={statistic.totalGuests}
              />
            </TableCell>
            <TableCell className={classes.statisticItem}>
              <StatisticItem
                type={locales.t('statistics.table.guestsRow.newGuests')}
                stat={statistic.totalNewGuests}
              />
            </TableCell>
            <TableCell style={{ flex: 1 }} />
          </TableRow>
          <TableRow key="2">
            <TableCell
              className={classes.tableHeader}
              component="th"
              scope="row"
            >
              { locales.t('statistics.table.reserveRow.title') }
            </TableCell>
            <TableCell className={classes.statisticItem}>
              <StatisticItem
                type={locales.t('statistics.table.reserveRow.all')}
                stat={statistic.total}
              />
            </TableCell>
            <TableCell className={classes.statisticItem}>
              <StatisticItem
                type={locales.t('statistics.table.reserveRow.success')}
                color="primary"
                stat={statistic.totalClosed}
              />
            </TableCell>
            <TableCell className={classes.statisticItem}>
              <StatisticItem
                type={locales.t('statistics.table.reserveRow.canceledByRestaurant')}
                stat={statistic.totalCancelledByRestaurant}
                color="error"
              />
            </TableCell>
            <TableCell className={classes.statisticItem}>
              <StatisticItem
                type={locales.t('statistics.table.reserveRow.rejectByRestaurant')}
                stat={statistic.totalDeclined}
              />
            </TableCell>
            <TableCell className={classes.statisticItem}>
              <StatisticItem
                type={locales.t('statistics.table.reserveRow.canceledByGuest')}
                stat={statistic.totalCancelledByGuest}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  </Paper>
);

export default withStyles(styles)(index);
