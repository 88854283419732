import React from 'react';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import { TransitionProps } from '@material-ui/core/transitions';
import {
  SnackbarContent,
  Theme,
  Snackbar,
  Slide,
} from '@material-ui/core';

import { TNotification } from '../../../../types/INotificationsStore';
// import { TCreateReserveInitialData } from '../../../../types/IReserveModalStore';

import { Routers } from '../../../../config/constants';
import locales from '../../../../locales';

import Header from './Header';

const useStyles = makeStyles((theme: Theme) => ({
  // root: {
  //   top: (props: any) => props.offset,
  // },
  contentRoot: {
    backgroundColor: 'rgba(84, 110, 122, 0.95)',
    width: 444,
    [theme.breakpoints.up('lg')]: {
      width: 468,
    },
  },
  contentRootActive: {
    backgroundColor: 'rgba(50, 50, 50, 0.95)',
    width: 444,
    [theme.breakpoints.up('lg')]: {
      width: 468,
    },
  },
  contentMessage: {
    width: '100%',
  },
}));

interface Props {
  notification: TNotification;
  onToggle: (key: string) => void;
  body: React.ReactElement;
  footer: React.ReactElement;
}

function SlideTransition(props: TransitionProps) {
  return <Slide {...props} direction="left" />;
}

const NotificationSnackbar: React.FunctionComponent<Props> = ({
  notification,
  onToggle,
  body,
  footer,
}) => {
  const history = useHistory();
  const classes = useStyles();

  const { payload, offset, open } = notification;
  const { id, phone, state } = payload;

  const guestFirstName = notification.getFirstName();
  const guestLastName = notification.getLastName();

  // const toggleFold = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
  //   if (reason === 'clickaway') {
  //     return;
  //   }
  //
  //   onToggle(notification.getKey());
  // };

  const names = [];

  if (guestFirstName) {
    names.push(guestFirstName);
  }

  if (guestLastName) {
    names.push(guestLastName);
  }

  const isGuest = names.length === 0;
  const name = isGuest ? locales.t('notifications.guest') : names.join(' ');

  const goToUser = (phone: string) => {
    if (!isGuest) {
      history.push(`${Routers.userStatistics}/${phone}`);
    }
  };

  return (
    <Snackbar
      style={{
        top: offset,
      }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transitionDuration={250}
      TransitionComponent={SlideTransition}
      open
    >
      <SnackbarContent
        classes={{
          root: state === 'ACTIVE' ? classes.contentRootActive : classes.contentRoot,
          message: classes.contentMessage,
        }}
        message={(
          <>
            <Header
              id={notification.getKey()}
              name={name}
              phone={phone}
              isGuest={isGuest}
              open={open}
              clickPerson={phone => goToUser(phone)}
              onToggle={onToggle}
            />

            {body}
            {footer}
          </>
        )}
      />
    </Snackbar>
  );
};

export default NotificationSnackbar;
