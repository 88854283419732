import React from 'react';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import locales from '../../../locales';

const rows: Array<{
  id: string;
  sortable: boolean;
  label: string;
  last?: boolean;
  first?: boolean;
}> = [
  {
    id: 'date', sortable: true, label: locales.t('sourceStatistic.reserveTable.date'), first: true,
  },
  { id: 'name', sortable: false, label: locales.t('sourceStatistic.reserveTable.name') },
  { id: 'phone', sortable: false, label: locales.t('sourceStatistic.reserveTable.phone') },
  { id: 'status', sortable: false, label: locales.t('sourceStatistic.reserveTable.status') },
  { id: 'check', sortable: false, label: locales.t('sourceStatistic.reserveTable.check') },
  {
    id: 'empty', sortable: false, label: '', last: true,
  },
];

interface Props {
  onRequestSort: (event: any, property: any) => void;
  order: 'asc' | 'desc';
  orderBy: string;
}

const useStyles = makeStyles({
  paddingLeft: {
    paddingLeft: 24,
  },
});

const SourceStatisticReserveTableHead: React.FC<Props> = (props) => {
  const { onRequestSort, order, orderBy } = props;
  const classes = useStyles();

  const createSortHandler = (property: any) => (event: any) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead style={{ backgroundColor: '#F2F2F2' }}>
      <TableRow>
        {rows.map((row) => (
          <TableCell
            key={row.id}
            align="left"
            padding={row.last ? 'none' : 'default'}
            className={classNames({ [classes.paddingLeft]: row.first })}
            sortDirection={orderBy === row.id ? order : false}
          >
            {row.sortable
              ? (
                <TableSortLabel
                  active={orderBy === row.id}
                  direction={order}
                  onClick={createSortHandler(row.id)}
                >
                  {row.label}
                </TableSortLabel>
              )
              : (<span>{row.label}</span>)
            }
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default SourceStatisticReserveTableHead;
