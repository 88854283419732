import React from 'react';
import moment from 'moment';

import { WithStyles, withStyles, createStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';

import ArrowLeft from '../Common/Icons/ArrowLeft';
import ArrowRight from '../Common/Icons/ArrowRight';
import BookingDatePicker from '../Common/DatePickers/BookingDatePicker';

const styles = createStyles({
  wrap: {
    width: '100%',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
  },
  marginRight: {
    marginRight: 20,
  },
  marginLeft: {
    marginLeft: 20,
  },
  textCenter: {
    textAlign: 'center',
    cursor: 'pointer',
  },
});

interface IProps extends WithStyles {
  currentStatisticDate: string;
  handleChangeDate: (d: any) => void;
}

const BookingDate: React.FC<IProps> = (props) => {
  const {
    classes,
    currentStatisticDate,
    handleChangeDate,
  } = props;

  /** @description Переключение даты */
  const onChangeDate = (type: number) => {
    let newDate = null;

    switch (type) {
      case 0: {
        newDate = moment(currentStatisticDate)
          .subtract(1, 'days')
          .format();
        break;
      }
      case 1: {
        newDate = moment(currentStatisticDate)
          .add(1, 'days')
          .format();
        break;
      }
      default: {
        newDate = currentStatisticDate;
      }
    }

    handleChangeDate(newDate);
  };

  return (
    <div className={classes.wrap}>
      <IconButton
        onClick={() => onChangeDate(0)}
        className={classes.marginRight}
      >
        <ArrowLeft />
      </IconButton>
      <BookingDatePicker
        handleChangeDate={handleChangeDate}
        currentStatisticDate={currentStatisticDate}
      />
      <IconButton
        onClick={() => onChangeDate(1)}
        className={classes.marginLeft}
      >
        <ArrowRight />
      </IconButton>
    </div>
  );
};

export default withStyles(styles)(BookingDate);
