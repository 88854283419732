// TODO Поправить типизацию i18next
// @ts-ignore
import i18next from 'i18next';
import locales from './locales.json';

export default i18next.init({
  lng: 'ru',
  debug: true,
  resources: locales,
  react: {
    transEmptyNodeValue: '',
    transSupportBasicHtmlNodes: true, // allow <br/> and simple html elements in translations
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'a'],
  },
});
