import React, { ChangeEvent } from 'react';
import { WrappedFieldProps } from 'redux-form';
import moment from 'moment';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { Divider, ListSubheader, FormHelperText } from '@material-ui/core';

import locales from '../../locales';
import { emptyString, emptyTime } from '../../config/constants';
import { IWorkTime } from '../../types/IScheduleStore';
import { errorColor } from '../../theme';

interface IProps extends WrappedFieldProps {
  time: Array<IWorkTime>;
  selectedTime: string;
  handleSetTime: (event: ChangeEvent<HTMLSelectElement>) => void;
}

/** @description Селектор для выбора времени бронирования */
const ReserveTimeSelect: React.FC<IProps> = ({
  input,
  meta: { touched, error },
  time,
}) => {
  const pastTime = moment(input.value) < moment();
  const isError = (touched && error) || pastTime;
  const colorError = isError ? { color: errorColor } : {};

  return (
    <FormControl fullWidth error={isError} disabled={!time || time.length === 0}>
      <InputLabel htmlFor="time">
        { locales.t('common.reserveModal.time') }
      </InputLabel>
      <Select
        style={colorError}
        {...input}
        inputProps={{
          name: 'time',
          id: 'time',
        }}
        fullWidth
      >
        {
          input.value === emptyTime && (
            <MenuItem selected value={emptyTime} key={emptyTime}>
              { locales.t('common.reserveModal.emptyTime') }
            </MenuItem>
          )
        }
        {
          time.map((t, i) => t.value === emptyString
            ? (
              <ListSubheader key={t.value}>
                <Divider />
              </ListSubheader>
            )
            : (
              <MenuItem alignItems="center" key={t.value + i.toString()} value={t.value}>
                { t.label }
              </MenuItem>
            ))
        }
      </Select>
      {
        pastTime && (
          <FormHelperText>
            { locales.t('common.reserveModal.formErrors.pastTime') }
          </FormHelperText>
        )
      }
    </FormControl>
  );
};

export default ReserveTimeSelect;
