import * as ActionTypes from './BookingActionsTypes';
import { TReserve, TSort, TFilterListItem } from '../../types/IBookingStore';
import { IBookingActions } from './IBookingActions';

/** @description Инит страницы */
export function initBookingPages(): IBookingActions {
  return {
    type: ActionTypes.INIT_BOOKING_PAGE,
  };
}

/** @description Успешная инит страницы */
export function initBookingPagesSuccess(
  reserves: Array<TReserve>, filters: Array<TFilterListItem>, onlyActive: boolean,
): IBookingActions {
  return {
    type: ActionTypes.INIT_BOOKING_PAGE_SUCCESS,
    payload: {
      reserves,
      filters,
      onlyActive,
    },
  };
}

/** @description Обработка ошибки инита страницы */
export function initBookingPageError(
  filters: Array<TFilterListItem>, onlyActive: boolean,
): IBookingActions {
  return {
    type: ActionTypes.INIT_BOOKING_PAGE_ERROR,
    payload: {
      filters,
      onlyActive,
    },
  };
}

/** @description Экшен для получения списка броней */
export function getBookingList(restaurantId: number, date: string): IBookingActions {
  return {
    type: ActionTypes.GET_BOOKING_REQUEST,
    payload: {
      restaurantId,
      date,
    },
  };
}

/** @description Экшен на обработку успешного запроса на получение списка резервов */
export function getBookingSuccess(
  bookingList: Array<TReserve>,
  active: boolean,
  sort: TSort,
  date: string,
): IBookingActions {
  return {
    type: ActionTypes.GET_BOOKING_SUCCESS,
    payload: {
      bookingList,
      active,
      sort,
      date,
    },
  };
}

/** @description Экшен на обработку ошибки запроса на получение списка резервов */
export function getBookingError(date: string): IBookingActions {
  return {
    type: ActionTypes.GET_BOOKING_ERROR,
    error: 'Не удалось получить список резервов',
    payload: {
      date,
    },
  };
}

/** @description Экшен для переключения фильтра */
export function switchBookingFilter(active: boolean, resetFilters?: boolean): IBookingActions {
  return {
    type: ActionTypes.SWITCH_BOOKING_ACTIVE,
    payload: {
      active,
      resetFilters,
    },
  };
}

/** @description Экшен на сортировку списка */
export function sortBookingList(sort: TSort): IBookingActions {
  return {
    type: ActionTypes.SORT_BOOKING_LIST,
    payload: {
      sort,
    },
  };
}

/** @description Экшен для редактирования фильтров для списка броней */
export function filterBookingListBy(filters: Array<TFilterListItem>): IBookingActions {
  return {
    type: ActionTypes.SET_FILTERS,
    payload: {
      filters,
    },
  };
}
