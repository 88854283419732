import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import EmployeeFormComponent from '../../../components/Employees/Form';
import NotFoundComponent from '../../../components/Employees/Employee/NotFound';
import { useRestaurantId } from '../../../selectors/RestaurantSelectors';
import { Employees } from '../../../entities';

export default function EmployeeFormContainer(props: { type: 'add' | 'edit' | 'profile' }) {
  const { type } = props;
  const { data: currentEmployee } = Employees.useCurrentEmployee();
  const restaurantId = useRestaurantId();
  const history = useHistory();
  const params = useParams<{employeeId: string}>();
  const createMutation = Employees.useCreateMutation();
  const updateMutation = Employees.useUpdateMutation();

  useEffect(() => {
    if (createMutation.status === 'success') {
      history.push('/employees');
    }
  }, [type, createMutation.status]);

  function getEmployeeId(): number | undefined {
    if (type === 'profile') {
      if (currentEmployee) {
        return currentEmployee.id;
      }
    } else if (type === 'edit') {
      const id = parseInt(params.employeeId, 10);
      if (!Number.isNaN(id)) {
        return id;
      }
    }

    return undefined;
  }

  const id = getEmployeeId();
  if ((id === undefined && type !== 'add') || !restaurantId) {
    return <NotFoundComponent />;
  }

  let employee: GM.Employees.Employee | null = null;
  let canBeDeleted = false;

  if (type === 'edit' && id) {
    const { data: employees } = Employees.useEmployees();
    if (employees) {
      employee = employees[id];
      canBeDeleted = Employees.employeeCanBeDeleted(id, employees);
    }
  } else if (type === 'profile') {
    employee = currentEmployee;
  }

  if (!employee && type !== 'add') {
    return <NotFoundComponent />;
  }

  const onSubmit = (employee: GM.Employees.Form) => {
    if (type === 'add') {
      createMutation.mutate({
        requestData: employee,
        restaurantId,
      });
    } else if (id && (type === 'edit' || type === 'profile')) {
      const payload = {
        requestData: employee,
        id,
        isProfile: type === 'profile' || (type === 'edit' && currentEmployee !== null && currentEmployee.id === id),
      };

      // Если не админ правит свой профиль, то не нужно отправлять в запросе его роли
      if (
        !(currentEmployee && Employees.isAdmin(currentEmployee))
        && type === 'profile'
        && Object.prototype.hasOwnProperty.call(payload.requestData, 'authorities')
      ) {
        delete payload.requestData.authorities;
      }

      updateMutation.mutate(payload);
    }
  };

  const data: GM.Employees.Form = {
    firstName: (employee && employee.firstName) || '',
    lastName: (employee && employee.lastName) || '',
    phone: (employee && employee.phone) || '',
    email: (employee && employee.email) || '',
    authorities: (employee && employee.authorities) || [],
  };

  return (
    <EmployeeFormComponent
      data={data}
      onCancel={() => history.goBack()}
      onSubmit={onSubmit}
      type={employee ? 'edit' : 'create'}
      canChangeAuthorities={currentEmployee !== null && Employees.isAdmin(currentEmployee)}
      canUnsetAdminAuthority={!employee || canBeDeleted}
    />
  );
}
