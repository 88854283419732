import Konva from 'konva';
import { reduce } from 'lodash';

import { TSectionAdaptSchema } from '../../../../types/IRestaurantTablesSchemaStore';
import { ITable } from '../../../../types/IRestaurantTablesStore';

export const SCALE_MAX: number = 4;
export const SCALE_MIN: number = 0.5;
export const SCALE_STEP: number = 1.1;

export const getScaleToFit = (
  data: TSectionAdaptSchema,
  width: number,
  height: number,
): number => {
  const bgImage: any = data.elements.find(d => d.type === 'image');
  if (!width) return 1;
  const getScale = (imageWidth: number, imageHeight: number) => {
    const scaleX = width / imageWidth;
    const scaleY = height / imageHeight;
    return scaleY < scaleX ? scaleY : scaleX;
  };
  if (bgImage) {
    return getScale(bgImage.element.width, bgImage.element.height);
  }
  return getScale(data.width, data.height);
};

export const scaleUp = (scale: number) => scale * SCALE_STEP;
export const scaleDown = (scale: number) => scale / SCALE_STEP;

export const handleZoomChange = (stage: Konva.Stage, scaleTo: (scale: number) => void) => {
  const onWheel = (e: any) => {
    e.evt.preventDefault();
    const currentScale = stage.scaleX();
    const pointer = stage.getPointerPosition();

    if (pointer) {
      const scale = e.evt.deltaY > 0 ? scaleUp(currentScale) : scaleDown(currentScale);

      // prevent further upscale/downscale
      if (e.evt.deltaY > 0 ? currentScale > SCALE_MAX : currentScale < SCALE_MIN) return;


      const mousePointTo = {
        x: pointer.x / currentScale - stage.x() / currentScale,
        y: pointer.y / currentScale - stage.y() / currentScale,
      };
      const newPos = {
        x: -(mousePointTo.x - pointer.x / scale) * scale,
        y: -(mousePointTo.y - pointer.y / scale) * scale,
      };

      scaleTo(scale);
      stage.position(newPos);
      stage.batchDraw();
    }
  };
  stage.on('wheel', onWheel);

  return () => {
    stage.off('wheel', onWheel);
  };
};

/**
 * @description: get distance between 2 points, e.g. for multi touch event
 * original source https://konvajs.org/docs/sandbox/Multi-touch_Scale_Stage.html
 * @param p1
 * @param p2
 */
export function getDistance(p1: Konva.Vector2d | null, p2: Konva.Vector2d | null): number {
  if (!p1 || !p2) return 0;
  return Math.sqrt(((p2.y - p1.y) ** 2) + ((p2.x - p1.x) ** 2));
}

/**
 * original source https://konvajs.org/docs/sandbox/Multi-touch_Scale_Stage.html
 * @param p1
 * @param p2
 */
export function getCenter(p1: Konva.Vector2d | null, p2: Konva.Vector2d | null): Konva.Vector2d {
  if (!p1 || !p2) return { x: 0, y: 0 };

  return {
    x: (p1.x + p2.x) / 2,
    y: (p1.y + p2.y) / 2,
  };
}

/**
 * @description: resize Stage via touch events
 * @param stage
 * @param scaleTo
 */
export const handleTouchMove = (stage: Konva.Stage, scaleTo: (scale: number) => void) => {
  let lastCenter: Konva.Vector2d | null = null;
  let lastDist = 0;

  const setDefaults = () => {
    lastDist = 0;
    lastCenter = null;
  };

  /**
   * original source https://konvajs.org/docs/sandbox/Multi-touch_Scale_Stage.html
   * @param e
   */
  const onTouchMove = (e: any) => {
    e.evt.preventDefault();
    const [touch1, touch2] = e.evt.touches || [];

    if (touch1 && touch2) {
      // if the stage was under Konva's drag&drop
      // we need to stop it, and implement our own pan logic with two pointers
      if (stage.isDragging()) {
        stage.stopDrag();
      }

      const p1 = {
        x: touch1.clientX,
        y: touch1.clientY,
      };
      const p2 = {
        x: touch2.clientX,
        y: touch2.clientY,
      };

      if (!lastCenter) {
        lastCenter = getCenter(p1, p2);
        return;
      }
      const newCenter = getCenter(p1, p2);
      const dist = getDistance(p1, p2);

      if (!lastDist) {
        lastDist = dist;
      }

      // local coordinates of center point
      const pointTo = {
        x: (newCenter.x - stage.x()) / stage.scaleX(),
        y: (newCenter.y - stage.y()) / stage.scaleX(),
      };

      const scale: number = stage.scaleX() * (dist / lastDist);
      stage.scaleX(scale);
      stage.scaleY(scale);

      // calculate new position of the stage
      const dx = newCenter.x - lastCenter.x;
      const dy = newCenter.y - lastCenter.y;

      const newPos = {
        x: newCenter.x - pointTo.x * scale + dx,
        y: newCenter.y - pointTo.y * scale + dy,
      };

      stage.position(newPos);
      stage.batchDraw();

      lastDist = dist;
      lastCenter = newCenter;
    }
  };

  const onTouchMoveEnd = () => {
    const currentScale = stage.scaleX();
    scaleTo(currentScale);
    setDefaults();
  };

  stage.on('touchmove', onTouchMove);
  stage.on('touchend', onTouchMoveEnd);

  return () => {
    stage.off('touchmove', onTouchMove);
    stage.off('touchend', onTouchMoveEnd);
  };
};

export const setCursor = (type: string) => (e: Konva.KonvaEventObject<MouseEvent>) => {
  const stage = e.target.getStage();
  if (stage) {
    const container = stage.container();
    container.style.cursor = type;
  }
};

export const genAvailableTableMap = (tables: ITable[]) => reduce(tables, (result: {
  [key: string]: ITable
}, value) => ({
  ...result,
  [String(value.id)]: value,
}), {});

export const hasCapacity = (table: ITable, persons?: number): boolean => (!persons ? true : table.capacity >= persons);
