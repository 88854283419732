import * as ActionTypes from './RestaurantsActionTypes';
import { IRestaurant } from './IRestaurant';
import { TRestaurantSectionAdapt } from '../../types/IRestaurantTablesSchemaStore';

/** @description Экшен для удаления выбранного ресторана */
export function deleteRestaurant(restaurantId: number): IRestaurant {
  return {
    type: ActionTypes.DELETE_RESTAURANT_REQUEST,
    payload: {
      restaurantId,
    },
  };
}

/** @description Экшен для обработки успешного удаления ресторана */
export function deleteRestaurantSuccess(): IRestaurant {
  return {
    type: ActionTypes.DELETE_RESTAURANT_SUCCESS,
    message: 'Ресторан успешно удален',
  };
}

/** @description Экшен для обработки ошибки удаления ресторана */
export function deleteRestaurantError(): IRestaurant {
  return {
    type: ActionTypes.DELETE_RESTAURANT_ERROR,
    error: 'Не удалось удалить ресторан',
  };
}

/** @description Экшен для отправки запроса на получение залов */
export function getSections(
  restaurantId: number,
): IRestaurant {
  return {
    type: ActionTypes.GET_SECTION_REQUEST,
    payload: {
      restaurantId,
    },
  };
}

/** @description Экшен для обработки успешного запроса на получение столов */
export function getSectionSuccess(sections: Array<TRestaurantSectionAdapt>): IRestaurant {
  return {
    type: ActionTypes.GET_SECTION_REQUEST_SUCCESS,
    payload: {
      sections,
    },
  };
}

/** @description Экшен для обработки ошибки запроса на получение столов */
export function getSectionError(): IRestaurant {
  return {
    type: ActionTypes.GET_SECTION_REQUEST_ERROR,
    error: 'Не удалось получить список залов',
  };
}
