import React, { Component } from 'react';
import _ from 'lodash';

import {
  withStyles, createStyles, WithStyles,
} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import EnhancedTableHead from './EnhancedTableHead';
import locales from '../../../locales';
import { parseDateFromBookingList, parseCreateDateFromBookingList } from '../../../helpers/dateHelpers';
import { parsingMergedTables } from '../../../helpers/bookingHelpers';
import { parsePhoneNumber } from '../../../helpers/reserveHelpers';
import { TReserve, TBookingFilters, TSort } from '../../../types/IBookingStore';
import { filterBookingListBy, sortBookingList } from '../../../actions/booking/BookingActions';
import { errorColor } from '../../../theme';

const styles = () => createStyles({
  tableRow: {
    cursor: 'pointer',
    height: 64,
    '& td': {
      fontSize: 14,
    },
  },
  tableTimeWrap: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
  },
  tableTimeBanquetLabel: {
    position: 'absolute',
    top: '-14px',
    fontSize: 11,
    color: '#f45858',
  },
  NEED_PREPARE_TABLE: { color: 'orange' },
  GUEST_IS_LATE: { color: errorColor },
});

interface IProps extends WithStyles<typeof styles> {
  bookingInfoList: Array<TReserve>;
  handleOpenEditReserveModal: any;
  handleSortBookingList: typeof sortBookingList;
  handleFilterBy: typeof filterBookingListBy;
  sort: TSort;
  filters: TBookingFilters;
}

interface IState {
  page: number;
  rowsPerPage: number;
}

class ReserveList extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      page: 0,
      rowsPerPage: 8,
    };
  }

  /** @description Запрос на сортировку */
  handleRequestSort = (event: any, property: any) => {
    const { sort, handleSortBookingList } = this.props;
    const orderBy = property;
    let order: 'asc' | 'desc' = 'desc';

    if (sort.orderBy === property && sort.order === 'desc') {
      order = 'asc';
    }

    handleSortBookingList({ order, orderBy });
  };

  render() {
    const {
      classes,
      bookingInfoList,
      sort,
      filters,
      handleOpenEditReserveModal,
      handleFilterBy,
    } = this.props;
    const {
      rowsPerPage, page,
    } = this.state;

    const emptyRows = rowsPerPage - Math.min(
      rowsPerPage, bookingInfoList.length - page * rowsPerPage,
    );

    return (
      <Paper elevation={3}>
        <Table style={{ minWidth: 960 }}>
          <EnhancedTableHead
            order={sort.order}
            orderBy={sort.orderBy}
            onRequestSort={this.handleRequestSort}
            filters={filters}
            handleFilterBy={handleFilterBy}
          />

          <TableBody>
            { bookingInfoList.map((n: TReserve) => (
              <TableRow
                hover
                onClick={() => handleOpenEditReserveModal(n)}
                role="checkbox"
                tabIndex={-1}
                key={n.id}
                className={classes.tableRow}
              >
                <TableCell
                  style={{ minWidth: '15%' }}
                  // @ts-ignore
                  className={classes[n.state]}
                  align="center"
                  padding="default"
                >
                  { locales.t(`booking.status.${n.state}`) }
                </TableCell>
                <TableCell style={{ fontSize: 16, minWidth: '10%' }} align="center" padding="default">
                  <div className={classes.tableTimeWrap}>
                    { n.isBanquet && (
                      <span className={classes.tableTimeBanquetLabel}>
                        { locales.t('booking.banquet') }
                      </span>
                    ) }
                    { parseDateFromBookingList(n.date) }
                  </div>
                </TableCell>
                <TableCell align="center">
                  { n.persons }
                </TableCell>
                <TableCell align="center" style={{ minWidth: '10%' }}>
                  { parsingMergedTables(n.tables)}
                </TableCell>
                <TableCell style={{ minWidth: '10%' }} align="center" padding="default">
                  {(`${n.firstName} ${!_.isNull(n.lastName) ? n.lastName : ''}`) }
                </TableCell>
                <TableCell style={{ minWidth: '10%' }} align="center" padding="default">
                  { parsePhoneNumber(n.phone)}
                </TableCell>
                <TableCell style={{ width: '15%', wordBreak: 'break-all' }} align="center" padding="default">
                  { n.comment }
                </TableCell>
                <TableCell style={{ minWidth: '10%' }} align="center" padding="default">
                  { parseCreateDateFromBookingList(n.createdDate) }
                </TableCell>
                <TableCell style={{ minWidth: '10%' }} align="center" padding="default">
                  { n.source.name }
                </TableCell>
              </TableRow>
            )) }
            { emptyRows > 0 && (
              <TableRow style={{ height: 64 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            ) }
          </TableBody>
        </Table>
      </Paper>
    );
  }
}

export default withStyles(styles)(ReserveList);
