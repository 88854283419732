import {
  TColor,
  TSectionSchema,
  TSectionAdaptSchema,
  TElementAdaptScheme,
  TSectionMarkScheme,
  TSectionImageScheme,
  TSectionTableScheme,
  TSectionElementScheme,
} from '../../types/IRestaurantTablesSchemaStore';

/**
 * @description Преобразование объекта IColor строку css rgba(255, 255, 255, 1);
 * @param color
 * @returns {string}
 */
function getColor(color: TColor): string {
  return `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`;
}

/**
 * @description Объединяет все сущности в единую коллекцию и сортирует по z;
 */
function getSchema(data: TSectionSchema): TSectionAdaptSchema {
  const {
    id, uuid, createdDate, lastModifiedDate, width, height,
  } = data || {};
  return {
    id,
    createdDate,
    lastModifiedDate,
    width,
    height,
    uuid,
    elements: [
      ...(data.marks || []).map((d: TSectionMarkScheme): TElementAdaptScheme => ({
        type: 'text',
        element: d,
      })),
      ...(data.images || []).map((d: TSectionImageScheme): TElementAdaptScheme => ({
        type: 'image',
        element: d,
      })),
      ...(data.tables || []).map((d: TSectionTableScheme): TElementAdaptScheme => ({
        type: 'table',
        element: d,
      })),
      ...(data.rectangles || []).map((d: TSectionElementScheme): TElementAdaptScheme => ({
        type: 'rect',
        element: d,
      })),
      ...(data.ellipses || []).map((d: TSectionElementScheme): TElementAdaptScheme => ({
        type: 'ellipse',
        element: d,
      })),
    ].sort((a: TElementAdaptScheme, b: TElementAdaptScheme) => a.element.z - b.element.z),
  };
}

export { getColor, getSchema };
