import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';

import TableCell from '@material-ui/core/TableCell/TableCell';
import TableRow from '@material-ui/core/TableRow/TableRow';
import Typography from '@material-ui/core/Typography';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';

import ReserveDetails from '../../Common/Statistic/StatisticReserveTable/ReserveDetails';
import { TReserve } from '../../../types/IBookingStore';
import locales from '../../../locales';
import { TOrder } from '../../../types/IStatisticStore';
import { Routers } from '../../../config/constants';
import ReserveTime from '../../Common/Statistic/StatisticReserveTable/ReserveTime';

interface OwnProps {
  reserveInfo: TReserve;
  handleOpenBookingDetails: (bookingId: number) => void;
  order: TOrder;
  moneySymbol: string;
}

type Props = OwnProps;

const UserStatisticsReserveTableItem: React.FC<Props> = ({
  reserveInfo,
  order,
  handleOpenBookingDetails,
  moneySymbol,
}) => {
  const [isOpen, changeVisible] = useState(false);
  const handleChangeVisible = () => {
    if (!isOpen) {
      handleOpenBookingDetails(reserveInfo.id);
    }

    changeVisible(!isOpen);
  };

  const stateColor = (state: string): 'initial' | 'primary' | 'error' => {
    switch (state) {
      case 'DECLINED':
      case 'GUEST_DECLINE':
      case 'RESTAURATEUR_DECLINE':
      case 'UNKNOWN_DECLINE':
        return 'error';
      case 'CLOSED':
        return 'primary';
      default: return 'initial';
    }
  };

  return (
    <Fragment>
      <TableRow>
        <TableCell padding="default" style={{ paddingLeft: 24 }}>
          <ReserveTime reserveInfo={reserveInfo} />
        </TableCell>
        <TableCell padding="default">
          <Typography color={stateColor(reserveInfo.state)}>
            { reserveInfo.state ? locales.t(`booking.status.${reserveInfo.state}`) : '—' }
          </Typography>
        </TableCell>
        <TableCell padding="default">
          { reserveInfo.persons || '—'}
        </TableCell>
        <TableCell padding="default">
          <Typography variant="body1">
            { reserveInfo.receiptAmount ? `${reserveInfo.receiptAmount} ${moneySymbol}` : '—' }
          </Typography>
        </TableCell>
        <TableCell padding="default">
          <span style={reserveInfo.source && { textDecoration: 'underline' }}>
            { reserveInfo.source ? (
              <Link to={`${Routers.sourceStatistics}/${reserveInfo.source.id}`}>
                <span style={{ textDecoration: 'underline' }}>
                  { reserveInfo.source.name || '—' }
                </span>
              </Link>
            ) : '—' }
          </span>
        </TableCell>
        <TableCell padding="default" align="right" style={{ paddingRight: 12 }}>
          <IconButton onClick={handleChangeVisible}>
            { isOpen ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </TableCell>
      </TableRow>
      <Collapse
        in={isOpen}
        unmountOnExit
        timeout="auto"
        component={() => <ReserveDetails moneySymbol={moneySymbol} info={reserveInfo} order={order} />}
      />
    </Fragment>
  );
};

export default UserStatisticsReserveTableItem;
