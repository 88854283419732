import React from 'react';

import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import DialogContentText from '@material-ui/core/DialogContentText';

import locales from '../../../locales';

const styles = createStyles({
  contentText: {
    paddingTop: 8,
    color: '#323232',
  },
});

interface IProps extends WithStyles<typeof styles> {}

const ConnectInstruction: React.FC<IProps> = ({ classes }) => (
  <div>
    <DialogContentText className={classes.contentText}>
      <div dangerouslySetInnerHTML={{ __html: locales.t('home.connectFormDescription', {
        tagStart: '<a href="https://guestme.ru/getting-started/new-rest/" style="text-decoration: underline" >',
        tagEnd: '</a>',
        interpolation: { escapeValue: false },
      }),
      }}
      />
    </DialogContentText>
  </div>
);

export default withStyles(styles)(ConnectInstruction);
