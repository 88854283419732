import React, { PropsWithChildren } from 'react';
import {
  Button, Typography, withStyles, WithStyles,
} from '@material-ui/core';

import AuthWrapComp from '../../components/Auth/AuthWrapComp';
import Logo from '../../components/Auth/CommonAuth/Logo';
import UseRules from '../../components/Auth/CommonAuth/UseRules';

type Props = WithStyles & PropsWithChildren<{
  logoSubTitle: string;
  contentTitle?: string;
  note?: string;
  buttonTitle?: string;
  buttonAction?: () => void;
  error?: boolean;
}>;

function NoAuthPage(props: Props) {
  const {
    classes,
    logoSubTitle,
    contentTitle,
    note,
    buttonTitle,
    buttonAction,
    children,
    error,
  } = props;

  return (
    <AuthWrapComp>
      <Logo text={logoSubTitle} />

      {contentTitle && (
        <Typography
          align="center"
          className={classes.contentTitle}
          color={(error && 'error') || 'inherit'}
          dangerouslySetInnerHTML={{ __html: contentTitle }}
        />
      )}

      <div className={classes.contentText}>
        {children}
      </div>

      {buttonTitle && buttonAction && (
        <Button
          variant="contained"
          color="primary"
          className={classes.contentBtn}
          onClick={buttonAction}
        >
          {buttonTitle}
        </Button>
      )}

      {note && (
        <Typography
          align="center"
          variant="caption"
          className={classes.contentDesc}
          dangerouslySetInnerHTML={{ __html: note }}
        />
      )}

      <UseRules />
    </AuthWrapComp>
  );
}

const styles = {
  contentTitle: {
    marginTop: 34,
    fontSize: 20,
    lineHeight: '24px',
  },
  contentText: {
    marginTop: 34,
    fontSize: 14,
  },
  contentBtn: {
    marginTop: 34,
    height: 46,
    width: 226,
  },
  contentDesc: {
    marginTop: 34,
    width: 226,
  },
};

export default withStyles(styles)(NoAuthPage);
