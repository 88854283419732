export const SET_APP_RESERVES = 'SET_APP_RESERVES';

export const ADD_APP_RESERVE = 'ADD_APP_RESERVE';
export const REMOVE_APP_RESERVE = 'REMOVE_APP_RESERVE';

export const OPEN_APP_RESERVE = 'OPEN_APP_RESERVE';
export const CLOSE_APP_RESERVE = 'CLOSE_APP_RESERVE';
export const TOGGLE_APP_RESERVES = 'TOGGLE_APP_RESERVES';

export const CHANGE_APP_RESERVE = 'CHANGE_APP_RESERVE';
export const OUTER_CHANGE_APP_RESERVE = 'OUTER_CHANGE_APP_RESERVE';
