import Reserve, { IAppReservesStore } from '../types/IAppReservesStore';
import { IAppActions } from '../types/IAppActions';
import * as ActionTypes from '../actions/appReserves/AppReservesActionsTypes';

const initState: IAppReservesStore = {
  items: [],
};

export default function reducer(state: IAppReservesStore = initState, action: IAppActions): IAppReservesStore {
  switch (action.type) {
    case ActionTypes.SET_APP_RESERVES:
      return {
        ...state,
        items: action.reserves,
      };

    case ActionTypes.ADD_APP_RESERVE:
      if (state.items && state.items.find(reserve => reserve.key === action.reserve.key)) {
        return state;
        // return {
        //   ...state,
        //   items: state.items.map(
        //     (reserve: Reserve) => (reserve.key === action.reserve.key ? action.reserve : reserve),
        //   ),
        // };
      }

      return {
        ...state,
        items: [
          ...(state.items || []),
          action.reserve,
        ],
      };

    case ActionTypes.REMOVE_APP_RESERVE:
      return {
        ...state,
        items: (state.items || []).filter(item => item.key !== action.key),
      };

    case ActionTypes.OPEN_APP_RESERVE:
      let openKey = action.key || state.lastKey;

      if (state.items && (!openKey || !state.items.find(i => i.key === openKey))) {
        openKey = state.items[0].key;
      }

      return {
        ...state,
        items: state.items.map((item: Reserve) => ({
          ...item,
          open: item.key === openKey,
        })),
      };

    case ActionTypes.CLOSE_APP_RESERVE:
      const selectedReserve = state.items.find((i: Reserve) => i.open);
      const closeKey = action.key || (selectedReserve && selectedReserve.key);

      if (!closeKey) {
        throw new Error('No reserve to close');
      }

      return {
        ...state,

        // Последний ключ сохраняем только, если отключается видимость
        //   всех форм (через кнопку back или иконку в шапке)
        // lastKey: (!action.key && selectedReserve && selectedReserve.key) || state.lastKey,
        lastKey: (!action.key && selectedReserve && selectedReserve.key) || undefined,

        items: state.items.map((item: Reserve) => {
          if (item.key === closeKey) {
            return {
              ...item,
              open: false,
              // @todo Убрать везде payload, если не будет использоваться
              // payload: (action.options && action.options.payload) || item.payload,
            };
          }

          return item;
        }),
      };

    case ActionTypes.CHANGE_APP_RESERVE:
      return {
        ...state,
        items: state.items.map((item: Reserve) => {
          if (item.open) {
            return {
              ...item,
              payload: {
                ...(item.payload || {}),
                [action.name]: action.value,
              },
              changed: true,
            };
          }

          return item;
        }),
      };

    case ActionTypes.OUTER_CHANGE_APP_RESERVE:
      return {
        ...state,
        items: state.items.map((r: Reserve) => {
          if (r.payload && r.payload.id === action.reserveId) {
            return {
              ...r,
              outerChangeType: action.outerChangeType,
            };
          }

          return r;
        }),
      };

    default:
      return state;
  }
}
