import React, { PureComponent } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Bar } from 'react-chartjs-2';
import _ from 'lodash';
import { primaryColor } from '../../../theme';

const styles = {
  container: {
    height: 260,
    padding: 24,
  },
};

const options = {
  scales: {
    xAxes: [{
      gridLines: {
        drawOnChartArea: false,
        lineWidth: 0,
      },
      ticks: {
        fontSize: 11,
      },
    }],
    yAxes: [{
      gridLines: {
        drawOnChartArea: false,
        lineWidth: 0,
      },
      ticks: {
        beginAtZero: true,
        callback: (value) => {
          if (Math.floor(value) === value) {
            return value;
          }
        },
      },
    }],
  },
  legend: false,
  display: false,
  maintainAspectRatio: false,
};

class Chart extends PureComponent {
  render() {
    const { classes } = this.props;

    const data = {
      labels: _.get(this.props.data, 'labels'),
      datasets: [
        {
          fill: false,
          backgroundColor: primaryColor,
          borderColor: primaryColor,
          pointBorderColor: 'rgba(75,192,192,1)',
          pointBackgroundColor: '#fff',
          pointHoverBackgroundColor: 'rgba(75,192,192,1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          barThickness: 10,
          data: _.get(this.props.data, 'total'),
        },
      ],
    };

    return (
      <div className={classes.container}>
        <Bar
          data={data}
          options={options}
        />
      </div>
    );
  }
}

export default withStyles(styles)(Chart);
