import React from 'react';

import {
  // Theme,
  makeStyles,
  Button,
  Box,
} from '@material-ui/core';

import { Add, ArrowBack } from '@material-ui/icons';
import Reserve, { TAppReserves } from '../../types/IAppReservesStore';
import NavItem from './Nav/Item';
import { useWindowSize } from '../../helpers/callHelpers';

const tabWidth = 206;
const backButtonWidth = 72;
const addButtonWidth = 64;
const serviceButtonsWidth = backButtonWidth + addButtonWidth;

// const useStyles = makeStyles((theme: Theme) => ({
const useStyles = makeStyles(() => ({
  nav: {
    display: 'flex',
    height: 55,
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    backgroundColor: '#ffffff',
    zIndex: 20,

    // [theme.breakpoints.down('xs')]: {
    //   alignItems: 'flex-start',
    //   flexDirection: 'column',
    //   height: '100%',
    //   paddingBottom: 24,
    // },
  },
  actionButton: {
    boxShadow: 'none',
    borderRadius: 0,
  },
  buttonBorder: {
    borderRight: '1px solid #e7ebee',
  },
  backButton: {
    width: backButtonWidth,
  },
  reserveButton: {
    width: tabWidth,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  reserveButtonLong: {
    justifyContent: 'flex-start',
  },
  reserveWarning: {
    backgroundColor: 'rgba(244, 88, 88, 0.2)',
  },
  reserveWarningLabel: {
    color: 'rgba(50, 50, 50, 0.5)',
  },
  addButton: {
    '& svg': {
      fontSize: 28,
    },
  },
}));

interface IProps {
  reserves: TAppReserves;
  onBack: () => void;
  onAddReserve: () => void;
  onClickReserve: (reserve: Reserve) => void;
}


const Nav: React.FunctionComponent<IProps> = ({
  reserves,
  onBack,
  onAddReserve,
  onClickReserve,
}) => {
  const {
    nav,
    actionButton,
    backButton,
    buttonBorder,
    addButton,
  } = useStyles();

  const size = useWindowSize();

  let maxWidth: number | undefined;
  let itemWidth: number | undefined;

  if (size.width) {
    // Ширина кнопки назад и кнопки добавить
    maxWidth = size.width - serviceButtonsWidth;

    // Если открыта боковая панель слева
    if (size.width > 1280) {
      maxWidth -= 270;
    }

    itemWidth = Math.min(maxWidth / reserves.length, tabWidth);
  }

  return (
    <Box clone display="flex" flexDirection="row">
      <nav className={nav}>
        <Box clone>
          <Button
            className={`${actionButton} ${backButton} ${buttonBorder}`}
            onClick={onBack}
          >
            <ArrowBack />
          </Button>
        </Box>

        <Box
          display="flex"
          flexWrap="nowrap"
          style={{ maxWidth: maxWidth || `calc(100vw - ${serviceButtonsWidth}px)` }}
        >
          {reserves.map(
            (reserve: Reserve) => (
              <NavItem
                key={reserve.key}
                reserve={reserve}
                onClick={() => onClickReserve(reserve)}
                open={reserve.open}
                width={itemWidth}
              />
            ),
          )}
        </Box>

        <Box clone>
          <Button
            variant="contained"
            color="primary"
            className={`${actionButton} ${addButton}`}
            onClick={() => onAddReserve()}
          >
            <Add />
          </Button>
        </Box>
      </nav>
    </Box>
  );
};

export default Nav;
