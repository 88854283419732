import React from 'react';

import { Button, makeStyles } from '@material-ui/core';

import { IButtonProps } from '../IProps';


interface IContextButtonProps extends IButtonProps {
  isStrong?: boolean;
}


const useStyles = makeStyles(() => ({
  contextButton: {
    textTransform: 'uppercase',
    '& .MuiSvgIcon-root': {
      fontSize: 27,
    },
  },

  passive: {
    '& .MuiButton-label': {
      opacity: 0.7,
    },
  },
}));


const ContextButtonComponent: React.FC<IContextButtonProps> = ({
  onClick,
  startIcon,
  title,
  isStrong,
  ...other
}) => {
  const classes = useStyles();
  let className = classes.contextButton;
  if (!isStrong) {
    className += ` ${classes.passive}`;
  }

  return (
    <Button
      startIcon={startIcon || null}
      onClick={onClick}
      className={className}
      {...other}
    >
      {title}
    </Button>
  );
};

export default ContextButtonComponent;
