import React from 'react';
import { Typography, useTheme } from '@material-ui/core';
import { NoAuthPage } from '../../../shared';
import locales from '../../../locales';

export function ActivationError() {
  const theme = useTheme();

  const messages: Array<string> = [];
  ['message1', 'message2'].forEach((paramName: string) => {
    const param = `activation.errorPage.${paramName}`;
    const message = locales.t(param);
    if (message && message !== param) {
      messages.push(message);
    }
  });

  return (
    <NoAuthPage
      logoSubTitle={locales.t('activation.errorPage.logoSubTitle')}
      contentTitle={locales.t('activation.errorPage.contentTitle')}
      note={locales.t('activation.errorPage.description')}
      error
    >
      {messages.map((message: string, index: number) => (
        <Typography
          key={message}
          align="center"
          dangerouslySetInnerHTML={{ __html: message }}
          style={index > 0 ? { marginTop: theme.spacing(3) } : {}}
        />
      ))}
    </NoAuthPage>
  );
}
