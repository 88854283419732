import * as ActionTypes from '../actions/photo/PhotoActionsTypes';
import { IAppActions } from '../types/IAppActions';
import { IPhotoStore } from '../types/IPhotoStore';

export const initState: IPhotoStore = {
  images: [],
};

export default function reducer(state: IPhotoStore = initState, action: IAppActions): IPhotoStore {
  switch (action.type) {
    case ActionTypes.GET_IMAGES_SUCCESS:
      return {
        ...state,
        images: action.payload.imagesList,
      };

    case ActionTypes.SHOW_IMAGE_SUCCESS:
      return {
        ...state,
        images: state.images.map((img) => {
          if (img.id === action.payload.imageId) {
            return {
              ...img,
              isHidden: false,
            };
          }

          return img;
        }),
      };

    case ActionTypes.HIDE_IMAGE_SUCCESS:
      return {
        ...state,
        images: state.images.map((img) => {
          if (img.id === action.payload.imageId) {
            return {
              ...img,
              isHidden: true,
            };
          }

          return img;
        }),
      };

    case ActionTypes.SET_COVER_SUCCESS: {
      return {
        ...state,
        images: state.images.map((img) => {
          if (img.id === action.payload.newMainId) {
            return {
              ...img,
              isMain: true,
              isHidden: false,
            };
          }
          return {
            ...img,
            isMain: false,
          };
        }),
      };
    }

    case ActionTypes.HIDE_MAIN_IMAGE_SUCCESS: {
      return {
        ...state,
        images: state.images.map((img) => {
          if (img.id === action.payload.newMainId) {
            return {
              ...img,
              isMain: true,
              isHidden: false,
            };
          }
          return {
            ...img,
            isMain: false,
            isHidden: img.id === action.payload.hideImgId ? true : img.isHidden,
          };
        }),
      };
    }

    default:
      return state;
  }
}
