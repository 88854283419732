import React from 'react';
import { Ellipse } from 'react-konva';

import { TSectionElementScheme } from '../../../../types/IRestaurantTablesSchemaStore';
import { getColor } from '../../../../helpers/scheme/schemeHelpers';
import rotateAroundCenter from './rotateHelper';

interface IProps {
  data: TSectionElementScheme;
  handleSelect?: (data: TSectionElementScheme) => void;
}

function EllipseElements(props: IProps) {
  const {
    handleSelect, data,
  } = props;
  const {
    x, y, angle, width, height, color,
  } = data;

  const shapeRef = React.useRef(null);
  React.useEffect(() => {
    rotateAroundCenter(shapeRef.current, angle);
  }, [shapeRef, angle]);

  const onClick = () => {
    if (typeof handleSelect === 'function') handleSelect(data);
  };

  return (
    <Ellipse
      ref={shapeRef}
      x={x + width / 2}
      y={y + height / 2}
      radiusX={0}
      radiusY={0}
      height={height}
      width={width}
      fill={getColor(color)}
      onClick={onClick}
    />
  );
}

export default EllipseElements;
