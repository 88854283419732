import {
  all, put, takeLatest,
} from 'redux-saga/effects';

import * as Api from '../api';
import * as ActionTypes from '../actions/restorePassword/RestorePasswordActionsTypes';
import * as IActionTypes from '../actions/restorePassword/IRestorePasswordActions';
import * as AuthActions from '../actions/restorePassword/RestorePasswordActions';
import locales from '../locales';


/** @description Сага для восстановления пароля */
function* restorePassword(action: IActionTypes.IRestorePassword) {
  try {
    yield Api.restorePasswordReq(action.payload.email);
    yield put(AuthActions.restorePasswordSuccess());
  } catch (e) {
    const errorText = locales.t('common.errors.restorePassword.unknownError');
    yield put(AuthActions.restorePasswordError(errorText));
  }
}

/** @description Сага для отправки нового пароля */
function* sendNewPassword(action: IActionTypes.ISendNewPassword) {
  try {
    yield Api.sendNewPasswordReq(action.payload.key, action.payload.newPassword);
    yield put(AuthActions.sendNewPasswordSuccess());
  } catch (e) {
    let errorText = locales.t('common.errors.sendNewPassword.unknownError');

    if (e.response.status === 409) {
      errorText = locales.t('common.errors.sendNewPassword.wrongEmail');
    }

    yield put(AuthActions.sendNewPasswordError(errorText));
  }
}

export default function* saga() {
  yield all([
    takeLatest(ActionTypes.RESTORE_PASSWORD_REQUEST, restorePassword),
    takeLatest(ActionTypes.SEND_NEW_PASSWORD_REQUEST, sendNewPassword),
  ]);
}
