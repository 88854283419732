import React from 'react';
import { WrappedFieldProps } from 'redux-form';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import locales from '../../locales';
import { errorColor } from '../../theme';
import { IPropsSelectOption } from '../../reducers/selectors';

interface IProps extends WrappedFieldProps {
  disabled: boolean;
  options: Array<IPropsSelectOption>;
  allOptionDisabled: boolean;
}

/** @description Селектор для выбора зала */
const ReserveSectionSelect: React.FC<IProps> = ({
  input,
  meta: { touched, error },
  disabled,
  options,
  allOptionDisabled,
}) => {
  const isError = touched && error;
  const colorError = isError ? { color: errorColor } : {};

  return (
    <FormControl fullWidth error={isError} disabled={disabled}>
      <InputLabel htmlFor="section">{ locales.t('common.reserveModal.sections') }</InputLabel>
      <Select {...input} style={colorError}>
        <MenuItem
          alignItems="center"
          value={0}
          disabled={allOptionDisabled}
        >
          { locales.t('common.reserveModal.allSections') }
        </MenuItem>

        {
          options.map((t, i) => (
            <MenuItem
              alignItems="center"
              key={t.value + i.toString()}
              value={t.value}
            >
              {t.label}
            </MenuItem>
          ))
        }
      </Select>
    </FormControl>
  );
};

export default ReserveSectionSelect;
