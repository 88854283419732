import React, { Fragment } from 'react';
import wrapWithPath from './wrapSvgPath';

const arrowBottomPath = (
  <Fragment>
    <g id="left-arrow" className="cls-1" transform="translate(-2 -2.441) rotate(-90)">
      <path
        id="Path_464"
        data-name="Path 464"
        d="M11.77,23.782a.782.782,0,0,0,1.1-1.1C9.192,19.117,5.571,15.557,1.888,12c3.683-3.56,7.3-7.121,10.988-10.681.737-.675-.368-1.78-1.1-1.1C7.9,3.955,4.036,7.7.23,11.444a.778.778,0,0,0,0,1.1C4.036,16.293,7.9,20.038,11.77,23.782Z"
        transform="translate(-15.56 2)"
      />
    </g>
  </Fragment>
);

export default wrapWithPath(arrowBottomPath, '0 0 24 13.118');
