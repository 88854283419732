import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';

import AuthIikoModalComp from './AuthIikoModal';
import { authFromIiko, selectOrganization } from '../../actions/home/HomeActions';
import { switchAuthIikoModal } from '../../actions/cabinet/CabinetActions';
import { IAppStore } from '../../types/IAppStore';
import { IAppActions } from '../../types/IAppActions';
import { StateProps, DispatchProps } from './IAuthIikoModal';

const mapStateToProps = (state: IAppStore): StateProps => {
  const currentRestaurantId = state.cabinet.currentRestaurant ? state.cabinet.currentRestaurant.id : 0;

  return {
    modalOpen: state.cabinet.modalAuthIikoOpen,
    iikoAuth: state.cabinet.iikoAuth,
    currentRestaurantId,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<IAppActions>): DispatchProps => {
  return bindActionCreators({
    switchAuthIikoModal,
    authFromIiko,
    selectOrganization,
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthIikoModalComp);
