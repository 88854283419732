import React from 'react';

import Delete from '@material-ui/icons/Delete';

import {
  withStyles, createStyles, WithStyles,
} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import locales from '../../../locales';
import { errorColor } from '../../../theme';

const styles = () => createStyles({
  paperStyle: {
    height: 224,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  contentWrap: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '0 24px',
    textAlign: 'center',
  },
  icon: {
    width: 64,
    height: 64,
    boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.24)',
    backgroundColor: '#f8dfe0',
    borderRadius: '50%',
    position: 'absolute',
    top: '-80px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  // TODO Оформить по людски
  button: {
    marginTop: 24,
    color: errorColor,
    border: '1px solid rgb(220, 0, 78)',
    '&:hover': {
      border: '1px solid #C42126',
    },
    '&:focus': {
      border: '1px solid #C42126',
    },
  },
});

interface IProps extends WithStyles<typeof styles> {
  handleDeleteRestaurant: () => void;
}

const DeleteRestaurantMessages: React.FC<IProps> = ({ classes, handleDeleteRestaurant }) => (
  <Paper
    className={classes.paperStyle}
    elevation={3}
  >
    <div className={classes.contentWrap}>
      <div className={classes.icon}>
        <Delete fontSize="large" color="error" />
      </div>
      <Typography variant="h6">
        { locales.t('settings.deleteRestaurant.title') }
      </Typography>
      <Typography variant="body2">
        { locales.t('settings.deleteRestaurant.desc') }
      </Typography>
      <Button
        className={classes.button}
        variant="outlined"
        color="secondary"
        onClick={handleDeleteRestaurant}
      >
        { locales.t('settings.deleteRestaurant.deleteBtn') }
      </Button>
    </div>
  </Paper>
);

export default withStyles(styles)(DeleteRestaurantMessages);
