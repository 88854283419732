export const CREATE_WIDGET_REQUEST = 'CREATE_WIDGET_REQUEST';
export const CREATE_WIDGET_SUCCESS = 'CREATE_WIDGET_SUCCESS';
export const CREATE_WIDGET_ERROR = 'CREATE_WIDGET_ERROR';

export const UPDATE_WIDGET_REQUEST = 'UPDATE_WIDGET_REQUEST';
export const UPDATE_WIDGET_SUCCESS = 'UPDATE_WIDGET_SUCCESS';
export const UPDATE_WIDGET_ERROR = 'UPDATE_WIDGET_ERROR';

export const DELETE_WIDGET_REQUEST = 'DELETE_WIDGET_REQUEST';
export const DELETE_WIDGET_SUCCESS = 'DELETE_WIDGET_SUCCESS';
export const DELETE_WIDGET_ERROR = 'DELETE_WIDGET_ERROR';

export const GET_ALL_WIDGETS_REQUEST = 'GET_ALL_WIDGETS_REQUEST';
export const GET_ALL_WIDGETS_SUCCESS = 'GET_ALL_WIDGETS_SUCCESS';
export const GET_ALL_WIDGETS_ERROR = 'GET_ALL_WIDGETS_ERROR';
