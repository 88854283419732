import {
  SWITCH_RESERVE_SCHEDULE_TYPE_SUCCESS,
  SET_SECTION_ID,
} from '../actions/restaurantsSettings/RestaurantsSettingsActionsTypes';
import { GET_SCHEDULE_LIST_SUCCESS } from '../actions/schedules/SchedulesActionTypes';
import { GET_SECTION_REQUEST_SUCCESS } from '../actions/restaurant/RestaurantsActionTypes';
import { IAppActions } from '../types/IAppActions';
import { IRestaurantSettingsStore } from '../types/IRestaurantSettingsStore';

const initState: IRestaurantSettingsStore = {
  isActiveRestaurantSchedule: undefined,
  sectionId: undefined,
};

export default function reducer(
  state: IRestaurantSettingsStore = initState, action: IAppActions,
): IRestaurantSettingsStore {
  switch (action.type) {
    case SWITCH_RESERVE_SCHEDULE_TYPE_SUCCESS: {
      return {
        ...state,
        isActiveRestaurantSchedule: action.payload.isActiveRestaurantSchedule,
      };
    }

    case GET_SCHEDULE_LIST_SUCCESS: {
      return {
        ...state,
        isActiveRestaurantSchedule: action.payload.bookingSchedules.length === 0,
      };
    }

    case SET_SECTION_ID: {
      return {
        ...state,
        sectionId: action.payload.sectionId,
      };
    }

    case GET_SECTION_REQUEST_SUCCESS: {
      const { sections } = action.payload;
      return {
        ...state,
        sectionId: sections.length ? sections[0].id : undefined,
      };
    }

    default:
      return state;
  }
}
