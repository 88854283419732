import React from 'react';
import Paper from '@material-ui/core/Paper';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import TablePagination from '@material-ui/core/TablePagination/TablePagination';

import SourceStatisticReserveTableHead from './SourceStatisticReserveTableHead';
import SourceStatisticsReserveTableItem from './SourceStatisticsReserveTableItem';
import { TReserve } from '../../../types/IBookingStore';
import { IBookingOrders, TPages } from '../../../types/IStatisticStore';
import Title from '../../Common/Title';
import locales from '../../../locales';

type Props = {
  bookingOrders: IBookingOrders;
  reserves: Array<TReserve>;
  handleGetReserves: (page: number, size: number, sortType: 'desc' | 'asc') => void;
  handleOpenBookingDetails: (bookingId: number) => void;
  pages: TPages;
  moneySymbol: string;
};

const index: React.FC<Props> = ({
  bookingOrders,
  reserves,
  handleGetReserves,
  handleOpenBookingDetails,
  pages,
  moneySymbol,
}) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [sortType, changeSortType] = React.useState<"desc" | "asc">('desc');

  /** @description Ручка для переключение страниц в таблице */
  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    handleGetReserves(newPage, rowsPerPage, sortType);
  };

  /** @description Ручка для переключение количества строк в таблице */
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const nRowsPerPage = parseInt(event.target.value);
    setRowsPerPage(nRowsPerPage);
    setPage(0);
    handleGetReserves(0, nRowsPerPage, sortType);
  };

  /** @description Ручка для изменения типа сортировки */
  const handleChangeSortType = () => {
    if (sortType === 'desc') {
      changeSortType('asc');
      handleGetReserves(page, rowsPerPage, 'asc');
    } else {
      changeSortType('desc');
      handleGetReserves(page, rowsPerPage, 'desc');
    }
  };

  return (
    <Paper elevation={3}>
      <Title
        padding="8px 12px 8px 24px"
        text={locales.t('statistics.reserves.title')}
        borderBottom
        spaceBetween
      />
      <Table>
        <SourceStatisticReserveTableHead
          onRequestSort={handleChangeSortType}
          order={sortType}
          orderBy="date"
        />
        <TableBody>
          {
            reserves.map(reserve => (
              <SourceStatisticsReserveTableItem
                bookingOrders={bookingOrders}
                key={reserve.id}
                reserveInfo={reserve}
                handleOpenBookingDetails={handleOpenBookingDetails}
                moneySymbol={moneySymbol}
              />
            ))
          }
        </TableBody>
      </Table>
      <TablePagination
        labelRowsPerPage="Строк на странице:"
        style={{ paddingRight: 8 }}
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={pages.totalElements}
        rowsPerPage={pages.size || rowsPerPage}
        page={page}
        backIconButtonProps={{
          'aria-label': 'previous page',
        }}
        nextIconButtonProps={{
          'aria-label': 'next page',
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default index;
