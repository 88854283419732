import React, { useState } from 'react';
import { Edit, Delete, Star } from '@material-ui/icons';

import {
  TableRow,
  TableCell,
  IconButton,
  makeStyles,
  Theme,
} from '@material-ui/core';

import locales from '../../../locales';
import { primaryColor } from '../../../theme';
import DeleteDialog from '../Dialog/Delete';
import { Employees } from '../../../entities';


const useStyles = makeStyles((theme: Theme) => ({
  row: {
    '& td': {
      verticalAlign: 'top',
      padding: theme.spacing(3),
      // height: 190,
    },
  },

  name: {
    fontSize: 16,
    textDecoration: 'underline',
  },

  inner: {
    margin: 0,
    marginTop: theme.spacing(2),

    '& dt': {
      fontSize: 12,
      color: '#aaaaaa',
    },

    '& dd': {
      margin: 0,
      fontSize: 14,
      color: '#323232',
    },
  },

  contacts: {
    listStyleType: 'none',
    margin: 0,
    padding: 0,

    '& li': {
      marginBottom: theme.spacing(1),
      fontSize: 14,
      color: '#323232',
    },

    '& li:last': {
      marginBottom: 0,
    },
  },

  roles: {
    listStyleType: 'none',
    margin: 0,
    padding: 0,

    '& li': {
      marginBottom: 0,
      fontSize: 14,
    },
  },

  active: {
    textTransform: 'uppercase',
    color: primaryColor,
  },

  disabled: {
    textTransform: 'uppercase',
  },

  invite: {
    fontSize: 12,
    color: primaryColor,
    textDecoration: 'underline',
    marginTop: theme.spacing(3),
    cursor: 'pointer',
  },

  actions: {
    marginTop: -theme.spacing(1),
    marginLeft: -theme.spacing(2),
  },

  currentStar: {
    color: primaryColor,
    fontSize: 14,
    marginLeft: 3,
  },
}));


const ItemComponent: React.FC<{
  employee: GM.Employees.Employee;
  onClick: (id: number) => void;
  onEdit: (id: number) => void;
  onDelete?: (id: number) => void;
  onInvite: (id: number) => void;
  isCurrentEmployee: boolean;
  canBeDeleted: boolean;
}> = ({
  employee,
  onClick,
  onEdit,
  onDelete,
  onInvite,
  isCurrentEmployee,
  canBeDeleted,
}) => {
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isUnableToDeleteAdminAlert, setUnableToDeleteAdminAlert] = useState(false);

  const {
    id,
    email,
    phone,
    // iikoEmployeeId,
    authorities,
    status,
    createdDate,
  } = employee;

  const classes = useStyles();

  return (
    <TableRow className={classes.row}>
      <TableCell>
        <a
          href={`/employees/${id}`}
          onClick={(e) => {
            e.preventDefault();
            onClick(id);
          }}
          className={classes.name}
        >
          {Employees.getName(employee)}
        </a>

        {isCurrentEmployee && <sup><Star className={classes.currentStar}/></sup>}

        {/*
        <dl className={classes.inner}>
          <dt>Пользователь iiko:</dt>
          <dd>{iikoEmployeeId || 'Не назначено'}</dd>
        </dl>
        */}
      </TableCell>
      <TableCell>
        {(email || phone) && (<ul className={classes.contacts}>
          {email && <li>{email}</li>}
          {phone && <li>{phone}</li>}
        </ul>)}
      </TableCell>
      <TableCell>
        {authorities && (
          <ul className={classes.roles}>
            {authorities.map(authority => (
              <li key={authority}>
                {locales.t(`employees.properties.authorities.${authority}.title`)}
              </li>
            ))}
          </ul>
        )}
      </TableCell>
      <TableCell>
        {(status === 'ACTIVE' && (
          <div className={classes.active}>
            {locales.t('employees.status_active')}
          </div>
        )) || (
          <>
            <div className={classes.disabled}>
              {locales.t('employees.status_disabled')}
            </div>
            <div
              className={classes.invite}
              onClick={() => onInvite(id)}
              // onKeyPress={() => onInvite()}
              tabIndex={0}
              role="button"
            >
              Выслать приглашение
              {createdDate && ' повторно'}
            </div>
          </>
        )}
      </TableCell>
      <TableCell>
        <div className={classes.actions}>
          <IconButton onClick={() => onEdit(id)}>
            <Edit fontSize="small" />
          </IconButton>

          {!canBeDeleted && (
            <>
              <IconButton onClick={() => setUnableToDeleteAdminAlert(true)}>
                <Delete fontSize="small" color="disabled" />
              </IconButton>

              {isUnableToDeleteAdminAlert && (
                <Employees.UnableToDeleteAlert onClose={() => setUnableToDeleteAdminAlert(false)} />
              )}
            </>
          )}

          {canBeDeleted && !isCurrentEmployee && onDelete && (
            <>
              <IconButton onClick={() => setDeleteDialogOpen(true)}>
                <Delete fontSize="small" />
              </IconButton>

              {isDeleteDialogOpen && (
                <DeleteDialog
                  onClose={() => setDeleteDialogOpen(false)}
                  onDelete={() => onDelete(id)}
                />
              )}
            </>
          )}
        </div>
      </TableCell>
    </TableRow>
  );
};

export default ItemComponent;
