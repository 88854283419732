import * as ActionTypes from '../actions/schedules/SchedulesActionTypes';
import { IAppActions } from '../types/IAppActions';
import {
  IScheduleGroup,
  IScheduleStore,
  TScheduleFieldType,
  TWeekDay,
} from '../types/IScheduleStore';
import { getMergedSchedule } from '../helpers/scheduleHelpers';

const defaultFreeDays: { [day: string]: boolean } = {
  MONDAY: true,
  TUESDAY: true,
  WEDNESDAY: true,
  THURSDAY: true,
  FRIDAY: true,
  SATURDAY: true,
  SUNDAY: true,
};

export const initState: IScheduleStore = {
  bookingRows: [],
  bookingSchedules: [],
  bookingFreeDays: defaultFreeDays,
  restaurantRows: [],
  restaurantSchedules: [],
  restaurantFreeDays: defaultFreeDays,
  restaurantWorkTimes: {},
};

/** @description Получая название поля */
const getFieldName = (field: TScheduleFieldType): {
  rows: 'bookingRows' | 'restaurantRows';
  schedules: 'restaurantSchedules' | 'bookingSchedules';
  freeDays: 'restaurantFreeDays' | 'bookingFreeDays'
} => ({
  // @ts-ignore
  rows: `${field}Rows`,
  // @ts-ignore
  schedules: `${field}Schedules`,
  // @ts-ignore
  freeDays: `${field}FreeDays`,
});

/** @description Редактирование списка свободных дней */
const changeFreeDays = (
  initDays: { [day: string]: boolean },
  activate: Array<IScheduleGroup>,
) => {
  const days = { ...initDays };

  activate.forEach(d => days[d.day] = false);

  return days;
};

/** @description Редактирование списка свободных дней */
const changeFreeDaysForUpdatesDays = (
  initDays: { [day: string]: boolean },
  activate: Array<TWeekDay>,
  deactivate?: Array<TWeekDay>,
) => {
  const days = { ...initDays };

  activate.forEach(d => days[d] = false);

  if (deactivate) {
    deactivate.forEach(d => days[d] = true);
  }

  return days;
};

export default function reducer(state = initState, action: IAppActions): IScheduleStore {
  switch (action.type) {
    case ActionTypes.GET_SCHEDULE_LIST_SUCCESS: {
      const { bookingSchedules, restaurantSchedules } = action.payload;

      return {
        ...state,
        ...action.payload,
        restaurantFreeDays: changeFreeDays(defaultFreeDays, restaurantSchedules),
        bookingFreeDays: changeFreeDays(defaultFreeDays, bookingSchedules),
        restaurantWorkTimes: getMergedSchedule(restaurantSchedules),
      };
    }

    case ActionTypes.UPDATE_SCHEDULE_SUCCESS: {
      const { row, field, schedules } = action.payload;
      const fields = getFieldName(field);

      if (field === 'restaurant') {
        return {
          ...state,
          [fields.rows]: state[fields.rows].map(r => r.id === row.id ? row : r),
          [fields.schedules]: schedules,
          [fields.freeDays]: changeFreeDays(defaultFreeDays, schedules),
          restaurantWorkTimes: getMergedSchedule(schedules),
        };
      }

      return {
        ...state,
        [fields.rows]: state[fields.rows].map(r => r.id === row.id ? row : r),
        [fields.schedules]: schedules,
        [fields.freeDays]: changeFreeDays(defaultFreeDays, schedules),
      };
    }

    case ActionTypes.ADD_SCHEDULE_ROW: {
      const { rows } = getFieldName(action.payload.field);
      const newValue = state[rows].concat([{
        id: Math.floor(Math.random() * 10000000),
        start: '',
        end: '',
        days: [],
      }]);

      return {
        ...state,
        [rows]: newValue,
      };
    }

    case ActionTypes.DELETE_SCHEDULE_ROW_SUCCESS: {
      const { field, row } = action.payload;
      const { rows, schedules, freeDays } = getFieldName(field);

      const newRows = state[rows].filter(r => r.id !== row.id);
      const newSchedules = state[schedules]
        .filter(s => row.days.indexOf(s.day) === -1);

      return {
        ...state,
        [rows]: newRows,
        [schedules]: newSchedules,
        [freeDays]: changeFreeDays(state[freeDays], newSchedules),
      };
    }

    case ActionTypes.UPDATE_FREE_DAYS: {
      const { days, field } = action.payload;
      const { freeDays } = getFieldName(field);

      const newDays = {
        ...state[freeDays],
      };

      return {
        ...state,
        [freeDays]: changeFreeDaysForUpdatesDays(newDays, days.activate, days.deactivate),
      };
    }

    case ActionTypes.UPDATE_SCHEDULE_ERROR: {
      const { rowId, field } = action.payload;

      if (rowId && field) {
        const { rows } = getFieldName(field);

        return {
          ...state,
          [rows]: state[rows].map(r => r.id === rowId ? { ...r, error: true } : r),
        };
      }

      return state;
    }

    default:
      return state;
  }
}
