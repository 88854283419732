import {
  put, all, takeLatest, select,
} from 'redux-saga/effects';

import * as ActionTypes from '../actions/restaurantsSettings/RestaurantsSettingsActionsTypes';
import { ISwitchReserveSchedule } from '../actions/restaurantsSettings/IRestaurantsSettingsActions';
import {
  switchReserveScheduleSuccess,
  switchReserveScheduleError,
} from '../actions/restaurantsSettings/RestaurantsSettingsActions';
import { IScheduleGroup } from '../types/IScheduleStore';
import { createScheduleGroupReq, deleteScheduleGroupReq } from '../api';
import { localStorageItems } from '../config/constants';
import { getScheduleList } from '../actions/schedules/SchedulesAction';

/**
 * @description Переключение приёма резервов с дефолтного на кастомное расписание
 * - При переключении с кастомного на дефолтное сохраняем в локалсторадж текущее расписание
 * - При переключение с дефолтного на кастомное проверяем есть ли в локалсторадже
 * ранее созданные расписания и создаем их, после этого чистим локал сторадж.
 */
function* updateBookingScheduleType(action: ISwitchReserveSchedule) {
  const { isActiveRestaurantSchedule } = action.payload;

  if (isActiveRestaurantSchedule) {
    const scheduleList: Array<IScheduleGroup> = yield select(state => state.schedule.bookingSchedules);

    if (scheduleList.length > 0) {
      // @ts-ignore
      yield deleteScheduleGroupReq(scheduleList.map(d => d.id));
      const prepareForSave = scheduleList.map(i => ({
        day: i.day,
        restaurantId: i.restaurantId,
        type: i.type,
        records: i.records.map(r => ({
          start: r.start,
          end: r.end,
          isWorkingTime: r.isWorkingTime,
          day: r.day,
          type: r.type,
        })),
      }));

      localStorage.setItem(localStorageItems.autoBookingSchedule, JSON.stringify(prepareForSave));
    }

    yield put(switchReserveScheduleSuccess(isActiveRestaurantSchedule));
  } else {
    try {
      const savedSchedule = yield localStorage.getItem(localStorageItems.autoBookingSchedule);
      const parsedSchedule: Array<IScheduleGroup> = savedSchedule ? yield JSON.parse(savedSchedule) : null;

      if (parsedSchedule && parsedSchedule.length > 0) {
        yield createScheduleGroupReq(parsedSchedule);
        yield put(getScheduleList());
      }

      localStorage.setItem(localStorageItems.autoBookingSchedule, '');

      yield put(switchReserveScheduleSuccess(isActiveRestaurantSchedule));
    } catch (e) {
      yield put(switchReserveScheduleError());
    }
  }
}

export default function* saga() {
  yield all([
    takeLatest(ActionTypes.SWITCH_RESERVE_SCHEDULE_TYPE_REQUEST, updateBookingScheduleType),
  ]);
}
