import React, { useState, useEffect, ChangeEvent } from 'react';

import {
  Grid,
  makeStyles,
  TextField,
  Switch,
  Paper,
  Typography,
} from '@material-ui/core';

import locales from '../../../locales';
import { TRestaurantSettings } from '../../../types/ICabinetStore';
import { TNewInfo } from '../../../types/IRestaurantInfoStore';
import { cardFieldType } from '../../../config/constants';
import Title from '../../Common/Title';

interface IProps {
  settings: TRestaurantSettings;
  onUpdate: (data: TNewInfo) => void;
}

const useStyles = makeStyles((theme) => {
  const spacing = theme.spacing(3);

  return {
    paperContent: {
      padding: `0 ${spacing}px ${spacing}px ${spacing}px`,
    },
    blockLabelContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    blockLabel: {
      flexGrow: 1,
      marginRight: spacing,
    },
    description: {
      margin: 0,
      fontSize: 12,
      '& li': {
        paddingBottom: theme.spacing(1),
      },
    },
    tgLabel: {
      display: 'block',
      marginBottom: theme.spacing(2),
    },
    counter: {
      textAlign: 'right',
    },
  };
});

const ReservesSystemAdditionalNotificationsComponent: React.FC<IProps> = ({
  settings,
  onUpdate,
}) => {
  const { isEnabled, usernames } = settings.telegramNotificationSettings;
  const classes = useStyles();
  const tgRules = locales.t('settings.additionalNotifications.tg.rules').split('\n');
  // const inputRef = React.createRef<HTMLInputElement>();

  const [inputValue, setInputValue] = useState<string>('');
  const [accounts, setAccounts] = useState<Array<string>>([]);
  const [accountCounter, setAccountCounter] = useState<number>(0);
  const accountsLimit = 50;

  const toggleTg = () => {
    onUpdate({
      type: cardFieldType.isTelegramNotificationEnabled,
      value: !isEnabled,
    });
  };

  // useEffect(() => {
  //   if (inputRef.current) {
  //     inputRef.current.focus();
  //   }
  // }, [isEnabled]);

  const normalizeAccounts = (value_: string|Array<string>): Array<string> => {
    const value: Array<string> = (typeof value_ === 'string')
                               ? value_.split('\n')
                               : value_;
    return value
      .map(x => x.trim())
      .filter(x => x)
      .map(x => x.startsWith('@') ? x : `@${x}`);
  };

  useEffect(() => {
    const accounts = changeAccounts(usernames);
    setInputValue(accounts.join('\n'));
  }, [usernames]);

  const changeAccounts = (value: undefined | string | Array<string>): Array<string> => {
    const accounts = (value && normalizeAccounts(value)) || [];
    setAccounts(accounts);
    setAccountCounter(accounts.length);

    return accounts;
  };

  const changeInputValue = (event: ChangeEvent<HTMLInputElement|HTMLTextAreaElement>) => {
    const { value } = event.target;
    setInputValue(value);
    changeAccounts(value);
  };

  const submitAccounts = () => {
    const usernames_ = (usernames && usernames.join('\n')) || '';
    const accounts_ = accounts
      .map(x => x.replace(/^@/, '').trim())
      .filter(x => x);

    if (usernames_ === accounts_.join('\n')) {
      return;
    }

    if (accounts_.length > accountsLimit) {
      return;
    }

    onUpdate({
      type: cardFieldType.telegramNotificationUsernames,
      value: accounts_,
    });
  };

  return (
    <Paper elevation={3}>
      <Title
        text={locales.t('settings.additionalNotifications.title')}
        subTitle={locales.t('settings.additionalNotifications.desc')}
      />
      <div className={classes.paperContent}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className={classes.blockLabelContainer}>
              <Typography variant="subtitle2" className={classes.blockLabel}>
                Telegram
              </Typography>
              <Switch
                color="primary"
                onChange={toggleTg}
                checked={isEnabled}
              />
            </div>
          </Grid>
          {isEnabled && (<>
            <Grid item xs={12} sm={6}>
              <Typography variant="caption" className={classes.tgLabel}>
                {locales.t('settings.additionalNotifications.tg.title')}
              </Typography>
              <TextField
                // inputRef={inputRef}
                // rows={accountCounter > 7 ? 7 : undefined}
                rows={7}
                multiline
                placeholder={locales.t('settings.additionalNotifications.tg.desc')}
                fullWidth
                helperText={`${accountCounter}/${accountsLimit}`}
                FormHelperTextProps={{
                  className: classes.counter,
                  error: accountCounter > accountsLimit,
                }}
                onChange={changeInputValue}
                onBlur={submitAccounts}
                value={inputValue}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ol className={classes.description}>{tgRules.map((rule: string, i: number) =>
                <li key={i} dangerouslySetInnerHTML={{__html: rule}} />
              )}</ol>
            </Grid>
          </>)}
        </Grid>
      </div>
    </Paper>
  )
};

export default ReservesSystemAdditionalNotificationsComponent;
