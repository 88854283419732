import React, { useState } from 'react';
import _ from 'lodash';

import {
  IFreeDay,
  IScheduleRow,
  TWeekDay,
} from '../../../types/IScheduleStore';
import ScheduleRow from './ScheduleRow';
import { workTimes } from '../../../config/constants';

interface IProps {
  handelUpdateSchedule: (row: IScheduleRow) => void;
  handleDeleteScheduleRow: (row: IScheduleRow) => void;
  handleUpdateFreeDays: (days: {
    activate: Array<TWeekDay>,
    deactivate: Array<TWeekDay>
  }) => void;
  schedule: IScheduleRow;
  freeDays: Array<IFreeDay>;
}

interface IState {
  days: Array<TWeekDay>;
  start: string;
  end: string;
}

const SchedulePeriodRow: React.FC<IProps> = (props) => {
  const {
    schedule,
    handleUpdateFreeDays,
    handelUpdateSchedule,
    handleDeleteScheduleRow,
    freeDays,
  } = props;

  const [state, changeState] = useState<IState>({
    days: schedule.days,
    start: schedule.start,
    end: schedule.end,
  });

  /** @description Отправка экшена на апдейт */
  const onUpdate = () => {
    const initValue = {
      end: schedule.end, start: schedule.start, days: schedule.days,
    };
    const newValue = {
      end: state.end, start: state.start, days: state.days,
    };
    const fieldEmpty = (state.days.length === 0) || !state.start || !state.end;

    if (!_.isEqual(initValue, newValue) && !fieldEmpty) {
      handelUpdateSchedule({
        start: state.start,
        end: state.end,
        id: schedule.id,
        days: state.days,
      });
    }
  };

  /** @description Редактирование полей время и дни недели */
  const onChange = (value: string | Array<string> | unknown, type: 'start' | 'end' | 'days') => {
    if (type === 'days' && Array.isArray(value)) {
      handleUpdateFreeDays({
        activate: value,
        deactivate: state.days.filter(day => value.indexOf(day) < 0),
      });

      changeState({
        ...state,
        [type]: value,
      });
    } else {
      changeState({
        ...state,
        [type]: value,
      });
    }
  };

  /** @description Отпарвка экшена на удаление строки и всех выбранных дней */
  const onDelete = () => {
    const { days } = state;

    handleUpdateFreeDays({
      activate: [], // В случае удаления периода проставлям выбранным дням что они свободны.
      deactivate: days,
    });
    handleDeleteScheduleRow(schedule);
  };

  return (
    <ScheduleRow
      onChange={onChange}
      onDelete={onDelete}
      onUpdate={onUpdate}
      state={state}
      schedule={schedule}
      freeDays={freeDays}
      workTimes={workTimes}
    />
  );
};

export default SchedulePeriodRow;
