import React, { Fragment } from 'react';

import {
  withStyles, WithStyles, createStyles, Typography,
} from '@material-ui/core';
import Button from '@material-ui/core/Button/Button';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';

import locales from '../../../locales';

const styles = () => createStyles({
  topBorder: {
    borderTop: '1px solid rgba(224, 224, 224, 1)',
  },
});

interface IProps extends WithStyles<typeof styles> {
  handleCloseDialog: () => void;
}

const AuthIikoErrorMessages: React.FC<IProps> = ({
  classes,
  handleCloseDialog,
}) => (
  <Fragment>
    <div>
      <DialogContent>
        <Typography color="error">
          { locales.t('common.unknownError') }
        </Typography>
      </DialogContent>
    </div>
    <div className={classes.topBorder}>
      <DialogActions>
        <Button
          color="secondary"
          onClick={handleCloseDialog}
        >
          { locales.t('common.reject') }
        </Button>
      </DialogActions>
    </div>
  </Fragment>
);

export default withStyles(styles)(AuthIikoErrorMessages);
