import { put, takeEvery } from 'redux-saga/effects';

import * as AuthActionsTypes from '../actions/auth/AuthActionsTypes';
import * as MenuActionsTypes from '../actions/menu/MenuActionsTypes';
import * as PromoActionsTypes from '../actions/promo/PromoActionsTypes';
import * as RestaurantTypes from '../actions/restaurant/RestaurantsActionTypes';
import * as RestaurantsInfoTypes from '../actions/restaurantsInfo/RestaurantsInfoActionsTypes';
import * as RestaurantsTablesTypes from '../actions/restaurantsTables/RestaurantsTablesTypes';
import * as ReserveModalActionsTypes from '../actions/reservationsPage/ReservationsPageActionsTypes';
import * as StatisticsActionsTypes from '../actions/statistics/StatisticsActionsTypes';
import * as SchedulesActionTypes from '../actions/schedules/SchedulesActionTypes';
import * as HomeActionTypes from '../actions/home/HomeActionTypes';
import {
  REJECT_RESERVE_ERROR,
  REJECT_RESERVE_SUCCESS,
} from '../actions/reserveReject/RejectReserveActionsTypes';
import { GET_BOOKING_ERROR } from '../actions/booking/BookingActionsTypes';
import {
  CREATE_RESTAURANT_SUCCESS,
  CREATE_RESTAURANT_ERROR,
} from '../actions/cabinet/CabinetActionsTypes';
import { GET_USER_STATISTIC_ERROR } from '../actions/userStatistic/UserStatisticActionsTypes';
import {
  CUSTOM_ERROR,
  CUSTOM_MESSAGE,
  CUSTOM_SNACKBAR,
  ENQUEUE_SNACKBAR,
} from '../actions/snackbars/SnackBarsActionsTypes';
import { errorSnackBar, messageSnackBar, customSnackBar } from '../components/Common/Snackbar/SnackBars';
import * as WidgetActionTypes from '../actions/restaurantsWidgets/RestaurantsWidgetsTypes';
import { OPEN_BOOKING_DETAILS_ERROR } from '../actions/statistics/StatisticsActionsTypes';
import { removeSnackbar } from '../actions/snackbars/SnackBarsActions';

/** @description Сага для обработки системных сообщений */
function* snackbarsSaga(action: any) {
  const errorBody = errorSnackBar();
  const messageBody = messageSnackBar();

  if (action.error) {
    yield put({
      type: ENQUEUE_SNACKBAR,
      notification: {
        ...errorBody,
        message: action.error,
      },
    });
  }

  if (action.message) {
    yield put({
      type: ENQUEUE_SNACKBAR,
      notification: {
        ...messageBody,
        message: action.message,
      },
    });
  }

  if (action.type === CUSTOM_SNACKBAR) {
    yield put({
      type: ENQUEUE_SNACKBAR,
      notification: {
        ...customSnackBar(action.payload.params),
      },
    });
  }
}

export default function* saga() {
  yield takeEvery([
    // --------------ERRORS----------- //
    ReserveModalActionsTypes.CONFIRM_RESERVE_ERROR,
    ReserveModalActionsTypes.GET_FREE_TABLES_ERROR,
    ReserveModalActionsTypes.GET_RESERVE_INFO_ERROR,
    ReserveModalActionsTypes.REJECT_NEW_RESERVE_ERROR,
    ReserveModalActionsTypes.CREATE_RESERVE_ERROR,
    RestaurantsInfoTypes.UPDATE_RESTAURANT_INFO_ERROR,
    RestaurantsTablesTypes.UPDATE_RESTAURANT_TABLE_ERROR,
    RestaurantsTablesTypes.GET_ALL_TABLE_ERROR,
    HomeActionTypes.SELECT_ORGANIZATION_ERROR,
    HomeActionTypes.GET_ORGANIZATION_LIST_FORM_IIKO_ERROR,
    HomeActionTypes.AUTH_FORM_IIKO_ERROR,
    PromoActionsTypes.GET_PROGRAMS_ERROR,
    PromoActionsTypes.LOAD_PROGRAMS_ERROR,
    PromoActionsTypes.LOAD_PROGRAMS_ERROR,
    AuthActionsTypes.SIGN_UP_ERROR,
    RestaurantTypes.DELETE_RESTAURANT_ERROR,
    MenuActionsTypes.GET_MENU_FROM_IIKO_ERROR,
    MenuActionsTypes.GET_MENU_ERROR,
    HomeActionTypes.SWITCH_RESERVE_PROCESSOR_ERROR,
    ReserveModalActionsTypes.UPDATE_RESERVE_ERROR,
    StatisticsActionsTypes.GET_SUMMARY_STATISTICS_ERROR,
    SchedulesActionTypes.UPDATE_SCHEDULE_ERROR,
    SchedulesActionTypes.DELETE_SCHEDULE_ROW_ERROR,
    REJECT_RESERVE_ERROR,
    CREATE_RESTAURANT_ERROR,
    GET_BOOKING_ERROR,
    GET_USER_STATISTIC_ERROR,
    OPEN_BOOKING_DETAILS_ERROR,
    WidgetActionTypes.CREATE_WIDGET_ERROR,
    WidgetActionTypes.GET_ALL_WIDGETS_ERROR,
    WidgetActionTypes.UPDATE_WIDGET_ERROR,
    WidgetActionTypes.DELETE_WIDGET_ERROR,
    // --------------SUCCESS----------- //
    RestaurantsTablesTypes.UPDATE_RESTAURANT_TABLE_SUCCESS,
    RestaurantsInfoTypes.UPDATE_RESTAURANT_INFO_SUCCESS,
    HomeActionTypes.CONNECT_RESTAURANT_SUCCESS,
    ReserveModalActionsTypes.REJECT_NEW_RESERVE_SUCCESS,
    ReserveModalActionsTypes.CONFIRM_RESERVE_SUCCESS,
    HomeActionTypes.SELECT_ORGANIZATION_SUCCESS,
    HomeActionTypes.AUTH_FORM_IIKO_SUCCESS,
    PromoActionsTypes.LOAD_PROGRAMS_SUCCESS,
    AuthActionsTypes.SIGN_UP_SUCCESS,
    RestaurantTypes.DELETE_RESTAURANT_SUCCESS,
    MenuActionsTypes.GET_MENU_FROM_IIKO_SUCCESS,
    ReserveModalActionsTypes.UPDATE_RESERVE_SUCCESS,
    SchedulesActionTypes.UPDATE_SCHEDULE_SUCCESS,
    REJECT_RESERVE_SUCCESS,
    CREATE_RESTAURANT_SUCCESS,
    WidgetActionTypes.CREATE_WIDGET_SUCCESS,
    WidgetActionTypes.UPDATE_WIDGET_SUCCESS,
    WidgetActionTypes.DELETE_WIDGET_SUCCESS,
    // ------------CUSTOM MESSAGES/ERRORS----------- //
    CUSTOM_ERROR,
    CUSTOM_MESSAGE,
    CUSTOM_SNACKBAR,
  ], snackbarsSaga);
}
