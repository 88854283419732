import { combineReducers } from 'redux';
import { connectRouter as router } from 'connected-react-router';
import { reducer as form } from 'redux-form';

import { IAppStore } from '../types/IAppStore';
import { IAppActions } from '../types/IAppActions';
import auth from './AuthReducer';
import booking from './BookingReducer';
import cabinet from './CabinetReducer';
import home from './HomeReducer';
import menu from './MenuReducer';
import promo from './PromoReducer';
import photo from './PhotoReducer';
import loading from './LoadingReducer';
import restorePassword from './RestorePasswordReducer';
import reserveModal from './ReservationPageReducer';
import reservesSystemInfo from './RestaurantInfoReducer';
import reservesSystemSettings from './RestaurantSettingsReducer';
import widgets from './RestaurantWidgetsReducer';
import schedule from './ScheduleReducer';
import statistic from './StatisticsReducer';
import sourceStatistic from './SourceStatisticReducer';
import snackbars from './SnackbarsReducer';
import tables from './RestaurantsTablesReducer';
import userStatistics from './UserStatisticsReducer';
import websocket from './WebsocketReducer';
import calls from './RestaurantCallsReducer';
import openReserves from './OpenReservesReducer';
import notifications from './NotificationsReducer';
import appReserves from './AppReservesReducer';

export default (history: any) => combineReducers<IAppStore, IAppActions>({
  auth,
  booking,
  cabinet,
  home,
  loading,
  reserveModal,
  reservesSystemInfo,
  reservesSystemSettings,
  restorePassword,
  // @ts-ignore
  router: router(history),
  tables,
  statistic,
  snackbars,
  schedule,
  sourceStatistic,
  promo,
  photo,
  menu,
  form,
  userStatistics,
  websocket,
  widgets,
  calls,
  openReserves,
  notifications,
  appReserves,
});
