import React from 'react';

import { Button, withStyles } from '@material-ui/core';

import { IButtonProps } from './IProps';


const StyledButton = withStyles({
  root: {
    color: '#323232',
    backgroundColor: '#e7ebee',
  },
})(Button);


const ButtonComponent: React.FC<IButtonProps> = ({
  onClick,
  startIcon,
  title,
  ...other
}) => (
  <StyledButton
    variant="contained"
    startIcon={startIcon || null}
    onClick={onClick}
    fullWidth
    {...other}
  >
    {title}
  </StyledButton>
);

export default ButtonComponent;
