import * as ActionTypes from './PromoActionsTypes';
import { IPromoActions } from './IPromoActions';

/** @description Экшен на получение акций */
export function getPrograms(restaurantId: number): IPromoActions {
  return {
    type: ActionTypes.GET_PROGRAMS_REQUEST,
    payload: { restaurantId },
  };
}

/** @description Экшен на обработку успешного получения акций */
export function getProgramsSuccess(programList: Array<any>): IPromoActions {
  return {
    type: ActionTypes.GET_PROGRAMS_SUCCESS,
    payload: {
      programList,
    },
  };
}

/** @description Экшен на обработку ошибки получения акций */
export function getProgramsError(): IPromoActions {
  return {
    type: ActionTypes.GET_PROGRAMS_ERROR,
    error: 'Не удалось загрузить акции',
  };
}

/** @description Экшен на загрузку акций из iiko */
export function loadProgramsFromIiko(restaurantId: number): IPromoActions {
  return {
    type: ActionTypes.LOAD_PROGRAMS_REQUEST,
    payload: { restaurantId },
  };
}

/** @description Экшен на обработку успешной загрузки акций из iiko */
export function loadProgramsFromIikoSuccess(programList: Array<any>): IPromoActions {
  return {
    type: ActionTypes.LOAD_PROGRAMS_SUCCESS,
    message: 'Акции успешно загружены',
    payload: {
      programList,
    },
  };
}

/** @description Экшен на обработку ошибки загрузки акций из iiko */
export function loadProgramsFromIikoError(): IPromoActions {
  return {
    type: ActionTypes.LOAD_PROGRAMS_ERROR,
    error: 'Не удалось загрузить акции из сервиса iiko',
  };
}
