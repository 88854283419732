import * as ReserveRejectActions from './RejectReserveActionsTypes';
import { TReserve } from '../../types/IBookingStore';
import { IRejectReserveAction } from './IRejectReserve';

/** @description Экшен на отмену резерва */
export function rejectReserve(reserveId: number): IRejectReserveAction {
  return {
    type: ReserveRejectActions.REJECT_RESERVE_REQUEST,
    payload: {
      reserveId,
    },
  };
}

/** @description Экшен для обработки успешной отмены резерва */
export function rejectReserveSuccess(reserve: TReserve): IRejectReserveAction {
  return {
    type: ReserveRejectActions.REJECT_RESERVE_SUCCESS,
    message: 'Резерв отклонен',
    payload: {
      reserve,
    },
  };
}

/** @description Экшен для обработки ошибки при отмене резерва */
export function rejectReserveError(): IRejectReserveAction {
  return {
    type: ReserveRejectActions.REJECT_RESERVE_ERROR,
    error: 'Не удалось отклонить резерв',
  };
}
