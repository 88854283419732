import React, { Component } from 'react';
import moment from 'moment';
import _ from 'lodash';

import Reservations from '../../components/Reservations';
import locales from '../../locales';
import { IProps } from './IReserveConfirmPage';
import { ITable } from '../../types/IRestaurantTablesStore';
import {
  validateConfirmReserveFields,
} from '../../helpers/bookingHelpers';
import { getHoursAndMinutes } from '../../helpers/dateHelpers';
import { IAppReserveCloseOptions } from '../../actions/appReserves/IAppReservesActions';


class ReserveConfirmPage extends Component<IProps> {
  /** @description Закрытие модального окна */
  handleCloseDialog = (options?: IAppReserveCloseOptions): void => {
    const { closeAppReserve, selectedAppReserve } = this.props;

    closeAppReserve(
      selectedAppReserve && selectedAppReserve.key,
      options,
    );
  };

  /** @description Выбор стола для резерва */
  handleSelectTable = (activeTable: ITable): void => {
    const { setActiveTable } = this.props;

    setActiveTable(activeTable);
  };

  /** @description Отмена резерва */
  handleRejectReserve = (): void => {
    const {
      rejectNewReserve,
      reserveInfo,
      closeAppReserve,
      selectedAppReserve,
    } = this.props;

    rejectNewReserve(reserveInfo.id);

    closeAppReserve(selectedAppReserve && selectedAppReserve.key, {
      remove: true,
      openNext: true,
      // skipModalClosing: true,
    });
  };

  /** @description Отправка запроса на подтверждения новой брони с выбранным столом */
  handleSendReq = (): void => {
    const {
      confirmReserve,
      reserveInfo,
      activeTable,
      customErrorShackBar,
      closeAppReserve,
      selectedAppReserve,
    } = this.props;

    if (!_.isEmpty(activeTable)) {
      const updateReserveInfo = {
        tables: Object.keys(activeTable).map(id => parseInt(id)),
        isBanquet: reserveInfo.isBanquet,
      };

      confirmReserve(reserveInfo.id, updateReserveInfo);

      closeAppReserve(selectedAppReserve && selectedAppReserve.key, {
        remove: true,
        openNext: true,
        // skipModalClosing: true,
      });
    } else {
      customErrorShackBar(locales.t('common.reserveModal.emptyActiveTables'));
    }
  };

  /** @description Переключение в режим объеденения столов */
  handleMergeTables = () => {
    const {
      mergeTables,
      isMergeTables,
      reserveInfo,
    } = this.props;

    const newTableType = !isMergeTables;

    if (newTableType && validateConfirmReserveFields(reserveInfo)) {
      mergeTables(newTableType, {
        date: reserveInfo.date,
        persons: 0,
        duration: reserveInfo.duration,
      });
    } else if (!newTableType && validateConfirmReserveFields(reserveInfo)) {
      mergeTables(newTableType, {
        date: reserveInfo.date,
        persons: reserveInfo.persons,
        duration: reserveInfo.duration,
      });
    }
  };

  handleViewChange = (sectionId: number) => {
    const { changeSectionToFirstItem, formName } = this.props;

    changeSectionToFirstItem(formName, sectionId);
  };

  render() {
    const {
      formName,
      disabled,
      outerChangeType,

      modalOpen,
      freeTables,
      reserveInfo,
      activeTable,
      isMergeTables,
      isSchemasEnabled,
      sections,
      schema,

      selectedAppReserve,
      appReserves,
      addAppReserve,
      removeAppReserve,
      openAppReserve,
      closeAppReserve,
      changeAppReserve,
      appReserveFormValues,
      alreadySelectedTables,
    } = this.props;

    if (!modalOpen) {
      return null;
    }

    const duration = getHoursAndMinutes(reserveInfo.duration);

    return (
      <Reservations
        form={formName}
        disabled={disabled}
        outerChangeType={outerChangeType}

        type="online"
        reserveInfo={reserveInfo}

        title={locales.t('common.newReserveModal.title')}
        reserveBtnText={locales.t('common.newReserveModal.reserve')}
        rejectBtnText={locales.t('common.newReserveModal.reject')}
        freeTables={freeTables}
        isSchemasEnabled={isSchemasEnabled}
        sections={sections}
        section={reserveInfo.section}
        schema={schema}
        persons={reserveInfo.persons}
        // @ts-ignore
        onSubmit={this.handleSendReq}
        handleSelectTable={this.handleSelectTable}
        handleCloseDialog={this.handleCloseDialog}
        handleReject={this.handleRejectReserve}
        handleMergeTables={this.handleMergeTables}
        handleViewChange={this.handleViewChange}
        isMergeTables={isMergeTables}
        activeTables={activeTable}
        initialValues={{
          hours: duration.h,
          minutes: duration.m,
          comment: reserveInfo.comment,
          firstName: reserveInfo.firstName,
          lastName: reserveInfo.lastName,
          phone: reserveInfo.phone,
          time: moment(reserveInfo.date).format('HH:mm'),
          date: moment(reserveInfo.date).format('DD.MM.YYYY'),
          persons: reserveInfo.persons,
          section: reserveInfo.section,
        }}
        enableReinitialize

        selectedAppReserve={selectedAppReserve}
        appReserves={appReserves}
        addAppReserve={addAppReserve}
        removeAppReserve={removeAppReserve}
        openAppReserve={openAppReserve}
        closeAppReserve={closeAppReserve}
        changeAppReserve={changeAppReserve}
        appReserveFormValues={appReserveFormValues}
        alreadySelectedTables={alreadySelectedTables}

        isBanquet={!!(selectedAppReserve
          && selectedAppReserve.originPayload
          && selectedAppReserve.originPayload.isBanquet)}
      />
    );
  }
}

export default ReserveConfirmPage;
