import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';

import {
  Grid,
  Paper,
  Divider,
  Box,
  makeStyles,
  Theme,
  TextField,
  // MenuItem,
} from '@material-ui/core';

import locales from '../../../locales';
import Title from '../../Common/Title';
import RoleDescription from '../Employee/RoleDescription';
import Button from '../../Common/Button';
import SolidButton from '../../Common/Button/Solid';
import { Employees } from '../../../entities';
import { formatPhone, parsePhone } from '../../../helpers/helpers';
// import { innerUsers } from '../../../api/mocks/employees';

const useStyles = makeStyles((theme: Theme) => {
  const spacing2 = theme.spacing(2);
  const spacing3 = theme.spacing(3);

  return {
    form: {
      padding: spacing3,
      paddingTop: 0,
    },

    buttons: {
      padding: `${spacing2}px ${spacing3}px`,
      display: 'flex',
      columnGap: spacing2,
    },
  };
});

const requiredMessages = 'Обязательно для заполнения';

const validationCreateSchema = yup.object({
  // firstName: yup
  //   .string()
  //   .required(requiredMessages),
  // lastName: yup
  //   .string()
  //   .required(requiredMessages),
  phone: yup
    .string()
    .required(requiredMessages),
  email: yup
    .string()
    .email('Введите корректный адрес эл. почты')
    .required(requiredMessages),
  authorities: yup
    .array()
    .min(1, 'Нужно выбрать хотя бы одну роль'),
});

const validationEditSchema = yup.object({
  authorities: yup
    .array()
    .min(1, 'Нужно выбрать хотя бы одну роль'),
});

const EmployeeFormComponent: React.FC<{
  data: GM.Employees.Form;
  onCancel: () => void;
  onSubmit: (user: GM.Employees.Form) => void;
  type: 'create' | 'edit';
  canChangeAuthorities: boolean;
  canUnsetAdminAuthority: boolean;
}> = ({
  data,
  onCancel,
  onSubmit,
  type,
  canChangeAuthorities,
  canUnsetAdminAuthority,
}) => {
  const classes = useStyles();

  const formik = useFormik({
    validationSchema: type === 'edit' ? validationEditSchema : validationCreateSchema,

    initialValues: data,

    onSubmit: (values) => {
      const employee: GM.Employees.Form = {
        ...values,
      };

      onSubmit(employee);
    },

    validate: (values) => {
      const errors: {[p: string]: string} = {};

      if (values.phone) {
        if (!parsePhone(values.phone)) {
          errors.phone = locales.t('employees.errors.phoneFormat');
        }
      }

      return errors;
    },
  });

  const { authorities } = formik.values;

  const { authorities: authoritiesErrors } = formik.errors;
  let authorityError: string | undefined;

  if (formik.submitCount > 0 && authoritiesErrors && formik.touched.authorities) {
    if (Array.isArray(authoritiesErrors)) {
      authorityError = authoritiesErrors.join(' ');
    } else {
      authorityError = authoritiesErrors;
    }
  }

  return (
    <>
      <Grid item md={6} xs={12}>
        <Paper elevation={0}>
          <Title text={locales.t('employees.form')} borderBottom />

          <Box className={classes.form}>
            <TextField
              fullWidth
              margin="normal"
              label={locales.t('employees.properties.firstName')}
              name="firstName"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              error={formik.touched.firstName && Boolean(formik.errors.firstName)}
              helperText={formik.touched.firstName && formik.errors.firstName}
            />

            <TextField
              fullWidth
              margin="normal"
              label={locales.t('employees.properties.lastName')}
              name="lastName"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
            />

            <TextField
              fullWidth
              margin="normal"
              label={locales.t('employees.properties.email')}
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              // Эл. адрес нельзя менять
              disabled={!!data.email}
            />

            <TextField
              fullWidth
              margin="normal"
              label={locales.t('employees.properties.phone')}
              name="phone"
              value={formik.values.phone}
              onChange={formik.handleChange}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
              onBlur={(e) => {
                const phoneNumber = parsePhone(e.target.value);
                if (phoneNumber) {
                  formik.setFieldValue('phone', formatPhone(phoneNumber));
                }
              }}
            />

            {/*
            <TextField
              select
              fullWidth
              margin="normal"
              label={locales.t('employees.properties.iikoEmployee')}
              name="iikoEmployeeId"
              value={formik.values.iikoEmployeeId}
              onChange={formik.handleChange}
              error={formik.touched.iikoEmployeeId && Boolean(formik.errors.iikoEmployeeId)}
              helperText={formik.touched.iikoEmployeeId && formik.errors.iikoEmployeeId}
            >
              {Object.values(innerUsers).map(item => (
                <MenuItem key={item.id} value={item.id}>{item.title}</MenuItem>
              ))}
            </TextField>
            */}
          </Box>

          <Divider />

          <Box className={classes.buttons}>
            <Button onClick={onCancel} title={locales.t('employees.properties.cancel')} />
            <SolidButton onClick={formik.handleSubmit} title={locales.t('employees.properties.submit')} />
          </Box>
        </Paper>
      </Grid>
      {canChangeAuthorities && (
        <Grid item md={6} xs={12}>
          <Paper elevation={0}>
            <Title text={locales.t('employees.properties.authority')} borderBottom />

            {Object.values(Employees.employeeAuthorities).map((authority) => {
              const enabled = authorities && authorities.indexOf(authority.id) !== -1;

              return (
                <RoleDescription
                  key={authority.id}
                  title={locales.t(`employees.properties.authorities.${authority.id}.title`)}
                  list={locales.t(`employees.properties.authorities.${authority.id}.list`).split('.')}
                  isDivider
                  isOn={enabled}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const result = authorities ? authorities.filter(item => item !== authority.id) : [];
                    if (e.target.checked) {
                      result.push(authority.id);
                    }

                    formik.setFieldTouched('authorities', true);
                    formik.setFieldValue('authorities', result);
                  }}
                  error={authorityError}
                  isDisabled={
                    !canChangeAuthorities
                    || (enabled && authority.id === 'ADMINISTRATOR' && !canUnsetAdminAuthority)
                  }
                />
              );
            })}
          </Paper>
        </Grid>
      )}
    </>
  );
};

export default EmployeeFormComponent;
