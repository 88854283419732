import {
  all,
  put,
  takeEvery,
  select,
} from 'redux-saga/effects';

import { reset } from 'redux-form';
import * as Sentry from '@sentry/react';

import {
  // FETCH_OPEN_RESERVES_SUCCESS,
  // ADD_OPEN_RESERVE,
  REMOVE_OPEN_RESERVE,
} from '../actions/reservationsPage/ReservationsPageActionsTypes';

import Reserve, {TAppReserves, TNewReserveFields} from '../types/IAppReservesStore';

import {
  IFetchOpenReservesSuccess,
  // IAddOpenReserve,
  IRemoveOpenReserve,
} from '../actions/reservationsPage/IReservationsPageActions';

import {
  openReserveEditModal,
  closeReserveEditModal,
  openReserveCreateModal,
  closeReserveCreateModal,
  openReserveConfirmModal,
  closeReserveConfirmModal,
} from '../actions/reservationsPage/ReservationsPageActions';

import {
  setAppReserves,
  addAppReserve,
  removeAppReserve,
  openAppReserve,
  closeAppReserve,
} from '../actions/appReserves/AppReservesActions';

import { TOpenReserve, TReserve } from '../types/IBookingStore';

import {
  ADD_APP_RESERVE,
  OPEN_APP_RESERVE,
  CLOSE_APP_RESERVE,
  TOGGLE_APP_RESERVES,
} from '../actions/appReserves/AppReservesActionsTypes';

import {
  IAddAppReserve,
  ICloseAppReserve,
  IOpenAppReserve,
} from '../actions/appReserves/IAppReservesActions';

import {
  selectorLastAppReserve,
  selectorOpenModal,
  selectorSelectedAppReserve,
} from '../containers/Reservation/selectors';
import { closeNotifications } from '../actions/notifications/NotificationsActions';
import { getReserveFormName } from '../containers/Reservation/constants';


function* setAppReservesSaga(action: IFetchOpenReservesSuccess) {
  const reserves: TAppReserves = action.reserves.map(
    (reserve: TOpenReserve) => new Reserve('WIDGET', reserve),
  );

  yield put(setAppReserves(reserves));
}

// function* addOpenReserveSaga(action: IAddOpenReserve) {
//   yield put(addAppReserve(new Reserve('WIDGET', action.reserve)));
// }

function* addAppReserveSaga(action: IAddAppReserve) {
  const selectedReserve = yield select(selectorSelectedAppReserve);

  if (selectedReserve) {
    yield put(closeAppReserve(
      selectedReserve.key,
      { openNextKey: action.reserve.key },
    ));
  } else {
    yield put(openAppReserve(action.reserve.key));
  }
}

function* removeOpenReserveSaga(action: IRemoveOpenReserve) {
  const reserve = yield select(state => state.appReserves.items.find(
    (x: Reserve) => x.payload && x.payload.id === action.id,
  ));

  if (reserve && !reserve.open) {
    yield put(removeAppReserve(reserve.key));
  }
}

function* openAppReserveSaga(action: IOpenAppReserve) {
  let reserve: Reserve | undefined;
  if (action.key) {
    reserve = yield select(state => state.appReserves.items.find(
      (x: Reserve) => x.key === action.key,
    ));
  }

  if (!reserve) {
    reserve = yield select(selectorLastAppReserve);
  }

  if (!reserve) {
    reserve = yield select(state =>
      state.appReserves.items && state.appReserves.items[0],
    );
  }

  if (!reserve) {
    Sentry.captureMessage('No reserve to open');
    throw new Error('No reserve to open');
  }

  const formType = reserve.getFormType();

  switch (formType) {
    case 'CREATE':
      yield put(openReserveCreateModal(reserve.payload as TNewReserveFields));
      break;

    case 'CONFIRM':
      if (reserve.payload && reserve.payload.id) {
        yield put(openReserveConfirmModal(reserve.payload.id));
      }

      break;

    case 'EDIT':
      if (reserve.payload) {
        yield put(openReserveEditModal(reserve.payload as TReserve));
      }

      break;

    default:
      Sentry.captureMessage(`Unknown reserve form type "${formType}"`);
      throw new Error(`Unknown reserve form type "${formType}"`);
  }

  const isNotificationsOpen: boolean = yield select(state => state.notifications.open);
  if (isNotificationsOpen) {
    yield put(closeNotifications());
  }
}

function* closeAppReserveSaga(action: ICloseAppReserve) {
  let reserve: Reserve;

  if (action.key) {
    reserve = yield select(state => state.appReserves.items.find(
      (x: Reserve) => x.key === action.key,
    ));
  } else {
    reserve = yield select(selectorLastAppReserve);
  }

  if (!reserve) {
    Sentry.captureMessage('No reserve to close');
    throw new Error('No reserve to close');
  }

  if (!action.options || !action.options.skipModalClosing) {
    switch (reserve.getFormType()) {
      case 'CREATE':
        yield put(reset(getReserveFormName('CREATE')));
        yield put(closeReserveCreateModal());
        break;

      case 'CONFIRM':
        if (reserve.payload && reserve.payload.id) {
          yield put(reset(getReserveFormName('CONFIRM')));
          yield put(closeReserveConfirmModal(reserve.payload.id));
        }
        break;

      case 'EDIT':
        yield put(reset(getReserveFormName('EDIT')));
        yield put(closeReserveEditModal());
        break;
    }
  }

  // Удаление черновиков пустого или неизмененного резерва при скрытии формы
  //   резерва через интерфейс (иконка в шапке или кнопка назад в навигации)
  const removed: Array<string> = [];

  if (!action.key) {
    const reserves: Array<Reserve> = yield select(state => state.appReserves && state.appReserves.items);

    for (let i = 0; i < reserves.length; i++) {
      if (
        !reserves[i].changed && (
          reserves[i].type !== 'WIDGET' ||
          reserves[i].outerChangeType === 'CANCELED'
        )
      ) {
        removed.push(reserves[i].key);
        yield put(removeAppReserve(reserves[i].key));
      }
    }
  }


  // Обработка дополнительных событий
  if (action.options) {
    if (action.options.openNext) {
      if (!action.key) {
        Sentry.captureMessage('Unknown reserve key to open next');
        throw new Error('Unknown reserve key to open next');
      }

      const nextAppReserve = yield select(state => {
        const { items } = state.appReserves;
        if (!items || items.length <= 1) {
          return undefined;
        }

        const openIndex = items.findIndex((reserve: Reserve) => reserve.key === action.key);
        if (openIndex + 1 <= items.length - 1) {
          return items[openIndex + 1];
        }

        return items[openIndex - 1];
      });

      if (nextAppReserve) {
        yield put(openAppReserve(nextAppReserve.key));
      }
    }

    if (action.options.openNextKey) {
      yield put(openAppReserve(action.options.openNextKey));
    }

    if (action.options.openNewReserve) {
      yield put(addAppReserve(action.options.openNewReserve));
    }

    if (action.options.remove && removed.indexOf(reserve.key) === -1) {
      yield put(removeAppReserve(reserve.key));
    }
  }
}

function* toggleAppReservesSaga() {
  const openModal = yield select(selectorOpenModal);

  if (openModal) {
    yield put(closeAppReserve());
  } else {
    yield put(openAppReserve());
  }
}

export default function* saga() {
  yield all([
    // takeEvery(FETCH_OPEN_RESERVES_SUCCESS, setAppReservesSaga),
    // takeEvery(ADD_OPEN_RESERVE, addOpenReserveSaga),
    takeEvery(REMOVE_OPEN_RESERVE, removeOpenReserveSaga),

    takeEvery(ADD_APP_RESERVE, addAppReserveSaga),
    takeEvery(OPEN_APP_RESERVE, openAppReserveSaga),
    takeEvery(CLOSE_APP_RESERVE, closeAppReserveSaga),
    takeEvery(TOGGLE_APP_RESERVES, toggleAppReservesSaga),
  ]);
}
