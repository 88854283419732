import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Employees } from '../../../entities';
import { ActivationSuccess, ActivationError, ActivationPassword } from '../ui';

interface EmployeeData {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
}

export function useEmployeeActivationFeature() {
  const { key } = useParams();
  const getEmployeeMutation = Employees.useGetEmployeeByActivationKeyMutation();
  const activateEmployeeMutation = Employees.useActivateEmployeeMutation();

  const [employee, setEmployee] = useState<EmployeeData | undefined>();

  const submitPassword = (password: string) => {
    if (!employee) {
      return;
    }

    const payload: GM.Employees.Request.Activate = {
      key,
      firstName: employee.firstName,
      lastName: employee.lastName,
      phone: employee.phone,
      password,
    };

    activateEmployeeMutation.mutate(payload);
  };

  useEffect(() => {
    if (key && getEmployeeMutation.status === 'idle') {
      getEmployeeMutation.mutate({ key });
    }
  }, [key, getEmployeeMutation.status, getEmployeeMutation]);

  useEffect(() => {
    if (getEmployeeMutation.status === 'success' && getEmployeeMutation.data) {
      const {
        firstName, lastName, phone, email,
      } = getEmployeeMutation.data.data;
      setEmployee({
        firstName, lastName, phone, email,
      });
    }
  }, [getEmployeeMutation.status, getEmployeeMutation.data]);

  if (
    getEmployeeMutation.status === 'error'
    || activateEmployeeMutation.status === 'error'
  ) {
    return <ActivationError />;
  }

  if (getEmployeeMutation.status === 'success') {
    if (activateEmployeeMutation.status === 'success') {
      return <ActivationSuccess />;
    }

    return <ActivationPassword submitPassword={submitPassword} />;
  }

  return null;
}
