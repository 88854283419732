import React from 'react';

import EditField from '../../Common/SelfSavingFields/EditField';
import {
  cardFieldType,
} from '../../../config/constants';
import { TRestaurantSettings } from '../../../types/ICabinetStore';
import { TNewInfo } from '../../../types/IRestaurantInfoStore';
import FieldWrap from '../../Common/FieldWrap';
import CustomTooltip from '../../Common/CustomTooltip';
import locales from '../../../locales';

interface IProps {
  currentRestaurantSettings: TRestaurantSettings;
  handleUpdateRestaurantSettings: (data: TNewInfo) => void;
}

/**
 * @param classes {Object}
 * @param handleUpdateRestaurantSettings {Function} - Ручка для редактирования полей в ресторане
 * @param currentRestaurantSettings {Object} - Настройки ресторана
 */
const AutoBookingFields: React.FC<IProps> = ({
  handleUpdateRestaurantSettings,
  currentRestaurantSettings,
}) => (
  <div style={{ padding: '12px 0' }}>
    <FieldWrap>
      <EditField
        label={locales.t('settings.isAutoBookingEnabled.disableBooking.title')}
        endAdornment={locales.t('settings.isAutoBookingEnabled.disableBooking.desc')}
        value={currentRestaurantSettings.autoBookingMinFreeTables}
        handleChangeList={handleUpdateRestaurantSettings}
        type={cardFieldType.autoBookingMinFreeTables}
        fieldType="number"
      />
      <CustomTooltip marginLeft content="" />
    </FieldWrap>

    <FieldWrap>
      <EditField
        label={locales.t('settings.isAutoBookingEnabled.maxSizeBookingTable.title')}
        endAdornment={locales.t('settings.isAutoBookingEnabled.maxSizeBookingTable.desc')}
        value={currentRestaurantSettings.autoBookingMaxNumberEmptySeats}
        handleChangeList={handleUpdateRestaurantSettings}
        type={cardFieldType.autoBookingMaxNumberEmptySeats}
        fieldType="number"
      />
      <CustomTooltip marginLeft content="" />
    </FieldWrap>

    <FieldWrap>
      <EditField
        label={locales.t('settings.isAutoBookingEnabled.table.title')}
        endAdornment={locales.t('settings.isAutoBookingEnabled.table.desc')}
        value={currentRestaurantSettings.autoBookingMinTimeElapsed}
        handleChangeList={handleUpdateRestaurantSettings}
        type={cardFieldType.autoBookingMinTimeElapsed}
        fieldType="number"
      />
      <CustomTooltip marginLeft content="" />
    </FieldWrap>
  </div>
);

export default AutoBookingFields;
