import React from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import * as EmailValidator from 'email-validator';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography/Typography';

import TextField from '../Common/TextFieldComp';
import CheckBoxComp from '../Common/CheckBoxComp';
import locales from '../../locales';

interface IProps {
  regError?: string
}

const RegistrationForm: React.FC<IProps & InjectedFormProps<{}, IProps>> = ({ handleSubmit, regError }) => (
  <form onSubmit={handleSubmit}>
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Field
          name="firstName"
          component={TextField}
          label={locales.t('common.firstName')}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          name="lastName"
          component={TextField}
          label={locales.t('common.lastName')}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          name="email"
          component={TextField}
          label={locales.t('common.email')}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          name="password"
          component={TextField}
          type="password"
          label={locales.t('common.password')}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          name="password2"
          component={TextField}
          type="password"
          label={locales.t('common.confirmPassword')}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          name="acceptRules"
          type="checkbox"
          label={locales.t('common.agreeProcessingPersonalData')}
          component={CheckBoxComp}
        />
      </Grid>
      <Grid item xs={12} container justify="center">
        <div style={{ width: '50%' }}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
          >
            { locales.t('common.registration') }
          </Button>
        </div>
      </Grid>
      {
        regError
        && (
          <Grid item xs={12} container justify="center">
            <Typography color="error" variant="subtitle1">{regError}</Typography>
          </Grid>
        )
      }
    </Grid>
  </form>
);

interface IError {
  email?: string;
  password?: string;
  password2?: string;
  firstName?: string;
  lastName?: string;
}

const validateName = (value: string | undefined): boolean => {
  const re = new RegExp(/^[\p{L}\s-]+$/u);
  return !!(value && value.match(re));
}

const validate = ({
  email, password, firstName, lastName, password2,
}: IError) => {
  const errors: IError = {};

  if (!firstName) {
    errors.firstName = locales.t('common.errors.fieldErrors.name.empty');
  } else if (firstName.length < 3) {
    errors.firstName = locales.t('common.errors.fieldErrors.name.short');
  } else if (!validateName(firstName)) {
    errors.firstName = locales.t('common.errors.fieldErrors.name.characters');
  }

  if (!lastName) {
    errors.lastName = locales.t('common.errors.fieldErrors.lastName.empty');
  } else if (lastName.length < 3) {
    errors.lastName = locales.t('common.errors.fieldErrors.lastName.short');
  } else if (!validateName(lastName)) {
    errors.lastName = locales.t('common.errors.fieldErrors.lastName.characters');
  }

  if (!email) errors.email = locales.t('common.errors.fieldErrors.email.empty');
  else if (!EmailValidator.validate(email)) errors.email = locales.t('common.errors.fieldErrors.email.incorrect');

  if (!password) errors.password = locales.t('common.errors.fieldErrors.password.empty');
  else if (password.length < 8) errors.password = locales.t('common.errors.fieldErrors.password.short');

  if (!password2) errors.password2 = locales.t('common.errors.fieldErrors.password.empty');
  else if (password !== password2) errors.password2 = locales.t('common.errors.fieldErrors.password.notMatch');

  return errors;
};

export default reduxForm<{}, IProps>({
  form: 'registration',
  validate,
})(RegistrationForm);
