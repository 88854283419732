import React, { useState, useEffect} from 'react';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';

import locales from '../../../locales';
import { TFilterListItem } from '../../../types/IBookingStore';

interface OwnProps {
  anchorEl: HTMLElement;
  filterIsOpen: boolean;
  handleMenuClose: (
    e: React.SyntheticEvent<HTMLElement>,
    newFilters?: TFilterListItem,
    itemList?: TFilterListItem,
  ) => void;
  itemList: Array<TFilterListItem>;
}

type Props = OwnProps;

const FilterList: React.FC<Props> = ({
  anchorEl,
  filterIsOpen,
  handleMenuClose,
  itemList,
}) => {
  const [items, changeItems] = useState<Array<TFilterListItem>>(itemList);

  useEffect(() => {
    changeItems(itemList);
  }, [itemList]);

  /** @description Редактирование фильтра */
  const onChange = (filter: TFilterListItem) => {
    changeItems(items.map(
      i => i.value === filter.value
        ? { value: filter.value, active: !filter.active }
        : i,
    ));
  };

  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={filterIsOpen}
      // @ts-ignore
      onClose={e => handleMenuClose(e, items, itemList)}
    >
      {
        items.map(item => (
          <MenuItem key={item.value} onClick={() => onChange(item)}>
            <Checkbox
              style={{ paddingLeft: 0 }}
              checked={item.active}
              onChange={() => onChange(item)}
              color="primary"
            />
            { locales.t(`booking.filters.${item.value}`) }
          </MenuItem>
        ))
      }
    </Menu>
  );
};

export default FilterList;
