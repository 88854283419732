import {
  put, takeLatest, all, takeEvery,
} from 'redux-saga/effects';

import * as PhotoActionsTypes from '../actions/photo/PhotoActionsTypes';
import {
  getImageReq,
  hideImageReq,
  setImageAsCoverReq,
  showImageReq,
  uploadImageReq,
} from '../api';
import { customErrorShackBar } from '../actions/snackbars/SnackBarsActions';
import {
  IGetImages,
  IHideImage,
  IUploadImage,
  IShowImage,
  ISetCoverRequest,
  IHideMainImage,
} from '../actions/photo/IPhotoActions';
import {
  getImagesError,
  getImagesSuccess,
  uploadImageSuccess,
  getImages,
  uploadImageError,
  hideImageSuccess,
  hideImageError,
  hideMainImageSuccess,
  hideMainImageError,
  showImageSuccess,
  showImageError,
  setCoverSuccess,
  setCoverError,
} from '../actions/photo/PhotoActions';

/** @description Сага для получения всех изображений */
function* getImagesSaga(action: IGetImages) {
  try {
    const images: Array<any> = yield getImageReq(action.payload.restaurantId);
    yield put(getImagesSuccess(images));
  } catch (e) {
    yield put(getImagesError());
  }
}

/** @description Сага для загрузки новго изображегия */
function* uploadImageSaga(action: IUploadImage) {
  try {
    for (const image of action.payload.image) {
      yield uploadImageReq(action.payload.restaurantId, image);
    }
    yield put(uploadImageSuccess());
    yield put(getImages(action.payload.restaurantId));
  } catch (e) {
    yield put(uploadImageError());
  }
}

/** @description Сага для переключения видимости изображения */
function* switchVisibleImageSaga(action: IHideImage | IShowImage) {
  if (action.type === PhotoActionsTypes.HIDE_IMAGE_REQUEST) {
    try {
      yield hideImageReq(action.payload.restaurantId, action.payload.imageId);
      yield put(hideImageSuccess(action.payload.imageId));
    } catch (e) {
      yield put(hideImageError());
    }
  } else {
    try {
      yield showImageReq(action.payload.restaurantId, action.payload.imageId);
      yield put(showImageSuccess(action.payload.imageId));
    } catch (e) {
      yield put(showImageError());
    }
  }
}

/** @description Сага для установки фото в качестве обложки */
function* setCoverSaga(action: ISetCoverRequest) {
  const { restaurantId, imageId } = action.payload;
  try {
    yield showImageReq(restaurantId, imageId); // Обрабатываем кейс когда новая обложка скрыта
    yield setImageAsCoverReq(restaurantId, imageId);
    yield put(setCoverSuccess(imageId));
  } catch (e) {
    yield put(setCoverError());
  }
}

/** @description Сага для изменения видимости обложки
 *  - Если юзер скрывает обложку то выбераем новой обложкой следующю фотку из списка.
 *  - Если у юзера только 1 фотография кидаем еррор.
 * */
function* hideMainImageSaga(action: IHideMainImage) {
  try {
    const { restaurantId, mainImgId, secondImgId } = action.payload;
    if (secondImgId) {
      yield showImageReq(restaurantId, secondImgId); // Обрабатываем кейс когда новая обложка скрыта
      yield setImageAsCoverReq(restaurantId, secondImgId);
      yield hideImageReq(restaurantId, mainImgId);
      yield put(hideMainImageSuccess(secondImgId, mainImgId));
    } else {
      yield put(customErrorShackBar('Нельзя изменить видимость обложки'));
    }
  } catch (e) {
    yield put(hideMainImageError());
  }
}

export default function* saga() {
  yield all([
    takeLatest(PhotoActionsTypes.GET_IMAGES_REQUEST, getImagesSaga),
    takeEvery([
      PhotoActionsTypes.SHOW_IMAGE_REQUEST,
      PhotoActionsTypes.HIDE_IMAGE_REQUEST,
    ], switchVisibleImageSaga),
    takeLatest(PhotoActionsTypes.UPLOAD_IMAGE_REQUEST, uploadImageSaga),
    takeLatest(PhotoActionsTypes.SET_COVER_REQUEST, setCoverSaga),
    takeLatest(PhotoActionsTypes.HIDE_MAIN_IMAGE_REQUEST, hideMainImageSaga),
  ]);
}
