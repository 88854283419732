import { createSelector } from 'reselect';
import { IAppStore } from '../../types/IAppStore';
import { sortWeekdays } from '../../helpers/scheduleHelpers';

export const freeDaysSelectors = createSelector([
  (state: IAppStore) => state.schedule.bookingFreeDays,
], freeDays => (Object.keys(freeDays).map(d => ({
  day: d,
  free: freeDays[d],
}))
));

export const rowsSelector = createSelector([
  (state: IAppStore) => state.schedule.bookingRows,
], rows => rows.map(r => ({ ...r, days: sortWeekdays(r.days) })));
