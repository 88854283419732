import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';

import {
  updateRestaurantInfo,
} from '../../actions/restaurantsInfo/RestaurantsInfoActions';
import ReservesSystemSchemaCont from './ReservesSystemSchemaCont';
import { IAppStore } from '../../types/IAppStore';
import { IAppActions } from '../../types/IAppActions';
import { IDispatchProps, IStateProps } from './IReservesSystemInfo';

import { selectorSectionOptions } from '../../reducers/selectors';
import { selectorId, selectorSettingsSectionSchema } from './selectors';
import { getSections } from '../../actions/restaurant/RestaurantActions';
import { switchSectionId } from '../../actions/restaurantsSettings/RestaurantsSettingsActions';

function mapStateToProps(state: IAppStore): IStateProps {
  return {
    currentRestaurant: state.cabinet.currentRestaurant,
    sections: selectorSectionOptions(state),
    sectionId: selectorId(state),
    // schema: selectorSectionSchema(state),
    schema: selectorSettingsSectionSchema(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch<IAppActions>): IDispatchProps {
  return bindActionCreators({
    updateRestaurantInfo,
    getSections,
    switchSectionId,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ReservesSystemSchemaCont);
