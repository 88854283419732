import {
  all, put, takeLatest,
} from 'redux-saga/effects';
import _ from 'lodash';
import * as Sentry from '@sentry/browser';

import { Routers } from '../config/constants';
import * as Api from '../api';
import * as ActionTypes from '../actions/auth/AuthActionsTypes';
import * as IActionTypes from '../actions/auth/IAuthActions';
import * as AuthActions from '../actions/auth/AuthActions';
import history from '../redux/history';
import { TUser } from '../types/IAuthStore';
import { setTokens, deleteTokens, setRememberMe } from '../helpers/tokensHelpers';
import locales from '../locales';

function* setToken(email: string, password: string) {
  const tokenData = yield Api.tokenReq(email, password);
  const Token = tokenData.data;

  setTokens(Token.access_token, Token.refresh_token);
  yield put(AuthActions.getSessionSuccess());
}

/** @description Регистарция юзера */
function* signUpSaga(action: IActionTypes.ISignUp) {
  try {
    yield Api.registerRestaurateurReq(action.payload);
    yield put(AuthActions.signUpSuccess());

    yield setToken(action.payload.email, action.payload.password);

    history.push(Routers.thanks);
  } catch (e) {
    if (_.has(e, 'response.status') && (e.response.status === 409)) {
      yield put(AuthActions.signUpError(locales.t('common.errors.signUpErrors.emailError')));
    } else {
      yield put(AuthActions.signUpError(locales.t('common.errors.signUpErrors.unknownError')));
    }
  }
}

/** @description Подтверждение регистрации по емейлу */
function* confirmEmailSaga(action: IActionTypes.IConfirmEmail) {
  try {
    yield Api.confirmEmailReq(action.payload.code);
    yield put(AuthActions.confirmEmailSuccess());
  } catch (e) {
    yield put(AuthActions.confirmEmailError());
  }
}

/** @description Получение нового приглаcительного письма на почту */
function* confirmEmailResendSaga() {
  try {
    yield Api.confirmEmailResendReq();
  } catch (e) {
    const errorText = locales.t('common.errors.signInErrors.unknownError');
    yield put(AuthActions.signInError(errorText));
  }
}

/** @description Авторизация юзера в ЛК */
function* signInSaga(action: IActionTypes.ISignIn) {
  try {
    const { email, password, remember } = action.payload;

    setRememberMe(remember);
    yield setToken(email, password);

    const result = yield Api.getAccountReq();
    const User:TUser = result.data;

    if (User.activated) {
      yield put(AuthActions.signInSuccess(User));

      history.push(Routers.home);
    } else {
      yield put(AuthActions.signInError('common.errors.sendNewPassword.wrongEmail'));

      history.push(Routers.thanks);
    }
  } catch (e) {
    let errorText = locales.t('common.errors.signInErrors.unknownError');

    if (e.response && e.response.data.error === 'invalid_grant') {
      errorText = locales.t('common.errors.signInErrors.wrongEmailOrPasswordError');
    }

    yield put(AuthActions.signInError(errorText));
  }
}

/** @description Получение сессии для авторизированного юзера */
function* getSessionSaga() {
  try {
    const result = yield Api.getAccountReq();
    const User:TUser = result.data;

    yield put(AuthActions.signInSuccess(User));
  } catch (e) {
    yield put({ type: ActionTypes.GET_SESSION_FAIL });
  }
}

/** @description Прекращение сессии для авторизированного юзера */
function* signOutSaga() {
  try {
    yield deleteTokens();
    // yield Api.logoutReq();
  } catch (e) {
    console.log(e);
  }
}

function signInSuccessSaga(action: IActionTypes.ISignInSuccess) {
  const { id, email } = action.payload.user;

  Sentry.setUser({
    id: id.toString(),
    email,
  });
}


export default function* saga() {
  yield all([
    takeLatest(ActionTypes.SIGN_IN_REQUEST, signInSaga),
    takeLatest(ActionTypes.SIGN_UP_REQUEST, signUpSaga),
    takeLatest(ActionTypes.GET_SESSION_REQUEST, getSessionSaga),
    takeLatest(ActionTypes.SIGN_OUT, signOutSaga),
    takeLatest(ActionTypes.CONFIRM_EMAIL_REQUEST, confirmEmailSaga),
    takeLatest(ActionTypes.CONFIRM_EMAIL_RESEND, confirmEmailResendSaga),

    takeLatest(ActionTypes.SIGN_IN_SUCCESS, signInSuccessSaga),
  ]);
}
