import { TModalTypes } from '../../pages/ReservationsPage';

// eslint-disable-next-line import/prefer-default-export
export const getReserveFormName = (modalName: TModalTypes): string => {
  switch (modalName) {
    case 'CREATE':
      return 'reserveCreateForm';

    case 'CONFIRM':
      return 'reserveConfirmForm';

    case 'EDIT':
      return 'reserveEditForm';

    default:
      throw new Error('Unknown modal type');
  }
};
