import { all, takeLatest, put } from 'redux-saga/effects';

import {
  deleteRestaurantError,
  deleteRestaurantSuccess,
} from '../actions/restaurant/RestaurantActions';
import { IDeleteRestaurant } from '../actions/restaurant/IRestaurant';
import { resetConnectStatus } from '../actions/home/HomeActions';
import * as ActionTypes from '../actions/restaurant/RestaurantsActionTypes';
import { deleteRestaurantReq } from '../api';
import history from '../redux/history';
import { Routers } from '../config/constants';

/** @description Сага для удаления выбранного ресторана */
function* deleteRestaurantSaga(action: IDeleteRestaurant) {
  try {
    yield deleteRestaurantReq(action.payload.restaurantId);
    localStorage.removeItem('pinCodeDate');
    yield put(deleteRestaurantSuccess());
    yield put(resetConnectStatus());
    history.push(Routers.home);
  } catch (e) {
    yield put(deleteRestaurantError());
  }
}

export default function* saga() {
  yield all([
    takeLatest(ActionTypes.DELETE_RESTAURANT_REQUEST, deleteRestaurantSaga),
  ]);
}
