import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';

import { IProps, IState } from './ICabinetPage';
import { Routers } from '../../config/constants';
import {
  ReservesSystemPage,
  RestaurantSchemePage,
  ReservesSystemEmployeesPage,
  ReservesSystemProfilePage,
} from '../../pages/ReservesSystemPage';
import HomeCont from '../../containers/Home';
import BookingCont from '../../containers/Booking';
import StatisticsCont from '../../containers/Statistics';
import AddRestaurantModal from '../../components/Common/CreateRestaurantModal';
import Preloader from '../../components/Common/Preloader';
import UserStatisticCont from '../../containers/UserStatistic';
import SourceStatisticCont from '../../containers/SourceStatistic';
import AuthIkkoModalCont from '../../containers/AuthIikoModalCont';
import DrawerAndHeaderComp from '../../components/Common/DrawerAndHeader/DrawerAndHeaderComp';
import Notifier from '../../components/Common/Snackbar/Notifier';
import NotFoundPage from '../../pages/NotFoundPage';
import SocketErrorScreen from '../../containers/SocketErrorScreenCont';
import ReservationsPage from '../../pages/ReservationsPage';
import Notifications from '../../components/Common/Notifications';
import Reserve from '../../types/IAppReservesStore';
import { IRestaurantCall } from '../../types/IRestaurantCallsStore';
import { Employees } from '../../entities';
import { TNotifications } from '../../types/INotificationsStore';
import { useRestaurantId } from '../../selectors/RestaurantSelectors';
import InitMessages from '../../components/Home/InitMessages';
import PageHeaderWrap from '../../components/Common/PageHeaderWrap';
import PageContentWrap from '../../components/Common/PageContentWrap';
import locales from '../../locales';

function CabinetPage(props: IProps & IState) {
  const {
    classes,
    isInit,
    loading,
    modalOpen,
    cashBoxStatus,
    needAuthFromIiko,
    modalAuthIikoOpen,
    reservationsIsOpen,
    withoutRestaurants,
    pathName,
    notifications,
    notificationsOpen,
    removeNotification,
    toggleNotification,
    removeRestaurantCall,
    confirmOpenReserve,
    rejectOpenReserve,
    addAppReserve,
    calls,
    idle,
    resetRestaurantSuccessStatus,
    createRestaurantSuccess,
    history,
    openCreateModal,
    initStarting,
  } = props;

  let interval: number | undefined;

  const resetIdleTimer = () => {
    window.clearInterval(interval);
    interval = window.setInterval(idle, 10000);
  };

  // Редирект на главную страницу после успешного создания ресторана
  useEffect(() => {
    if (createRestaurantSuccess) {
      resetRestaurantSuccessStatus();
      if (history.location.pathname !== Routers.home) {
        history.push(Routers.home);
      }
    }
  }, [createRestaurantSuccess]);

  useEffect(() => {
    initStarting();
    resetIdleTimer();

    document.addEventListener('mousemove', resetIdleTimer);
    document.addEventListener('click', resetIdleTimer);
    document.addEventListener('keypress', resetIdleTimer);

    return () => {
      document.removeEventListener('mousemove', resetIdleTimer);
      document.removeEventListener('click', resetIdleTimer);
      document.removeEventListener('keypress', resetIdleTimer);
      window.clearInterval(interval);
    };
  }, [initStarting]);

  const { data: currentEmployee } = Employees.useCurrentEmployee();
  const restaurantId = useRestaurantId();

  if (!isInit) {
    return <Preloader loading />;
  }

  // У пользователя нет ни одного ресторана, показываем форму создания ресторана
  if (!restaurantId) {
    return (
      <div className={classes.root} style={{ height: '100vh' }}>
        <DrawerAndHeaderComp
          isRegistrationCompleted={false}
          pathname={pathName}
          reservationsIsOpen={false}
          handleOpenReserveCreateModal={() => {}}
        />

        <main className={classes.content}>
          <PageHeaderWrap>
            <Typography variant="h6">
              {locales.t('home.title')}
            </Typography>
          </PageHeaderWrap>

          <PageContentWrap fullWidth>
            <Grid item lg={6}>
              <InitMessages handleClickOpen={openCreateModal} />
            </Grid>
          </PageContentWrap>

          {modalOpen && <AddRestaurantModal />}
        </main>
      </div>
    );
  }

  // По идее это условие не должно срабатывать, так как сотрудника не будет,
  //   если нет ресторана, на что есть проверка выше. Это условие оставляю, чтобы
  //   не делать лишних проверок по коду ниже, где ожидается, что currentEmployee есть.
  if (!currentEmployee) {
    return <Preloader loading />;
  }

  const isCallNotifications = Employees.hasAccess(currentEmployee, 'callNotifications:access');
  const isWidgetNotifications = Employees.hasAccess(currentEmployee, 'widgetNotifications:access');
  let userNotifications: TNotifications = [];

  if (notifications && (isCallNotifications || isWidgetNotifications)) {
    userNotifications = notifications.filter(
      notification => (notification.type === 'CALL' && isCallNotifications)
        || (notification.type === 'RESERVE' && isWidgetNotifications),
    );
  }

  const hasStatsAccess = Employees.hasAccess(currentEmployee, 'stats:access');
  const hasReservesAccess = Employees.hasAccess(currentEmployee, 'reserves:access');

  return (
    <div className={classes.root} style={{ height: '100vh' }}>
      <DrawerAndHeaderComp
        isRegistrationCompleted={!(withoutRestaurants || !cashBoxStatus)}
        pathname={pathName}
        handleOpenReserveCreateModal={() => addAppReserve(new Reserve('APP'))}
        reservationsIsOpen={reservationsIsOpen}
      />

      <Notifier />

      {notificationsOpen && userNotifications && (
        <Notifications
          items={userNotifications}
          onToggle={(key: string) => { toggleNotification(key); }}
          onRemoveCall={(notificationKey: string, itemId: number) => {
            removeRestaurantCall(itemId, true);
          }}
          onCreateReserveFromCall={(notificationKey: string, itemId: number) => {
            const call = calls && calls.find((i: IRestaurantCall) => i.id === itemId);

            if (call) {
              removeNotification(notificationKey);

              addAppReserve(new Reserve('WIDGET', {
                phone: call.phone,
                firstName: call.guestFirstName,
                lastName: call.guestLastName,
              }));
            }
          }}
          onRemoveReserve={(notificationKey: string, itemId: number) => {
            rejectOpenReserve(itemId);
          }}
          onConfirmReserve={(notificationKey: string, itemId: number) => {
            confirmOpenReserve(itemId);
          }}
          onEditReserve={(notificationKey, reserve: Reserve) => {
            removeNotification(notificationKey);

            addAppReserve(reserve);
          }}
        />
      )}

      <Preloader loading={loading} />
      <SocketErrorScreen />

      <main className={classes.content}>
        {reservationsIsOpen ? <ReservationsPage /> : (
          <>
            <Switch>
              <Route
                exact
                path={Routers.main}
                render={() => {
                  if (hasStatsAccess) {
                    return <Redirect to={Routers.home} />;
                  }

                  if (hasReservesAccess) {
                    return <Redirect to={Routers.booking} />;
                  }

                  return <NotFoundPage />;
                }}
              />

              <Route
                path={Routers.home}
                render={() => {
                  if (hasStatsAccess) {
                    return <HomeCont />;
                  }

                  if (hasReservesAccess) {
                    return <Redirect to={Routers.booking} />;
                  }

                  return <NotFoundPage />;
                }}
              />

              <Route
                path={Routers.booking}
                render={() => {
                  if (hasReservesAccess) {
                    return <BookingCont />;
                  }
                  return <NotFoundPage />;
                }}
              />

              <Route
                path={Routers.reservesSystem}
                render={() => {
                  if (Employees.hasAccess(currentEmployee, 'restaurantSettings:access')) {
                    return <ReservesSystemPage />;
                  }
                  return <NotFoundPage />;
                }}
              />

              <Route
                path={Routers.restaurantScheme}
                render={() => {
                  if (Employees.hasAccess(currentEmployee, 'restaurantSettings:access')) {
                    return <RestaurantSchemePage />;
                  }
                  return <NotFoundPage />;
                }}
              />

              <Route
                path={Routers.statistics}
                render={() => {
                  if (hasStatsAccess) {
                    return <StatisticsCont />;
                  }
                  return <NotFoundPage />;
                }}
              />

              {/* @todo Проверить: могут быть ошибки в UI */}
              <Route
                path={`${Routers.userStatistics}/:phone`}
                render={() => {
                  if (hasStatsAccess) {
                    return UserStatisticCont;
                  }
                  return <NotFoundPage />;
                }}
              />

              {/* @todo Проверить: могут быть ошибки в UI */}
              <Route
                path={`${Routers.sourceStatistics}/:source`}
                render={() => {
                  if (hasStatsAccess) {
                    return SourceStatisticCont;
                  }
                  return <NotFoundPage />;
                }}
              />

              <Route
                path={Routers.employees}
                render={() => {
                  if (Employees.hasAccess(currentEmployee, 'employees:access')) {
                    return <ReservesSystemEmployeesPage />;
                  }
                  return <NotFoundPage />;
                }}
              />

              <Route path={Routers.profile} component={ReservesSystemProfilePage} />
              <Route component={NotFoundPage} />
            </Switch>
            {modalOpen && <AddRestaurantModal />}
            {needAuthFromIiko && modalAuthIikoOpen && <AuthIkkoModalCont />}
          </>
        )}
      </main>
    </div>
  );
}

export default CabinetPage;
