import { AxiosResponse } from 'axios';
import { delay } from 'redux-saga/effects';

// eslint-disable-next-line import/prefer-default-export
export function dataProp<T>(res: AxiosResponse<T>) {
  return res.data;
}

type RequestCall<T, TP> = (args: TP) => Promise<T>;

const interval5minInMs = 300 * 1000;

export function* queryWithRetry<T, P>(
  requestFunction: RequestCall<T, P>,
  params: P,
  retries = 5,
  interval = 3000,
  maxInterval = interval5minInMs,
) {
  let lastError = null;
  let intervalMs = interval;
  for (let i = 1; i <= retries; i += 1) {
    try {
      return yield requestFunction(params);
    } catch (err) {
      lastError = err;

      if (err && err.response) {
        if (String(err.response.status) === '404') throw err;
      }

      const delayMs = Math.round(intervalMs * 1.2);
      intervalMs = delayMs > maxInterval ? maxInterval : delayMs;
      yield delay(intervalMs);
    }
  }

  // Stop failed several attempts
  throw lastError || new Error('request_failed');
}
