import React, { PureComponent } from 'react';
import moment from 'moment';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DayPickerRangeController } from 'react-dates';

import { TDate } from '../../../types/IDate';

type State = {
  startDate: any;
  endDate: any;
  focusedInput: 'startDate' | 'endDate';
}

interface Props {
  handleDate: (date: TDate) => void;
  date?: TDate;
}

class RangeDatePicker extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      startDate: null,
      endDate: null,
      focusedInput: 'startDate',
    };
  }

  componentDidMount() {
    const { date } = this.props;

    if (date) {
      this.setState({
        startDate: moment(date.from),
        endDate: moment(date.to),
      });
    }
  }

  /** @description Изменение даты  */
  onDatesChange = (
    { startDate, endDate }: any,
  ) => {
    const { handleDate } = this.props;

    this.setState({
      startDate,
      endDate,
    });

    if (startDate && endDate) {
      const value: TDate = {
        from: startDate.format(),
        to: endDate.endOf('day').format(),
      };

      handleDate(value);
    }
  };

  render() {
    const { startDate, endDate, focusedInput } = this.state;

    return (
      <DayPickerRangeController
        startDate={startDate}
        endDate={endDate}
        onDatesChange={this.onDatesChange}
        focusedInput={focusedInput}
        onFocusChange={focusedInput => this.setState({
          focusedInput: focusedInput || 'startDate',
        })}
        noBorder
        hideKeyboardShortcutsPanel
        numberOfMonths={2}
      />
    );
  }
}

export default RangeDatePicker;
