import React, { Fragment } from 'react';
import wrapWithPath from './wrapSvgPath';

const TrashIcon = (
  <Fragment>
    <g id="trash-filled" opacity="0.7" transform="translate(-5.5)">
      <path
        style={{ fill: '#323232' }}
        d="M21.432 2.034h-4.76a2.694 2.694 0 0 0-.615-1.6A1.434 1.434 0 0 0 14.991 0H11.6a1.434 1.434 0 0 0-1.066.437 2.694 2.694 0 0 0-.615 1.6h-4.08a.339.339 0 1 0 0 .678h15.593a.339.339 0 1 0 0-.678zm-10.829 0a2 2 0 0 1 .42-1.125.767.767 0 0 1 .577-.231h3.389a.768.768 0 0 1 .579.231 2 2 0 0 1 .42 1.125z"
      />
      <path
        style={{ fill: '#323232' }}
        d="M9.245 24.922a1.645 1.645 0 0 0 1.664 1.688h9.584a1.643 1.643 0 0 0 1.663-1.681L22.777 10H8.624zm2.388-4.075h8.136a.339.339 0 1 1 0 .678h-8.136a.339.339 0 0 1 0-.678zm0 1.695h8.136a.339.339 0 1 1 0 .678h-8.136a.339.339 0 0 1 0-.678zm0 1.695h8.136a.339.339 0 1 1 0 .678h-8.136a.339.339 0 1 1 0-.678z"
        transform="translate(-2.065 -6.61)"
      />
    </g>
  </Fragment>
);

export default wrapWithPath(TrashIcon, '-2 -2 22 24');
