import React from 'react';

import {
  Divider,
  Box,
  makeStyles,
  Theme,
  Switch,
} from '@material-ui/core';


const useStyles = makeStyles((theme: Theme) => {
  const spacing2 = theme.spacing(2);
  const spacing3 = theme.spacing(3);

  return {
    role: {
      padding: `${spacing2}px ${spacing3}px`,
    },

    title: {
      fontWeight: 500,
      fontSize: 16,
      marginBottom: spacing2,
    },

    list: {
      fontSize: 12,
      marginBottom: spacing3,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },

    passiveList: {
      color: '#aaaaaa',
    },

    error: {
      color: theme.palette.error.main,
      fontSize: 12,
    },
  };
});

const RoleDescriptionComponent: React.FC<{
  title: string;
  list: Array<string>;
  isDivider?: boolean;
  isReadonly?: boolean;
  isDisabled?: boolean;
  isOn?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  error?: string;
}> = ({
  title,
  list,
  isDivider,
  isReadonly,
  isDisabled,
  isOn,
  onChange,
  error,
}) => {
  const classes = useStyles();

  const listClassName = [classes.list];
  if (isReadonly) {
    listClassName.push(classes.passiveList);
  }

  return (
    <>
      <Box className={classes.role}>
        <Box className={classes.title}>
          {title}
        </Box>

        <Box className={listClassName.join(' ')}>
          <Box>
            {list && list.map(item => (
              <div key={item}>
                {item}
              </div>
            ))}
          </Box>
          <Box>
            {!isReadonly && (
              <Switch
                onChange={onChange}
                checked={isOn}
                color="primary"
                disabled={isDisabled}
              />
            )}
          </Box>
        </Box>

        {error && (
          <Box className={classes.error}>{error}</Box>
        )}
      </Box>

      {isDivider && <Divider />}
    </>
  );
};

export default RoleDescriptionComponent;
