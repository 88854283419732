import React, { Component } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';

import { Routers } from '../../config/constants';
import { getSession } from '../../actions/auth/AuthActions';
import Preloader from './Preloader';

class ProtectedRoute extends Component {
  componentDidMount() {
    const { authorized, getSession } = this.props;
    if (!authorized) {
      getSession();
    }
  }

  renderProtected = (routeProps) => {
    const { component: ProtectedComponent, authorized } = this.props;
    return authorized ? <ProtectedComponent {...routeProps} /> : <Redirect to={Routers.login} />;
  };

  render() {
    const { getSessionProcess, component, ...rest } = this.props;

    if (getSessionProcess) {
      return <Preloader loading />;
    }

    return <Route {...rest} render={this.renderProtected} />;
  }
}

function mapStateToProps(state) {
  return {
    authorized: state.auth.authorized,
    getSessionProcess: state.auth.getSessionProcess,
  };
}

export default connect(
  mapStateToProps,
  { getSession },
  null,
  { pure: false },
)(ProtectedRoute);
