import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';

import { IAppActions } from '../../types/IAppActions';
import { IDispatchProps, IStateProps } from './IReservesSystemSettings';
import { IAppStore } from '../../types/IAppStore';
import {
  addNewScheduleRow,
  deleteScheduleRow,
  getScheduleList,
  updateFreeDays,
  updateSchedule,
} from '../../actions/schedules/SchedulesAction';
import { switchBookingProcessor } from '../../actions/home/HomeActions';
import { updateRestaurantInfo } from '../../actions/restaurantsInfo/RestaurantsInfoActions';
import { switchReserveSchedule } from '../../actions/restaurantsSettings/RestaurantsSettingsActions';
import ReservesSystemSettingsCont from './ReservesSystemSettingsCont';
import { freeDaysSelectors, rowsSelector } from './selectors';

function mapStateToProps(state: IAppStore): IStateProps {
  return {
    isActiveRestaurantSchedule: state.reservesSystemSettings.isActiveRestaurantSchedule,
    currentRestaurant: state.cabinet.currentRestaurant,
    scheduleList: rowsSelector(state),
    freeDays: freeDaysSelectors(state),
    restaurantWorkTimes: state.schedule.restaurantWorkTimes,
  };
}

function mapDispatchToProps(dispatch: Dispatch<IAppActions>): IDispatchProps {
  return bindActionCreators({
    getScheduleList,
    switchBookingProcessor,
    updateRestaurantInfo,
    addNewScheduleRow,
    updateSchedule,
    deleteScheduleRow,
    updateFreeDays,
    switchReserveSchedule,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ReservesSystemSettingsCont);
