import { createStyles } from '@material-ui/core';

export default createStyles({
  paper: {
    width: 370,
    height: 310,
  },
  paperEdit: {
    width: 630,
    height: 540,
  },
  desc: {
    margin: 0,
  },
  textFields: {
    flexGrow: 1,
  },
  textFiledGroup: {
    color: '#323232',
  },
  textFieldsGroup: {
    paddingBottom: 44,
  },
  textFieldsGroupName: {
    paddingTop: 15,
  },
  textFieldsGroupColors: {
    paddingBottom: 34,
  },
  textField: {
    paddingTop: 24,
    paddingBottom: 24,
  },
  colorField: {
    width: 120,
  },
  colorFieldIcon: {
    padding: 0,
  },
  dialogActionsWrap: {
    padding: '16px 24px',
  },
  btnWrap: {
    flexBasis: '50%',
    height: 36,
    '&:not(:first-child)': {
      marginLeft: 12,
    },
    '&:not(:last-child)': {
      marginRight: 12,
    },
  },
});
